import React, { Fragment } from "react";

import { useSalesOrderHookNae } from '../../../../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { PropsId } from "../../../../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from '../../../../../../_generated/_custom/config/NaeSSchema';
import { useConvert } from "@newageerp/v3.bundles.hooks-bundle";
import { MenuItem } from "@newageerp/v3.bundles.modal-bundle";

export default function CreditInvoice(props: PropsId) {
  const t = (s: string) => s;
  const element = useSalesOrderHookNae(props.id);
  const [doConvert] = useConvert({
    from : "sales-order",
    to: NaeSSchemaMap.InvoiceOutgoingCreditRequest.schema,
    id: props.id,
    inPopup: true,
  })


  if (!element) {
    return <Fragment />;
  }

  let disabledCreditInvoice = false;
  let disableCreditInvoiceText = "";

  if (element.invoicesCount === 0) {
    disabledCreditInvoice = true;
    disableCreditInvoiceText =
      "negalima išrašyti kreditinės, kol nėra išrašyta sąskaita";
  }

  return (
    <MenuItem
      isDisabled={disabledCreditInvoice}
      tooltipContent={disableCreditInvoiceText}
      onClick={doConvert}
      iconName={"rotate-left"}
    >
      {t("Issue a credit invoice")}
    </MenuItem>
  );
}
