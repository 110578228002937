import React, { Fragment } from "react";

import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorCargoNae } from "../../../_generated/_custom/models/ormSelectors";
import { ICargoModelNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle'
import { MenuItem } from "@newageerp/v3.bundles.modal-bundle";

export default function ManagerSalesOrderAddressChange(props: PropsId) {
  const element = useSalesOrderHookNae(props.id);

  const elementCargos = element ? element.cargos.map((el) => el.id) : [-1];
  // @ts-ignore
  const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

  const showAddressChangePopup = () => {
    SFSOpenEditModalWindowProps({
      id: props.id,
      schema: "sales-order",
      type: "address-change",
    });
  };

  const t = (s: string) => s;

  let disableEdit = false;

  if (!element) {
    return <Fragment />;
  }
  if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
    return <Fragment />;
  }
  if (element.status <= 0 || element.status === 20 || element.status === 900) {
    disableEdit = true;
  }

  const hasPoKz = cargos.filter((e: ICargoModelNae) => !!e.kzCarriersOrder).length > 0;
  if (!(element.isNeedTerminal && !hasPoKz)) {
    disableEdit = true;
  }

  return (
    <MenuItem
      iconName={"edit"}
      onClick={showAddressChangePopup}
      isDisabled={disableEdit}
    >
      {t("Update unload address")}
    </MenuItem>
  );

}
