import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import OldBadge, { BadgeBgColor } from '../../../../core/old-ui/OldBadge';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoStatus7Badge(props: PropsId) {
    const t = (s: string) => s;
    
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (element.status !== 7) {
        return <Fragment />
    }
    return (
        <StatusWidget color='red' smallPadding={true}>
            {t('Returned')}
        </StatusWidget>
    )
}
