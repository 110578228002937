import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { TextCardTitle, TextToolbarTitle } from '@newageerp/v3.bundles.typography-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import { TemplatesLoader } from '@newageerp/v3.templates.templates-core'
import React, { Fragment, useState } from 'react'

import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { useInvoiceIncomingSepaExportHookNae } from '../../_generated/_custom/hooks/useInvoiceIncomingSepaExportHookNae';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';

type Props = {
    id: number
}

export default function SepaExportInvoices(props: Props) {
    const t = (s: string) => s;
    const [popupVisible, setPopupVisible] = useState(false);
    const element = useInvoiceIncomingSepaExportHookNae(props.id);

    const [doReq, doReqParams] = useURequest(NaePathsMap.post.AppPluginsSepaExportAddInvoice);

    const onSelect = (id: number) => {
        doReq({
            id,
            exportId: props.id
        })
    }

    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>


            <WhiteCard isCompact={true} key={`exp-${element.updatedAt}`}>
                <div className='flex items-center gap-4'>
                    <TextCardTitle>{t('Invoices')}</TextCardTitle>
                    <div>
                        <ToolbarButton
                            iconName='plus'
                            onClick={() => setPopupVisible(true)}
                            loading={doReqParams.loading}
                        />
                    </div>
                </div>
                <TemplatesLoader
                    templateName='SepaExportInvoicesTpl'
                    data={props}
                />
            </WhiteCard>

            {popupVisible && (
                <TemplatesLoader
                    templateName="PageInlineList"
                    data={{
                        schema: NaeSSchemaMap.InvoiceIncoming.schema,
                        type: "main",
                        isPopup: true,
                        addSelectButton: true,
                        addToolbar: true,
                        extraFilters: [{
                            "and": [
                                ['i.status', '=', 0, true]
                            ]
                        }]
                    }}
                    templateData={{
                        onBack: () => {
                            setPopupVisible(false);
                        },
                        onAddSelectButton: (el: any) => {
                            onSelect(el.id);
                        },
                    }}
                />
            )}
        </Fragment>
    )
}
