import React, { Fragment } from 'react'

import CargoLdmCalculationType from '../../../_generated/_custom/models-cache-data/Cargo/CargoLdmCalculationType';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';

export default function CargoLdmCalculationTypeWithTooltip(props: PropsId) {
    const t = (s: string) => s;
    const element = useCargoHookNae(props.id);

    if (!element) return <Fragment />

    if (element.ldm === 0) {
        return <Fragment />
    }

    let hint = '';
    if (element.ldmCalculationType === 1) {
        hint = calculationType1;
    } else if (element.ldmCalculationType === 2) {
        hint = calculationType2;
    } else if (element.ldmCalculationType === 3) {
        hint = calculationType3;
    } else if (element.ldmCalculationType === 4) {
        hint = calculationType4;
    } else if (element.ldmCalculationType === 5) {
        hint = calculationType5;
    }
    return (
        <Fragment>
            <Tooltip text={hint} />
        </Fragment>
    )
}

const calculationType1 = `<div>
    <h5>Schema 1 (Standartiniai kroviniai)</h5>
    <div>К стандартным видам мы относим те грузы, которые покупатель требует везти «как есть».
    Иными словами – <strong>груз нельзя распалетить/перепалетить, нельзя ничего грузить на него
    и при погрузке «по полу» он занимает ровно столько места, сколько должен</strong>.</div>
    <div><strong>Нормативы для него таковы</strong>: на одно погрузочное место (евро паллет место)
     объём груза должен быть не более 2.4 кубических метров, а вес брутто – не более 660 килограмм.</div>
    <div><strong>Алкоголь, краски/лаки/химикаты/ароматизаторы (в канистрах и бочонках)</strong>
    мы считаем всегда по первой схеме, но по 800 килограмм на паллет место. Иными словами, если у Вас получается,
    что груз весит меньше, чем 800 килограмм на паллет место, то и посчитан он будет также по килограммам (т.е. CLM будет меньше фактического количества паллет).
    Также и в обратном случае – если на паллету приходится 900 килограмм, например, то окончательный CLM будет больше, чем количество паллет.</div>
</div>`

const calculationType2 = `<div>
    <h5>Schema 2 (Lengvi monolitiniai kroviniai)</h5>
    <div>К данной схеме относятся грузы, которые также нельзя распалетить/перепалетить и ничего на них грузить нельзя. <strong>Однако, в отличие от стандартных грузов, вес и объём на каждое паллет место значительно ниже</strong>, что даёт нам возможность поставить их вторым ярусом на какие-то другие грузы.</div>
    
    <div><strong>Есть 5 категорий лёгких монолитных грузов, по которым CLM высчитывается разным способом</strong>:</div>
    <ul>
    <li>Лёгкие монолитные грузы высотой до 80 сантиметров</li>
    <li>Лёгкие монолитные грузы высотой от 81 до 120 сантиметров</li>
    <li>Лёгкие монолитные грузы высотой от 121 до 140 сантиметров</li>
    <li>Лёгкие монолитные грузы высотой от 141 до 150 сантиметров</li>
    <li>Лёгкие монолитные грузы в Бишкек высотой от 151 сантиметра</li>
    </ul>
    
    <div>Последняя категория исключительна для Бишкека в силу того, что туда у нас есть большой поток тяжелых грузов и для лучше заполняемости машин нам нужно больше лёгких грузов.</div>
    
    <div><strong>Норматив по весу для всех категорий</strong>: на одно евро паллет место вес должен быть не более 250 килограмм</div>
</div>`

const calculationType3 = `<div>
    <h5>Schema 3 (Perpaletavimas)</h5>
    <div><strong>Это грузы, которые состоят из коробок</strong> (могут быть как россыпью, так и упакованы в паллеты). Если они упакованы на паллеты, то <strong>у нас должна быть возможность эти паллеты либо распаковать, либо перепаковать</strong> в паллеты иного размера (например, из одной паллеты высотой 240см сделать две паллеты высотой 120см каждая). </div>

    <div><strong>Есть две категории грузов, подпадающих под перепалечивания</strong>:</div>
    <ul>
    <li>Вес на 1 кубический метр более 200 килограмм, но менее 400 килограмм</li>
    <li>Вес на 1 кубический метр более 400 килограмм, но менее 550 килограмм</li>
    </ul>
    
    <div><strong>Норматив по размеру коробок, из которых состоит груз</strong>: одна отдельная коробка должна быть объёмом не более 0.8 кубических метра.</strong></div>
    </div>
</div>`

const calculationType4 = `<div>
    <h5>Schema 4 (Labai lengvų krovinių perpaletavimas)</h5>
    <div><strong>Это грузы, которые состоят из коробок</strong> (могут быть как россыпью, так и упакованы в паллеты). Если они упакованы на паллеты, то <strong>у нас должна быть возможность эти паллеты либо распаковать, либо перепаковать</strong> в паллеты иного размера (например, из одной паллеты высотой 240см сделать две паллеты высотой 120см каждая).</div>
    <div>Главное отличие от предыдущей схемы – <strong>вес на каждый кубический метр груза должен быть не более 200 килограмм, а объём отдельных коробок не более 0.3 кубических метра каждая.</strong></div>

    <div><strong>Есть две категории лёгких перепалечиваемых грузов</strong>:</div>
    <ul>
    <li>Грузы, общим объёмом не более 40 кубических метров</li>
    <li>Грузы, общим объёмом от 40.1 до 60 кубических метров</li>
    </ul>
    
    <div><strong>Грузы более 60 кубических метров ни под какую схему не подпадают и считаются как стандартный груз.</strong></div>
</div>`

const calculationType5 = `<div>
    <h5>Schema 5 (Sunkus kroviniai)</h5>
    <div>Это грузы, которые <strong>при небольшом объёме имеют большой вес</strong>, а также могут быть со штабелированы между собой, либо же иной груз может быть погружен на них.</div>

    <div><strong>Есть три категории тяжёлых грузов для тентов</strong>:</div>
    <ul>
    <li>Общий вес груза менее 12100 килограмм</li>
    <li>Общий вес груза более 12100 килограмм, но менее 16900 килограмм</li>
    </ul>
    
    
    <div><strong>Есть две категории тяжёлых грузов для рефов</strong>:</div>
    <ul>
    <li>Общий вес груза менее 10800 килограмм</li>
    <li>Общий вес груза более 10800 килограмм, но менее 14100 килограмм</li>
    </ul>
    
    <div><strong>Норматив по объёму груза для всех категорий</strong>: на каждое евро паллет место, после просчёта груза по любой из вышеупомянутых категорий, объём груза не должен превышать 2 кубических метров.</div>
    
    <div><strong>Если на каждое евро паллет место после просчёта приходится более, чем 2 кубических метра груза, то тогда общий CLM считается от объёма (общий объём / 2 = CLM).</strong></div>
</div>`