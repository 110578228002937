import React, { Fragment } from "react";
import moment from "moment";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin } from "../../../_generated/_custom/config/NaeSPermissions";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

interface Props extends PropsId {
  transparent?: boolean,
  element?: IData,
}

interface IData {
  kzCarriersOrder: {
    id: number,
  },
  activePoOrders: IDataPo[],
  loadDateSeller: string,
  poLoadDateStr: string,
  needTerminal: boolean,
  needLoadDateConfirm: boolean,
  expeditor: {
    id: number,
  },
  status: number,
}

interface IDataPo {
  id: number,
}

const defObject: IData = {
  kzCarriersOrder: {
    id: 0,
  },
  activePoOrders: [
    {
      id: 0,
    }
  ],
  loadDateSeller: '',
  poLoadDateStr: '',
  needTerminal: false,
  needLoadDateConfirm: false,
  expeditor: {
    id: 0,
  },
  status: 0,
}

export default function CargoNeedLoadDateConfirm(props: Props) {
  const element = useCargoHookNae(props.id);

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  const t = (s: string) => s;

  const [saveData] = useUSave("cargo");
  const doSave = (data: any) => {
    saveData(data, element.id);
  }

  if (!element) {
    return <Fragment />
  }
  const isLogistic = (!!element.expeditor && userState.id === element.expeditor.id) || isAdmin;
  if (!(element.needLoadDateConfirm && isLogistic && element.status === 5)) {
    return <Fragment />
  }
  const kzCarriersOrder = element.kzCarriersOrder;
  const carriersOrders = element.activePoOrders;


  const onReturn = () => {
    const text = `Actual ready date ${moment(
      element.loadDateSeller
    ).format("YYYY-MM-DD")} - loading date ${element.poLoadDateStr}`;

    doSave({
      status: 7,
      loadingInfoReturnText: text,
      loadDateDiffManagerNeedConfirm: true,
      addNote: {
        content: text,
        notify: [element.creator.id],
      },
    });
  };

  return (
    <WhiteCard isCompact={true} className={(props.transparent ? "bg-transparent" : "")} title={t('Loading date')}>


      <div className={"text-red-500"}>
        {t('The loading date is very different from the cargo preparation date.')}
      </div>


      <div className={"flex gap-2"}>

        <MainButton color="blue" onClick={onReturn} className="w-full">
          {t('Send for reconciliation?')}
        </MainButton>

        <MainButton
          color="sky"
          className="w-full"
          onClick={() =>
            element.needTerminal
              ? SFSOpenEditModalWindowProps(
                {
                  id: carriersOrders[0].id, schema: NaeSSchemaMap.CarriersOrder.schema
                }
              )
              : SFSOpenEditModalWindowProps(
                {
                  id: kzCarriersOrder.id, schema: NaeSSchemaMap.KzCarriersOrder.schema
                }
              )
          }
        >
          {t('Update order')}
        </MainButton>

      </div>

    </WhiteCard>
  );
}
