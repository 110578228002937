import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import OldBadge, { BadgeBgColor } from '../../../../core/old-ui/OldBadge';

export default function CargoTypeInFtlBadge(props: PropsId) {
    const t = (s: string) => s;
    
    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    if (element.typeIn !== 'ftl') {
        return <Fragment />
    }
    return (
        <OldBadge size={"sm"} bgColor={BadgeBgColor.nsecondary}>
            {t('FTL')}
        </OldBadge>
    )
}
