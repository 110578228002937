import React, { useState, Fragment } from 'react'

import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
// import { Menu, MenuItem } from "@szhsin/react-menu";
// import "@szhsin/react-menu/dist/index.css";
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { useTerminalHookNae } from '../../_generated/_custom/hooks/useTerminalHookNae';
import { getLinkForFile } from '@newageerp/v3.bundles.data-bundle';

const langs: any = {
    en: "English",
    lt: "Lietuviškai",
    ru: "По-русски",
    pl: "Po polsku",
};

interface Props {
    cargoId?: number,
    id: number
}

export default function SenderAttorneyWidget(props: Props) {
    const element = useCargoHookNae(props.cargoId ? props.cargoId : -1);
    const terminal = useTerminalHookNae(element && element.terminal ? element.terminal.id : -1);

    const [isPopup, setIsPopup] = useState(false);

    const t = (s: string) => s;

    const openEmail = (lang: string) => {
        const files = [

        ];
        if (terminal && terminal.attorneyFile) {
            files.push(
                {
                    name: terminal.attorneyFile.filename,
                    link: getLinkForFile(
                        terminal.attorneyFile
                    ),
                }
            );
        }

        const options = {
            extraData: {
                id: props.id,
                schema: NaeSSchemaMap.Sender.schema,
                template: "attorney",
                lang: lang,
                cargoId: props.id,
            },
            files: files
        }
        const event = new CustomEvent(
            'SFSOpenEmailForm',
            {
                detail: options
            }
        );
        window.dispatchEvent(event);
        setIsPopup(false);
    }


    return (
        <Fragment>
            <MenuItem onClick={() => setIsPopup(true)} iconName="paper-plane" className="whitespace-nowrap">
                {t("Letter of authorization")}
            </MenuItem>
            {isPopup &&
                <Popup title={t('Letter of authorization')} isPopup={true} onClick={() => setIsPopup(false)}>
                    <div className="space-y-2">
                        {Object.keys(langs).map((_lang: string) => (
                            <MainButton
                                key={"lang-" + _lang}
                                color="white"
                                className="w-[350px]"
                                onClick={() => {
                                    openEmail(_lang);
                                }}
                            >
                                {langs[_lang]}
                            </MainButton>
                        ))}
                    </div>
                </Popup>
            }
        </Fragment>
    )
}
