import { TabContainer } from '@newageerp/v3.bundles.layout-bundle';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { ChildFullWindow, useFullWindowSize, ChildFullWindowSize } from '@newageerp/v3.bundles.layout-bundle';
import { TemplatesLoader, toast } from '@newageerp/v3.templates.templates-core';
import React, { Fragment, useState } from 'react'

import TableCargos from '../../../components/kz-carriers-order/Components/Widget/TableCargos/TableCargos';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import PlannedCargos from '../../../components/kz-carriers-order/Components/Widget/PlannedCargos';
import classNames from 'classnames';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';

export default function KzPlanningV3() {
    const fWindowSize = useFullWindowSize();

    const [isPopup, setIsPopup] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState<number[]>([]);

    const [fullScreenPlanning, setFullScreenPlanning] = useState(false);

    const addOrder = (id: number) => {
        const _orders = JSON.parse(JSON.stringify(selectedOrders));
        _orders.push(id);
        setSelectedOrders(_orders);
        toast.success(`${t('Added')}`);
    }
    const removeOrder = (id: number) => {
        const _orders: number[] = JSON.parse(JSON.stringify(selectedOrders));
        setSelectedOrders(_orders.filter(o => o !== id));
    }

    const createNew = () => {
        SFSOpenEditModalWindowProps(
            {
                id: "new",
                schema: NaeSSchemaMap.KzCarriersOrder.schema,
                onSaveCallback(el, backFunc) {
                    addOrder(el.id);
                    backFunc();
                },
            }
        )
    }

    const t = (s: string) => s;

    const items = selectedOrders.map((oId) => {
        return ({
            content: <KzOrderTab
                id={oId}
                fullScreenPlanning={fullScreenPlanning}
                selectedOrders={selectedOrders}
            />,
            tab: {
                children: <Fragment>
                    <div className='flex gap-2 items-center'>
                        <DfValueView id={oId} path={"kz-carriers-order.serialNumber"} />
                        <ToolbarButton iconName='trash' onClick={() => removeOrder(oId)} />
                    </div>
                </Fragment>
            },
        })
    });

    return (
        <ChildFullWindow>
            <div style={{ height: ChildFullWindowSize.height, width: fWindowSize.width }} className={"px-2 relative"}>
                <div className={
                    classNames("w-full space-y-2")}
                >

                    <TabContainer
                        items={items.length > 0 ? items : [{
                            content: <Fragment />,
                            tab: {
                                children: <Fragment></Fragment>
                            }
                        }]}
                        title={
                            {
                                text: <div className='inline-block'>
                                    <button
                                        className='inline-block text-blue-500 underline hover:no-underline mr-4'
                                        // iconName='add'
                                        onClick={() => setIsPopup(true)}
                                    >
                                        {t('Add')}
                                    </button>

                                    <button
                                        className='inline-block text-teal-500 underline hover:no-underline'
                                        // iconName='add'
                                        onClick={createNew}
                                    >
                                        {t('Create')}
                                    </button>
                                </div>
                            }
                        }
                    />

                </div>

                <div className='fixed bottom-0 left-[240px] right-0 border-t border-slate-500'>
                    <div
                        className='w-full overflow-y-scroll'
                        style={{ maxHeight: `calc(${ChildFullWindowSize.height} / 2)` }}
                    >
                        <PlannedCargos
                            ids={selectedOrders}
                            onSave={() => {

                            }}
                            hideContent={!fullScreenPlanning}
                            contentBeforeTitle={
                                <ToolbarButton
                                    iconName={fullScreenPlanning ? 'minimize' : 'maximize'}
                                    onClick={() => {
                                        setFullScreenPlanning(!fullScreenPlanning)
                                    }}

                                />
                            }
                        />
                    </div>
                </div>
            </div>

            {isPopup &&
                <TemplatesLoader
                    templateName="PageInlineList"
                    data={
                        {
                            schema: NaeSSchemaMap.KzCarriersOrder.schema,
                            type: "main",
                            isPopup: true,
                            extraFilters: [{
                                "and": [
                                    ["i.status", "in", [0, 10, 15], true]
                                ]
                            }],
                            addSelectButton: true,
                            addToolbar: true,
                        }}
                    templateData={{
                        onBack: () => {
                            setIsPopup(false)
                        },
                        onAddSelectButton: (el: any) => {
                            addOrder(el.id);
                        }
                    }}
                />

            }
        </ChildFullWindow>
    )
}

type KzOrderTabProps = {
    fullScreenPlanning: boolean,
    selectedOrders: number[],

} & PropsId;


const KzOrderTab = (props: KzOrderTabProps) => {
    const { fullScreenPlanning, selectedOrders } = props;

    const kzOrder = useKzCarriersOrderHookNae(props.id);
    if (!kzOrder) {
        return <Fragment />
    }
    return (
        <div
            className={
                classNames(
                    { 'overflow-y-scroll': fullScreenPlanning },
                    'pb-20',
                    'my-[-16px]'
                )
            }
            key={`${kzOrder.id}-${kzOrder.updatedAt}`}
            style={fullScreenPlanning ? { maxHeight: `calc((${ChildFullWindowSize.height} - 80px) / 2)` } : {}}
        >
            <TableCargos id={props.id} kzIds={selectedOrders.filter(o => o !== props.id)} />
        </div>
    )
}