import React, { Fragment } from 'react'

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { ICargoModelNae, useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import CargoSalesOrderClientOrFilialName from '../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName';
import { ISalesOrderModelNae, useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { ICalculationModelNae, useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import {
    checkUserOperational,
    checkUserOperationalPro,
    checkUserManager,
    checkUserHeadOfLogisticsHelper,
    checkUserLogistic,
    checkUserLogisticFtl,
    checkUserLogisticSea,
    checkUserLogisticTrain,
    checkUserAdmin,
    checkUserHeadOfLogistics,
} from '../../../_generated/_custom/config/NaeSPermissions';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';

export default function CargoUnloadBlock(props: PropsId) {
    const { userState } = useTemplatesCore()

    const isOperationsDepartmentAdministrator = checkUserHeadOfLogisticsHelper(userState);
    const isSea = checkUserLogisticSea(userState);
    const isTrain = checkUserLogisticTrain(userState);
    const isLtl = checkUserLogistic(userState);
    const isFtl = checkUserLogisticFtl(userState);
    const isHeadOfDocumentProcessingDepartment = checkUserOperationalPro(userState);
    const isDocumentProcessingManager = checkUserOperational(userState);

    const t = (s: string) => s;
    const element = useCargoHookNae(props.id);
    const salesOrder = useSalesOrderHookNae(element && element.salesOrder ? element.salesOrder.id : -1);
    const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);

    if (!element) {
        return <Fragment />
    }
    const isCreator = userState.id === element.creator.id;

    return (
        <Fragment>

            {!(isLtl || isFtl || isHeadOfDocumentProcessingDepartment || isOperationsDepartmentAdministrator) &&
                <ClientBlock
                    salesOrder={salesOrder}
                    calculation={calculation}
                    cargo={element}
                />
            }

            {!isFtl &&
                <UnloadAddressBlock
                    salesOrder={salesOrder}
                    calculation={calculation}
                    cargo={element}
                />
            }

            {!(isLtl || isFtl || isHeadOfDocumentProcessingDepartment) &&
                <UnloadResponsiblePersonBlock salesOrder={salesOrder} />
            }

        </Fragment>
    )
}

const ClientBlock = (props: {
    calculation: ICalculationModelNae,
    salesOrder: ISalesOrderModelNae,
    cargo: ICargoModelNae
}) => {
    const { userState } = useTemplatesCore()
    const isSalesManager = checkUserManager(userState);
    const isDocumentProcessingManager = checkUserOperational(userState);

    const isMom = checkUserLogisticSea(userState);
    const isAdmin = checkUserAdmin(userState);
    const isHeadOfLogistics = checkUserHeadOfLogistics(userState);

    const isShowClientAddress = isMom || isAdmin || isHeadOfLogistics;

    const t = (s: string) => s;

    const { calculation, salesOrder } = props;
    const element = props.cargo;

    const contactId = salesOrder && salesOrder.contact ? salesOrder.contact.id : (calculation && calculation.contact ? calculation.contact.id : -1);
    const clientId = salesOrder && salesOrder.client ? salesOrder.client.id : (calculation && calculation.client ? calculation.client.id : -1);

    const clientNameInner = <div className='flex'>
        <CargoSalesOrderClientOrFilialName id={element.id} /> (<DfValueView path="country.nameLt" id={element.clientCountryId} />)
    </div>

    return (
        <Fragment>
            <div>
                <FieldLabel>{t('Client')}</FieldLabel>
                {clientId > 0 && (
                    <p>
                        {isSalesManager || isDocumentProcessingManager ? (
                            <Fragment>
                                <RsButton
                                    id={clientId}
                                    schema={NaeSSchemaMap.Client.schema}
                                    defaultClick='modal'
                                    button={{
                                        children: clientNameInner,
                                        color: "white",
                                        skipPadding: true,
                                    }}
                                />
                            </Fragment>
                        ) : clientNameInner}
                    </p>
                )}
            </div>

            {isShowClientAddress && clientId > 0 && <div>
                <FieldLabel>{t('Address')}</FieldLabel>

                <DfValueView id={clientId} path='client.address' />

            </div>}

            {contactId > -1 && (
                <div>
                    <FieldLabel>{t('Contact person')}</FieldLabel>

                    <DfValueView path="contact.fullName" id={contactId} />

                    <DfValueView path="contact.phones" id={contactId} />

                    <DfValueView path="contact.emails" id={contactId} />

                </div>
            )}
        </Fragment>
    )
}

const UnloadAddressBlock = (props: {
    calculation: ICalculationModelNae,
    salesOrder: ISalesOrderModelNae,
    cargo: ICargoModelNae
}) => {
    const t = (s: string) => s;

    const element = props.cargo;
    const { salesOrder, calculation } = props;

    const unloadAddressId = salesOrder && salesOrder.address ? salesOrder.address.id : (calculation && calculation.address ? calculation.address.id : -1);


    return (
        <Fragment>
            {unloadAddressId > 0 && (
                <div>
                    <FieldLabel>{t("Unload address")}:</FieldLabel>
                    <DfValueView path="unload-address.fullAddress" id={unloadAddressId} />
                </div>
            )}
            {!!element.extraServiceDeliveryClientAddress && (
                <div>
                    <FieldLabel>{t("Delivery to the customer")}:</FieldLabel>
                    <p>{element.extraServiceDeliveryClientAddress}</p>
                </div>
            )}
        </Fragment>
    )
}


const UnloadResponsiblePersonBlock = (props: {
    salesOrder: ISalesOrderModelNae
}) => {
    if (!props.salesOrder || !props.salesOrder.unloadResponsiblePerson) {
        return <Fragment />
    }
    return (
        <div>
            <FieldLabel>Responsible for unloading:</FieldLabel>
            <DfValueView path="contact.fullName" id={props.salesOrder.unloadResponsiblePerson.id} />
        </div>
    )
}