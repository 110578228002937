import React from 'react'
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { Popup } from '@newageerp/v3.bundles.popup-bundle'

import { TemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface Props {
    orders: PropsId[],
    onClose: () => void,
}

export default function ManagerOrdersPopup(props: Props) {
    const t = (s: string) => s;

    return (
        <Popup onClick={props.onClose} title={""} isPopup={true}>
            <TemplatesLoader
                templateName="PageInlineList"
                data={{
                    schema: "sales-order",
                    type: "numbers-widget-popup",
                    extraFilters: [{
                        "and": [
                            ['i.id', 'in', props.orders, true]
                        ]
                    }]
                }}
            />
        </Popup>
    )
}
