import React, { Fragment, useEffect } from "react";


import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import OldTbody from "../../../core/old-ui/OldTbody";
import { defaultStrippedRowClassName } from "../../../core/old-ui/OldTrow";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";


interface IGroupedData {
  [key: string]: ICalculationData[];
}

interface ICalculationData {
  expeditor: {
    fullName: string;
  };
  expeditorCalculation: {
    fullName: string;
  };
  id: number;
  calculationPoints: number;
  onRoadPoints: number;
}

interface IPoints {
  [key: string]: number;
}

const defObject: ICalculationData = {
  expeditor: {
    fullName: "",
  },
  expeditorCalculation: {
    fullName: "",
  },
  id: 0,
  calculationPoints: 0,
  onRoadPoints: 0,
};

let reloadTimeout: any = -1;

export default function AdminLogisticCalculation() {
  const t = (s: string) => s;

  const [getData, getDataParams] = useUList<ICalculationData>(
    "cargo",
    getKeysFromObject(defObject)
  );

  const loadData = () => {
    getData(
      [
        {
          and: [
            ["i.status", "!=", 900, true],
            {
              or: [
                {
                  and: [
                    ["i.expeditorCalculation", ">", 0, true],
                    ["i.calculation.status", ">=", 5, true],
                    ["i.loadingPriceCalculated", "=", false, true],
                  ],
                },
                {
                  and: [
                    ["i.expeditor", ">", 0, true],
                    ["i.calculatedStatus", ">=", 10, true],
                    ["i.calculatedStatus", "<", 30, true],
                  ],
                },
              ],
            },
          ],
        },
      ],
      1,
      1000,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        },
      ]
    );
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 1500);
  };

  useEffect(() => {
    SocketService.subscribe("calc-cargo-dashboard-all");
    return () => {
      SocketService.unsubscribe("calc-cargo-dashboard-all");
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "calc-cargo-dashboard-all",
      "calc-cargo-dashboard-all",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "calc-cargo-dashboard-all",
        "calc-cargo-dashboard-all"
      );
    };
  }, []);

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;

  const data: ICalculationData[] = isData ? getDataParams.data.data : [];

  const points: IPoints = {};
  data.forEach((el: ICalculationData) => {
    const k = el.expeditor ? el.expeditor.fullName : "-";
    const k2 = el.expeditorCalculation ? el.expeditorCalculation.fullName : "-";
    points[k] = 0;
    points[k2] = 0;
  });

  data.forEach((el: ICalculationData) => {
    const k = el.expeditor ? el.expeditor.fullName : "-";
    const k2 = el.expeditorCalculation ? el.expeditorCalculation.fullName : "-";
    points[k] += el.onRoadPoints;
    points[k2] += el.calculationPoints;
  });

  return (
    <div className="space-y-2">
      <TextCardTitle>{t("Quotations")}</TextCardTitle>

      <WhiteCard isCompact={true}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Cargo")}</Th>
                <Th>{t("Quotation")}</Th>
                <Th>{t("Responsible person")}</Th>
                <Th>{t("Loading")}</Th>
                <Th>{t("Unloading")}</Th>
                <Th textAlignment="text-right">{t("Points")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <OldTbody
              group={(item) =>
                item.expeditorCalculation
                  ? item.expeditorCalculation.fullName
                  : "-"
              }
              data={data.filter(
                (cargo: ICalculationData) => cargo.calculationPoints > 0
              )}
              callback={(item, index: number) => {
                return {
                  columns: [
                    {
                      content: (
                        <DfValueView id={item.id} path={"cargo.serialNumber"} link={true} />
                      ),
                      props: { className: "text-left" },
                    },
                    {
                      content: (
                        <DfValueView
                          path="cargo.calculation.serialNumber"
                          id={item.id}
                          link={true}
                        />
                      ),
                      props: { className: "text-left" },
                    },
                    {
                      content: <DfValueView path="cargo.creator.fullName" id={item.id} />,
                      props: { className: "text-left" },
                    },
                    {
                      content: <DfValueView path="cargo.loadingAddress" id={item.id} />,
                      props: { className: "text-left" },
                    },
                    {
                      content: <UnloadAddress id={item.id} />,
                      props: { className: "text-left" },
                    },
                    {
                      content: <DfValueView path="cargo.calculationPoints" id={item.id} />,
                      props: { className: "text-right" },
                    },
                  ],
                  className: defaultStrippedRowClassName(index),
                };
              }}
            />
          }
        />
      </WhiteCard>

      <TextCardTitle>{t("Freights")}</TextCardTitle>

      <WhiteCard isCompact={true}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Cargo")}</Th>
                <Th>{t("Quotation")}</Th>
                <Th>{t("Responsible person")}</Th>
                <Th>{t("Loading")}</Th>
                <Th>{t("Unloading")}</Th>
                <Th textAlignment="text-right">{t("Points")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <OldTbody
              group={(item) => (item.expeditor ? item.expeditor.fullName : "-")}
              data={data.filter(
                (cargo: ICalculationData) => cargo.onRoadPoints > 0
              )}
              callback={(item, index: number) => {
                return {
                  columns: [
                    {
                      content: (
                        <DfValueView id={item.id} link={true} path={"cargo.serialNumber"} />
                      ),
                      props: { className: "text-left" },
                    },
                    {
                      content: (
                        <DfValueView
                          path="cargo.calculation.serialNumber"
                          id={item.id}
                          link={true}
                        />
                      ),
                      props: { className: "text-left" },
                    },
                    {
                      content: <DfValueView path="cargo.creator.fullName" id={item.id} />,
                      props: { className: "text-left" },
                    },
                    {
                      content: <DfValueView path="cargo.loadingAddress" id={item.id} />,
                      props: { className: "text-left" },
                    },
                    {
                      content: <UnloadAddress id={item.id} />,
                      props: { className: "text-left" },
                    },
                    {
                      content: <DfValueView path="cargo.onRoadPoints" id={item.id} />,
                      props: { className: "text-right" },
                    },
                  ],
                  className: defaultStrippedRowClassName(index),
                };
              }}
            />
          }
        />
      </WhiteCard>

      <WhiteCard isCompact={true} title={t("Summary")}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>User</Th>
                <Th className={"text-right"}>Points</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {Object.keys(points)
                .sort((a: string, b: string) => {
                  return points[a] > points[b]
                    ? 1
                    : points[a] < points[b]
                      ? -1
                      : 0;
                })
                .filter((groupKey: string) => points[groupKey] > 0)
                .map((groupKey: string, index: number) => {
                  return (
                    <tr key={"points-" + index}>
                      <Td>{groupKey}</Td>
                      <Td className={"text-right"}>{points[groupKey]}</Td>
                    </tr>
                  );
                })}
            </tbody>
          }
        />
      </WhiteCard>
    </div>
  );
}

function UnloadAddress(props: PropsId) {
  const element = useCargoHookNae(props.id);

  if (!element) {
    return <Fragment />;
  }

  const hasTerminal = element.terminal && element.terminal.id > 0;
  const hasSO = element.salesOrder && element.salesOrder.id > 0;
  const hasCalc = element.calculation && element.calculation.id > 0;

  return (
    <Fragment>
      {hasTerminal ? (
        <DfValueView id={props.id} path={"cargo.terminal.fullAddress"} />
      ) : hasSO ? (
        <DfValueView id={props.id} path={"cargo.salesOrder.address.fullAddress"} />
      ) : hasCalc ? (
        <DfValueView path="cargo.calculation.address.fullAddress" id={props.id} />
      ) : (
        ""
      )}
    </Fragment>
  );
}
