import React, { Fragment, useState, useEffect } from "react";

import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import moment from "moment";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { checkUserManager } from "../../_generated/_custom/config/NaeSPermissions";
import SalesOrderCostContent from "../SalesOrder/Widget/SalesOrderCostContent";

import {
  SelectAdvId,
  InputInt,
  TextBlock,
} from "@newageerp/ui.form.base.form-pack";

import { Table, Th, Td, MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle'
import { getPropertyEnumLabel } from "../../_generated/v3/utils";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { DatepickerRangeDates, FieldDateRange, FieldLabel, FieldSelectData, Wide, WideRow } from "@newageerp/v3.bundles.form-bundle";
import { RsButton } from "@newageerp/v3.bundles.buttons-bundle";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";

interface IData {
  id: number;
  serialNumber: string;
  client: {
    id: number;
    name: string;
  };
  creator: {
    id: number;
    fullName: string;
  };
  totalEur: number;
  internalPoints: number;
  profit: number;
  plannedProfit: number;
  date: string;
  finishDate: string;
  address: {
    country: {
      nameEn: string;
    };
  };
  cargoRelType: number;
  cargoExportType: string;
  clientStatus: string;
}

const defObject: IData = {
  id: 0,
  serialNumber: "",
  client: {
    id: 0,
    name: "",
  },
  creator: {
    id: 0,
    fullName: "",
  },
  totalEur: 0,
  internalPoints: 0,
  profit: 0,
  plannedProfit: 0,
  date: "",
  finishDate: "",
  address: {
    country: {
      nameEn: "",
    },
  },
  cargoRelType: 0,
  cargoExportType: '',
  clientStatus: '',
};

export default function SalesReports() {
  const t = (s: string) => s;

  const [clientStatus, setClientStatus] = useState(-1);

  const [importType, setImportType] = useState('');
  const [exportType, setExportType] = useState('');

  const [unloadingCountry, setUnloadingCountry] = useState(0);
  const [activeCreator, setActiveCreator] = useState('-');


  const { userState } = useTemplatesCore()
  const isManager = checkUserManager(userState);
  const [pointCostDb, setPointCostDb] = useState(0);

  const [dates, setDates] = React.useState<DatepickerRangeDates>({
    dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
    dateTo: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [costs, setCosts] = React.useState(30000);
  const [mode, setMode] = React.useState(isManager ? 2 : 1);

  const [getData, getDataParams] = useUList<IData>(
    "sales-order",
    getKeysFromObject(defObject)
  );
  const [getPointCostData, getPointCostDataParams] = useUList<IData>(
    NaeSSchemaMap.SalaryPointCost.schema,
    ["cost", "fullCost"]
  );

  React.useEffect(() => {
    if (!dates) {
      return;
    }
    let _filter: any = [
      mode === 1
        ? {
          and: [
            ["i.date", "dgte", dates.dateFrom, true],
            ["i.date", "dlte", dates.dateTo, true],
            ["i.status", "in", [10, 20], true],
          ],
        }
        : {
          and: [
            ["i.finishDate", "dgte", dates.dateFrom, true],
            ["i.finishDate", "dlte", dates.dateTo, true],
            ["i.status", "eq_num", 20, true],
          ],
        },
    ];
    if (clientStatus > 0) {
      _filter[0]['and'].push(["i.client.clientStatus.id", "=", clientStatus, true])
    }
    if (unloadingCountry > 0) {
      _filter[0]['and'].push(["i.address.country.id", "=", unloadingCountry, true])
    }
    if (importType) {
      _filter[0]['and'].push(["i.cargos.typeIn", "=", importType])
    }
    if (exportType) {
      _filter[0]['and'].push(["i.cargos.typeOut", "=", exportType])
    }
    getData(
      _filter,
      1,
      999999
      // [{ 'key': 'i.creator.firstName', 'value': 'ASC' }, { 'key': 'i.creator.lastName', 'value': 'ASC' }],
    );
    getPointCostData([
      {
        and: [["i.date", "=", moment(dates.dateFrom).format("YYYY-MM"), true]],
      },
    ]).then((res: any) => {
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        setPointCostDb(res.data.data[0].cost);
        setCosts(res.data.data[0].fullCost);
      }
    });
  }, [dates, mode, unloadingCountry, importType, exportType, clientStatus]);

  const dataByCreator = groupMap(getDataParams.data.data, (el: IData) =>
    el.creator ? el.creator.fullName : "XXX"
  );

  const dataByCountries = groupMap(getDataParams.data.data, (el: IData) =>
    el.address && el.address.country ? el.address.country.nameEn : "XXX"
  );
  const dataByClientStatus = groupMap(getDataParams.data.data, (el: IData) =>
    el.clientStatus ? el.clientStatus : "XXX"
  );

  const allPoints = getDataParams.data.data
    .map((o) => o.internalPoints)
    .reduce((a: number, b: number) => a + b, 0);
  const onePoint = costs / allPoints;

  return (
    <Fragment>
      <MainToolbarTitle title={t('Profit report')} />
      <div className={"space-y-2"}>
        <WhiteCard title={t("Settings")}>
          <Wide className="w-1/2">
            <WideRow
              label={<FieldLabel>{t("Dates")}</FieldLabel>}
              control={<FieldDateRange compact={true} dates={dates} setDates={setDates} />}
            />

            <WideRow
              label={<FieldLabel>{t("Client status")}</FieldLabel>}
              control={<FieldSelectData
                field="titleManager"
                schema={NaeSSchemaMap.ClientStatus.schema}
                onChange={setClientStatus}
                value={clientStatus}
                sort={[{ key: 'i.turnover', value: 'ASC' }]}
                extraOptions={[{ value: -1, label: '' }]}
              />}
            />

            <WideRow
              label={<FieldLabel>{t("Unloading country")}</FieldLabel>}
              control={<FieldSelectData
                field="nameEn"
                schema={NaeSSchemaMap.Country.schema}
                onChange={setUnloadingCountry}
                value={unloadingCountry}
                sort={[{ key: 'i.nameEn', value: 'ASC' }]}
                extraOptions={[{ value: -1, label: '-' }]}
              />}
            />
            <WideRow
              label={<FieldLabel>{t("Import")}</FieldLabel>}
              control={
                <SelectAdvId
                  selectedId={importType}
                  onSelectId={setImportType}
                  options={[
                    {
                      value: "",
                      label: "",
                    },
                    {
                      value: "ltl",
                      label: t("Carriage by road (LTL / Partial cargo)"),
                    },
                    {
                      value: "ftl",
                      label: t("Carriage by road (FTL / Full cargo)"),
                    },
                    {
                      value: "avia",
                      label: t("Avia"),
                    },
                    {
                      value: "sea",
                      label: t("Sea"),
                    },
                    {
                      value: "none",
                      label: t("Other"),
                    },
                  ]}
                />
              }
            />

            <WideRow
              label={<FieldLabel>{t("Export")}</FieldLabel>}
              control={
                <SelectAdvId
                  selectedId={exportType}
                  onSelectId={setExportType}
                  options={[
                    {
                      value: "",
                      label: "",
                    },
                    {
                      value: "ltl",
                      label: t("Carriage by road (LTL / Partial cargo)"),
                    },
                    {
                      value: "ftl",
                      label: t("Carriage by road (FTL / Full cargo)"),
                    },
                    {
                      value: "avia",
                      label: t("Avia"),
                    },
                    {
                      value: "sea",
                      label: t("Sea"),
                    },
                    {
                      value: "train",
                      label: t("Railway"),
                    },

                    {
                      value: "none",
                      label: t("Other"),
                    },
                  ]}
                />
              }
            />


            <WideRow
              label={<FieldLabel>{t("Type")}</FieldLabel>}
              control={
                <SelectAdvId
                  selectedId={mode.toString()}
                  onSelectId={(e) => setMode(parseInt(e, 10))}
                  options={[
                    {
                      value: "1",
                      label: t("By creation date"),
                    },
                    {
                      value: "2",
                      label: t("Completed by completion time"),
                    },
                  ]}
                />
              }
            />
            {!isManager && (
              <Fragment>
                <WideRow
                  label={<FieldLabel>{t("Administravimo išlaidos")}</FieldLabel>}
                  control={<InputInt value={costs} onChangeInt={setCosts} />}
                />

                <WideRow
                  label={<FieldLabel>{t("Selfcost of single point")}</FieldLabel>}
                  control={<TextBlock>{onePoint.toFixed(4)} EUR</TextBlock>}
                />
                <WideRow
                  label={<FieldLabel>{t("Selfcost of single point in settings")}</FieldLabel>}
                  control={<TextBlock>{pointCostDb.toFixed(4)} EUR</TextBlock>}
                />
                <WideRow
                  label={<FieldLabel>{t("Difference")}</FieldLabel>}
                  control={<TextBlock>{((getDataParams.data.data
                    .map((o) => o.internalPoints)
                    .reduce((a: number, b: number) => a + b, 0) *
                    pointCostDb) - costs).toFixed(2)} EUR</TextBlock>}
                />
              </Fragment>
            )}
          </Wide>
        </WhiteCard>

        {!isManager && (
          <WhiteCard title={t("All")} isCompact={true}>
            <Table className={"w-full"}
              thead={
                <thead>
                  <tr>
                    <Th>
                      {t("Number")}
                    </Th>
                    <Th>
                      {t("Client")}
                    </Th>
                    <Th>
                      {t("Country of unloading")}
                    </Th>
                    <Th>
                      {t("Transportation type")}
                    </Th>
                    <Th textAlignment="text-center">
                      {t("Date")}
                    </Th>
                    <Th textAlignment="text-center">
                      {t("Finish date")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Price, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("SO (quantity)")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Profit, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Planned profit, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Internal points, pcs")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Internal points, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Pelnas po administravimo išlaidų, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Profit after internal points, EUR")}
                    </Th>
                  </tr>
                </thead>
              }

              tbody={
                <tbody>
                  <tr className={"total-row"}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td textAlignment="text-right">
                      {getDataParams.data.data
                        .map((o) => o.totalEur)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td textAlignment="text-right">
                      {getDataParams.data.data.length.toFixed(0)}
                    </Td>
                    <Td textAlignment="text-right">
                      {getDataParams.data.data
                        .map((o) => o.profit)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td textAlignment="text-right">
                      {getDataParams.data.data
                        .map((o) => o.plannedProfit)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td textAlignment="text-right">
                      {getDataParams.data.data
                        .map((o) => o.internalPoints)
                        .reduce((a: number, b: number) => a + b, 0)
                        .toFixed(2)}
                    </Td>
                    <Td textAlignment="text-right">
                      {(getDataParams.data.data
                        .map((o) => o.internalPoints)
                        .reduce((a: number, b: number) => a + b, 0) *
                        pointCostDb).toFixed(2)}
                    </Td>
                    <Td textAlignment="text-right">
                      {(
                        getDataParams.data.data
                          .map((o) => o.profit)
                          .reduce((a: number, b: number) => a + b, 0) -
                        (getDataParams.data.data
                          .map((o) => o.internalPoints)
                          .reduce((a: number, b: number) => a + b, 0) *
                          onePoint)
                      ).toFixed(2)}
                    </Td>
                    <Td textAlignment="text-right">
                      {(
                        getDataParams.data.data
                          .map((o) => o.profit)
                          .reduce((a: number, b: number) => a + b, 0) -
                        (getDataParams.data.data
                          .map((o) => o.internalPoints)
                          .reduce((a: number, b: number) => a + b, 0) *
                          pointCostDb)
                      ).toFixed(2)}
                    </Td>
                  </tr>
                </tbody>
              }
            />
          </WhiteCard>
        )}

        {!isManager && (
          <WhiteCard title={t("By countries and export type")} isCompact={true}>
            <Table
              thead={
                <thead>
                  <tr>
                    <Th>Title</Th>
                    <Th textAlignment="text-right">
                      {t("Price, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("SO (quantity)")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Planned profit, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Profit, EUR")}
                    </Th>
                  </tr>
                </thead>
              }
              tbody={
                <tbody>
                  {Object.keys(dataByCountries).map((country) => {
                    const countriesOrders: IData[] = dataByCountries[country];

                    const dataByExportType = groupMap(countriesOrders, (el: IData) =>
                      el.cargoExportType ? el.cargoExportType : "XXX"
                    );

                    return (
                      <Fragment key={`country-data-${country}`}>
                        <tr className="total-row">
                          <Td>{country}</Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o: IData) => o.totalEur)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders.length.toFixed(0)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o) => o.plannedProfit)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o) => o.profit)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                        </tr>
                        {Object.keys(dataByExportType).map((exportType) => {
                          const exportTypeOrders: IData[] = dataByExportType[exportType];

                          return (
                            <tr key={`country-data-${country}-${exportType}`}>
                              <Td>{exportType}</Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o: IData) => o.totalEur)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders.length.toFixed(0)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o) => o.plannedProfit)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o) => o.profit)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </tbody>
              }
            />
          </WhiteCard>
        )}

        {!isManager && (
          <WhiteCard title={t("By client status and countries")} isCompact={true}>
            <Table
              thead={
                <thead>
                  <tr>
                    <Th>Title</Th>
                    <Th textAlignment="text-right">
                      {t("Price, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("SO (quantity)")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Planned profit, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Profit, EUR")}
                    </Th>
                  </tr>
                </thead>
              }
              tbody={
                <tbody>
                  {Object.keys(dataByClientStatus).map((clientStatus) => {
                    const countriesOrders: IData[] = dataByClientStatus[clientStatus];

                    const dataByCountries = groupMap(countriesOrders, (el: IData) =>
                      el.address && el.address.country ? el.address.country.nameEn : "XXX"
                    );

                    return (
                      <Fragment key={`clientStatus-data-${clientStatus}`}>
                        <tr className="total-row">
                          <Td>{clientStatus}</Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o: IData) => o.totalEur)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders.length.toFixed(0)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o) => o.plannedProfit)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o) => o.profit)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                        </tr>
                        {Object.keys(dataByCountries).map((country) => {
                          const exportTypeOrders: IData[] = dataByCountries[country];

                          return (
                            <tr key={`clientStatus-data-${clientStatus}-${country}`}>
                              <Td>{country}</Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o: IData) => o.totalEur)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders.length.toFixed(0)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o) => o.plannedProfit)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o) => o.profit)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </tbody>
              }
            />
          </WhiteCard>
        )}

{!isManager && (
          <WhiteCard title={t("By manager")} isCompact={true}>
            <Table
              thead={
                <thead>
                  <tr>
                    <Th>Title</Th>
                    <Th textAlignment="text-right">
                      {t("Price, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("SO (quantity)")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Planned profit, EUR")}
                    </Th>
                    <Th textAlignment="text-right">
                      {t("Profit, EUR")}
                    </Th>
                  </tr>
                </thead>
              }
              tbody={
                <tbody>
                  {Object.keys(dataByCreator).map((clientStatus) => {
                    const countriesOrders: IData[] = dataByCreator[clientStatus];

                    const dataByCountries = groupMap(countriesOrders, (el: IData) =>
                      el.address && el.address.country ? el.address.country.nameEn : "XXX"
                    );

                    return (
                      <Fragment key={`dataByCreator-data-${clientStatus}`}>
                        <tr className="total-row">
                          <Td>{clientStatus}</Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o: IData) => o.totalEur)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders.length.toFixed(0)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o) => o.plannedProfit)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                          <Td textAlignment="text-right" className="font-medium">
                            {countriesOrders
                              .map((o) => o.profit)
                              .reduce((a: number, b: number) => a + b, 0)
                              .toFixed(2)}
                          </Td>
                        </tr>
                        {Object.keys(dataByCountries).map((country) => {
                          const exportTypeOrders: IData[] = dataByCountries[country];

                          return (
                            <tr key={`clientStatus-data-${clientStatus}-${country}`}>
                              <Td>{country}</Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o: IData) => o.totalEur)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders.length.toFixed(0)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o) => o.plannedProfit)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                              <Td textAlignment="text-right">
                                {exportTypeOrders
                                  .map((o) => o.profit)
                                  .reduce((a: number, b: number) => a + b, 0)
                                  .toFixed(2)}
                              </Td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </tbody>
              }
            />
          </WhiteCard>
        )}

        {Object.keys(dataByCreator).map((owner) => {
          const data: IData[] = dataByCreator[owner];
          const isActive = activeCreator === owner;
          return (
            <WhiteCard key={"owner-" + owner} isCompact={true}>
              <TextCardTitle className="px-2">
                <button
                  onClick={() => setActiveCreator(isActive ? '-' : owner)}
                  className="text-left"
                >
                  {owner} ({data.length})
                </button>
              </TextCardTitle>

              {isActive && (
                <Table
                  thead={
                    <thead>
                      <tr>
                        <Th>
                          {t("Number")}
                        </Th>
                        <Th>
                          {t("Client")}
                        </Th>
                        <Th>
                          {t("Country of unloading")}
                        </Th>
                        <Th>
                          {t("Transportation type")}
                        </Th>
                        <Th textAlignment="text-center">
                          {t("Date")}
                        </Th>
                        <Th textAlignment="text-center">
                          {t("Finish date")}
                        </Th>
                        <Th textAlignment="text-right">
                          {t("Price, EUR")}
                        </Th>
                        <Th textAlignment="text-right">
                          {t("Profit, EUR")}
                        </Th>
                        <Th textAlignment="text-right">
                          {t("Planned profit, EUR")}
                        </Th>
                        {!isManager && (
                          <Fragment>

                            <Th textAlignment="text-right">
                              {t("Internal points, pcs.")}
                            </Th>
                            <Th textAlignment="text-right">
                              {t("Pelnas po administravimo išlaidų, EUR")}
                            </Th>
                            <Th textAlignment="text-right">
                              {t("Profit after internal points, EUR")}
                            </Th>
                          </Fragment>
                        )}
                        <Th>{t("Selfcost")}</Th>
                      </tr>
                    </thead>
                  }
                  tbody={
                    <tbody>
                      {data.map((o: IData, index: number) => {
                        return (
                          <tr>
                            <Td>
                              <RsButton
                                id={o.id}
                                schema={"sales-order"}
                                defaultClick="modal"
                                button={{
                                  children: o.serialNumber,
                                  skipPadding: true,
                                  color: "white"
                                }}
                              />

                            </Td>
                            <Td>
                              <RsButton
                                id={o.client.id}
                                schema={NaeSSchemaMap.Client.schema}
                                defaultClick="modal"
                                button={{
                                  children: o.client.name,
                                  skipPadding: true,
                                  color: "white"
                                }}
                              />
                            </Td>
                            <Td>
                              {!!o.address &&
                                !!o.address.country &&
                                o.address.country.nameEn}
                            </Td>
                            <Td>
                              {getPropertyEnumLabel(
                                "sales-order",
                                "cargoRelType",
                                o.cargoRelType
                              )}
                            </Td>

                            <Td textAlignment="text-center" className={"whitespace-nowrap"}>
                              {moment(o.date).format("YYYY-MM-DD")}
                            </Td>
                            <Td textAlignment="text-center" className={"whitespace-nowrap"}>
                              {moment(o.finishDate).format("YYYY-MM-DD")}
                            </Td>
                            <Td textAlignment="text-right">
                              {o.totalEur.toFixed(2)}
                            </Td>

                            <Td textAlignment="text-right">
                              {o.profit.toFixed(2)}
                            </Td>
                            <Td textAlignment="text-right">
                              {o.plannedProfit.toFixed(2)}
                            </Td>
                            {!isManager && (
                              <Fragment>

                                <Td textAlignment="text-right">
                                  {o.internalPoints.toFixed(2)}
                                </Td>
                                <Td textAlignment="text-right">
                                  {(
                                    o.profit -
                                    o.internalPoints * onePoint
                                  ).toFixed(2)}
                                </Td>
                                <Td textAlignment="text-right">
                                  {(
                                    o.profit -
                                    o.internalPoints * pointCostDb
                                  ).toFixed(2)}
                                </Td>
                              </Fragment>
                            )}

                            <Td>
                              <SalesOrderCostContent
                                hideHeader={true}
                                id={o.id}
                              />
                            </Td>
                          </tr>
                        );
                      })}
                      <tr className={"total-row"}>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td textAlignment="text-center"></Td>
                        <Td textAlignment="text-center"></Td>
                        <Td textAlignment="text-right">
                          {data
                            .map((o) => o.totalEur)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td textAlignment="text-right">
                          {data
                            .map((o) => o.profit)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        <Td textAlignment="text-right">
                          {data
                            .map((o) => o.plannedProfit)
                            .reduce((a: number, b: number) => a + b, 0)
                            .toFixed(2)}
                        </Td>
                        {!isManager && (
                          <Fragment>

                            <Td textAlignment="text-right">
                              {data
                                .map((o) => o.internalPoints)
                                .reduce((a: number, b: number) => a + b, 0)
                                .toFixed(2)}
                            </Td>

                            <Td textAlignment="text-right">
                              {(
                                data
                                  .map((o) => o.profit)
                                  .reduce((a: number, b: number) => a + b, 0) -
                                data
                                  .map((o) => o.internalPoints)
                                  .reduce((a: number, b: number) => a + b, 0) *
                                onePoint
                              ).toFixed(2)}
                            </Td>
                            <Td textAlignment="text-right">
                              {(
                                data
                                  .map((o) => o.profit)
                                  .reduce((a: number, b: number) => a + b, 0) -
                                data
                                  .map((o) => o.internalPoints)
                                  .reduce((a: number, b: number) => a + b, 0) *
                                pointCostDb
                              ).toFixed(2)}
                            </Td>
                          </Fragment>
                        )}

                        <Td></Td>
                      </tr>
                    </tbody>
                  }
                />

              )}
            </WhiteCard>
          );
        })}
      </div>
    </Fragment>
  );
}
