import React, { useEffect, useState } from 'react'
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';

import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle';
import { useNaeRecord } from '@newageerp/v3.app.mvc.record-provider';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

interface IData {
    id: number,
    title: string,
}

export default function MoveWidget(props: PropsId) {
    const [selected, setSelected] = useState(-1);
    const t = (s: string) => s;
    const { reloadData } = useNaeRecord();

    const [saveData, saveDataParams] = useURequest(NaePathsMap.post['CUSTOMmoveLeadSourceClients']);

    const [getData, getDataParams] = useUList<IData>(NaeSSchemaMap.LeadSource.schema, ['id', 'title']);
    const loadData = () => {
        getData([], 1, 9999, [{ key: 'i.title', 'value': 'ASC' }])
    }
    useEffect(loadData, []);

    const doMove = () => {
        saveData({
            sourceId: props.id,
            targetId: selected
        }).then(() => {
            reloadData();
            setSelected(-1);
        });
    }

    const options = [{ value: -1, label: t('Not assigned') }, ...getDataParams.data.data.map(o => ({ value: o.id, label: o.title }))];

    return (
        <WhiteCard isCompact={true} title={t('Move')}>
            {getDataParams.loading && <LogoLoader />}
            <FieldSelect value={selected} onChange={setSelected} options={options} />
            {selected > 0 &&
                <MainButton
                    confirmation={true}
                    onClick={doMove}
                    loading={saveDataParams.loading}
                    iconName={"arrows-alt"}
                    className={"w-full"}>
                    {t('Move')}
                </MainButton>
            }
        </WhiteCard>
    )
}
