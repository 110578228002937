import React, { Fragment } from 'react'

import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';

export default function KzCarriersOrderEpi(props: PropsId) {
    const t = (s: string) => s;

    const openEmail = () => {
        const options = {
            extraData: {
              id: props.id,
              schema: 'kz-carriers-order',
              template: 'epi',
            },
          }
          const event = new CustomEvent(
            'SFSOpenEmailForm',
            {
              detail: options
            }
          );
          window.dispatchEvent(event);
    }

    return (
        <Fragment>
            <MenuItem iconName={"paper-plane"} onClick={openEmail}>
                {t("EPI")}
            </MenuItem>
        </Fragment>
    )
}
