import React, { Fragment } from "react";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import SenderAttorneyWidget from "../../../Plugins/Sender/SenderAttorneyWidget";
import {
  checkUserAdmin,
  checkUserOperationalPro,
  checkUserOperational,
} from '../../../_generated/_custom/config/NaeSPermissions';
import SenderName from "../../../_generated/_custom/models-cache-data/Sender/SenderName";
import BottomWidgetLoadAddress from "../../../UserComponents/Cargo/BottomWidget/BottomWidgetLoadAddress";
import ContactFullName from "../../../_generated/_custom/models-cache-data/Contact/ContactFullName";
import ContactEmailsWithLink from "../../../_generated/_custom/models-cache-data/Contact/ContactEmailsWithLink";
import ContactPhonesWithLink from "../../../_generated/_custom/models-cache-data/Contact/ContactPhonesWithLink";
import CargoContactWidget from "../../../UserComponents/Cargo/Widget/CargoContactWidget";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useTerminalHookNae } from '../../../_generated/_custom/hooks/useTerminalHookNae';
import { RsButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { DfValueView } from "@newageerp/v3.bundles.app-bundle";

export default function CargoSellerComponent(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const terminal = useTerminalHookNae(element && element.terminal ? element.terminal.id : -1);

  const seller = element.sellerJson;

  const t = (s: string) => s;

  const { userState } = useTemplatesCore()

  const isCreator = element.creator && element.creator.id === userState.id;
  const isExpeditor =
    element.expeditor && element.expeditor.id === userState.id;
  const isAdmin = checkUserAdmin(userState);
  const isOperational = checkUserOperational(userState);
  const isOperationalPro = checkUserOperationalPro(userState);

  const canViewElement =
    isCreator ||
    isAdmin ||
    isExpeditor ||
    isOperational ||
    isOperationalPro;

  if (!element || !seller) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <div className="py-2">
        <DfValueView path="sender.name" link={canViewElement} id={seller.id} />
      </div>


      {element.needMakeEx &&
        !!terminal &&
        element.sellerJson.validAttorneysCount === 0 &&
        (isExpeditor || isAdmin) && (
          <div className="py-2">
            <SenderAttorneyWidget cargoId={element.id} id={seller.id} />
          </div>
        )}

      {!!element.loadAddress && (
        <BottomWidgetLoadAddress
          canViewElement={canViewElement}
          id={element.loadAddress.id}
        />
      )}

      {!!element.loadAddressAdditional && (
        <BottomWidgetLoadAddress
          canViewElement={canViewElement}
          id={element.loadAddressAdditional.id}
        />
      )}

      {!!element.loadAddressAdditional2 && (
        <BottomWidgetLoadAddress
          canViewElement={canViewElement}
          id={element.loadAddressAdditional2.id}
        />
      )}


      <div className="flex items-center py-2">
        {!!element.contact && (
          <div className="flex-grow space-y-2">
            <DfValueView path="contact.fullName" id={element.contact.id} link={canViewElement} />

            <ContactPhonesWithLink id={element.contact.id} />

            <ContactEmailsWithLink id={element.contact.id} />
          </div>
        )}

        <CargoContactWidget id={element.id} />
      </div>

    </Fragment>
  );
}
