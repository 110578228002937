import React, { Fragment } from "react";

import moment from "moment";
import KzLoadDate from '../../../KzCarriersOrder/Components/KzLoadDate';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import KzFtlCalculations from '../../../KzCarriersOrder/Components/KzFtlCalculations';
import KzCargoTerminals from "../../../KzCarriersOrder/Components/KzCargoTerminals";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';

interface IData {
  id: number,
  loadDate: string,
}

interface Props {
  data: IData[];
}

export default function FtlLogisticsToCalculate(props: Props) {
  const t = (s: string) => s;

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Paskaičiavimui")}>

        <Table
          thead={
            <thead>
              <tr>
                <Th>{t('Number')}</Th>
                <Th>{t('Quotations')}</Th>
                <Th>{t('Cargo specifics')}</Th>
                <Th>{t('Loading')}</Th>
                <Th>{t('Route')}</Th>
                <Th>{t('Planned loading date')}</Th>
                <Th>{t('Weight')}</Th>
                <Th>{t('Pakrovimo komentaras')}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data.sort((a: IData, b: IData) => {
                const aDateStr = !!a.loadDate ? moment(a.loadDate).format("YYYY-MM-DD") : '';
                const bDateStr = !!b.loadDate ? moment(b.loadDate).format("YYYY-MM-DD") : '';

                var x = aDateStr.toLowerCase();
                var y = bDateStr.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
              }).map((o: IData, oIndex: number) => {

                return (
                  <FtlLogisticsPlannedLine
                    id={o.id}
                    key={"kz-o-" + o.id}
                    index={oIndex}
                  />
                );
              })}
            </tbody>
          }
        />

      </WhiteCard>

    </Fragment>
  );
}

interface FtlLogisticsPlannedLineProps extends PropsId {
  index: number,
}

const FtlLogisticsPlannedLine = (props: FtlLogisticsPlannedLineProps) => {
  const element = useKzCarriersOrderHookNae(props.id);

  if (!element) {
    return <Fragment />
  }

  return (
    <Fragment>
      <tr>
        <Td>
          <DfValueView path="kz-carriers-order.serialNumber" id={props.id} link={true} />
        </Td>
        <Td>
          <KzFtlCalculations id={props.id} />
        </Td>
        <Td>
          <DfValueView path="kz-carriers-order.cargoNotes" id={props.id} />
        </Td>
        <Td>
          <KzCargoTerminals id={props.id} />
        </Td>
        <Td>
          <DfValueView path="kz-carriers-order.trip" id={props.id} />
        </Td>
        <Td>
          <KzLoadDate id={props.id} />
        </Td>
        <Td>
          <DfValueView path="kz-carriers-order.allWeight" id={props.id} />
        </Td>
        <Td>
          <DfValueView path="kz-carriers-order.notesForFtlCalculation" id={props.id} />
        </Td>
      </tr>

    </Fragment>
  );
}