import React, { Fragment } from "react";

import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { uniqueArray } from "../../../core/components/utils/mapUtils";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { CargoLoadDateConfirmation } from "./CargoLoadDateConfirmation";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";

export default function ManagerCargoReturnWidget(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const t = (s: string) => s;

  const [saveData, saveDataParams] = useUSave(
    "cargo"
  );
  const doSave = (data: any) => {
    if (saveDataParams.loading) return;
    saveData(data, element.id);
  };

  if (!element) {
    return <Fragment />;
  }
  if (element.status !== 7) {
    return <Fragment />;
  }

  const lookFurther = () => {
    doSave({
      loadDateDiffManagerConfirmed: false,
      loadDateDiffManagerNeedConfirm: false,
      status: 5,
      cancelPoOrder: true,
    })
  }

  const confirmDateDiff = () => {
    doSave({
      status: 5,
      loadDateDiffManagerNeedConfirm: false,
      loadDateDiffManagerConfirmed: true,
      addNote: {
        content: t('Cargo loading date confirmed'),
        notify: uniqueArray([
          element.expeditor ? element.expeditor.id : 0,
          element.expeditorCalculation ? element.expeditorCalculation.id : 0
        ].filter(n => n > 0))
      }
    })
  }

  const cancelSoOrder = () => {
    doSave({
      cancelSoOrder: true,
      status: 0,
    })
  }

  return (
    <Fragment>
      {element.loadDateDiffManagerNeedConfirm && <CargoLoadDateConfirmation onLookFurther={lookFurther} onYes={confirmDateDiff} onNo={cancelSoOrder} text={element.loadingInfoReturnText} />}
      {!element.loadDateDiffManagerNeedConfirm && (
        <WhiteCard isCompact={true} className={"border border-red-500"}>
          <p>{element.loadingInfoReturnText}</p>

          <MainButton
            className={"w-full"}
            color="teal"
            iconName="screwdriver"
            loading={saveDataParams.loading}
            confirmation={true}
            onClick={() => doSave({ status: 5 })}
          >
            {t("Is the issue resolved?")}
          </MainButton>
          <hr />
          <MainEditContentInline
            schema={"cargo"}
            type={"cargoReturnLoadDate"}
            id={element.id.toString()}
            isCompact={true}
          />


        </WhiteCard>
      )
      }
    </Fragment>
  );
}
