import React, { Fragment, useState } from "react";

import KzOrderLogisticCargoLines from "../../../../components/dashboard/cargo/kz-order/KzOrderLogisticCargoLines";
import FtlLogisticsReturnToRoadBtn from "../../../../components/dashboard/cargo/kz-order/FtlLogisticsReturnToRoadBtn";
import RailwayOrderTransportInfo from "./Components/RailwayOrderTransportInfo";
import Status30DateComponent from "../../../KzCarriersOrder/Components/Status30DateComponent";
import KzCarrierLinkWithEye from "../../../KzCarriersOrder/Components/KzCarrierLinkWithEye";
import AviaTerminalName from "./Components/AviaTerminalName";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import KzOrderCargoLinks from "../../../KzCarriersOrder/Components/KzOrderCargoLinks";
import KzOrderLocationHistory from "../../../../components/dashboard/cargo/kz-order/KzOrderLocationHistory";

interface Props {
  data: IData[];
}

interface IData {
  id: number;
  status20Date: string;

}

export default function AviaLogisticsUnload(props: Props) {
  const t = (s: string) => s;
  const [viewCargosId, setViewCargosId] = useState<number>(0);

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Arrived for unloading")}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t('Number')}</Th>
                {/* <Th>{t("Cargo")}</Th> */}
                <Th>{t('Carrier')}</Th>
                <Th>{t('Set off date')}</Th>
                <Th>{t("Date of arrival to the unloading")}</Th>

                <Th>{t('Vehicle')}</Th>
                <Th>{t('Terminal')}</Th>
                <Th>{t('Unloading address')}</Th>
                <Th>{t('Vehicle location')}</Th>

                <Th>{t('Return to En route')}</Th>
                <Th>{t('My notes')}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data
                .sort((a, b) => {
                  const aDateStr = a.status20Date;
                  const bDateStr = b.status20Date;

                  var x = aDateStr.toLowerCase();
                  var y = bDateStr.toLowerCase();
                  return x < y ? -1 : x > y ? 1 : 0;
                })
                .map((o: IData) => {
                  return (
                    <Fragment key={"kz-o-" + o.id}>
                      <tr>
                        <Td className={"whitespace-nowrap space-y-2"}>
                          <KzCarrierLinkWithEye id={o.id} viewCargosId={viewCargosId} setViewCargosId={setViewCargosId} />

                          <KzOrderCargoLinks id={o.id} />
                        </Td>
                        {/* <Td>
                          <KzOrderCargoLinks id={o.id} />
                        </Td> */}
                        <Td>
                          <p>
                            <DfValueView path="kz-carriers-order.client.name" id={o.id} />
                          </p>
                          <p>
                            <DfValueView path="kz-carriers-order.contact.fullName"
                              id={o.id}
                            />
                          </p>
                        </Td>
                        <Td className="whitespace-nowrap">
                          <DfValueView path="kz-carriers-order.status20Date" id={o.id} />
                        </Td>
                        <Td className="whitespace-nowrap">
                          <Status30DateComponent id={o.id} />
                        </Td>
                        <Td>
                          <RailwayOrderTransportInfo id={o.id} />
                        </Td>
                        <Td>
                          <AviaTerminalName id={o.id} />
                        </Td>
                        <Td><DfValueView path="kz-carriers-order.cargoUnloadAddress" id={o.id} /></Td>
                        <Td className={"w-[300px] whitespace-pre-wrap"}>
                          <DfValueView path="kz-carriers-order.location" id={o.id} />
                          <br />
                          <DfValueView path="kz-carriers-order.locationUpdateDate" id={o.id} />

                          <KzOrderLocationHistory id={o.id} />
                        </Td>

                        <Td>
                          <FtlLogisticsReturnToRoadBtn id={o.id} />
                        </Td>
                        <Td className={"w-[200px]"}>
                          <DfValueView path="kz-carriers-order.internalComment" id={o.id} />
                        </Td>
                      </tr>
                      {viewCargosId === o.id && (
                        <tr>
                          <td colSpan={99}>
                            <KzOrderLogisticCargoLines id={o.id} />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          }
        />
      </WhiteCard>
    </Fragment>
  );
}
