import React, { Fragment, useState } from "react";

import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import {
  SelectorCargoNae,
} from "../../../_generated/_custom/models/ormSelectors";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { ICargoModelNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useKzCarriersOrderHookNae } from "../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { AlertWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { Popup } from '@newageerp/v3.bundles.popup-bundle';
import TablePenalties from "./Widget/TablePenalties";

export default function KzCarriersOrderUnloadWidget(props: PropsId) {
  const t = (s: string) => s;

  const [showExtraExpensesPopup, setShowExtraExpensesPopup] = useState(false);

  const [ignoreFileErrors, setIgnoreFileErrors] = useState(false);

  const element = useKzCarriersOrderHookNae(props.id);
  const elementCargos = element ? element.cargos.map((el) => el.id) : [-1];
  // @ts-ignore
  const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));


  if (!element) {
    return <Fragment />;
  }
  if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
    return <Fragment />;
  }

  let wrongFilesIE599: ICargoModelNae[] = [];
  let wrongFilesEx: ICargoModelNae[] = [];
  cargos.forEach((cargo: ICargoModelNae) => {
    if (cargo.needIE599File && !cargo.files["IE599"]) {
      wrongFilesIE599.push(cargo);
    }
    if (cargo.needMakeEx && !cargo.files["20"]) {
      wrongFilesEx.push(cargo);
    }
  });

  return (
    <Fragment>
      {element.status === 30 && (
        <WhiteCard isCompact={true}>
          <MainEditContentInline
            schema={NaeSSchemaMap.KzCarriersOrder.schema}
            type={"trueUnloadDate"}
            id={element.id.toString()}
            isCompact={true}
          />
        </WhiteCard>
      )}

      {!ignoreFileErrors &&
        element.trueUnloadDate &&
        (wrongFilesIE599.length > 0 || wrongFilesEx.length > 0) &&
        element.status === 30 && (
          <Fragment>
            <AlertWidget
              color="danger"
            >
              <p>{t("Nėra pridėti visi EX/T1 + IE599.")}</p>
              <p>{t("Ar tikrai norite užbaigti užsakymą?")}</p>

              <MainButton
                className={"w-full mt-2"}
                color={"orange"}
                onClick={() => setIgnoreFileErrors(true)}
              >
                {t("Taip")}
              </MainButton>
            </AlertWidget>
          </Fragment>
        )}

      {element.status === 30 &&
        element.trueUnloadDate &&
        (ignoreFileErrors ||
          (wrongFilesIE599.length === 0 && wrongFilesEx.length === 0)) && (
          <MainButton
            iconName={"arrow-alt-right"}
            color="teal"
            onClick={() => setShowExtraExpensesPopup(true)}
          >
            {t("Unloaded")}
          </MainButton>
        )}
      {showExtraExpensesPopup && <Popup isPopup={true} onClick={() => setShowExtraExpensesPopup(false)} title={t('Please, check if all additional expenses are mentioned')}>
        <TablePenalties
          elementId={props.id}
          unloadOnSave={true}
          onSave={() => {
            setShowExtraExpensesPopup(false);
          }}
        />
      </Popup>}
    </Fragment>
  );
}
