import React, { Fragment } from "react";
import CargoLogisticCalculates from "../../../components/cargo/CargoLogisticCalculates";


import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import CargoNotesLogistic from "../../../_generated/_custom/models-cache-data/Cargo/CargoNotesLogistic";
import CargoLogisticCalculatesLine from "../../../components/cargo/Components/CargoLogisticCalculatesLine";
import CargoUrgentChangesTable from "../../../components/cargo/Components/CargoUrgentChangesTable";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useLoadAddressHookNae } from "../../../_generated/_custom/hooks/useLoadAddressHookNae";
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { AlertWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import { CompactRow, FieldLabel } from '@newageerp/v3.bundles.form-bundle'
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserLogisticFtl } from "../../../_generated/_custom/config/NaeSPermissions";
import { RsButton } from "@newageerp/v3.bundles.buttons-bundle";

export default function CargoLogisticCalculatesCard(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const loadAddress1 = useLoadAddressHookNae(
    element && element.loadAddress ? element.loadAddress.id : -1
  );
  const loadAddress2 = useLoadAddressHookNae(
    element && element.loadAddressAdditional ? element.loadAddressAdditional.id : -1
  );
  const loadAddress3 = useLoadAddressHookNae(
    element && element.loadAddressAdditional2 ? element.loadAddressAdditional2.id : -1
  );
  const calculation = useCalculationHookNae(
    element && element.calculation ? element.calculation.id : -1
  );
  const prevCalculation = useCalculationHookNae(
    calculation && calculation.copyFrom ? calculation.copyFrom.id : -1
  );

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isLogisticFtl = checkUserLogisticFtl(userState);

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />;
  }

  let elementForChanges = JSON.parse(JSON.stringify(element));
  if (element.loadAddress && !!loadAddress1) {
    elementForChanges.loadAddress = loadAddress1;
  }
  if (element.loadAddressAdditional && !!loadAddress2) {
    elementForChanges.loadAddressAdditional = loadAddress2;
  }
  if (element.loadAddressAdditional2 && !!loadAddress3) {
    elementForChanges.loadAddressAdditional2 = loadAddress3;
  }

  if (!calculation) {
    return <Fragment />;
  }
  const isLogisticCalculation = !!element.expeditorCalculation && userState.id === element.expeditorCalculation.id;

  if (
    !(
      (isLogisticCalculation || isAdmin || isLogisticFtl) &&
      calculation &&
      calculation.status >= 5
    )
  ) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <WhiteCard title={t("Quotations")} isCompact={true}>
        {!!calculation.status5LastChange && (
          <CompactRow
            label={<FieldLabel>{t("Sent for quotation")}</FieldLabel>}
            control={<DfValueView id={calculation.id} path="calculation.status5LastChange" />}
          />
        )}

        {!!element.notesLogistic ? (
          <AlertWidget color="slate" isCompact={true}>
            <p className={"strong"}>{t("Quotation notes")}</p>
            <CargoNotesLogistic id={element.id} />
          </AlertWidget>
        ) : (
          ""
        )}

        {!!element.beforeChange && (
          <div>
            <CargoUrgentChangesTable id={element.id} />
          </div>
        )}

        {!!element.beforeChange && (
          <p>
            {t(
              "Due to that cargo dimensions have changed, new price has to be negotiated with Sales manager. Please fill in new transportation price."
            )}
          </p>
        )}

        <CargoLogisticCalculates cargoId={element.id} compact={true} calcType={
          element.expeditorCalculationOut && element.expeditorCalculationOut.id === userState.id ? 'out' : 'in'
        } />

      </WhiteCard>

      {!!prevCalculation && <WhiteCard title="Previous quotation" isCompact={true}>
        <RsButton
          button={{
            children: prevCalculation.serialNumber,
            color: 'white'
          }}
          schema="calculation"
          id={prevCalculation.id}
        />
      </WhiteCard>}

      {!!element.deletedCargoLoadingCalculations &&
        element.deletedCargoLoadingCalculations.length > 0 && (
          <WhiteCard title={t("Canceled quotations")} isCompact={true}>

            {element.deletedCargoLoadingCalculations.map((calc: any) => {
              return (
                <Fragment key={"calc-" + calc.id}>
                  <CargoLogisticCalculatesLine
                    id={calc.id}

                    canEdit={false}
                  />
                </Fragment>
              );
            })}

          </WhiteCard>
        )}
    </Fragment>
  );
}
