import React, { useEffect } from "react";

import AdminLogisticCalculation from "./AdminLogisticCalculation";
import AdminOperativeCalculation from "./AdminOperativeCalculation";


import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";

export default function DashboardAdmin() {
 const t = (s: string) => s;
  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
  useEffect(() => {
    setToolbarTitle(t(''));
  }, []);
  
  return (
    <div>
      <AdminLogisticCalculation />
      <AdminOperativeCalculation />
    </div>
  );
}
