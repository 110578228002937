import React, { Fragment } from 'react'
import { PropsId } from './types';
import { NaeSSchemaMap } from '../config/NaeSSchema';
import ClientName from './Client/ClientName';
import SalesOrderSerialNumber from './SalesOrder/SalesOrderSerialNumber';
import SalesOrderClientOrFilialName from './SalesOrder/SalesOrderClientOrFilialName';
import CalculationSerialNumber from './Calculation/CalculationSerialNumber';
import CalculationClientName from './Calculation/CalculationClientName';
import CargoSerialNumber from './Cargo/CargoSerialNumber';
import CargoSalesOrderClientOrFilialName from './Cargo/CargoSalesOrderClientOrFilialName';
import { ClientDebtsSchema, SoDebtsSchema } from '../../../UserComponents/Reports/DebtReports/DebtsReports';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

interface Props {
    parentId: number,
    parentSchema: string,
}

export const NotesSchemaFix = (schema: string) => {
    if (schema === SoDebtsSchema) {
        return "sales-order";
    }
    return schema;
}

export default function NotesNameResolver(props: Props) {
    const t = (s: string) => s;

    if (props.parentSchema === NaeSSchemaMap.Client.schema || props.parentSchema === ClientDebtsSchema) {
        return <NotesNameResolverClient id={props.parentId} />
    }
    if (props.parentSchema === "sales-order" || props.parentSchema === SoDebtsSchema) {
        return <NotesNameResolverSalesOrder id={props.parentId} />
    }
    if (props.parentSchema === "calculation") {
        return <NotesNameResolverCalculation id={props.parentId} />
    }
    if (props.parentSchema === "cargo") {
        return <NotesNameResolverCargo id={props.parentId} />
    }
    if (props.parentSchema === "cargo-insurance") {
        return <NotesNameResolverInsurance id={props.parentId} />
    }
    if (props.parentSchema === "kz-carriers-order") {
        return <NotesNameResolverKzCarriersOrder id={props.parentId} />
    }

    return (
        <Fragment>
            {t("Atidaryti elementą")}
        </Fragment>
    )
}

const NotesNameResolverClient = (props: PropsId) => {
    return <ClientName id={props.id} />
}

const NotesNameResolverSalesOrder = (props: PropsId) => {
    return <Fragment>
        <SalesOrderSerialNumber id={props.id} /> (<SalesOrderClientOrFilialName id={props.id} />)
    </Fragment>
}

const NotesNameResolverCalculation = (props: PropsId) => {
    return <Fragment>
        <CalculationSerialNumber id={props.id} /> (<CalculationClientName id={props.id} />)
    </Fragment>
}

const NotesNameResolverCargo = (props: PropsId) => {
    return <Fragment>
        <CargoSerialNumber id={props.id} /> (<CargoSalesOrderClientOrFilialName id={props.id} />)
    </Fragment>
}

const NotesNameResolverKzCarriersOrder = (props: PropsId) => {
    return <Fragment>
        <DfValueView path='kz-carriers-order.serialNumber' id={props.id} />
    </Fragment>
}

const NotesNameResolverInsurance = (props: PropsId) => {
    return <Fragment>
        <DfValueView path='cargo-insurance.cargo.serialNumber' id={props.id} />
    </Fragment>
}