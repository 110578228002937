import React, { Fragment, useEffect, useState } from "react";

import NEWidgetFiles from "../../../core/components/widgets/NEWidgetFiles";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import { useRecoilState } from "recoil";
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useKzCarriersOrderHookNae } from "../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { useDidMount, useUList, useURequest, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import OldFilesWidget from "../../../core/old-ui/OldFilesWidget";
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { Compact, CompactRow, FieldLabel, FieldTextarea } from "@newageerp/v3.bundles.form-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";

interface ICargo {
  id: number;
  serialNumber: string;
}
interface IData {
  id: number;
  serialNumber: string;
  client: {
    name: string;
    orgDocumentSend: {
      address: string
    }
  };
  clientFilial: {
    name: string;
  };
  creator: {
    fullName: string;
  };
  accounterNotes: string;
  cargos: ICargo[];
}

const defObject: IData = {
  id: 0,
  serialNumber: "",
  client: {
    name: "",
    orgDocumentSend: {
      address: ""
    }
  },
  clientFilial: {
    name: "",
  },
  creator: {
    fullName: "",
  },
  accounterNotes: "",
  cargos: [
    {
      id: 0,
      serialNumber: "",
    },
  ],
};

interface FieldProps {
  comment: string;
  id: number;
}

function AccounterNotesField(props: FieldProps) {
  const isMount = useDidMount();

  const t = (s: string) => s;

  const [comment, setComment] = useState(props.comment);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (!isMount) {
      setHasChanges(true);
    }
  }, [comment]);

  const [saveData, saveDataParams] = useUSave(
    "sales-order"
  );

  const sentData = () => {
    saveData(
      {
        accounterNotes: comment,
      },
      props.id
    ).then(() => {
      setHasChanges(false);
    });
  };

  return (
    <div className={"grid gap-1"}>
      <FieldTextarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      {hasChanges && (
        <MainButton
          onClick={sentData}
          iconName={"save"}
          loading={saveDataParams.loading}
        >
          {t("Save")}
        </MainButton>
      )}
    </div>
  );
}

export default function DashboardAccountingDocs() {
  const t = (s: string) => s;
  const [getData, getDataParams] = useUList<IData>(
    "sales-order",
    getKeysFromObject(defObject)
  );
  const [saveData, saveDataParams] = useUSave(
    "sales-order"
  );

  const [reloadFile, reloadFileParams] = useURequest(
    NaePathsMap.post["AddToSalesOrderFileProcessor"]
  );

  const doReloadFile = (id: number) => {
    reloadFile({ id }).then(() => {
      setTimeout(() => {
        loadData();
      }, 500);
    });
  };

  const loadData = () => {
    getData(
      [
        {
          and: [
            ["i.needSendOrgDocs", "=", true, true],
            ["i.status", "!=", 900, true],
          ],
        },
      ],
      1,
      500,
      [
        {
          key: "i.id",
          value: "ASC",
        },
      ]
    );
  };

  const doSend = (id: number) => {
    if (saveDataParams.loading) return;
    saveData(
      {
        needSendOrgDocs: false,
        addNote: {
          content: "Išsiųsti originalus dokumentai",
        },
      },
      id
    ).then(() => loadData());
  };

  useEffect(loadData, []);

  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState);
  useEffect(() => {
    setToolbarTitle(t("Documents sending"));
  }, []);

  const isData =
    !getDataParams.loading &&
    !!getDataParams &&
    !!getDataParams.data &&
    getDataParams.data.data.length > 0;
  const data = isData ? getDataParams.data.data : [];

  return (
    <Fragment>
      <Fragment>
        <WhiteCard isCompact={true} title={t("Užsakymai")}>
          <Table
            thead={
              <thead>
                <tr>
                  <Th className="w-[200px]">{t("Order")}</Th>
                  <Th className="w-[200px]">{t("Client")}</Th>
                  <Th>{t("Files")}</Th>
                  <Th>{t("Actions")}</Th>
                  <Th>{t("Comment")}</Th>
                  <Th>{t('Waybill (after delivery)')}</Th>
                </tr>
              </thead>
            }
            tbody={
              <tbody>
                {data.map((order) => {
                  return (
                    <tr key={order.id}>
                      <Td>
                        <Compact>
                          <CompactRow
                            label={<FieldLabel>Client order</FieldLabel>}
                            control={<p>{order.serialNumber}</p>}
                          />

                          {order.cargos.length > 0 && (
                            <CompactRow label={<FieldLabel>Carrier order</FieldLabel>} control={<SoKzSerialNumber id={order.cargos[0].id} />} />
                          )}

                          <CompactRow label={<FieldLabel>Responsible</FieldLabel>} control={<p>{order.creator.fullName}</p>} />
                        </Compact>
                      </Td>

                      <Td>
                        <Compact>
                          <CompactRow
                            label={<FieldLabel>Title</FieldLabel>}
                            control={<p>{order.clientFilial
                              ? order.clientFilial.name
                              : order.client.name}</p>}
                          />
                          <CompactRow
                            label={<FieldLabel>Address</FieldLabel>}
                            control={<p>{order.client.orgDocumentSend.address}</p>}
                          />
                        </Compact>

                      </Td>

                      <Td className="space-y-2">
                        <Fragment>
                          <NEWidgetFiles
                            schema={"sales-order"}
                            element={{ id: order.id }}
                            options={{
                              type: 50,
                              title: "Documents",
                              readOnly: true,
                              hideDeletedFiles: true,
                              skipHeader: true,
                              actions: ["download", "preview", "print"],
                            }}
                          />

                          <MainButton
                            className={"w-full"}
                            iconName="sync"
                            color="sky"
                            confirmation={true}
                            onClick={() => doReloadFile(order.id)}
                            loading={reloadFileParams.loading}
                          >
                            {t("Regenerate")}
                          </MainButton>

                        </Fragment>
                      </Td>
                      <Td>
                        <MainButton
                          onClick={() => doSend(order.id)}
                          confirmation={true}
                          color={"teal"}
                          iconName="check"

                        >
                          {t("Finish")}
                        </MainButton>
                      </Td>
                      <Td>
                        <AccounterNotesField
                          id={order.id}
                          comment={order.accounterNotes}
                        />
                      </Td>
                      <Td className="space-y-2">
                        {order.cargos.map((cargo) => {
                          return (
                            <OldFilesWidget
                              schema={"cargo"}
                              element={cargo}
                              options={{
                                title: cargo.serialNumber,
                                type: 70,
                                readOnly: true,
                              }}
                            />
                          );
                        })}
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            }
          />
        </WhiteCard>
      </Fragment>
    </Fragment>
  );
}

const SoKzSerialNumber = (props: PropsId) => {
  const el = useCargoHookNae(props.id);
  const kz = useKzCarriersOrderHookNae(
    el && el.kzCarriersOrder ? el.kzCarriersOrder.id : -1
  );
  if (!el || !kz) {
    return <Fragment />;
  }
  return <Fragment>{kz.serialNumber}</Fragment>;
};
