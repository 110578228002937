import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types'

import { useClientHookNae } from '../../../_generated/_custom/hooks/useClientHookNae';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';

export default function AdminReturnToList(props: PropsId) {
    const t = (s: string) => s;
    const element = useClientHookNae(props.id);

    const [doReq, doReqData] = useURequest(NaePathsMap.post['ClientNeedBusinessDevResetContactsNotFound']);
    const onClick = () => {
        doReq({ id: props.id })
    }

    if (!element) {
        return <Fragment />
    }

    if (element.scopes.indexOf('contacts-not-found') === -1) {
        return <Fragment />
    }

    return (
        <MainButton
            color='red'
            iconName='arrow-rotate-left'
            confirmation={true}
            onClick={onClick}
            loading={doReqData.loading}
        >
            {t('Return to contacts list')}
        </MainButton>
    )
}
