import React, { Fragment } from "react";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { SelectorKzFtlCalculationNae } from "../../../_generated/_custom/models/ormSelectors";
import { useKzCarriersOrderHookNae } from "../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { useSelector, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { IKzFtlCalculationModelNae } from '../../../_generated/_custom/hooks/useKzFtlCalculationHookNae';

import { checkUserAdmin } from '../../../_generated/_custom/config/NaeSPermissions';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { AlertWidget } from "@newageerp/v3.bundles.widgets-bundle";

export default function KzFtlCalculations(props: PropsId) {
 const t = (s: string) => s;
  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  const element = useKzCarriersOrderHookNae(props.id);
  const elementFtlCalculations = element ? element.ftlCalculations.map((el) => el.id) : [-1];

  // @ts-ignore
  const ftlCalculations: IKzFtlCalculationModelNae[] = useSelector((state) => SelectorKzFtlCalculationNae(state, elementFtlCalculations));

  if (!element) {
    return <Fragment />;
  }
  if (ftlCalculations.filter((e: IKzFtlCalculationModelNae) => e === null).length > 0) {
    return <Fragment />;
  }

  let bestCalculation: any = undefined;
  let bestCreatorId = 0;

  ftlCalculations.forEach((calc: IKzFtlCalculationModelNae) => {
    if (bestCalculation === undefined) {
      bestCalculation = calc;
      bestCreatorId = calc.creator.id;
    } else {
      if (bestCalculation.total > calc.total) {
        bestCalculation = calc;
        bestCreatorId = calc.creator.id;
      }
    }
  });


  return (
    <div className="space-y-2">
      {bestCalculation && (
        <AlertWidget
          color={
            (bestCreatorId === userState.id || isAdmin)
              ? "success"
              : "danger"
          }
          isCompact={true}
          className={"space-y-1"}
        >
          <p className="font-bold">{t("Geriausias variantas")}</p>
          <div className={"flex gap-2"}>
            <p className="flex-grow">{bestCalculation.serialNumber}</p>
            <p className="whitespace-nowrap">
              {bestCalculation.total.toFixed(2)} {bestCalculation.currency ? bestCalculation.currency.currency : ""}
            </p>
          </div>
          <p>
            <DfValueView path="user.fullName" id={bestCalculation.creator.id} />
          </p>

        </AlertWidget>
      )}

    </div>
  );
}
