import React, { Fragment, useState, useEffect } from "react";
import CalculationCostHistory from "../../Calculation/Widget/CalculationCostHistory";
import CalculationSerialNumberButtonLink from '../../../_generated/_custom/models-cache-data/Calculation/CalculationSerialNumberButtonLink';

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { TextMultiline } from "@newageerp/v3.bundles.data-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";

interface ICalculation {
  id: number,
  serialNumber: string,
  cost: string,
}

const defCalculation: ICalculation = {
  id: 0,
  serialNumber: '',
  cost: '',
}

export default function SalesOrderCalculationWidget(props: PropsId) {
  const t = (s: string) => s;
  const element = useSalesOrderHookNae(props.id);
  const [showCost, setShowCost] = useState(false);

  const [getData, getDataParams] = useUList<ICalculation>("calculation", getKeysFromObject(defCalculation));
  const loadData = () => {
    getData(
      [
        {
          "and": [
            ['i.cargos.salesOrder.id', '=', element.id, true]
          ]
        }
      ],
      1,
      100,
      [{
        key: 'i.serialNumber',
        value: 'ASC'
      }]
    )
  }

  useEffect(() => {
    if (showCost) {
      loadData();
    }
  }, [showCost]);

  if (!element) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {getDataParams.data.data.map((calculation) => {
        const cost: string = calculation.cost;

        return (
          <Fragment>
            <CalculationSerialNumberButtonLink id={calculation.id} />
            <WhiteCard isCompact={true} key={"calculation-" + calculation.id}>
              <div className={"text-xs"}>
                <TextMultiline
                  text={cost.replaceAll(":", "\n").replaceAll(" . ", "")}
                  initRows={5}
                  isMarkdown={true}
                />
              </div>

            </WhiteCard>
            
            <CalculationCostHistory id={calculation.id} />
          </Fragment>
        );
      })}

      {!showCost &&
        <MainButton
          color="bluelight"
          className={"w-full"}
          onClick={() => setShowCost(true)}
          iconName="calculator"
        >
          {t("Show selfcost calculations")}
        </MainButton>
      }
    </Fragment>
  );
}
