import React, { Fragment, useState } from "react";

import KzOrderLocation from "../cargo/kz-order/KzOrderLocation";
import KzOrderLogisticCargoLines from "../cargo/kz-order/KzOrderLogisticCargoLines";
import KzOrderCarNumberWithInfo from "../../../UserComponents/KzCarriersOrder/Components/KzOrderCarNumberWithInfo";

import KzCarrierLinkWithEye from '../../../UserComponents/KzCarriersOrder/Components/KzCarrierLinkWithEye';
import KzDriverTd from "../../../UserComponents/KzCarriersOrder/Components/KzDriverTd";
import KzOrderLocationDate from "../cargo/kz-order/KzOrderLocationDate";

import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserOperational } from "../../../_generated/_custom/config/NaeSPermissions";
import KzOrderLocationHistory from "../cargo/kz-order/KzOrderLocationHistory";
interface IElement {
  id: number,
  status20Date: string,
}

interface Props {
  data: IElement[];
}

export default function KzLogisticOrderRoad(props: Props) {
  const t = (s: string) => s;

  const [viewCargosId, setViewCargosId] = useState<number>(0);

  const {userState} = useTemplatesCore();
  const isDps = checkUserOperational(userState);

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("En route")}>

        <Table
          thead={
            <thead>
              <tr>
                <Th>{t('Number')}</Th>
                <Th>{t('Carrier')}</Th>
                <Th>
                  {t('Date of departure from the warehouse')}
                </Th>
                <Th>{t('Cargo specifics')}</Th>
                <Th>{t('Driver\'s contact')}</Th>
                <Th>{t('Route')}</Th>
                <Th>{t('Vehicle numbers')}</Th>
                <Th>{t('Vehicle location')}</Th>
                <Th>{t('My notes')}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data
                .sort((a, b) => {
                  const aDateStr = a.status20Date;
                  const bDateStr = b.status20Date;

                  var x = aDateStr.toLowerCase();
                  var y = bDateStr.toLowerCase();
                  return x < y ? -1 : x > y ? 1 : 0;
                })
                .map((o: IElement) => {
                  return (
                    <Fragment key={"kz-o-" + o.id}>
                      <tr>
                        <Td>
                          <KzCarrierLinkWithEye id={o.id} viewCargosId={viewCargosId} setViewCargosId={setViewCargosId} />

                          <p><DfValueView path="kz-carriers-order.doer.fullName" id={o.id} /> (FWD)</p>
                          <p>---</p>
                          <p><DfValueView path="kz-carriers-order.userDps.fullName" id={o.id} /> (DPS)</p>
                        </Td>

                        <Td>
                          <p>
                            <DfValueView path="kz-carriers-order.client.name" id={o.id} />
                          </p>
                          <p>
                            <DfValueView path="kz-carriers-order.contact.fullName"
                              id={o.id}
                            />
                          </p>
                        </Td>
                        <Td className="whitespace-nowrap">
                          <DfValueView path="kz-carriers-order.status20Date" id={o.id} />
                        </Td>
                        <Td>
                          <DfValueView path="kz-carriers-order.cargoNotes" id={o.id} />
                        </Td>
                        <Td><KzDriverTd id={o.id} /></Td>
                        <Td>
                          <DfValueView path="kz-carriers-order.trip" id={o.id} />
                        </Td>
                        <Td>
                          <KzOrderCarNumberWithInfo id={o.id} />
                        </Td>

                        <Td>
                          <KzOrderLocation id={o.id} />
                          <KzOrderLocationDate id={o.id} />

                          <KzOrderLocationHistory id={o.id} />
                        </Td>

                        <Td className={"w-[200px]"}>
                        {isDps && <DfValueView path="kz-carriers-order.dpsNotes" id={o.id} />}
                        {!isDps && <DfValueView path="kz-carriers-order.internalComment" id={o.id} />}
                        </Td>
                      </tr>
                      {viewCargosId === o.id && (
                        <tr className={"bg-info-light"}>
                          <td colSpan={99}>
                            <KzOrderLogisticCargoLines id={o.id} />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          }
        />
      </WhiteCard>
    </Fragment>
  );
}
