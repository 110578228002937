import { NotesContent, NotesLine } from '@newageerp/v3.bundles.notes-bundle';
import { Popup } from '@newageerp/v3.bundles.popup-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment, useEffect, useState } from 'react'

import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';
import { getKeysFromObject } from '@newageerp/v3.bundles.utils-bundle';
import HTMLReactParser from 'html-react-parser';

interface Props {

}

export default function Comments(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const [showPopup, setShowPopup] = useState(false);
    const [notesReq, notesReqData] = useUList<INote>('note', getKeysFromObject(defObject))

    useEffect(() => {
        notesReq(
            [{
                "and": [
                    ['i.parentId', '=', tData.element.id, false],
                    ['i.parentSchema', '=', 'cargo-insurance', false]
                ]
            }],
            1,
            1,
            [
                {
                    key: 'i.id',
                    value: 'DESC'
                }
            ]
        )
    }, []);

    return (
        <div className='space-y-2'>
            {notesReqData.data.data.length > 0 && <NoteViewLine note={notesReqData.data.data[0]} />}
            <div>
                <ToolbarButton
                    onClick={() => setShowPopup(true)}
                    iconName='comment'
                    children={<Fragment>Show comments</Fragment>}
                />
            </div>
            {showPopup && <CommentsNotesPopup id={tData.element.id} onClose={() => setShowPopup(false)} />}
        </div>
    )
}

type NoteViewProps = {
    note: INote
}

const NoteViewLine = (props: NoteViewProps) => {

    return (
        <Fragment>
            <hr/>
            <div className='flex gap-1 items-center text-xs'>
                <div className={"text-gray-400"}>{props.note.createdAt}</div>
                <div>
                    {props.note.creator ? props.note.creator.fullName : "System"}
                </div>
            </div>
            <div>{HTMLReactParser(props.note.content)}</div>
            <hr/>
        </Fragment>
    )
}

interface CommentsNotesProps {
    id: number,
    onClose: () => void,
}

const CommentsNotesPopup = (props: CommentsNotesProps) => {
    const t = (s: string) => s;
    // const client = useClientHookNae(props.id);

    return (
        <Popup isPopup={true} onClick={props.onClose} title={t('Comment')}>
            <div className="container mx-auto">
                <WhiteCard isCompact={true}>


                    <NotesContent schema={"cargo-insurance"} id={props.id} options={{}} showOnlyMy={false} />
                </WhiteCard>
            </div>

        </Popup>
    )
}


interface INote {
    id: number;
    notifyAccept: any;
    notify: any;
    creator: {
        id: number;
        fullName: string;
    };
    createdAt: string;
    content: string;
    parentId: number;
    parentSchema: string;
    replyTo: number;
}
const defObject: INote = {
    id: 0,
    notifyAccept: 0,
    notify: 0,
    creator: {
        id: 0,
        fullName: "",
    },
    createdAt: "",
    content: "",
    parentId: 0,
    parentSchema: "",
    replyTo: 0,
};