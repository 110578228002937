import React from "react";

import KzLogisticOrdersWrapper from "./KZLogistic/KzLogisticOrdersWrapper";
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';

export default function DashboardKzLogistic() {
  const t = (s: string) => s;

  return (
    <div>
      <MainToolbarTitle title="Purchase orders (IK)" />
      <KzLogisticOrdersWrapper full={true} />
    </div>
  );
}
