import React, { Fragment } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types';

import { useSalesOrderHookNae } from '../../_generated/_custom/hooks/useSalesOrderHookNae';
import OldBadge, { BadgeBgColor, BadgeSize } from '../../core/old-ui/OldBadge';

export default function SalesOrderBadges(props: PropsId) {
    const salesOrder = useSalesOrderHookNae(props.id);
    const t = (s: string) => s;

    if (!salesOrder) {
        return <Fragment />
    }
    return (
        <Fragment>
            {!!salesOrder.confirmCancelText && (
                <OldBadge size={BadgeSize.xs} bgColor={BadgeBgColor.red}>
                    {t('Nepatvirtintas')}
                </OldBadge>
            )}
        </Fragment>
    )
}
