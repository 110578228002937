import React, { Fragment, useEffect } from "react";

import CargoLogisticLink from "../../cargo/CargoLogisticLink";
import CargoSenderMailBtn from "../../../Plugins/Cargo/CargoSenderMailBtn";
import LogisticCargoReturn from "../../../UserComponents/Cargo/Widget/LogisticCargoReturn";

import CargoSalesOrderClientOrFilialName from '../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName';
import CargoTerminalOrSalesOrderAddress from '../../../_generated/_custom/models-cache-data/Cargo/CargoTerminalOrSalesOrderAddress';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { StatusWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserLogisticSea, checkUserLogisticTrain } from "../../../_generated/_custom/config/NaeSPermissions";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { useDfValue } from "@newageerp/v3.app.data-cache-provider";
import ClientStatusLogist from "../../../_generated/_custom/models-cache-data/Client/ClientStatusLogist";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { getKeysFromObject, groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { useLoadAddressHookNae } from "../../../_generated/_custom/hooks/useLoadAddressHookNae";
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";

type IElement = {
  id: number,
  salesOrder: {
    id: number
  },
  seller: {
    id: number,
  }
}
const defObject: IElement = {
  id: 0,
  salesOrder: {
    id: 0,
  },
  seller: {
    id: 0,
  }
}

let reloadTimeout: any = -1;

type Props = {
  userId: number,
}

export default function LogisticCargoNew(props: Props) {
  const t = (s: string) => s;
  const { userState } = useTemplatesCore()
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const [getData, getDataParams] = useUList<IElement>("cargo", getKeysFromObject(defObject));
  const loadData = () => {
    let filter: any = [
      ['i.logisticCardIndex', '=', 1, true],
      ['i.status', 'in', [5, 7, 10], true]
    ];

    filter.push(
      ['i.expeditor', '=', props.userId, true]
    );

    getData(
      [{ "and": filter }],
      1,
      200,
      [
        { key: 'i.serialNumber', 'value': 'ASC' }
      ]
    )
  }
  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(loadData, 500);
  };

  useEffect(() => {
    SocketService.addCallback(
      "cargo-logisticCardIndex-1",
      "cargo-logisticCardIndex-1",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "cargo-logisticCardIndex-1",
        "cargo-logisticCardIndex-1"
      );
    };
  }, []);

  const groupedBySo = groupMap(getDataParams.data.data, (el: IElement) => `${el.salesOrder.id}-${el.seller.id}`);
  const keys = Object.keys(groupedBySo);

  return (
    <WhiteCard isCompact={true} title={t("New loads")}>
      <Table
        thead={
          <thead>
            <tr>
              <Th>{t('Cargo')}</Th>
              <Th>{t('Seller')}</Th>
              <Th>{t('Client')}</Th>
              <Th></Th>
              <Th>{t('Unloading')}</Th>
              {(isLogisticSea || isLogisticTrain) && <Th>{t('Type of transportation')}</Th>}
              <Th>{t('Return')}</Th>
              <Th>{t('Letter for seller')}</Th>

            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {keys.map((c, index) => <LogisticCargoNewLine ids={groupedBySo[c]} key={"cargo-" + c} />)}
          </tbody>
        }
      />
    </WhiteCard>
  );
}

type LogisticCargoNewLineProps = {
  ids: IElement[]
}

const LogisticCargoNewLine = (props: LogisticCargoNewLineProps) => {
  const { userState } = useTemplatesCore()
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const c = useCargoHookNae(props.ids[0].id);
  const loadAddress = useLoadAddressHookNae(!!c && c.loadAddress ? c.loadAddress.id : -1);
  if (!c) {
    return <Fragment />
  }
  return (
    <tr>
      <Td className="space-y-2">
        <div className="flex flex-col gap-1">
          {props.ids.map(el => <CargoLogisticLink id={el.id} key={`c-${el.id}`} />)}
        </div>
        <p><DfValueView path="cargo.creator.fullName" id={c.id} /></p>
      </Td>
      <Td>
        <DfValueView path="cargo.seller.name" id={c.id} />

        {!!loadAddress &&
          !!loadAddress.senderRequirements && (

            <Tooltip
              text={loadAddress.senderRequirements.replaceAll("\n", "|||")}
            />
          )}
      </Td>
      <Td>
        <CargoSalesOrderClientOrFilialName id={c.id} />
      </Td>
      <Td><ClientStatusLogist id={c.soClientId} /></Td>
      <Td>
        <CargoTerminalOrSalesOrderAddress id={c.id} />
      </Td>
      {(isLogisticSea || isLogisticTrain) &&
        <Td>
          <DfValueView path="cargo.typeIn" id={c.id} />
        </Td>
      }
      <Td>
        <LogisticCargoReturn
          ids={props.ids.map(el => el.id)}
          sm={true}
          transparent={true}
        />
      </Td>
      <Td className="space-y-2">
        <div className="flex flex-col gap-1">
          {props.ids.map(el => <InsuranceLine id={el.id} key={`ins-${el.id}`} />)}
        </div>

        <CargoSenderMailBtn ids={props.ids.map(el => el.id)} sm={true} />
      </Td>

    </tr>
  );
}
const InsuranceLine = (props: PropsId) => {
  const t = (s: string) => s;
  const insuranceStatus = useDfValue({
    id: props.id,
    path: 'cargo.cargoInsurance.status'
  })
  return <Fragment>
    {insuranceStatus === 10 && <StatusWidget color="red">{t('Insurance need order')}</StatusWidget>}
    {insuranceStatus === 30 && <StatusWidget color="teal">{t('Insurance ordered')}</StatusWidget>}
  </Fragment>
}