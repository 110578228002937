import React, { Fragment, useEffect } from "react";

import CargoLogisticCalculates from "../../cargo/CargoLogisticCalculates";

import NotesLine from "../../../UserComponents/Notes/NotesLine";
import RelatedCargosLine from "../../../UserComponents/Cargo/Components/RelatedCargosLine";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useCalculationHookNae } from "../../../_generated/_custom/hooks/useCalculationHookNae";
import CargoFtlAutoTypeBadge from "../../../_generated/badges/cargo/CargoFtlAutoTypeBadge";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getPropertyEnumLabel } from "../../../_generated/v3/utils";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { checkUserLogisticFtl } from "../../../_generated/_custom/config/NaeSPermissions";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import CargoCalculationReturnLogistic from "../../../UserComponents/Calculation/Components/CargoCalculationReturnLogistic";


interface IData {
  id: number;
  ftlType: number;
  ownerCompanyId: number;
}
const defObject: IData = {
  id: 0,
  ftlType: 0,
  ownerCompanyId: 0,
};

let timeoutCalcCargoReloadDashboard: any = -1;

export const ftlLogisticCargoFilter = {
  or: [
    ["i.type", "=", 20, true],
    {
      and: [
        ["i.type", "=", 40, true],
        ["i.modalType", "=", 10, true],
      ],
    },
    {
      and: [
        ["i.type", "=", 40, true],
        ["i.modalType", "=", 60, true],
      ],
    },
  ],
};


type Props = {
  ftlType: number
}

export default function LogisticCalculationFtl(props: Props) {
  const t = (s: string) => s;

  const { userState } = useTemplatesCore()
  const isFtl = checkUserLogisticFtl(userState);

  const [getData, getDataParams] = useUList<IData>(
    "cargo",
    getKeysFromObject(defObject)
  );

  const filter = props.ftlType === 0 ? {
    or: [
      {
        and: [
          ["i.type", "=", 20, true],
          ["i.ftlType", "=", 0, true],
        ]
      },

      {
        and: [
          ["i.type", "=", 40, true],
          ["i.modalType", "=", 10, true],
        ],
      },

      {
        and: [
          ["i.type", "=", 40, true],
          ["i.modalType", "=", 60, true],
        ],
      }

    ],
  } :
    {
      and: [
        ["i.type", "=", 20, true],
        ["i.ftlType", "=", 10, true],
      ]
    };

  const loadData = () => {
    const filters = [
      {
        and: [
          ["i.calculation.status", "gte", 5, true],
          ["i.loadingPriceCalculated", "=", 0, true],
          ["i.status", "!=", 900, true],
          ["i.type", "!=", 50, true],
          filter,
        ],
      },
    ];

    getData(filters, 1, 500, [
      {
        key: "i.calculation.purpose",
        value: "DESC",
      },
      {
        key: "i.serialNumber",
        value: "ASC",
      },
    ]);
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(timeoutCalcCargoReloadDashboard);
    timeoutCalcCargoReloadDashboard = setTimeout(() => {
      loadData();
    }, 1500);
  };

  useEffect(() => {
    SocketService.subscribe("calc-cargo-dashboard-" + userState.id);
    return () => {
      SocketService.unsubscribe("calc-cargo-dashboard-" + userState.id);
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "calc-cargo-reload-dashboard",
      "calc-cargo-reload-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "calc-cargo-reload-dashboard",
        "calc-cargo-reload-dashboard"
      );
    };
  }, []);

  const ftlCompanies: number[] = userState.ftlCompanies.map((i: any) => i.id);

  const dataToMap = getDataParams.data.data.filter((el: IData) => {
    if (isFtl) {
      return ftlCompanies.indexOf(el.ownerCompanyId) >= 0
    }
    return true;
  });
  const keysToMap = groupMap(dataToMap, (el: IData) =>
    el.ftlType.toString()
  );

  return (
    <WhiteCard isCompact={true}>
      <Table
        thead={
          <thead>
            <tr>
              <Th>{t("Cargo")}</Th>
              <Th className={"w-[20%]"}>{t("Loading")}</Th>
              <Th className={"w-[15%]"}>{t("Unloading")}</Th>
              <Th>{t("Type of vehicle")}</Th>
              <Th className={"w-[20%]"}>{t("Notes")}</Th>
              <Th></Th>
              <Th className={"w-[280px]"}>{t("Carrier, Price")}</Th>
            </tr>
          </thead>
        }
        tbody={<tbody>
          {Object.keys(keysToMap).map((key: string, keysToMapI: number) => {
            const data: IData[] = keysToMap[key];
            return (
              <Fragment key={`keysToMapI-${keysToMapI}`}>
                <tr className={"total-row"}>
                  <Td colSpan={99}>
                    <strong>
                      {getPropertyEnumLabel(
                        "cargo",
                        "ftlType",
                        parseInt(key, 10)
                      )}
                    </strong>
                  </Td>
                </tr>
                {data.map((cargo: IData) => {
                  return (
                    <LogisticCalculationFtlCargoLine
                      key={"cargo-el-" + cargo.id}
                      id={cargo.id}
                    />
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>}
      />
    </WhiteCard>
  );
}

const LogisticCalculationFtlCargoLine = (props: PropsId) => {
  const cargo = useCargoHookNae(props.id);
  const calculation = useCalculationHookNae(
    cargo && cargo.calculation ? cargo.calculation.id : -1
  );

  const { userState } = useTemplatesCore()

  if (!cargo) {
    return <Fragment />;
  }
  if (cargo.ftlCalculationStop[userState.id] === 1) {
    return <Fragment />;
  }

  const className = [];
  if (cargo.deletedCargoLoadingCalculations.length > 0) {
    className.push("bg-yellow-100");
  }
  if (!!calculation && calculation.purpose === 30) {
    className.push("border-bottom-2-warning");
  }

  return (
    <tr className={className.join(" ")}>
      <Td>
        <DfValueView id={cargo.id} path="cargo.serialNumber" link={true} />

        {!!calculation && (
          <p>
            <DfValueView path="calculation.purpose" id={calculation.id} />
          </p>
        )}
        <p>
          <DfValueView path="cargo.creator.fullName" id={cargo.id} />
        </p>
        <p>
          <DfValueView path="cargo.calculation.status5LastChange" id={cargo.id} />
        </p>

        <NotesLine
          parentId={cargo.id}
          parentSchema={"cargo"}
        />
      </Td>

      <Td>
        {!!calculation && calculation.purpose === 10 && (
          <DfValueView path="cargo.loadingAddress" id={cargo.id} />
        )}

        {!!calculation && calculation.purpose !== 10 && (
          <Fragment>
            <p className="font-medium">
              <DfValueView path="cargo.seller.name" id={cargo.id} />
            </p>
            <p>
              <DfValueView path="cargo.loadingAddressFullWoSeller" id={cargo.id} />
            </p>
          </Fragment>
        )}

        <RelatedCargosLine id={cargo.id} />
      </Td>
      <Td>
        {!!cargo.terminal && cargo.terminal.id > 0 ? (
          <DfValueView id={cargo.id} path={"cargo.terminal.fullAddress"} />
        ) : !!cargo.calculation && !!cargo.calculation.address ? (
          <DfValueView path="cargo.calculation.address.fullAddress" id={cargo.id} />
        ) : (
          ""
        )}
      </Td>
      <Td>
        <CargoFtlAutoTypeBadge id={cargo.id} isCompact={true} />
      </Td>

      <Td>
        <DfValueView path="cargo.notesLogistic" id={cargo.id} />
      </Td>
      <Td className="whitespace-nowrap">
        <CargoCalculationReturnLogistic id={cargo.id} />
      </Td>
      <Td className={"w-[250px]"}>
        <CargoLogisticCalculates cargoId={cargo.id} calcType="in" />
      </Td>
    </tr>
  );
};
