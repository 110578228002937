import React, { Fragment, useState } from 'react'
import { useSelector } from '@newageerp/v3.templates.templates-core';
import { SelectorCalculationNae } from '../../../../_generated/_custom/models/ormSelectors';
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';

import { ICalculationModelNae } from '../../../../_generated/_custom/hooks/useCalculationHookNae';
import { isCalculatedCalculationDelayed } from '../../../Calculation/CalculationHelpers';
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle';
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';

interface Props {
    ids: number[]
}


export default function ManagerCalculationCalculatedDelayedPopup(props: Props) {
    const t = (s: string) => s;

    const [hidePopover, setHidePopover] = useState(false);
    // @ts-ignore
    const elements: ICalculationModelNae[] = useSelector((state) => SelectorCalculationNae(state, props.ids));

    const delayedCalculations = !!elements && !hidePopover ? elements.filter((el: ICalculationModelNae) => isCalculatedCalculationDelayed(el)) : [];

    const [saveData, saveDataParams] = useUSave("calculation");

    const hideDelayed = () => {
        delayedCalculations.forEach(el => {
            saveData(
                {
                    status: 40
                },
                el.id
            )
        });
    }

    if (hidePopover || delayedCalculations.length === 0) {
        return <Fragment />;
    }

    return (
        <div className={"absolute inset-0 bg-gradient-to-br from-nsecondary-50 via-white"}>
            <div className={"flex justify-center items-center h-full"}>
                {saveDataParams.loading ? <LogoLoader /> :
                    <div className={"grid gap-4"}>
                        <TextCardTitle>{t('You have calculations that have not been given status for more than 14 days.')}</TextCardTitle>
                        <p>{t('Please change the status or this calculation will be given the status "No response".')}</p>
                        <div className={"grid gap-2 grid-cols-4"}>
                            {delayedCalculations.map(el => {
                                return (<p className={"text-[14px]"}>{el.serialNumber}</p>)
                            })}
                        </div>
                        <div className={"grid gap-2 grid-cols-2"}>

                            <MainButton color={"teal"} confirmation={true} onClick={hideDelayed}>{t('Remove from calculated')}</MainButton>

                            <MainButton color={"amber"} onClick={() => setHidePopover(true)}>
                                {t('Leave calculations')}
                            </MainButton>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
