import React, { Fragment } from 'react'

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export default function PriceBlock(props: PropsId) {
    const {userState} = useTemplatesCore()

    const element = useSalesOrderHookNae(props.id);

    const t = (s: string) => s;

    if (!element) {
        return <Fragment />
    }


    return (
        <div className='space-y-2'>
            <div className="flex gap-2 items-center">
                <div>
                    {t("Price")}
                    {", "}
                    {element.currency.currency}
                </div>
            </div>
            <div>
                <p className='text-3xl'>{element.total.toFixed(2)}</p>
            </div>
        </div>
    )
}
