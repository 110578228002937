import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import moment from 'moment';

import { checkUserManager, checkUserLogistic, checkUserLogisticFtl, checkUserLogisticSea, checkUserLogisticTrain, checkUserAdmin } from '../../../_generated/_custom/config/NaeSPermissions';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function BottomWidgetLoadDates(props: PropsId) {
    const { userState } = useTemplatesCore()
    const isManager = checkUserManager(userState);
    const isAdmin = checkUserAdmin(userState);

    const isLogistic = checkUserLogistic(userState);
    const isLogisticFtl = checkUserLogisticFtl(userState);
    const isLogisticTrain = checkUserLogisticTrain(userState);
    const isLogisticSea = checkUserLogisticSea(userState);

    const showLoadDatePopup = () => {
        SFSOpenEditModalWindowProps({
            id: props.id,
            schema: "cargo",
            type: 'cargo-load-date'
        })
    }
    const showLoadDateSellerPopup = () => {
        SFSOpenEditModalWindowProps({
            id: props.id,
            schema: "cargo",
            type: 'cargo-load-date-seller'
        })
    }

    const t = (s: string) => s;

    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }

    const canChangeLoadDate = isManager || isAdmin;

    const canChangeLoadDateSeller = isAdmin || isLogistic || isLogisticFtl || isLogisticSea || isLogisticTrain;

    return (
        <div className={"space-y-1 py-2"}>
            <div>
                <div className={"flex items-center gap-1"}>
                    <FieldLabel className='flex-grow'>{t('Approximate ready date')}:</FieldLabel>
                    <Tooltip text={t('Information about ready date was received from the client. Real ready date may be different')} />
                    {canChangeLoadDate &&
                        <ToolbarButton
                            iconName='edit'
                            bgColor='bg-transparent'
                            onClick={showLoadDatePopup}
                            small={true}
                        />
                    }

                </div>

                <div>
                    {element.loadDate ? moment(element.loadDate).format("YYYY-MM-DD") : "Not indicated"}
                </div>
            </div>


            <div>
                <div className={"flex items-center gap-1"}>
                    <FieldLabel className='flex-grow'>{t('Actual ready date')}:</FieldLabel>
                    {canChangeLoadDateSeller &&
                        <ToolbarButton
                            iconName='edit'
                            bgColor='bg-transparent'
                            onClick={showLoadDateSellerPopup}
                            small={true}
                        />
                    }
                </div>
                <div>{element.loadDateSeller ? moment(element.loadDateSeller).format("YYYY-MM-DD") : t('Not indicated')}</div>
            </div>
        </div>
    )
}
