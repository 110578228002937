import React, { Fragment } from "react";
import moment from "moment";

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { AlertWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useNaeWindow } from "@newageerp/v3.popups.window-provider";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

export default function CargoStockStatus(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const { closeViewPopup } = useNaeWindow();

  const t = (s: string) => s;

  const {userState} = useTemplatesCore()

  const [saveData, saveDataParams] = useUSave(
    "cargo",
  );
  const doSave = () => {
    saveData(
      {
        status: 15,
        unloadWarehouseDate: moment().format("YYYY-MM-DD"),
      },
      element.id
    ).then(() => { closeViewPopup(); });
  };

  if (!element) {
    return <Fragment />;
  }
  const isError =
    !element.files["10"] || (!element.files["20"] && element.needEx1T1File) || !element.files["stock-docs"];

  const isLogistic =
    !!element.expeditor && userState.id === element.expeditor.id;
  if (!isLogistic) {
    return <Fragment />;
  }

  if (element.status !== 10) {
    return <Fragment />;
  }
  if (!element.operationConfirmation) {
    return <Fragment />;
  }
  if (!element.stock) {
    return <Fragment />;
  }

  return (
    <div className={"space-y-2"}>
      {!element.files["stock-docs"] && (
        <AlertWidget isCompact={true} color={"danger"}>
          {t('No warehouse documents uploaded')}
        </AlertWidget>

      )}
      {!element.files["10"] && (
        <AlertWidget isCompact={true} color={"danger"}>
          {t('No Seller documents uploaded')}
        </AlertWidget>
      )}
      {!element.files["20"] && element.needEx1T1File && (
        <AlertWidget isCompact={true} color={"danger"}>
          {t('No EX / T1 uploaded')}
        </AlertWidget>
      )}
      {!isError && (
        <WhiteCard isCompact={true}>
          {!element.files["80"] && (
            <AlertWidget isCompact={true} color={"warning"}>
              {t('No photo uploaded')}
            </AlertWidget>
          )}

          <p>
            {t('I confirm that all information is filled in, all files are uploaded.')}
          </p>

          <MainButton iconName={"warehouse"} color={"teal"} className={"w-full"} onClick={doSave} loading={saveDataParams.loading}>
            {t('Finish')}
          </MainButton>

        </WhiteCard>
      )}
    </div>
  );
}
