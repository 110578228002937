import React, { useState, Fragment } from "react";


import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { AlertWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserHeadOfLogistics } from '../../../_generated/_custom/config/NaeSPermissions';

export default function CargoLogisticUrgentData(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isCoo = checkUserHeadOfLogistics(userState);

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />;
  }
  const isLogistic =
    !!element.expeditor && userState.id === element.expeditor.id;
  if (
    !(
      ((element.status === 5 || element.status === 10) &&
        !!element.salesOrder &&
        isLogistic &&
        element.contactedSeller 
        // && !element.operationConfirmation
        ) ||
      isCoo ||
      isAdmin
    )
  ) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Data changes")}>
        <AlertWidget
          color="warning"
          isCompact={true}
        >
          {t("Attention! If the data will change, cargo will be returned for requotation.")}
        </AlertWidget>

        <MainButton
          className={"w-full"}
          confirmation={true}
          onClick={() =>
            SFSOpenEditModalWindowProps({
              id: element.id,
              schema: "cargo",
              type: "cargo-logistic",
            })
          }
          iconName={"edit"}
          color={"bluelight"}
        >
          {t("Change dimensions or loading address")}
        </MainButton>
      </WhiteCard>
    </Fragment>
  );
}
