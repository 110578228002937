import React, { useState } from "react";

import LogisticCalculation from "../../../components/dashboard/Logistic/LogisticCalculation";
import LogisticCalculationNeedInfo from "./LogisticCalculationNeedInfo";
import LogisticCargoWrapper from "../../../components/dashboard/Logistic/LogisticCargoWrapper";
import { TemplatesLoader, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { checkUserAdmin } from "../../../_generated/_custom/config/NaeSPermissions";
import { FieldLabel, Wide, WideRow } from "@newageerp/v3.bundles.form-bundle";
import { SelectFieldSchema } from "@newageerp/v3.bundles.app-bundle";
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";

export default function DashboardLogistic() {
  const { getTabFromSchemaAndType } = useUIBuilder();

  const t = (s: string) => s;
  const { userState } = useTemplatesCore();
  const isAdmin = checkUserAdmin(userState);
  const [dashUserId, setDashUserId] = useState(userState.id);

  return (
    <div className="space-y-4">
      {isAdmin &&
        <WhiteCard isCompact={true}>
          <Wide>
            <WideRow
              label={<FieldLabel>{t("User")}</FieldLabel>}
              control={
                <div className="flex gap-2">
                  <SelectFieldSchema
                    tab={getTabFromSchemaAndType('user', 'main')}
                    schema={"user"}
                    value={{ id: dashUserId }}
                    onChange={(val) => setDashUserId(val.id)}
                    className={"w-60"}
                  />
                </div>
              } />
          </Wide>
        </WhiteCard>
      }

      <div className="space-y-4" key={`user-${dashUserId}`}>
        <TemplatesLoader
          templateName="DashboardPoints"
          data={{
            userId: dashUserId
          }}
        />

        <LogisticCalculationNeedInfo userId={dashUserId} />

        <LogisticCalculation userId={dashUserId} />

        <TextCardTitle>{t("Cargo")}</TextCardTitle>

        <LogisticCargoWrapper userId={dashUserId} />

        {/* <LogisticFtlHelperCargos /> */}
      </div>
    </div>
  );
}
