import React, { Fragment } from "react";

import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { getPropertyTitleForSchema } from "../../../_generated/v3/utils";
import { TextMultiline } from "@newageerp/v3.bundles.data-bundle";
import { AlertWidget } from "@newageerp/v3.bundles.widgets-bundle";

export default function CargoUrgentChangesTable(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />
  }

  const changes: string[] = Object.keys(element.beforeChange);

  return (
    <AlertWidget color="slate" isCompact={true}>

      <p className="border-b border-nsecondary-500 font-medium">{t("Old data")}</p>

      <div>
        <CargoUrgentChangesTableHeader changes={changes} />

        <div className={"grid gap-1 grid-flow-col grid-cols-" + changes.length}>
          {changes.indexOf('places') >= 0 &&
            <div>
              <TextMultiline text={element.beforeChange['places']} />
            </div>
          }
          {changes.indexOf('dimensions') >= 0 &&
            <div>
              <TextMultiline text={element.beforeChange['dimensions']} />
            </div>
          }
          {changes.indexOf('weight') >= 0 &&
            <div>
              <TextMultiline text={element.beforeChange['weight']} />
            </div>
          }
        </div>
      </div>

      <p className="border-b border-nsecondary-500 font-medium">{t("New data")}</p>

      <div>
        <CargoUrgentChangesTableHeader changes={changes} />

        <div className={"grid gap-1 grid-flow-col grid-cols-" + changes.length}>
          {changes.indexOf('places') >= 0 &&
            <div>
              <TextMultiline text={element['places']} />
            </div>
          }
          {changes.indexOf('dimensions') >= 0 &&
            <div>
              <TextMultiline text={element['dimensions']} />
            </div>
          }
          {changes.indexOf('weight') >= 0 &&
            <div>
              <TextMultiline text={element['weight']} />
            </div>
          }
        </div>
      </div>
    </AlertWidget>
  );
}

interface CargoUrgentChangesTableHeaderProps {
  changes: string[]
}

const CargoUrgentChangesTableHeader = (props: CargoUrgentChangesTableHeaderProps) => {
  const t = (s: string) => s;
  const { changes } = props;

  return (
    <div className={"grid gap-1 grid-flow-col grid-cols-" + changes.length}>
      {changes.indexOf('places') >= 0 && <div>
        {t(getPropertyTitleForSchema("cargo", 'places'))}
      </div>}
      {changes.indexOf('dimensions') >= 0 && <div>
        {t(getPropertyTitleForSchema("cargo", 'dimensions'))}
      </div>}
      {changes.indexOf('weight') >= 0 && <div>
        {t(getPropertyTitleForSchema("cargo", 'weight'))}
      </div>}
    </div>
  )
}