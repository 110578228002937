import React, { Fragment } from 'react';
import moment from 'moment';

export type OverdueDateProps = {
  value?: Date | string;
};

export function OverdueDate({ value }: OverdueDateProps) {
  if (!value) {
    return <Fragment />
  }

  const diffResult = moment().diff(moment(value), 'days');

  return (
    <span className={`${diffResult >= 0 ? 'text-red-500' : ''}`}>
      <DateBase value={value} />
    </span>
  );
}

export type DateBaseProps = {
    value?: Date | string;
    format?: string;
  };
  
  function DateBase({ value, format }: DateBaseProps) {
    return (
      <Fragment>
        {value ? moment(value).format(format ? format : 'YYYY-MM-DD') : ''}
      </Fragment>
    );
  }
  