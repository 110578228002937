import React, { Fragment } from "react";

import SalesOrderDashboardLink from "../../../../components/sales-order/SalesOrderDashboardLink";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import SalesOrderTotalWithCurrency from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderTotalWithCurrency';
import CargoLoadDate from '../../../../_generated/_custom/models-cache-data/Cargo/CargoLoadDate';
import SalesOrderClientWithLinkFilialName from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientWithLinkFilialName';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";

import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
interface IData {
  id: number,
  salesOrder: {
    id: number,
    isContactDataOk: boolean,
    confirmCancelText: string,
    serialNumber: string,
    status: number,
    totalWithCurrency: string,
    hasLoadedCargo: boolean,
    files: any,
    isNeedInvoiceFile: boolean,
    isNeedContractFile: boolean,
  },
  serialNumber: string,
  kzCarriersOrder: {
    id: number,
    status: number,
  },
  status: number,
  files: any,
  outgoingDocumentsChange: number,
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean,
  },
  beforeChange: any,
}

interface Props {
  data: IData[];
}

export default function ManagerOrderDraft(props: Props) {
  const t = (s: string) => s;

  const groupedData = groupMap(
    props.data,
    (el: IData) => el.salesOrder.id.toString()
  )
  const soIds = Object.keys(groupedData)

  return (
    <WhiteCard isCompact={true} title={t("Orders being prepared")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t('Order')}</Th>
              <Th>{t('Cargo')}</Th>
              <Th>{t('Client')}</Th>
              <Th>{t('Seller')}</Th>
              <Th>{t('Loading Country')}</Th>
              <Th><Fragment>{t('Expected cargo')}<br />{t('preparation date')}</Fragment></Th>
              <Th>{t('Price')}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {soIds.map((soId: string, soIndex: number) => {
              const cargos = groupedData[soId];

              return (
                <Fragment key={"so-row-" + soId}>
                  {cargos.map((o: IData, cargoIndex: number) => {
                    return (
                      <tr key={"order-draft-" + o.id}>
                        {cargoIndex === 0 &&
                          <Td rowSpan={cargos.length}>
                            <SalesOrderDashboardLink
                              element={o.salesOrder}

                            />
                          </Td>
                        }
                        <Td >
                          <SalesOrderDashboardLink
                            element={o.salesOrder}
                            cargo={o}
                          />
                        </Td>
                        {cargoIndex === 0 &&
                          <Td rowSpan={cargos.length}>
                            <SalesOrderClientWithLinkFilialName id={o.salesOrder.id} />
                          </Td>
                        }
                        <Td>
                          <DfValueView path="cargo.seller.name" id={o.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.seller.country.nameEn" id={o.id} />
                        </Td>
                        <Td className={"whitespace-nowrap	"}>
                          <CargoLoadDate id={o.id} />
                        </Td>
                        {cargoIndex === 0 &&
                          <Td textAlignment={"text-right"} className={"whitespace-nowrap	"} rowSpan={cargos.length}>
                            <SalesOrderTotalWithCurrency id={o.salesOrder.id} />
                          </Td>
                        }
                      </tr>
                    );
                  })}
                </Fragment>
              )
            })}


          </tbody>
        }
      />

    </WhiteCard>
  );
}
