import React from "react";

import SalesOrderDashboardLink from "../../../../components/sales-order/SalesOrderDashboardLink";
import moment from "moment";
import SalesOrderClientWithLinkFilialName from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientWithLinkFilialName';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { DfValueView } from "@newageerp/v3.bundles.app-bundle";

interface Props {
  data: any[];

}

interface IData {
  id: number,
  serialNumber: string,
  kzCarriersOrder: {
    id: number,
    status: number,
  },
  status: number,
  files: any,
  outgoingDocumentsChange: number,
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean,
  },
  beforeChange: any,
  salesOrder: {
    id: number,
    isContactDataOk: boolean,
    confirmCancelText: string,
    serialNumber: string,
    status: number,
    hasLoadedCargo: boolean,
    files: any,
    isNeedInvoiceFile: boolean,
    isNeedContractFile: boolean,
  },
  soClientJson: {
    id: number,
    name: string,
    filialName: string,
  },
  seller: {
    name: string,
  },
  loadDateSellerFact: string,
  unloadWarehouseDate: string,
}

export default function ManagerOrderStock(props: Props) {
  const t = (s: string) => s;
  return (
    <WhiteCard isCompact={true} title={t("Storred cargo")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t('Order')}</Th>
              <Th>{t('Cargo')}</Th>
              <Th>{t('Client')}</Th>
              <Th>{t('Seller')}</Th>
              <Th>{t('Loading Country')}</Th>
              <Th>{t('Loaded from seller')}</Th>
              <Th>{t('Arrived at warehouse')}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {props.data.sort((a, b) => {
              const aDateStr = !!a.unloadWarehouseDate ? moment(a.unloadWarehouseDate).format("YYYY-MM-DD") : '';
              const bDateStr = !!b.unloadWarehouseDate ? moment(b.unloadWarehouseDate).format("YYYY-MM-DD") : '';

              var x = aDateStr.toLowerCase();
              var y = bDateStr.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            }).map((c: IData) => {
              const seller = c.seller
              return (
                <tr key={"order-" + c.id}>
                  <Td>
                    <SalesOrderDashboardLink
                      element={c.salesOrder}
                    />
                  </Td>
                  <Td>
                    <SalesOrderDashboardLink
                      element={c.salesOrder}
                      cargo={c}
                    />
                  </Td>
                  <Td>
                    <SalesOrderClientWithLinkFilialName id={c.salesOrder.id} />
                  </Td>
                  <Td>
                    <DfValueView path="cargo.seller.name" id={c.id} />
                  </Td>
                  <Td>
                    <DfValueView path="cargo.seller.country.nameEn" id={c.id} />
                  </Td>
                  <Td>
                    {!!c.loadDateSellerFact
                      ? moment(c.loadDateSellerFact).format("YYYY-MM-DD")
                      : " "}
                  </Td>
                  <Td>
                    {!!c.unloadWarehouseDate
                      ? moment(c.unloadWarehouseDate).format("YYYY-MM-DD")
                      : " "}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        }
      />

    </WhiteCard>
  );
}
