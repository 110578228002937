import React, { Fragment } from "react";


import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";


export default function FtlLogisticsReturnToRoadBtn(props: PropsId) {
  const t = (s: string) => s;

  const [saveData, saveDataParams] = useUSave(
    "kz-carriers-order"
  );

  const doSave = () => {
    saveData({ status: 20 }, props.id);
  }

  if (saveDataParams.loading) {
    return <Fragment>{t("Siunčiama")}</Fragment>;
  }

  return (
    <MainButton color="amber" confirmation={true} onClick={doSave}>
      {t("Return")}
    </MainButton>

  );
}
