import React, { useState } from "react";

import OperativeCargoWrapper from "../../UserComponents/Dashboard/Operative/OperativeCargoWrapper";
import KzLogisticOrdersWrapper from './KZLogistic/KzLogisticOrdersWrapper';
import { checkUserAdmin } from "../../_generated/_custom/config/NaeSPermissions";
import { TemplatesLoader, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { FieldLabel, Wide, WideRow } from "@newageerp/v3.bundles.form-bundle";
import { SelectFieldSchema } from "@newageerp/v3.bundles.app-bundle";

export default function DashboardOperative() {
  const { getTabFromSchemaAndType } = useUIBuilder();

  const t = (s: string) => s;
  const { userState } = useTemplatesCore();
  const isAdmin = checkUserAdmin(userState);
  const [dashUserId, setDashUserId] = useState(userState.id);

  return (
    <div className="space-y-2">
      {isAdmin &&
        <WhiteCard isCompact={true}>
          <Wide>
            <WideRow
              label={<FieldLabel>{t("User")}</FieldLabel>}
              control={
                <div className="flex gap-2">
                  <SelectFieldSchema
                    tab={getTabFromSchemaAndType('user', 'main')}
                    schema={"user"}
                    value={{ id: dashUserId }}
                    onChange={(val) => setDashUserId(val.id)}
                    className={"w-60"}
                  />
                </div>
              } />
          </Wide>
        </WhiteCard>
      }

      <div className="space-y-4" key={`user-${dashUserId}`}>

        <TemplatesLoader
          templateName="DashboardPoints"
          data={{
            userId: dashUserId
          }}
        />

        <OperativeCargoWrapper userId={dashUserId} />

        {/* <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserOperationalPro]}> */}
          <KzLogisticOrdersWrapper
            full={false}
            query={
              [
                {
                  "and": [
                    {
                      "or": [
                        ['i.transportType', '=', 10, true],
                        // ['i.transportType', '=', 20, true],
                        ['i.transportType', '=', 50, true],
                      ]
                    },
                    ['i.status', 'in', [10, 15, 20], true]
                  ]
                }
              ]
            }
            // skipDirect={true}
          />
        {/* </CheckUserPermissionComponent> */}
      </div>
    </div>
  );
}
