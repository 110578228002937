import React, { Fragment, useState } from 'react'
import { IKZWfData } from '../../../components/kz-carriers-order/KzCarriersOrderWorkflowWidget';

import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { AlertWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { FieldInput } from '@newageerp/v3.bundles.form-bundle';
import { useNaeRecord } from '@newageerp/v3.app.mvc.record-provider';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useCargoOperationConfirmationHookNae } from '../../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export default function StockFiles() {
    const [showMailContent, setShowMailContent] = useState(false);
    const element: IKZWfData = useNaeRecord().element;
    const { userState } = useTemplatesCore()

    const [saveData] = useUSave(NaeSSchemaMap.KzCarriersOrder.schema);
    const onSend = () => {
        if (element.status < 15) {
            saveData({
                status: 15,
                userDps: {
                    id: userState.id,
                }
            }, element.id);
        }
    }

    const _defFileNames: any = {};
    element.cargos.forEach(c => {
        _defFileNames[c.serialNumber] = c.stock + ' (' + c.soClientJson.clientFilialName + ').zip';
    })
    const [defFileNames, setDefFileNames] = useState(_defFileNames);
    const onChangeName = (text: string, serialNumber: string) => {
        const _d = { ...defFileNames };
        _d[serialNumber] = text;
        setDefFileNames(_d);
    }

    const t = (s: string) => s;

    const openEmail = () => {
        const options = {
            extraData: {
                id: element.id,
                schema: NaeSSchemaMap.KzCarriersOrder.schema,
                template: "stock-mail",
                defFileNames,
                origin: window.location.origin,
                token: window.localStorage.getItem('token')
            },
            onSend: onSend
        }
        const event = new CustomEvent(
            'SFSOpenEmailForm',
            {
                detail: options
            }
        );
        window.dispatchEvent(event);
    }

    return (
        <Fragment>
            <WhiteCard isCompact={true} title={t('Letter for the warehouse')}>
                {showMailContent ? <Fragment>
                    <div className={"grid grid-cols-1 gap-2"}>
                        {element.cargos.map(c => {
                            return (
                                <CargoLine key={"cargo-stock-files-" + c.id} c={c} onChangeName={onChangeName} defFileNames={defFileNames} />
                            )
                        })}
                    </div>
                    <MainButton iconName={"paper-plane"} color={"blue"} className={"w-full"} onClick={openEmail}>
                        {t('Send')}
                    </MainButton>
                </Fragment> : <Fragment>
                    <MainButton iconName={"paper-plane"} color={"blue"} className={"w-full"} onClick={() => setShowMailContent(true)}>
                        {t('Show')}
                    </MainButton>
                </Fragment>}

            </WhiteCard>
        </Fragment>
    )
}


const CargoLine = (props: {
    c: any,
    onChangeName: (val: string, serial: string) => void,
    defFileNames: any,
}) => {
    const t = (s: string) => s;

    const { c, onChangeName, defFileNames } = props;

    const cargo = useCargoHookNae(c.id);
    const cargoConfirm = useCargoOperationConfirmationHookNae(cargo && cargo.operationConfirmation ? cargo.operationConfirmation.id : -1)

    if (!cargoConfirm) {
        return <Fragment/>
    }

    return (
        <div key={"cargo-stock-files-" + c.id} className={"space-y-1"}>
            <div className={"flex gap-2 items-center"}>
                <p className="font-medium flex-grow">
                    <Tooltip text={c.cargoStockHtmlInfo} />
                    {c.serialNumber}

                </p>
                {/* <OldBadge bgColor={c.filesSentToTerminal ? BadgeBgColor.green : BadgeBgColor.gray} size={BadgeSize.sm}>{c.filesSentToTerminal ? "Siųstas" : "Nesiųstas"}</OldBadge> */}
            </div>
            <FieldInput value={defFileNames[c.serialNumber]} onChange={e => onChangeName(e.target.value, c.serialNumber)} className={"text-sm w-full"} />
            {!!cargoConfirm.filesToStock && cargoConfirm.filesToStock.length > 0 ?
                <Fragment>
                    {cargoConfirm.filesToStock.map((f: any, fIndex: number) => {
                        return (
                            <p key={"cargo-stock-files-" + c.id + "-f-" + fIndex}>{f.filename}</p>
                        )
                    })}
                </Fragment>
                :
                <AlertWidget color='danger' isCompact={true}>{t('Nepasirinkti failai')}</AlertWidget>
            }
        </div>
    )
}