import React, { useEffect, Fragment } from 'react'

import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';
import { useURequest, useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../../_generated/_custom/config/NaePaths';

interface Props {
    elementId: number;
}

export default function TableCosts(props: Props) {

    const { elementId } = props;

    const t = (s: string) => s;

    const [getData, getDataParams] = useURequestRaw<any>(NaePathsMap.post['getKzCarriersOrderCosts']);

    useEffect(() => {
        getData({ id: elementId });
    }, []);

    if (!getDataParams.data) {
        return <Fragment />
    }

    const isData = !!getDataParams && !!getDataParams.data && !!getDataParams.data.data && getDataParams.data.data.length > 0;
    const costs = isData ? groupMap(getDataParams.data.data, (el) => el.group) : {};
    // const costsByCargo = isData ? groupMap(getDataParams.data.data, (el) => el.cargoSerialNumber) : {};

    let total = 0;

    return (
        <Fragment>

            {isData &&

                <Table
                    thead={<thead>
                        <tr>
                            <Th>{t('Name')}</Th>
                            <Th>{t('Cargo')}</Th>
                            <Th textAlignment={"text-right"}>{t('Selfcost')}</Th>
                            <Th textAlignment={"text-right"}>{t('Currency')}</Th>
                        </tr>
                    </thead>}
                    tbody={
                        <tbody>
                            {Object.keys(costs).map((key: string, index: number) => {
                                const costData = costs[key];
                                let groupTotal = 0;
                                return (
                                    <Fragment key={"costs-" + index}>
                                        <tr>
                                            <Td colSpan={4}><strong>{key}</strong></Td>
                                        </tr>
                                        {costData.map((costEl: any, costIndex: number) => {
                                            total += costEl.cost;
                                            groupTotal += costEl.cost;
                                            return (
                                                <tr key={"cost-l-" + index + "-" + costIndex}>
                                                    <Td>{costEl.title}</Td>
                                                    <Td>{costEl.cargoSerialNumber}</Td>
                                                    <Td textAlignment={"text-right"}>{costEl.cost}</Td>
                                                    <Td textAlignment={"text-right"}>{costEl.currency}</Td>
                                                </tr>
                                            )
                                        })}
                                        <tr className={"font-semibold"}>
                                            <Td><Fragment>{t('Total')} ({key})</Fragment></Td>
                                            <Td></Td>
                                            <Td textAlignment={"text-right"}>{groupTotal.toFixed(2)}</Td>
                                            <Td textAlignment={"text-right"}>EUR</Td>
                                        </tr>
                                    </Fragment>
                                )
                            })}
                            <tr className={"total-row font-bold"}>
                                <Td></Td>
                                <Td textAlignment={"text-right"}>{t('Costs total')}</Td>
                                <Td textAlignment={"text-right"}>{total.toFixed(2)}</Td>
                                <Td textAlignment={"text-right"}>EUR</Td>
                            </tr>
                            <tr className={"total-row font-bold"}>
                                <Td></Td>
                                <Td textAlignment={"text-right"}>{t('SO total')}</Td>
                                <Td textAlignment={"text-right"}>{getDataParams.data.profit.toFixed(2)}</Td>
                                <Td textAlignment={"text-right"}>EUR</Td>
                            </tr>
                            <tr className={"total-row font-bold"}>
                                <Td></Td>
                                <Td textAlignment={"text-right"}>{t('Profit')}</Td>
                                <Td textAlignment={"text-right"}>{(getDataParams.data.profit - total).toFixed(2)}</Td>
                                <Td textAlignment={"text-right"}>EUR</Td>
                            </tr>
                        </tbody>
                    }
                />

            }

        </Fragment>
    )
}
