import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import React, { Fragment, useState } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types'
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle'


export default function CargoCheckForDualUse(props: PropsId) {
    const [showPopup, setShowPopup] = useState(false);
    const [saveReq, doSaveParams] = useUSave("cargo");
    const doSave = async (s: number) => {
        await saveReq({
            cargoCheckForDualUse: s
        }, props.id);
        setShowPopup(false);
    }

    return (
        <Fragment>
            <MenuItem iconName='badge-check' onClick={() => setShowPopup(true)}>
                Cargo check for Dual Use
            </MenuItem>

            <Popup isPopup={showPopup} onClick={() => setShowPopup(false)} title="Cargo check for Dual Use">
                <div className='space-y-4'>

                    <MainButton className='w-full' onClick={() => doSave(1)}>Clean Cargo</MainButton>
                    <MainButton className='w-full' onClick={() => doSave(0)}>Non Dual Use</MainButton>
                    <MainButton color='amber' className='w-full' onClick={() => doSave(50)}>Manufacturers declaration</MainButton>
                    <MainButton color='amber' className='w-full' onClick={() => doSave(40)}>7KP</MainButton>
                    <MainButton color='orange' className='w-full' onClick={() => doSave(10)}>Dual Use LT</MainButton>
                    <MainButton color='red' className='w-full' onClick={() => doSave(20)}>Dual Use LV</MainButton>
                </div>
            </Popup>

        </Fragment>
    )
}
