import React, { useEffect, Fragment } from 'react'

import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { MenuItemWithSave } from '@newageerp/v3.bundles.modal-bundle';
import { useKzCarriersOrderHookNae } from '../../_generated/_custom/hooks/useKzCarriersOrderHookNae';

export default function AccountingUnlockOrder(props: PropsId) {
    const element = useKzCarriersOrderHookNae(props.id);

    const t = (s: string) => s;

    let isDisabled = false;
    if (!element) {
        return <Fragment />;
    }
    // if (element.status >= 40) {
    //     isDisabled = true;
    // }
    if (!element.responseDocsFinish) {
        isDisabled = true;
    }

    return (
        <MenuItemWithSave
            isDisabled={isDisabled}
            iconName="unlock"
            schema={NaeSSchemaMap.KzCarriersOrder.schema}
            saveData={{ responseDocsFinish: false }}
            elementId={props.id}
            confirmation={true}
        >
            {t("Unlock accounting changes")}
        </MenuItemWithSave>

    )
}