import React, { Fragment } from 'react'

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSelector } from '@newageerp/v3.templates.templates-core';
import { SelectorCargoNae } from '../../../_generated/_custom/models/ormSelectors';
import { ICargoModelNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'

export default function SalesOrderAllCargosOneUnload(props: PropsId) {
    const element = useSalesOrderHookNae(props.id);
    const elementCargos = element ? element.cargos.map(el => el.id) : [-1];
    // @ts-ignore
    const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

    const t = (s: string) => s;

    if (!element) {
        return <Fragment />
    }
    if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
        return <Fragment />
    }
    if (cargos.length === 0) {
        return <Fragment />
    }
    if (element.status === 900 || element.status === 20) {
        return <Fragment />
    }

    let hideAllCargos = false;
    if (element.allCargosOneUnload !== 10) {
        if (element.cargos.length < 2) {
            hideAllCargos = true;
        } else {
            const isAllLtlOut =
                cargos.filter((o: ICargoModelNae) => o.typeOut === "ltl").length === cargos.length;
            if (!isAllLtlOut) {
                hideAllCargos = true;
            } else {
                let allCargoWeight = 0;
                let allCbm = 0;
                cargos.forEach((c: ICargoModelNae) => {
                    allCargoWeight += c.allCargoWeight;
                    allCbm += c.cbm;
                });
                if (!(allCbm > 50 || allCargoWeight >= 10000)) {
                    hideAllCargos = true;
                }
            }
        }
    }

    if (!hideAllCargos) {
        return (
            <WhiteCard isCompact={true} className="border-4 border-yellow-500">
                <MainEditContentInline
                    schema={"sales-order"}
                    type={"all-cargos-one-unload"}
                    id={element.id.toString()}
                    isCompact={true}
                />
            </WhiteCard>

        );
    }
    return <Fragment />
}
