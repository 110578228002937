import React, { Fragment, useEffect, useState } from "react";


import ManagerCalculationWrapper from "../../UserComponents/Dashboard/Manager/ManagerCalculationWrapper";
import ManagerOrderWrapper from "../../UserComponents/Dashboard/Manager/ManagerOrderWrapper";
import FollowUpLeads from '../../UserComponents/Followup/FollowUpLeads';
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { MainToolbarTitle } from "@newageerp/v3.bundles.layout-bundle";
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin } from "../../_generated/_custom/config/NaeSPermissions";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { FieldLabel, Wide, WideRow } from "@newageerp/v3.bundles.form-bundle";
import { SelectFieldSchema } from "@newageerp/v3.bundles.app-bundle";

export default function DashboardManager() {
  const { getTabFromSchemaAndType } = useUIBuilder();

  const t = (s: string) => s;
  const { userState } = useTemplatesCore();
  const isAdmin = checkUserAdmin(userState);
  const [dashUserId, setDashUserId] = useState(userState.id);

  return (
    <Fragment>
      <MainToolbarTitle title=" " />
      <div className={"space-y-4"}>
        {isAdmin &&
          <WhiteCard isCompact={true}>
            <Wide>
              <WideRow
                label={<FieldLabel>{t("User")}</FieldLabel>}
                control={
                  <div className="flex gap-2">
                    <SelectFieldSchema
                      tab={getTabFromSchemaAndType('user', 'main')}
                      schema={"user"}
                      value={{ id: dashUserId }}
                      onChange={(val) => setDashUserId(val.id)}
                      className={"w-60"}
                    />
                  </div>
                } />
            </Wide>
          </WhiteCard>
        }

        <div className="space-y-4" key={`user-${dashUserId}`}>

          <FollowUpLeads userId={dashUserId} />


          <div className={"space-y-2"}>
            <TextCardTitle>{t("Orders")}</TextCardTitle>
            <ManagerOrderWrapper userId={dashUserId} />
          </div>

          <div className={"space-y-2"}>
            <TextCardTitle>{t("Quotations")}</TextCardTitle>
            <ManagerCalculationWrapper userId={dashUserId} />
          </div>

        </div>
      </div>
    </Fragment>
  );
}
