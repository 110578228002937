import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import moment from 'moment';
import React, { Fragment } from 'react'

import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { checkUserAdmin, checkUserManager } from '../../../_generated/_custom/config/NaeSPermissions';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { getPropertyEnumLabel } from '../../../_generated/v3/utils';
import ElementDelayInDaysRed from './ElementDelayInDaysRed';
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export default function PaymentDatesBlock(props: PropsId) {
    const {userState} = useTemplatesCore()
    const isAdmin = checkUserAdmin(userState);
    const isManager = checkUserManager(userState);

    const t = (s: string) => s;

    const element = useSalesOrderHookNae(props.id);

    const showChangeTerms = (e: any) => {
        if (e) {
            e.preventDefault();
        }
        const type =
            isAdmin 
                ? "paymentTermDaysAdmin"
                : "paymentTermDays";
                SFSOpenEditModalWindowProps({
            id: element.id,
            schema: "sales-order",
            type: type,
        });
    };

    if (!element) {
        return <Fragment />
    }

    // (element.status > 0 && element.status < 20 && element.paymentTermDaysChanges === 0)
    const canChangePaymentTerms = isAdmin  || isManager;

    var paymentTermDateToCheck = moment().add(90, 'days').format('YYYY-MM-DD');

    return (
        <div className="space-y-2">
            <div className="flex gap-2 items-center">
                <div>{t("Payment terms for transportation")}</div>
                {canChangePaymentTerms &&

                    <a href="#" onClick={showChangeTerms} className={"text-sky-500"} title={t("update")}>
                        <i className='fa fa-edit fa-solid'/>
                    </a>
                }
            </div>


            <div>
                <div className="flex items-center gap-2">
                    <TextCardTitle>
                        {getPropertyEnumLabel(
                            "sales-order",
                            "paymentTermType",
                            element.paymentTermType
                        )}
                    </TextCardTitle>
                    <span>(+{element.paymentTermDays}d.)</span>

                </div>
                <div className="flex items-center gap-2">
                    {element.paymentTermDate < paymentTermDateToCheck &&
                        <TextCardTitle>
                            {moment(element.paymentTermDate).format("YYYY-MM-DD")}
                        </TextCardTitle>
                    }
                    <ElementDelayInDaysRed id={props.id} />
                </div>
            </div>
        </div>
    )
}
