import React, { Fragment } from 'react'
import { PropsId } from '../types';
import CalculationSerialNumberWithLinkAndNotes from './CalculationSerialNumberWithLinkAndNotes';
import CalculationClientNameWithLink from './CalculationClientNameWithLink';
import CalculationStatusChange from '../../../../UserComponents/Calculation/Widget/CalculationStatusChange';
import { useCalculationHookNae } from '../../hooks/useCalculationHookNae';
import { SFSOpenViewModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function CalculationCalculatedSerialColumn(props: PropsId) {
    const t = (s: string) => s;
    
    const element = useCalculationHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            <CalculationSerialNumberWithLinkAndNotes id={element.id} />
            <CalculationClientNameWithLink id={element.id} />
            {element.status === 10 && <Fragment>
                {element.total > 0 ? (
                    <CalculationStatusChange id={element.id} compact={true} />
                ) : (
                    <MainButton
                        iconName='circle-dollar'
                        color='bluelight'
                        className={"w-full"}
                        onClick={() => SFSOpenViewModalWindowProps({ id: element.id, schema: "calculation" })}
                    >
                        {t('Set price')}
                    </MainButton>
                )}
            </Fragment>}


        </Fragment>
    )
}
