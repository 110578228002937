import React, { Fragment } from 'react'
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';

import { useCargoStatusHookNae } from '../../../_generated/_custom/hooks/useCargoStatusHookNae';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { StatusWidget, StatusWidgetColors } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoStatusComponent(props: PropsId) {
    const t = (s: string) => s;
    const element = useCargoHookNae(props.id);
    const cargoStatus = useCargoStatusHookNae(element && element.cargoStatus ? element.cargoStatus.id : -1);

    if (!element || !cargoStatus) {
        return <Fragment />
    }

    let badgeClassName: keyof typeof StatusWidgetColors | string = "";
    let badgeText = "";
    if (element.senderMakeEx === 30) {
        badgeText = "EX (Partners)";
        badgeClassName = "teal"
    } else if (element.senderMakeEx === 40) {
        badgeText = "EX (Remotely)";
        badgeClassName = "amber"
    } else if (element.senderMakeEx === 20) {
        badgeText = "EX (Warehouse)";
        badgeClassName = "slate"
    } else if (element.senderMakeEx === 10) {
        badgeText = "EX (Consignor)";
        badgeClassName = "teal"
    } else if (element.senderMakeEx === 0) {
        if (element.senderMakeExCost === 10) {
            badgeText = "EX (Consignor)";
            badgeClassName = "teal"
        } else {
            badgeText = "EX (Warehouse)";
            badgeClassName = "slate"
        }
    }


    return <Fragment>
        {cargoStatus.code.indexOf('T1') >= 0  && <StatusWidget smallPadding={true} color={"red"}>
            {"Transit (Т1)"}
        </StatusWidget>}
        {cargoStatus.code.indexOf('EX') >= 0  && <StatusWidget smallPadding={true} color={badgeClassName}>
            {badgeText}
        </StatusWidget>}
    </Fragment>
}
