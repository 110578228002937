import React, { useState } from "react";


import LogisticCalculation from "./Logistic/LogisticCalculation";
import LogisticCalculationNeedInfo from "../../UserComponents/Dashboard/Logistic/LogisticCalculationNeedInfo";
import LogisticCargoWrapper from "./Logistic/LogisticCargoWrapper";
import AviaLogisticOrdersWrapper from "../../UserComponents/Dashboard/LogisticAvia/AviaLogisticOrdersWrapper";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin } from "../../_generated/_custom/config/NaeSPermissions";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { FieldLabel, Wide, WideRow } from "@newageerp/v3.bundles.form-bundle";
import { SelectFieldSchema } from "@newageerp/v3.bundles.app-bundle";

export default function DashboardLogisticSea() {
  const { getTabFromSchemaAndType } = useUIBuilder();

  const t = (s: string) => s;
  const { userState } = useTemplatesCore();
  const isAdmin = checkUserAdmin(userState);
  const [dashUserId, setDashUserId] = useState(userState.id);

  return (
    <div className="space-y-4">
      {isAdmin &&
        <WhiteCard isCompact={true}>
          <Wide>
            <WideRow
              label={<FieldLabel>{t("User")}</FieldLabel>}
              control={
                <div className="flex gap-2">
                  <SelectFieldSchema
                    tab={getTabFromSchemaAndType('user', 'main')}
                    schema={"user"}
                    value={{ id: dashUserId }}
                    onChange={(val) => setDashUserId(val.id)}
                    className={"w-60"}
                  />
                </div>
              } />
          </Wide>
        </WhiteCard>
      }

      <div className="space-y-4" key={`user-${dashUserId}`}>
        <LogisticCalculationNeedInfo userId={dashUserId} />
        <div className="space-y-2">
          <TextCardTitle>{t("Quotations")}</TextCardTitle>

          <LogisticCalculation userId={dashUserId} />
        </div>

        <div className="space-y-2">
          <TextCardTitle>{t("Cargo")}</TextCardTitle>
          <LogisticCargoWrapper userId={dashUserId} />
        </div>

        <div className="space-y-2">
          <TextCardTitle>{t("Order for carrier (IK)")}</TextCardTitle>

          <AviaLogisticOrdersWrapper userId={dashUserId} />
        </div>
      </div>
    </div>
  );
}
