import React, { Fragment, useEffect } from "react";

import { useHistory, useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { CheckUserPermissionComponent, ENaeSPermissions } from "../../_generated/_custom/config/NaeSPermissions";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";

export default function MergeWidget() {
  const { data: tData } = useTemplatesLoader();
  const { saveError, element } = tData;

  const t = (s: string) => s;
  const history = useHistory();

  const [mergeData, mergeDataParams] = useURequest<any>(
    NaePathsMap.post["AppPluginsClientMerge"]
  );

  const doMerge = (targetId: number) => {
    mergeData({
      sourceId: element.id,
      targetId: targetId,
    });
  };

  useEffect(() => {
    if (
      mergeDataParams.data.data.length > 0 &&
      mergeDataParams.data.data[0].success === 1
    ) {
      history.replace("/u/client/main/view/" + mergeDataParams.data.data[0].id);
    }
  }, [mergeDataParams.data]);

  console.log("saveError", saveError);

  if (saveError) {
    let errorText = saveError.response.data.description;

    if (
      errorText &&
      errorText[0] === "{" &&
      errorText[errorText.length - 1] === "}"
    ) {
      const er = JSON.parse(errorText);
      if (er.type === "client-unique-error") {
        return (
          <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin]}>
            <div className={"space-y-2"}>
              <p className="font-bold">{t("Merge")}</p>
              {er.data.map((cl: any, index: number) => {
                return (

                  <MainButton
                    confirmation={true}
                    onClick={() => doMerge(cl.id)}
                    iconName={"object-group"}
                    color={'blue'}
                    key={"merge-btn-" + index}
                    loading={mergeDataParams.loading}
                  >
                    {cl.name}
                  </MainButton>

                );
              })}
            </div>
          </CheckUserPermissionComponent>
        );
      }
    }
  }
  return <Fragment />;
}
