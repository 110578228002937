import React, { Fragment, useState } from "react";

import LogisticCargoReturnForm from "./LogisticCargoReturnForm";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';

import { useLoadAddressHookNae } from "../../../_generated/_custom/hooks/useLoadAddressHookNae";
import { AlertWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { checkUserAdmin, checkUserLogisticFtl } from "../../../_generated/_custom/config/NaeSPermissions";
import { useDfValue } from "@newageerp/v3.app.data-cache-provider";
import { useNaeRecord } from "@newageerp/v3.app.mvc.record-provider";
import { useURequest, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";

const exReturnText =
  `You have indicated that Seller will make export declaration (EX) for this cargo, however Operations manager marked that Seller won't do it. This will impact on selfcost (~200 EUR). Settle these changes with Client. If you wish to continue, change option "Will shipper prepare EX?" to No in "Change cargo details" menu, choose date and then press "Is the issue resolved?"`;

const exReturnTextLogist =
  "Seller had to make export declaration (EX) for this cago. It is necessary to return cargo to the Sales manager for reconciliation";

export default function CargoCarriersOrder(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const insuranceStatus = useDfValue({
    id: props.id,
    path: 'cargo.cargoInsurance.status'
  })

  const loadAddress = useLoadAddressHookNae(element && element.loadAddress ? element.loadAddress.id : -1)

  const reloadData = useNaeRecord().reloadData;

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);

  const isLogisticFtl = checkUserLogisticFtl(userState);

  const t = (s: string) => s;

  const [onPurchaseOrderCreateCallback] = useURequest(NaePathsMap.post['onPurchaseOrderCreateCallback']);

  const [saveData] = useUSave("cargo");
  const doSave = (data: any) => {
    saveData(data, element.id);
  }

  const [showCancelForm, setShowCancelForm] = useState(false);

  const sendCallback = () => {
    onPurchaseOrderCreateCallback({
      id: element.id,
    })
  }

  const createNewCommon = () => {
    SFSOpenEditModalWindowProps({
      id: "new",
      schema: (element.needTerminal ? NaeSSchemaMap.CarriersOrder.schema : NaeSSchemaMap.KzCarriersOrder.schema),
      options: {
        createOptions: {
          convert: {
            schema: "cargo",
            element: { id: element.id },
          },
        },
      },
      onSaveCallback: (_el, back) => {
        reloadData();
        back();
        sendCallback();
      }
    })
  };

  const doReturnOnEx = () => {
    doSave({
      status: 7,
      loadingInfoReturnText: exReturnText,
    });
  };

  if (!element) {
    return <Fragment />
  }

  const isLogistic = ((!!element.expeditor && userState.id === element.expeditor.id) || (element.typeIn === 'ftl' && isLogisticFtl) || isAdmin);
  const carriersOrders = element.activePoOrders ? element.activePoOrders : [];
  const kzCarriersOrder = element.kzCarriersOrder;

  if (!(element.status === 5 &&
    !!element.salesOrder &&
    isLogistic &&
    element.contactedSeller &&
    (
      element.typeIn !== 'none' &&
      ((carriersOrders.length === 0 && element.needTerminal) ||
        (!kzCarriersOrder && !element.needTerminal))
    ))) {
    return <Fragment />
  }

  if (!loadAddress || (!loadAddress.stockWorkTime || !loadAddress.addressIndex)) {
    return (
      <Fragment>
        <AlertWidget color="danger" isCompact={true}>
          {t("Enter the seller's working hours and index")}
        </AlertWidget>
      </Fragment>
    );
  }

  if (!element.checks["logisticFillInfo"]) {

    return (

      <AlertWidget color="danger" isCompact={true}>
        {t("Fill in the information to create an order for the carrier")}
      </AlertWidget>

    );
  }

  if (element.checks["exSettingsProblems"]) {

    return (
      <WhiteCard isCompact={true} className={"border border-red-500"}>
        <p>{t(exReturnTextLogist)}</p>

        <MainButton className={"w-full"} onClick={doReturnOnEx} confirmation={true} color="blue">
          {t("Return")}
        </MainButton>
      </WhiteCard>
    );
  }

  if (insuranceStatus === 10) {
    return <Fragment />
  }

  return (
    <Fragment>
      {showCancelForm ? (
        <LogisticCargoReturnForm
          ids={[element.id]}
          onCloseForm={() => setShowCancelForm(false)}
        />
      ) : (

        <WhiteCard isCompact={true}>

          <p>
            {t(
              "The cargo description is correct?"
            )}
          </p>

          <div className={"flex gap-2"}>
            <MainButton
              color="sky"
              onClick={() => createNewCommon()}
            >
              {t("Yes")}
            </MainButton>

            <MainButton
              color="amber"
              onClick={() => setShowCancelForm(true)}
            >
              {t("No")}
            </MainButton>
          </div>
        </WhiteCard>

      )}
    </Fragment>
  );
}
