import React, { Fragment, useEffect } from "react";

import CargoLogisticLink from "../../../components/cargo/CargoLogisticLink";
import CargoDirectCarBadge from "../../../_generated/_custom/models-cache-data/Cargo/CargoDirectCarBadge";
import SalesOrderClientOrFilialName from "../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientOrFilialName";
import UserFullName from "../../../_generated/_custom/models-cache-data/User/UserFullName";
import CargoOutgoingDocumentsChangeWithFileCheck from "../../../_generated/_custom/models-cache-data/Cargo/CargoOutgoingDocumentsChangeWithFileCheck";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import CargoSellerDidEx from "../../../Plugins/Cargo/CargoSellerDidEx";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import CargoCargoCheckForDualUseBadge from "../../../_generated/badges/cargo/CargoCargoCheckForDualUseBadge";
import CargoCargoDocumentsCollectedBadge from "../../../_generated/badges/cargo/CargoCargoDocumentsCollectedBadge";
import CargoWarehouseExOrder from "../../../Plugins/Cargo/CargoWarehouseExOrder";

type Props = {
  userId: number
}

let reloadTimeout: any = -1;

export default function OperativeCargoPlanned(props: Props) {
  const t = (s: string) => s;

  const [getData, getDataParams] = useUList<PropsId>(
    "cargo",
    ["id"]
  );
  const loadData = () => {
    let filter: any = [
      ["i.operativeCardIndex", "=", 2, true],
      ["i.status", "!=", 900, true],
      ["i.operational", "=", props.userId, true],
    ];

    getData([{ and: filter }], 1, 200, [
      { key: "i.kzCarriersOrder.loadDate", value: "ASC" },
    ]);
  };
  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(loadData, 500);
  };

  useEffect(() => {
    SocketService.addCallback(
      "cargo-operativeCardIndex-2",
      "cargo-operativeCardIndex-2",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "cargo-operativeCardIndex-2",
        "cargo-operativeCardIndex-2"
      );
    };
  }, []);

  return (
    <WhiteCard isCompact={true} title={t("Planned cargo")}>
      <Table
        thead={
          <thead>
            <tr>
              <Th>{t("Cargo")}</Th>
              <Th>{t("Sales order")}</Th>
              <Th>{t("Seller")}</Th>
              <Th>{t("Consignee")}</Th>
              <Th>{t("Transport operations manager")}</Th>
              <Th>{t("Sales manager")}</Th>
              <Th>{t('Who will prepare EX?')}</Th>
              <Th>{t("Will the outgoing documents change?")}</Th>
              <Th>{t('Dual use')}</Th>
              <Th>{t('Documents collected')}</Th>
              <Th>{t("Planned departure date")}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {getDataParams.data.data.map((el: PropsId, index: number) => {
              return (
                <OperativeCargoNewLine
                  key={`cargo-${el.id}`}
                  id={el.id}
                />
              );
            })}
          </tbody>
        }
      />
    </WhiteCard>
  );
}

const OperativeCargoNewLine = (props: PropsId) => {
  const el = useCargoHookNae(props.id);
  if (!el) {
    return <Fragment />;
  }
  return (
    <tr>
      <Td>
        <CargoLogisticLink id={el.id} />
        <CargoDirectCarBadge id={el.id} />
      </Td>
      <Td><DfValueView path="cargo.salesOrder.serialNumber" link={true} id={el.id} /></Td>
      <Td>{el.seller && <DfValueView path="sender.name" link={true} id={el.seller.id} />}</Td>
      <Td>
        {el.salesOrder && (
          <SalesOrderClientOrFilialName id={el.salesOrder.id} link={true} />
        )}
      </Td>
      <Td>{!!el.expeditor && <UserFullName id={el.expeditor.id} />}</Td>
      <Td>{!!el.creator && <UserFullName id={el.creator.id} />}</Td>
      <Td>
        <CargoSellerDidEx id={el.id} />
        <CargoWarehouseExOrder id={el.id}/>
        </Td>
      <Td>
        <CargoOutgoingDocumentsChangeWithFileCheck id={el.id} />
      </Td>
      <Td>
        <CargoCargoCheckForDualUseBadge id={el.id} isCompact={true}  />
      </Td>
      <Td>
        <CargoCargoDocumentsCollectedBadge id={el.id} isCompact={true}  />
      </Td>
      <Td>
        <DfValueView path="cargo.kzCarriersOrder.loadDate" id={el.id} />
      </Td>
    </tr>
  );
};
