import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import BottomWidgetLoadDates from '../../../UserComponents/Cargo/BottomWidget/BottomWidgetLoadDates';
import CargoReferenceNumber from '../../../_generated/_custom/models-cache-data/Cargo/CargoReferenceNumber';
import CargoSellerComponent from './CargoSellerComponent';
import moment from 'moment';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { FieldLabel, Label } from '@newageerp/v3.bundles.form-bundle';

import CargoContactedSellerTime from '../../../Plugins/Cargo/CargoContactedSellerTime';

export default function CargoSellerBlock(props: PropsId) {
    const t = (s: string) => s;

    const element = useCargoHookNae(props.id);
    const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);

    if (!element) {
        return <Fragment />
    }
    if (!calculation) {
        return <Fragment />
    }

    return (
        <div>
            <FieldLabel>{t('Seller')}</FieldLabel>

            <div className='divide-y divide-slate-300'>

                {!!calculation && calculation.purpose === 10 && (
                    <Fragment>
                        <div>
                            <div className='flex gap-2'>
                                <DfValueView id={props.id} path="cargo.loadingCountry.nameEn" /><DfValueView id={props.id} path="cargo.loadingCountryRegion.name" />
                            </div>
                            <DfValueView id={props.id} path="cargo.loadingExactAddress" />
                        </div>
                    </Fragment>
                )}

                <CargoSellerComponent id={props.id} />



                {!!element.referenceNumber && (
                    <div className={"text-indigo-800 py-2"}>
                        <FieldLabel>
                            Reference:
                        </FieldLabel>
                        <CargoReferenceNumber id={element.id} />
                    </div>
                )}

                <BottomWidgetLoadDates id={element.id} />

                <div className={"py-2"}>
                <CargoContactedSellerTime id={element.id} />
                </div>

            </div>
        </div>
    )
}
