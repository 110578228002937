import React, { useEffect, Fragment, useState } from 'react'

import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../_generated/_custom/hooks/useCalculationHookNae';
import { useSalesOrderHookNae } from '../../_generated/_custom/hooks/useSalesOrderHookNae';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { Popup } from '@newageerp/v3.bundles.popup-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import moment from 'moment';
import { CheckUserPermissionComponent, ENaeSPermissions } from '../../_generated/_custom/config/NaeSPermissions';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle';
import { useUList, useURequest, useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';

interface IDataSalesOrder {
    id: number,
    serialNumber: string,
    client: {
        id: number,
        name: string,
    }
    date: string,
}
const defSalesOrder: IDataSalesOrder = {
    id: 0,
    serialNumber: '',
    client: {
        id: 0,
        name: '',
    },
    date: '',
}


export default function CargoMoveSalesOrder(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);
    const salesOrder = useSalesOrderHookNae(element && element.salesOrder ? element.salesOrder.id : -1);

    const clientId = salesOrder ? salesOrder.client.id : (calculation ? calculation.client.id : -1);
    const addressId = salesOrder ? salesOrder.address.id : (calculation ? calculation.address.id : -1);

    const [showForm, setShowForm] = useState(false);

    const t = (s: string) => s;

    const [getSalesOrders, getSalesOrdersParams] = useUList<IDataSalesOrder>("sales-order", getKeysFromObject(defSalesOrder));
    useEffect(() => {
        if (showForm) {
            getSalesOrders(
                [
                    {
                        "and": [
                            ['i.client.id', 'num_eq', clientId, true],
                            ['i.address.id', 'num_eq', addressId, true],
                            ['i.status', 'not_in', [0, 5, 20, 900], true]
                        ]
                    }
                ]
            );
        }
    }, [showForm]);

    const [moveToSalesOrder, moveSalesOrderParams] = useURequest(NaePathsMap.post.AppPluginsCargoMoveToSoMove);
    const [updateCalculation, updateCalculationParams] = useUSave("calculation");
    const [updateSalesOrder, updateSalesOrderParams] = useUSave("sales-order");
    const moveLoading = moveSalesOrderParams.loading || updateCalculationParams.loading || updateSalesOrderParams.loading;
    const doMove = (salesOrderId: number) => {
        if (!salesOrderId) return;

        setShowForm(false);

        const _calculationStatus = calculation.status;
        const _calculationId = element.calculation.id;

        const _salesOrderId = salesOrder ? salesOrder.id : 0;
        const _salesOrderCargos = salesOrder ? salesOrder.cargos.length : 0;

        moveToSalesOrder(
            {
                cargoId: element.id,
                soId: salesOrderId,
            }
        ).then((res: any) => {
            if (res) {
                onCancel();
                // TODO move to AppPluginsCargoMoveToSoMove

                if (_calculationStatus === 10) {
                    updateCalculation(
                        {
                            status: 28
                        },
                        _calculationId
                    )
                }
                if (_salesOrderCargos === 1) {
                    updateSalesOrder(
                        {
                            status: 900
                        },
                        _salesOrderId
                    )
                }
            }
        })
    }
    const onCancel = () => {
        setShowForm(false);
    }

    let disableText = '';

    if (!element) {
        return <Fragment />
    }
    if (!calculation) {
        return <Fragment />
    }
    if (calculation.status <= 5) {
        return <Fragment />
    }
    if (calculation.purpose !== 20) {
        return <Fragment />
    }
    if (element.status === 900) {
        return <Fragment />
    }
    if (!!element.kzCarriersOrder && element.kzCarriersOrder.id > 0) {
        disableText = 'You can\'t move when cargo is planned for loading.';
    }
    const isDisabled = !!disableText;

    return (
        <CheckUserPermissionComponent
            permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager, ENaeSPermissions.UserHeadOfLogistics,]}
        >
            <Fragment>
                <MenuItem
                    onClick={() => setShowForm(true)}
                    iconName='angles-right'
                    iconLoading={moveLoading}
                    isDisabled={isDisabled}
                    tooltipContent={isDisabled ? disableText : undefined}
                >
                    {t('Move cargo')}
                </MenuItem>

                {showForm &&
                    <Popup title={"Move cargo to"} isPopup={true} onClick={onCancel}>
                        <div className='space-y-2'>
                            {getSalesOrdersParams.data.data.map((so: IDataSalesOrder) => {
                                return (
                                    <div key={`so-${so.id}`} className={"flex items-center gap-4 text-gray-500"}>
                                        <MainButton
                                            color="white"
                                            confirmation={true}
                                            onClick={() => doMove(so.id)}
                                            disabled={so.id === salesOrder.id}
                                        >
                                            {so.serialNumber}
                                        </MainButton>
                                        <span>
                                            {so.client.name}
                                        </span>
                                        <span>
                                            {moment(so.date).format('YYYY-MM-DD')}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </Popup>

                }
            </Fragment>
        </CheckUserPermissionComponent>
    )
}
