import React, { useState, useEffect, Fragment } from "react";

import moment from "moment";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useRecoilState } from "recoil";
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { MailsContent } from "@newageerp/v3.bundles.mails-bundle";
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { useDidMount, useUList, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import CargoPoSendBtnAccounter from "../../../components/cargo/CargoPoSendBtnAccounter";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { FieldTextarea } from "@newageerp/v3.bundles.form-bundle";

interface IGroupedDataItem {
  id: number,
  reasons: string[],
  carriers: string[],
  schema: string,
  notes: string,
  title: string,
  maxUnloadDate: string,
}

interface IData {
  id: number;
  serialNumber: string;
  client: {
    id: number;
    name: string;
  };
  creator: {
    id: number;
    fullName: string;
  };
  reasonSalesOrderNotFinishedForAccounting: any;
  finishDate: string;
  date: string;
  accounterNotes: string;
  maxUnloadDate: string;
}

const defObject: IData = {
  id: 0,
  serialNumber: "",
  client: {
    id: 0,
    name: "",
  },
  creator: {
    id: 0,
    fullName: "",
  },
  reasonSalesOrderNotFinishedForAccounting: "",
  finishDate: "",
  date: "",
  accounterNotes: "",
  maxUnloadDate: "",
};

interface FieldProps {
  comment: string,
  id: number;
  schema: string,
}
function AccounterNotesField(props: FieldProps) {
  const isMount = useDidMount();

  const t = (s: string) => s;

  const [comment, setComment] = useState(props.comment)
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (!isMount) {
      setHasChanges(true);
    }
  }, [comment]);

  const [saveData, saveDataParams] = useUSave(
    props.schema
  );

  const sentData = () => {
    saveData(
      {
        accounterNotes: comment
      },
      props.id
    ).then(() => {
      setHasChanges(false);
    });
  }

  return (
    <div className={"grid gap-1"}>
      <FieldTextarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      {hasChanges &&
        <MainButton onClick={sentData} iconName={"save"} loading={saveDataParams.loading} color={"blue"}>
          {t("Save")}
        </MainButton>
      }
    </div>
  );
}

export default function UnFinishedOrdersReports() {
  const t = (s: string) => s;

  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
  useEffect(() => {
    setToolbarTitle(t('Unfinished orders'));
  }, []);

  const [showEmailPopup, setShowEmailPopup] = useState(0);
  const [showEmailPopupSchema, setShowEmailPopupSchema] = useState('');

  const [getData, getDataParams] = useUList<IData>(
    "sales-order",
    getKeysFromObject(defObject)
  );

  React.useEffect(() => {
    const date = moment().subtract(10, 'days').format('YYYY-MM-DD')

    let _filter = [
      {
        and: [
          ["i.status", "num_eq", 10, true],
          ["i.cargos.kzCarriersOrder.trueUnloadDate", "dlt", date],
        ],
      },
    ];
    getData(
      _filter,
      1,
      999999
      // [{ 'key': 'i.creator.firstName', 'value': 'ASC' }, { 'key': 'i.creator.lastName', 'value': 'ASC' }],
    );
  }, []);

  let groupedData: any = {};
  getDataParams.data.data.filter(o => o.reasonSalesOrderNotFinishedForAccounting.length > 0).forEach((o: IData, index: number) => {
    o.reasonSalesOrderNotFinishedForAccounting.forEach((s: any, i: number) => {
      if (!!s.rel && (s.rel === NaeSSchemaMap.KzCarriersOrder.schema || s.rel === NaeSSchemaMap.CarriersOrder.schema)) {
        if (!groupedData[s.title]) {
          groupedData[s.title] = {
            id: s.id,
            reasons: [],
            carriers: [],
            schema: s.rel,
            notes: s.notes,
            title: s.title,
            maxUnloadDate: o.maxUnloadDate,
          }
        }
        if (groupedData[s.title].reasons.indexOf(s.text) === -1) {
          groupedData[s.title].reasons.push(s.text);
        }
        if (groupedData[s.title].carriers.indexOf(s.carrier) === -1) {
          groupedData[s.title].carriers.push(s.carrier);
        }
      }
    })
  });

  const dataToParse: IGroupedDataItem[] = Object.keys(groupedData).map(k => groupedData[k]);
  return (
    <Fragment>
      <div className={"space-y-2"}>

        <WhiteCard isCompact={true} title={t('Orders')}>
          <Table
            thead={
              <thead>
                <tr>
                  <Th>{t("Entry date")}</Th>
                  <Th>{t("Order")}</Th>
                  <Th>{t("Reason")}</Th>
                  <Th>{t("Carrier")}</Th>
                  <Th>{t("Mails")}</Th>
                  <Th>{t("PDF")}</Th>
                  <Th>{t("Comment")}</Th>
                </tr>
              </thead>
            }
            tbody={
              <tbody>
                {dataToParse.map((item: IGroupedDataItem, index: number) => {
                  return (
                    <tr key={`item-${item.id}-${index}`}>
                      <Td className="whitespace-nowrap">
                        {moment(item.maxUnloadDate).add(10, 'days').format('YYYY-MM-DD')}
                      </Td>
                      <Td>{item.title}</Td>
                      <Td>
                        <Fragment>
                          {item.reasons.map((s: string, index: number) => {
                            return (
                              <div key={"orders-" + item.id + "-r-" + index}>
                                {s}
                              </div>
                            )
                          })}
                        </Fragment>
                      </Td>
                      <Td>
                        <Fragment>
                          {item.carriers.map((s: string, index: number) => {
                            return (
                              <div key={"orders-" + item.id + "-cr-" + index}>
                                {s}
                              </div>
                            )
                          })}
                        </Fragment>
                      </Td>
                      <Td>
                        <MainButton
                          onClick={() => {
                            setShowEmailPopup(item.id);
                            setShowEmailPopupSchema(item.schema);
                          }}
                          iconName={"eye"}
                          color={"bluelight"}
                        >
                          {t('Show')}
                        </MainButton>
                      </Td>
                      <Td className="w-[200px]">
                        <CargoPoSendBtnAccounter
                          id={item.id}
                          schema={item.schema}
                        />
                      </Td>
                      <Td>
                        <AccounterNotesField id={item.id} comment={item.notes} schema={item.schema} />
                      </Td>
                    </tr>
                  )
                })}
              </tbody>
            }
          />

        </WhiteCard>
      </div>
      {showEmailPopup > 0 && !!showEmailPopupSchema &&
        <Popup isPopup={true} onClick={() => setShowEmailPopup(0)} title="">
          <MailsContent id={showEmailPopup} schema={showEmailPopupSchema} />
        </Popup>}
    </Fragment >
  );
}
