import React, { Fragment, useEffect } from "react";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useUList, useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";

export default function UserCompaniesRel(props: PropsId) {
  const t = (s: string) => s;

  const [toggleLtl] = useURequest(NaePathsMap.post['AppPluginsUserLtlFtlRelToggleLtl']);
  const [toggleFtl] = useURequest(NaePathsMap.post['AppPluginsUserLtlFtlRelToggleFtl']);

  const [getUser, userData] = useUList(NaeSSchemaMap.User.schema, [
    "id",
    "ltlCompanies:id",
    "ftlCompanies:id",
    'scopes',
  ]);
  const loadUser = () => {
    getUser(
      [
        {
          "and": [
            ['i.id', '=', props.id, true]
          ]
        }
      ],
      1,
      1
    )
  }
  useEffect(loadUser, []);

  const [getCompanies, companiesData] = useUList(
    NaeSSchemaMap.Company.schema,
    ["id", "name"]
  );
  useEffect(() => {
    getCompanies();
  }, []);

  if (userData.data.data.length === 0) {
    return <Fragment />;
  }

  const user: any = userData.data.data[0];
  const activeLtlCompanies = user.ltlCompanies.map((i: any) => i.id);
  const activeFtlCompanies = user.ftlCompanies.map((i: any) => i.id);

  const isUserLogisticLtl = user.scopes.indexOf("user-logistic") >= 0;
  const isUserLogisticFtl = user.scopes.indexOf("user-logistic-ftl") >= 0;
  const isUserLogistic = isUserLogisticLtl || isUserLogisticFtl;

  if (!isUserLogistic) {
    return <Fragment />
  }

  const doToggleLtl = (companyId: number) => {
    toggleLtl({
      userId: props.id,
      companyId
    }).then(() => {
      loadUser();
    })
  }
  const doToggleFtl = (companyId: number) => {
    toggleFtl({
      userId: props.id,
      companyId
    }).then(() => {
      loadUser();
    })
  }


  return (
    <WhiteCard>
      {isUserLogisticLtl &&
        <Fragment>
          <TextCardTitle>{t("LTL quotations")}</TextCardTitle>

          {companiesData.data.data.map((company: any) => {
            const isChecked = activeLtlCompanies.indexOf(company.id) >= 0;
            return (
              <MainButton
                key={`ltl-${company.id}`}
                className={"w-full"}
                color={
                  isChecked ? "teal" : "slate"
                }
                onClick={() => doToggleLtl(company.id)}
              >
                {company.name}
              </MainButton>
            );
          })}
        </Fragment>
      }

      {isUserLogisticFtl &&
        <Fragment>
          <TextCardTitle>{t("LTL quotations")}</TextCardTitle>

          {companiesData.data.data.map((company: any) => {
            const isChecked = activeFtlCompanies.indexOf(company.id) >= 0;
            return (
              <MainButton
                key={`ftl-${company.id}`}
                className={"w-full"}
                color={
                  isChecked ? "teal" : "slate"
                }
                onClick={() => doToggleFtl(company.id)}
              >
                {company.name}
              </MainButton>
            );
          })}
        </Fragment>
      }
    </WhiteCard>
  );
}
