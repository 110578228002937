import React, { Fragment } from "react";
import "@szhsin/react-menu/dist/index.css";

import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'


export default function CargoSendTerminalMailBtn(props: PropsId) {
  const t = (s: string) => s;
  const element = useCargoHookNae(props.id);

  const showSendEmail = () => {
    const options = {
      extraData: {
        id: props.id,
        schema: "cargo",
        template: "cargo-loaded"
      },
    };
    const event = new CustomEvent(
      'SFSOpenEmailForm',
      {
        detail: options
      }
    );
    window.dispatchEvent(event);
  }

  if (!element) {
    return <Fragment />
  }
  if (element.status < 10 || element.status === 900) {
    return <Fragment />
  }

  return (
    <MenuItem onClick={showSendEmail} iconName="paper-plane">
      {t('Resend letter to the Terminal')}
    </MenuItem>
  )
}
