import React from "react";

import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useCarriersOrderHookNae } from "../../_generated/_custom/hooks/useCarriersOrderHookNae";
import { MenuItemWithSave } from '@newageerp/v3.bundles.modal-bundle';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';


export default function AccountingUnlockOrder(props: PropsId) {
  const element = useCarriersOrderHookNae(props.id);


  const t = (s: string) => s;

  return (
    <MenuItemWithSave
      isDisabled={!element.responseDocsFinish}
      iconName="unlock"
      schema={NaeSSchemaMap.CarriersOrder.schema}
      saveData={{ responseDocsFinish: false }}
      elementId={props.id}
      confirmation={true}
    >
      {t("Unlock accounting changes")}
    </MenuItemWithSave>
  )

}
