import React, { Fragment, useEffect, useState } from 'react'


import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { checkUserHeadOfLogisticsHelper } from '../../../_generated/_custom/config/NaeSPermissions';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';


export default function TableCargosTrip(props: PropsId) {
    const element = useKzCarriersOrderHookNae(props.id);

    const [saveData, saveDataParams] = useUSave(NaeSSchemaMap.KzCarriersOrder.schema);
    const [tripsArray, setTripsArray] = useState<any[]>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const t = (s: string) => s;

    const { userState } = useTemplatesCore()
    const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);


    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const doSave = () => {
        if (saveDataParams.loading) return;
        const tripSort: any = {};
        tripsArray.forEach((t: any, index: number) => {
            tripSort[t.text] = index * 10;
        })
        saveData(
            {
                tripSort: tripSort
            },
            props.id
        ).then(() => {
            setHasChanges(false);
        })
    }

    useEffect(() => {
        setTripsArray((!!element && element.trip ? element.trip.split("\n") : []).map((text: string, index: number) => (
            {
                text: text,
                id: "trip-" + index,
                index: index
            }
        )));
    }, [element]);

    if (!element) {
        return <Fragment />
    }


    return (
        <div className={"mt-2 grid gap-2 mb-2 w-[300px]"}>
            <strong>{t('Route')}</strong>
            {tripsArray.length > 0 &&
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={tripsArray}
                        strategy={verticalListSortingStrategy}
                    >
                        {tripsArray.map((trip: any, index: number) => <SortableItem key={"trip-" + trip.id} trip={trip} index={index} />)}
                    </SortableContext>
                </DndContext>
            }
            {(hasChanges || saveDataParams.loading) &&
                <MainButton iconName='save' loading={saveDataParams.loading} onClick={doSave} confirmation={true}>
                    {t('Save')}
                </MainButton>
            }
        </div>
    )

    function handleDragEnd(event: any) {
        const { active, over }: any = event;

        if (isTrackingSpecialist) {
            return;
        }

        if (active.id !== over.id) {
            setTripsArray((items) => {
                // @ts-ignore
                const oldIndex = items.map(t => t.id).indexOf(active.id);
                // @ts-ignore
                const newIndex = items.map(t => t.id).indexOf(over.id);

                const _items = arrayMove(items, oldIndex, newIndex);
                return _items;
            });
            setHasChanges(true);
        }
    }

}

function SortableItem(props: any) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.trip.id });

    const style: any = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {props.index + 1}) {props.trip.text}
        </div>
    );
}

