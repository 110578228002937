import React, { Fragment } from "react";

import { useCargoHookNae } from "../../../../_generated/_custom/hooks/useCargoHookNae";
import { PropsId } from "../../../../_generated/_custom/models-cache-data/types";
import { MainButtonWithSave } from "@newageerp/v3.bundles.buttons-bundle";
import { NaeSSchemaMap } from "../../../../_generated/_custom/config/NaeSSchema";
import { useCargoOperationConfirmationChangesHookNae } from "../../../../_generated/_custom/hooks/useCargoOperationConfirmationChangesHookNae";

export default function OperationalChangesSuccessBtn(props: PropsId) {
  const element = useCargoHookNae(props.id);
  if (!element) {
    return <Fragment />
  }

  return (
    <div className="space-y-2">
      {element.dpsConfirmationChanges.map(d => {
        return (<TextLine id={d.id} key={`d-${d.id}`} />)
      })}
    </div>
  )
}


const TextLine = (props: PropsId) => {
  const t = (s: string) => s;

  const el = useCargoOperationConfirmationChangesHookNae(props.id);
  if (!el) {
    return <Fragment />
  }
  if (!el.active) {
    return <Fragment />
  }
  return (<Fragment>
    <MainButtonWithSave
      confirmation={true}
      elementId={props.id}
      schema={NaeSSchemaMap.CargoOperationConfirmationChanges.schema}
      saveData={{
        active: false
      }}
      color={'teal'}
    >
      {t('Done')}
    </MainButtonWithSave>
  </Fragment>)
}