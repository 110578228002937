import React, { Fragment, useEffect, useState } from "react";

import TableCargos from "./Components/Widget/TableCargos/TableCargos";
import TableFiles from "./Components/Widget/TableFiles";
import TableContacts from "./Components/Widget/TableContacts";
import TablePenalties from "./Components/Widget/TablePenalties";
import TableCosts from "./Components/Widget/TableCosts";
import PlannedCargos from "./Components/Widget/PlannedCargos";
import TableCargosTrip from "../../UserComponents/KzCarriersOrder/BottomWidget/TableCargosTrip";

import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { defTableSort } from "../../_generated/v3/utils";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { TabContainer } from "@newageerp/v3.bundles.layout-bundle";
import { useKzCarriersOrderHookNae } from '../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { checkUserAdmin, checkUserForwardingManager, checkUserHeadOfLogisticsHelper, checkUserLogisticFtl, checkUserLogisticSea, checkUserLogisticTrain, checkUserOperational } from '../../_generated/_custom/config/NaeSPermissions';
import { Template, parseChildrenNode, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useNaeRecord } from "@newageerp/v3.app.mvc.record-provider";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";


interface IData {
  id: number;
  extraTotalPenalty: number;
  extraTotalDowntime: number;
  extraTotalCustomsInspection: number;
  serialNumber: string;
  status: number;
  outgoingDocumentsChange: number;
  outgoingDocumentsChangePurpose: {
    id: number;
    name: string;
    needConfirmMail: number;
  };
  name: string;
  ldm: number;
  cbm: number;
  allCargoWeight: number;
  ldmCalculationType: number;
  cargoNotes: string;
  isExtraSvh: boolean;
  extraServiceDeliveryClient: number;
  needTemperatureMode: number;

  unloadWarehouseDate: string;
  poUnloadDate: string;
  badKzCarriersDate: string;

  soUnloadAddressJson: {
    shortAddress: string;
    fullAddress: string;
  };

  operational?: {
    fullName: string;
  };
  commentLoading: string;
  stock: string;
  salesOrderJson: any;
  extraServiceDeliveryClientAddress: string;
  soClientJson: {
    clientFilialName: string;
  };
  soContactJson: {
    id: number;
    fullName: string;
    phones: any;
    emails: any;
  };
  sellerJson: {
    name: string;
  };

  filesSentToTerminal: boolean,

  needIE599File: boolean,
}
const defObject: IData = {
  id: 0,
  extraTotalPenalty: 0,
  extraTotalDowntime: 0,
  extraTotalCustomsInspection: 0,
  serialNumber: "",
  status: 0,
  outgoingDocumentsChange: 0,
  outgoingDocumentsChangePurpose: {
    id: 0,
    name: "",
    needConfirmMail: 0,
  },
  name: "",
  ldm: 0,
  cbm: 0,
  allCargoWeight: 0,
  ldmCalculationType: 0,
  cargoNotes: "",
  isExtraSvh: false,
  extraServiceDeliveryClient: 0,
  needTemperatureMode: 0,

  unloadWarehouseDate: "",
  poUnloadDate: "",
  badKzCarriersDate: "",

  soUnloadAddressJson: {
    shortAddress: "",
    fullAddress: "",
  },
  operational: {
    fullName: "",
  },
  commentLoading: "",
  stock: "",
  salesOrderJson: "",
  extraServiceDeliveryClientAddress: "",
  soClientJson: {
    clientFilialName: "",
  },
  soContactJson: {
    id: 0,
    fullName: "",
    phones: "",
    emails: "",
  },
  sellerJson: {
    name: "",
  },

  filesSentToTerminal: false,

  needIE599File: false,
};

type Props = {
  kzCalc: Template[],
  kzInvoices: Template[],
} & PropsId;

export default function KzCarriersOrderCargosWidget(props: Props) {
  const elementId = props.id;
  const element = useKzCarriersOrderHookNae(elementId);

  const t = (s: string) => s;

  const { viewStackIndex } = useNaeRecord();
  const { userState } = useTemplatesCore()

  const isFtlLogistic = checkUserLogisticFtl(userState);
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);
  const isDps = checkUserOperational(userState);

  const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);
  const isForwardingManager = checkUserForwardingManager(userState);

  const isAdmin = checkUserAdmin(userState);

  const [getData, getDataParams] = useUList<IData>("cargo", getKeysFromObject(defObject));

  const loadData = () => {
    getData(
      [
        {
          "and": [
            ['i.kzCarriersOrder', '=', elementId, true]
          ]
        }
      ],
      1,
      200,
      [
        defTableSort
      ]
    )
  };

  useEffect(loadData, [elementId]);

  if (!element) {
    return <Fragment />
  }

  const showPlannedCargoAvia = element.transportType === 20;

  let showPlannedCargo =
    ((element.status < 20 &&
      !isFtlLogistic &&
      (!(isLogisticSea) || showPlannedCargoAvia)) ||
      isAdmin ||
      isLogisticTrain) &&
    (viewStackIndex === 1 || viewStackIndex === 0);

  if (isTrackingSpecialist || isForwardingManager) {
    showPlannedCargo = false;
  }

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;

  const data: IData[] = isData ? getDataParams.data.data : [];

  const items = [];

  items.push(
    {
      content: (
        <Fragment>
          <TableCargos id={elementId} kzIds={[]} />
        </Fragment>
      ),
      tab: {
        children: t("Information"),
      },
    }
  );

  if (!isDps) {
    items.push(
      {
        content: (
          <TableCargosTrip
            id={elementId}
          />
        ),
        tab: {
          children: t("Trip"),
        },
      }
    );
  }

  if (showPlannedCargo && !isDps) {
    items.push(
      {
        content: (
          <PlannedCargos
            ids={[elementId]}
            onSave={loadData}
          />
        ),
        tab: {
          children: t("Planning orders"),
        },
      }
    );
  }

  if (!isDps) {
    items.push(
      {
        content: (
          parseChildrenNode(props.kzCalc)
        ),
        tab: {
          children: t("Calculations"),
        },
      }
    );
  }

  // if (element.scopes.indexOf('can-changes') > -1) {
  items.push(
    {
      content: (
        <TableFiles data={data} />
      ),
      tab: {
        children: t("Files upload"),
      },
    }
  );
  // }

  items.push(
    {
      content: (
        <TableContacts
          data={data}
          elementId={elementId}
        />
      ),
      tab: {
        children: t("Contacts"),
      },
    }
  );

  if (!isDps) {
    items.push(
      {
        content: (
          <TablePenalties
            elementId={elementId}
          />
        ),
        tab: {
          children: t("Expenses"),
        },
      }
    );
  }

  if (!isDps) {
    items.push(
      {
        content: (
          <TableCosts elementId={elementId} />
        ),
        tab: {
          children: t('Expense sheet'),
        },
      }
    );
  }

  // if (!isDps) {
    items.push(
      {
        content: (
          parseChildrenNode(props.kzInvoices)
        ),
        tab: {
          children: t("Invoices"),
        },
      }
    );
  // }

  return (
    <Fragment>
      {isData && (
        <WhiteCard isCompact={true}>
          <TabContainer items={items} />
        </WhiteCard>
      )}
    </Fragment>
  );
}
