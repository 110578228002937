import React, { Fragment, useEffect, useState } from 'react'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'

import { toast, useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../config/NaePaths';
import { useSelector } from 'react-redux';
import { SelectorUserNae } from '../../../models/ormSelectors';
import { IUserModelNae } from '../../../hooks/useUserHookNae';
import { Popup } from '@newageerp/v3.bundles.popup-bundle';
import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';
import { NaeSSchemaMap } from '../../../config/NaeSSchema';

interface Props {
    fieldKey: string,
}

export default function MoveClienClient(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const listDataSource = useListDataSource();
    const { element } = tData;
    const value = element[props.fieldKey];
    const [showPopup, setShowPopup] = useState(false);
    const [newId, setNewId] = useState(0);

    const [countryId, setCountryId] = useState(0);
    const [leadSourceId, setLeadSourceId] = useState(0);

    const [countriesReq, countriesData] = useUList(NaeSSchemaMap.Country.schema, ['id', 'nameEn']);
    const [leadSourceReq, leadSourceData] = useUList(NaeSSchemaMap.LeadSourceCategory.schema, ['id', 'name']);

    const t = (s: string) => s;
    const UserData = useSelector((state) => SelectorUserNae(state));
    const users = UserData.filter(
        (u: IUserModelNae) =>
            u.permissionGroup === "user-manager" && u.disabled === false
    ).sort((a: IUserModelNae, b: IUserModelNae) =>
        a.fullName.localeCompare(b.fullName)
    );

    const [unAssignClient, unAssignClientParams] = useURequest<number>(
        NaePathsMap.post["AppPluginsClientOwnersMoveOwnerClient"]
    );

    const moveClient = async () => {
        setShowPopup(false);
        unAssignClient({
            doerId: element.id,
            newId,
            countryId,
            leadSourceId,
        }).then(() => {
            listDataSource.data.reload.do();
            toast.success(
                "The job has been sent for processing. You will receive a notification after its completion."
            );
        });

    };
    useEffect(() => {
        if (!showPopup) {
            setNewId(0);
        }
    }, [showPopup]);

    useEffect(() => {
        countriesReq(
            [],
            1,
            1000,
            [
                {
                    key: 'i.nameEn',
                    value: 'ASC'
                }
            ]
        );
        leadSourceReq(
            [],
            1,
            1000,
            [
                {
                    key: 'i.name',
                    value: 'ASC'
                }
            ]
        );
    }, []);

    if (unAssignClientParams.loading) {
        return <Fragment />
    }

    return (
        <Fragment>
            <MainButton onClick={() => setShowPopup(true)} color={"orange"} iconName={"up-down-left-right"}>
                {t("Move clients")}
            </MainButton>
            <Popup isPopup={showPopup} title="Move" onClick={() => setShowPopup(false)}>

                <Compact className='pb-20'>
                    <CompactRow
                        control={
                            <FieldSelect
                                className='w-96'
                                value={newId}
                                onChange={setNewId}
                                options={[
                                    {
                                        label: "",
                                        value: 0,
                                    },
                                    ...users.map((u: IUserModelNae) => ({
                                        label: u.fullName,
                                        value: u.id,
                                    }))
                                ]}
                            />
                        }
                        label={<FieldLabel>Move to</FieldLabel>}
                    />

                    <CompactRow
                        control={
                            <FieldSelect
                                className='w-96'
                                value={countryId}
                                onChange={setCountryId}
                                options={[
                                    {
                                        label: "All",
                                        value: 0,
                                    },
                                    ...countriesData.data.data.map((u: any) => ({
                                        label: u.nameEn,
                                        value: u.id,
                                    }))
                                ]}
                            />
                        }
                        label={<FieldLabel>Country</FieldLabel>}
                    />

                    <CompactRow
                        control={
                            <FieldSelect
                                className='w-96'
                                value={leadSourceId}
                                onChange={setLeadSourceId}
                                options={[
                                    {
                                        label: "All",
                                        value: 0,
                                    },
                                    ...leadSourceData.data.data.map((u: any) => ({
                                        label: u.name,
                                        value: u.id,
                                    }))
                                ]}
                            />
                        }
                        label={<FieldLabel>Category</FieldLabel>}
                    />

                    <MainButton onClick={moveClient} disabled={newId === 0} confirmation={true} color='sky' iconName='up-down-left-right'>
                        Move
                    </MainButton>
                </Compact>

            </Popup>
        </Fragment>
    )
}
