import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';

import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import BottomWidgetTerminal from '../../../UserComponents/Cargo/BottomWidget/BottomWidgetTerminal';
import CargoLoadingFormComment from './CargoLoadingFormComment';
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';

export default function CargoTerminalBlock(props: PropsId) {

    const t = (s: string) => s;
    const element = useCargoHookNae(props.id);

    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>
            <BottomWidgetTerminal id={element.id}/>

            {!!element.stock && (
                <div>
                    <FieldLabel>{t('Stock')}:</FieldLabel>
                    <p>{element.stock}</p>
                </div>
            )}

            <CargoLoadingFormComment id={element.id} />
        </Fragment>
    )
}
