import React, { Fragment } from 'react'

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useKzCarriersOrderHookNae } from '../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';
import { checkUserOperationalPro } from '../../../_generated/_custom/config/NaeSPermissions';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';

export default function KzDriverTd(props: PropsId) {
    const {userState} = useTemplatesCore()
    const isOperationalPro = checkUserOperationalPro(userState);

    const element = useKzCarriersOrderHookNae(props.id);


    const t = (s: string) => s;

    if (!element) {
        return <Fragment />
    }
    if (!(element.transportType === 10 || element.transportType === 50)) {
        return <Fragment />
    }
    if (isOperationalPro) {
        return <Fragment />
    }

    return (
        <Fragment>
            {!!element.driver ? element.driver : <StatusWidget
                color={"red"}
            >
                {t('Driver\'s contacts are not indicated')}
            </StatusWidget>}
        </Fragment>
    )
}
