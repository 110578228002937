import React, { Fragment } from "react";

import OperationalCargoReturnSuccess from "../Operational/OperationalCargoReturnSuccess";
import OperationalCargoReturnText from "../Operational/OperationalCargoReturnText";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";

export default function OperationalCargoReturnBlock(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />
  }
  if (!(element.operativeCardIndex === 5 || element.operativeCardIndex === 6)) {
    return <Fragment />;
  }

  return (
    <WhiteCard isCompact={true} className={"border border-red-500"} title={t("Urgent task")}>

      <OperationalCargoReturnText id={element.id} />

      <OperationalCargoReturnSuccess id={element.id} />

    </WhiteCard>
  );
}
