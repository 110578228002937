import React, { Fragment } from "react";

import CargoDocumentsBtn from "../CargoDocumentsBtn";
import { kzSortCargo } from '../../../CaspianHelperFunctions';

import OutgoingDocumentsChangeWidget from '../../../../UserComponents/Cargo/Components/OutgoingDocumentsChangeWidget';
import CargoSerialNumberWithLinkAndIcons from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndIcons';
import CargoSalesOrderClientOrFilialName from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName';
import CargoExpeditorFullName from '../../../../_generated/_custom/models-cache-data/Cargo/CargoExpeditorFullName';
import CargoSalesOrderUnloadAddressShortAddress from '../../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderUnloadAddressShortAddress';
import CargoTerminalOrDirect from '../../../../_generated/_custom/models-cache-data/Cargo/CargoTerminalOrDirect';
import CargoLoadDateSellerOrLoadDate from '../../../../_generated/_custom/models-cache-data/Cargo/CargoLoadDateSellerOrLoadDate';
import CargoUnloadWarehouseDateOrPoUnloadDateStr from '../../../../_generated/_custom/models-cache-data/Cargo/CargoUnloadWarehouseDateOrPoUnloadDateStr';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import CarriersOrderCarNumber from '../../../../_generated/_custom/models-cache-data/CarriersOrder/CarriersOrderCarNumber';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorCargoNae } from "../../../../_generated/_custom/models/ormSelectors";

import { ICargoModelNae, useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { useKzCarriersOrderHookNae } from '../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { checkUserLogisticSea, checkUserLogisticTrain, checkUserLogisticFtl, checkUserOperational } from "../../../../_generated/_custom/config/NaeSPermissions";
import { StatusWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";

export default function KzOrderLogisticCargoLines(props: PropsId) {
  const t = (s: string) => s;

  const element = useKzCarriersOrderHookNae(props.id);
  const elementCargos = element ? element.cargos.map(el => el.id) : [-1];
  // @ts-ignore
  const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

  const { userState } = useTemplatesCore()
  const isTrainLogistic = checkUserLogisticTrain(userState);
  const isSeaLogistic = checkUserLogisticSea(userState);
  const isDps = checkUserOperational(userState);

  const isFtlLogistic = checkUserLogisticFtl(userState);
  const isAdmin = !(isTrainLogistic || isSeaLogistic) && !isFtlLogistic;

  if (!element) {
    return <Fragment />
  }
  if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
    return <Fragment />
  }


  return (
    <div className="p-4 bg-slate-100">
      <Table
        thead={
          <thead>
            <tr>
              <Th ySmall xSmall>{t('Cargo')}</Th>
              {isDps && <Th ySmall xSmall>{t('Sales order')}</Th>}
              <Th ySmall xSmall>{t('Cargo description')}</Th>
              {(element.status === 15 || ((isFtlLogistic || isTrainLogistic || isSeaLogistic || isDps) && element.status < 30)) && <Th ySmall xSmall>{t('Seller')}</Th>}
              <Th ySmall xSmall>{t('Client')}</Th>
              {(element.transportType === 10 && !isFtlLogistic) && (
                <Fragment>
                  <Th ySmall xSmall className={"text-right"}>{t('CLM')}</Th>
                  <Th ySmall xSmall className={"text-right"}>{t('CBM')}</Th>
                  <Th ySmall xSmall className={"text-right"}>{t('Weight')}</Th>
                  <Th ySmall xSmall className={"text-right"}>{t('Quotation scheme')}</Th>
                  <Th ySmall xSmall>{t('Cargo specifics')}</Th>
                </Fragment>
              )}
              {!isFtlLogistic && element.status < 15 &&
                <Th ySmall xSmall>{t('Logistas')}</Th>
              }
              {(element.status < 30 || isAdmin) && <Th ySmall xSmall>{t('Dokumentai')}</Th>}
              <Th ySmall xSmall>{t('Responsible person')}</Th>
              {!isFtlLogistic && !(isTrainLogistic || isSeaLogistic) && <Th ySmall xSmall>{t('Terminal')}</Th>}
              {/* <th>Vehicle</th> */}

              {(isSeaLogistic || isTrainLogistic) && element.status <= 15 && <Th ySmall xSmall>{t('Vehicle')}</Th>}
              {!isFtlLogistic && !(isSeaLogistic || isTrainLogistic) && <Th ySmall xSmall>{t('Unloading address')}</Th>}

              {element.status < 15 && (isSeaLogistic || isTrainLogistic) && <Th ySmall xSmall>{t('Approximate ready date')}</Th>}

              {element.status < 15 && !isFtlLogistic && <Th ySmall xSmall>{t('Approximate arrival date to warehouse')}</Th>}

              {element.transportType === 10 && element.status < 15 && !isFtlLogistic && (<Th ySmall xSmall>{t('Warehouse number')}</Th>)}
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {cargos.sort(kzSortCargo).map((cargo: ICargoModelNae) => {
              return (
                <KzOrderLogisticCargoLinesLine id={cargo.id} />
              )
            })}
          </tbody>
        }
      />
    </div>
  );
}

const KzOrderLogisticCargoLinesLine = (props: PropsId) => {
  const { userState } = useTemplatesCore()
  const isTrainLogistic = checkUserLogisticTrain(userState);
  const isSeaLogistic = checkUserLogisticSea(userState);
  const isFtlLogistic = checkUserLogisticFtl(userState);
  const isAdmin = !(isTrainLogistic || isSeaLogistic) && !isFtlLogistic;
  const isDps = checkUserOperational(userState);

  const elementZ = useCargoHookNae(props.id);
  const kzOrder = useKzCarriersOrderHookNae(elementZ && elementZ.kzCarriersOrder ? elementZ.kzCarriersOrder.id : -1);
  if (!elementZ || !kzOrder) {
    return <Fragment />
  }

  return (
    <tr key={"cargo-rel-" + elementZ.id}>
      <Td xSmall ySmall className={"whitespace-nowrap"}>
        <CargoSerialNumberWithLinkAndIcons id={elementZ.id} />

        <OutgoingDocumentsChangeWidget
          id={elementZ.id}
        />

      </Td>
      {isDps && <Td xSmall ySmall><DfValueView path="cargo.salesOrder.serialNumber" link={true} id={elementZ.id} /></Td>}
      <Td xSmall ySmall>
        <DfValueView path="cargo.name" id={elementZ.id} />
      </Td>

      {(kzOrder.status === 15 || ((isFtlLogistic || (isTrainLogistic || isSeaLogistic || isDps)) && kzOrder.status < 30)) &&
        <Td xSmall ySmall><DfValueView path="sender.name" id={elementZ.seller.id} link={isDps} /></Td>
      }
      <Td xSmall ySmall>
        <CargoSalesOrderClientOrFilialName id={elementZ.id} link={isDps} />
      </Td>
      {kzOrder.transportType === 10 && !isFtlLogistic && (
        <Fragment>
          <Td xSmall ySmall className={"text-right"}><DfValueView path="cargo.ldm" id={elementZ.id} /></Td>
          <Td xSmall ySmall className={"text-right"}><DfValueView path="cargo.cbm" id={elementZ.id} /></Td>
          <Td xSmall ySmall className={"text-right"}>
            <DfValueView path="cargo.allCargoWeight" id={elementZ.id} />
          </Td>
          <Td xSmall ySmall className={"text-right"}>
            <DfValueView path="cargo.ldmCalculationType" id={elementZ.id} />
          </Td>
          <Td xSmall ySmall>
            <DfValueView path="cargo.cargoNotes" id={elementZ.id} />
          </Td>
        </Fragment>
      )}
      {!isFtlLogistic && kzOrder.status < 15 &&
        <Td xSmall ySmall>
          <CargoExpeditorFullName id={elementZ.id} />
        </Td>
      }
      {(kzOrder.status < 30 || isAdmin) &&
        <Td xSmall ySmall>
          <CargoDocumentsBtn id={elementZ.id} />
        </Td>
      }
      <Td xSmall ySmall>
        <DfValueView path={"cargo.creator.fullName"} id={elementZ.id} />
      </Td>
      {!isFtlLogistic && !(isTrainLogistic || isSeaLogistic) && <Td xSmall ySmall><CargoTerminalOrDirect id={elementZ.id} /></Td>}

      {(isTrainLogistic || isSeaLogistic) && kzOrder.status <= 15 && <Td xSmall ySmall>
        {elementZ.activePoOrders.map(po => <CarriersOrderCarNumber key={"po-" + po.id} id={po.id} />)}
      </Td>}

      {!isFtlLogistic && !(isTrainLogistic || isSeaLogistic) && <Td xSmall ySmall>
        <CargoSalesOrderUnloadAddressShortAddress id={elementZ.id} />
      </Td>
      }

      {kzOrder.status < 15 && (isTrainLogistic || isSeaLogistic) && <Td xSmall ySmall><CargoLoadDateSellerOrLoadDate id={elementZ.id} /></Td>}

      {kzOrder.status < 15 && !isFtlLogistic && (
        <Td xSmall ySmall>
          {elementZ.badKzCarriersDate ? <StatusWidget color="red" smallPadding={true}>
            <CargoUnloadWarehouseDateOrPoUnloadDateStr id={elementZ.id} />
          </StatusWidget> : <CargoUnloadWarehouseDateOrPoUnloadDateStr id={elementZ.id} />
          }
        </Td>
      )}
      {kzOrder.transportType === 10 && !isFtlLogistic && kzOrder.status < 15 && (
        <Td xSmall ySmall><DfValueView path="cargo.stock" id={elementZ.id} /></Td>
      )}
    </tr>
  );
}