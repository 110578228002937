import React, { Fragment, useState, useEffect } from "react";
import "@szhsin/react-menu/dist/index.css";

import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'

interface Props extends PropsId {
  sm?: boolean;
}

const langs: any = {
  en: "English",
  lt: "Lietuviškai",
  ru: "По-русски",
  pl: "Po polsku",
};


export default function CargoSenderNonDualUse(props: Props) {
  const [isPopup, setIsPopup] = useState(false);

  const element = useCargoHookNae(props.id);

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />;
  }

  const title = t("Resend Non Dual Use letter to the Seller");

  const openEmail = (lang: string) => {
    const files = [
      {
        name: 'NON DUAL USE SAMPLE.docx',
        link: 'https://files.caspianlogistics.lt/api/d/D6SMz-qRER6LadXPraYCbrIkBiqE/NON_DUAL_USE_DRAFT.docx',
      },
      // {
      //   name: 'LETTER DRAFT.docx',
      //   link: 'https://files.caspianlogistics.lt/api/d/D6SMz-qRER6LadXPraYCbrIkBiqE/LETTER_DRAFT.docx',
      // }
    ];

    const options = {
      extraData: {
        id: element.id,
        schema: "cargo",
        template: "sender-non-dual-use-email",
        lang: lang,
        type: 'sender-non-dual-use-email',
      },
      files: files,
    }
    const event = new CustomEvent(
      'SFSOpenEmailForm',
      {
        detail: options
      }
    );
    window.dispatchEvent(event);
    setIsPopup(false);
  }

  return (
    <Fragment>
      <MenuItem onClick={() => setIsPopup(true)} iconName="paper-plane" className="whitespace-nowrap">
        {title}
      </MenuItem>

      {isPopup &&
        <Popup title={title} isPopup={true} onClick={() => setIsPopup(false)}>
          <div className="space-y-2">
            {Object.keys(langs).map((_lang: string) => (
              <MainButton
                key={"lang-" + _lang}
                color="white"
                className="w-[350px]"
                onClick={() => {
                  openEmail(_lang);
                }}
              >
                {langs[_lang]}
              </MainButton>
            ))}
          </div>
        </Popup>
      }

    </Fragment>
  );
}
