import React, { Fragment } from 'react'

import CancelSo from './components/so/CancelSo';

import { CheckUserPermissionComponent, ENaeSPermissions } from '../../../../_generated/_custom/config/NaeSPermissions';
import SalesOrderManagerClientFilial from '../../../../UserComponents/SalesOrder/Widget/SalesOrderManagerClientFilial';
import SalesOrderContactChange from '../../../../UserComponents/SalesOrder/Widget/SalesOrderContactChange';
import ManagerSalesOrderAddressChange from '../../../../UserComponents/SalesOrder/Widget/ManagerSalesOrderAddressChange';
import SalesOrderUnloadResponsiblePersonChange from '../../../../UserComponents/SalesOrder/Widget/SalesOrderUnloadResponsiblePersonChange';
import SalesOrderManagerNotes from '../../../../UserComponents/SalesOrder/Widget/SalesOrderManagerNotes';
import { ToolbarButtonWithMenu } from '@newageerp/v3.bundles.buttons-bundle';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { MenuItemWithEdit } from '@newageerp/v3.bundles.modal-bundle';
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';
import SoFinishBtn from '../../../SalesOrder/Element/SoFinishBtn';

export default function SoToolbarItemWidget(props: PropsId) {
    const t = (s: string) => s;

    return (
        <ToolbarButtonWithMenu
            button={{
                iconName: "circle-ellipsis-vertical",
                title: t('Extra settings')
            }}
            menu={{
                isAbsolute: true,
                children: <Fragment>
                    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager]} >
                        <SalesOrderManagerClientFilial id={props.id} />
                    </CheckUserPermissionComponent>

                    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager]} >
                        <SalesOrderContactChange id={props.id} />
                    </CheckUserPermissionComponent>

                    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager]} >
                        <ManagerSalesOrderAddressChange id={props.id} />
                    </CheckUserPermissionComponent>

                    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager]} >
                        <SalesOrderUnloadResponsiblePersonChange id={props.id} />
                    </CheckUserPermissionComponent>

                    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserManager]} >
                        <SalesOrderManagerNotes id={props.id} />
                    </CheckUserPermissionComponent>

                    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin]} >
                        <MenuItemWithEdit
                            elementId={props.id}
                            schema={"sales-order"}
                            type='doer'
                            children="Update responsible person"
                            forcePopup={true}
                            iconName='edit'
                        />
                    </CheckUserPermissionComponent>

                    <CancelSo id={props.id} />

                    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin]} >
                        <SoFinishBtn id={props.id} />
                    </CheckUserPermissionComponent>
                </Fragment>
            }}

        />
    )
}
