import React, { Fragment, useEffect } from "react";

import CargoLogisticLink from "../../cargo/CargoLogisticLink";
import LogisticCargoReturn from "../../../UserComponents/Cargo/Widget/LogisticCargoReturn";

import LogisticCarriersOrderCell from "./Components/LogisticCarriersOrderCell";
import CargoSalesOrderClientOrFilialName from '../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import CargoTerminalOrSalesOrderAddress from '../../../_generated/_custom/models-cache-data/Cargo/CargoTerminalOrSalesOrderAddress';
import { checkUserLogisticFtl, checkUserLogistic, checkUserLogisticSea, checkUserLogisticTrain } from '../../../_generated/_custom/config/NaeSPermissions';

import CargoFtlAutoTypeBadge from "../../../_generated/badges/cargo/CargoFtlAutoTypeBadge";

import { Tag } from '@newageerp/v3.bundles.badges-bundle'
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';

import { StatusWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import CargoContactedSellerTime from "../../../Plugins/Cargo/CargoContactedSellerTime";
import { getKeysFromObject, groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useDfValue } from "@newageerp/v3.app.data-cache-provider";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import ClientStatusLogist from "../../../_generated/_custom/models-cache-data/Client/ClientStatusLogist";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { OverdueDate } from "../../../Plugins/Common/Data/OverdueDate";


type IElement = {
  id: number,
  ownerCompanyId: number,
  salesOrder: {
    id: number
  },
  seller: {
    id: number,
  }
}

const defObject: IElement = {
  id: 0,
  ownerCompanyId: 0,
  salesOrder: {
    id: 0,
  },
  seller: {
    id: 0,
  }
}

let reloadTimeout: any = -1;

type Props = {
  userId: number,
}

export default function LogisticCargoPlaning(props: Props) {
  const t = (s: string) => s;

  const { userState } = useTemplatesCore()
  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const isLogisticFtl = checkUserLogisticFtl(userState);
  const isLogistic = checkUserLogistic(userState);

  const [getData, getDataParams] = useUList<IElement>("cargo", getKeysFromObject(defObject));
  const loadData = () => {
    let filter: any = [
      ['i.logisticCardIndex', '=', 2, true],
      ['i.status', 'in', [5, 7, 10], true]
    ];
    filter.push(
      ['i.expeditor', '=', props.userId, true]
    );
    getData(
      [{ "and": filter }],
      1,
      200,
      [
        { key: 'i.serialNumber', 'value': 'ASC' }
      ]
    )
  }
  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(loadData, 500);
  };

  useEffect(() => {
    SocketService.addCallback(
      "cargo-logisticCardIndex-2",
      "cargo-logisticCardIndex-2",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "cargo-logisticCardIndex-2",
        "cargo-logisticCardIndex-2"
      );
    };
  }, []);

  const data = getDataParams.data.data.filter((el: IElement) => {
    return true;
  });

  const groupData = groupMap(data, (el: IElement) => el.seller.id.toString())

  const groupedBySo = groupMap(getDataParams.data.data, (el: IElement) => `${el.salesOrder.id}-${el.seller.id}`);
  const keys = Object.keys(groupedBySo);

  return (
    <Fragment>
      <WhiteCard title={t("Planned loads")} isCompact={true}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t('Cargo')}</Th>
                <Th>{t('Seller')}</Th>
                <Th>{t('Client')}</Th>
                <Th></Th>
                <Th>{t('Unloading')}</Th>
                {isLogisticFtl && <Th>{t('Type of vehicle')}</Th>}
                {(isLogisticSea || isLogisticTrain) && <Th>{t('Type of transportation')}</Th>}
                <Th>{t('Return')}</Th>
                {(isLogisticFtl || isLogistic) && <Th>{t('Cargo ready date')}</Th>}
                <Th>{t('Order for carrier (PO)')}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {keys.map((c, index) => {
                return (
                  <LogisticCargoPlaningLine groupData={groupData} ids={groupedBySo[c]} key={"cargo-" + c} />
                )
              })}
            </tbody>
          }
        />
      </WhiteCard>

    </Fragment>
  );
}

type LogisticCargoPlaningLineProps = {
  ids: IElement[],
  groupData: any,
}

const LogisticCargoPlaningLine = (props: LogisticCargoPlaningLineProps) => {
  const { userState } = useTemplatesCore()

  const isLogisticSea = checkUserLogisticSea(userState);
  const isLogisticTrain = checkUserLogisticTrain(userState);

  const isLogisticFtl = checkUserLogisticFtl(userState);
  const isLogistic = checkUserLogistic(userState);

  const cargo = useCargoHookNae(props.ids[0].id);

  if (!cargo) {
    return <Fragment />
  }

  const canPo = cargo.typeIn !== 'none';

  const c = props.groupData[cargo.seller.id.toString()].length;

  return (
    <tr
      className={
        (cargo.needLoadDateConfirm ? " !border-b-2 !border-red-500" : "")
      }
    >
      <Td className="space-y-2">
        <div className="flex flex-col gap-1">
          {props.ids.map(el => <CargoLogisticLink id={el.id} key={`c-${el.id}`} />)}
        </div>

        <p className="whitespace-nowrap"><DfValueView path="cargo.creator.fullName" id={cargo.id} /></p>
      </Td>
      <Td className="space-y-1">
        <p className="flex items-center gap-2">
          <DfValueView path="cargo.seller.name" id={cargo.id} />
          {c > 1 && <Tag tagValue={c} color={"purple"} />}
        </p>

        <div className="bg-slate-300 h-[1px]"></div>

        <CargoContactedSellerTime id={cargo.id} />
      </Td>
      <Td>
        <CargoSalesOrderClientOrFilialName id={cargo.id} />
      </Td>
      <Td><ClientStatusLogist id={cargo.soClientId} /></Td>
      <Td>
        <CargoTerminalOrSalesOrderAddress id={cargo.id} />
      </Td>
      {isLogisticFtl && <Td>
        <CargoFtlAutoTypeBadge id={cargo.id} isCompact={true} />
      </Td>}
      {(isLogisticSea || isLogisticTrain) && (
        <Td>
          <DfValueView path="cargo.typeIn" id={cargo.id} />
        </Td>
      )}

      <Td>
        {!cargo.needLoadDateConfirm && (
          <LogisticCargoReturn
            ids={props.ids.map(el => el.id)}
            sm={true}
            transparent={true}
          />
        )}
      </Td>

      {(isLogisticFtl || isLogistic) && (
        <Td className="text-left whitespace-nowrap">
          <OverdueDate value={cargo.loadDateSeller} />
        </Td>
      )}
      <Td className="space-y-2">

        {canPo && <div className="flex flex-col gap-2">
          {props.ids.map(el => <div className="space-y-1" key={`po-${el.id}`}>

            {props.ids.length > 1 && <p className="font-medium"><DfValueView path="cargo.serialNumber" id={cargo.id} /></p>}
            <InsuranceLine id={el.id} key={`ins-${el.id}`} />
            <LogisticCarriersOrderCell id={el.id} />
          </div>)}
        </div>
        }




      </Td>
    </tr>
  );
}


const InsuranceLine = (props: PropsId) => {
  const t = (s: string) => s;
  const insuranceStatus = useDfValue({
    id: props.id,
    path: 'cargo.cargoInsurance.status'
  })
  return <Fragment>
    {insuranceStatus === 10 && <StatusWidget color="red">{t('Insurance need order')}</StatusWidget>}
    {insuranceStatus === 30 && <StatusWidget color="teal">{t('Insurance ordered')}</StatusWidget>}
  </Fragment>
}