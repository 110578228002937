import React, { useState, useEffect, Fragment } from "react";


import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import {
  CheckUserPermissionComponent,
  ENaeSPermissions,
} from '../../../_generated/_custom/config/NaeSPermissions';
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { useSalesOrderHookNae } from '../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { FieldLabel } from "@newageerp/v3.bundles.form-bundle";
import { MainButton, ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";

interface Props extends PropsId {
  hideHeader?: boolean;
}

export default function SalesOrderCostContent(props: Props) {
  const element = useSalesOrderHookNae(props.id);
  const [showCosts, setShowCosts] = useState(false);
  const t = (s: string) => s;

  const [getSalesOrderCosts, getSalesOrderCostsParams] =
    useURequest(NaePathsMap.post["getSalesOrderCosts"]);

  useEffect(() => {
    if (showCosts) {
      getSalesOrderCosts({ id: element.id });
    }
  }, [showCosts]);

  if (!element) {
    return <Fragment />;
  }

  const isData =
    !!getSalesOrderCostsParams &&
    !!getSalesOrderCostsParams.data &&
    !!getSalesOrderCostsParams.data.data &&
    getSalesOrderCostsParams.data.data.length > 0;
  const costs = isData
    ? groupMap(getSalesOrderCostsParams.data.data, (el) => el.group)
    : {};

  let total = 0;
  return (
    <WhiteCard isCompact={true}>
      {!props.hideHeader && (
        <TextCardTitle>{t("Net price")}</TextCardTitle>
      )}

      {showCosts && isData && (
        <Fragment>
          <Table
            thead={
              <thead>
                <Th>{t("Name")}</Th>
                <Th textAlignment="text-right">{t("Net price")}</Th>
                <Th textAlignment="text-right">{t("Currency")}</Th>
              </thead>
            }
            tbody={
              <tbody>
                {Object.keys(costs).map((key: string, index: number) => {
                  const costData = costs[key];
                  return (
                    <Fragment key={"costs-" + index}>
                      <tr>
                        <Td colSpan={3} className="font-medium text-xs">
                          {key}
                        </Td>
                      </tr>
                      {costData.map((costEl: any, costIndex: number) => {
                        total += costEl.cost;
                        return (
                          <tr key={"cost-l-" + index + "-" + costIndex}>
                            <Td
                              className="text-left"
                            >
                              {costEl.title}
                            </Td>
                            <Td textAlignment="text-right">
                              {costEl.cost.toFixed(2)}
                            </Td>
                            <Td textAlignment="text-right">
                              {costEl.currency}
                            </Td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
                <tr className={"bg-slate-200"}>
                  <Td
                    className="text-left"
                  >
                    {t("Total")}
                  </Td>
                  <Td textAlignment="text-right">
                    {total.toFixed(2)}
                  </Td>
                  <Td textAlignment="text-right">
                    EUR
                  </Td>
                </tr>
              </tbody>
            }
          />

          <MainButton
            color="slate"
            className={"w-full"}
            onClick={() => setShowCosts(false)}
          >
            {t("Hide Net price")}
          </MainButton>
        </Fragment>
      )}
      {!showCosts && (
        <MainButton
          color="slate"
          className={"w-full"}
          onClick={() => setShowCosts(true)}
        >
          {t("Show Net price")}
        </MainButton>
      )}

      <SalesOrderCostContentFullCost id={props.id} />
    </WhiteCard>
  );
}

const SalesOrderCostContentFullCost = (props: PropsId) => {
  const element = useSalesOrderHookNae(props.id);
  const t = (s: string) => s;

  const [saveData, saveDataParams] = useURequest(
    NaePathsMap.post["pluginsSalesOrderFullCostRecaclulateOrderFullCost"]
  );

  const doRequest = () => {
    saveData({
      id: props.id,
    });
  };

  return (
    <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin]}>
      <div className="flex items-center gap-2">
        <FieldLabel>{t("Saved cost")}</FieldLabel>
        <span className="text-base">{element.fullCost.toFixed(2)} EUR</span>
        <ToolbarButton
          iconName="retweet"
          onClick={doRequest}
          loading={saveDataParams.loading}
        />
      </div>
    </CheckUserPermissionComponent>
  );
};
