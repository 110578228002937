import React, { Fragment, useEffect, useState } from 'react'

import axios from "axios";
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function ClientCallsImportPage() {
    const t = (s: string) => s;

    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
    useEffect(() => {
        setToolbarTitle(t('Phone calls import'));
    }, []);

    const [uploadedData, setUploadedData] = useState<any>({});

    const [uploading, setUploading] = useState(false);

    const [importToDb, importToDbParams] = useURequest(NaePathsMap.post['NAEUSaveMultiple']);

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const uploadData = () => {
        if (!!uploadedFile) {
            setUploading(true);

            const fData = new FormData();
            fData.append("file", uploadedFile);

            axios
                .post("/app/nae-core/xlsx/toArray", fData, {
                    headers: {
                        Authorization: window.localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    setUploading(false);
                    setUploadedData(res.data.data);
                });
        }
    }

    const transformData = (data: any[]) => {
        let toReturn: any[] = [
            ['Phone', 'Code']
        ];

        data.forEach((el, i: number) => {
            if (i === 0) {

            } else {
                el.forEach((col: string, colIndex: number) => {
                    if (!!data[0][colIndex] && data[0][colIndex].indexOf('Telefon') === 0) {
                        const codeIndexName = data[0][colIndex].replace('Telefon', '') + ' code';
                        const codeIndex = data[0].indexOf(codeIndexName);
                        if (codeIndex >= 0 && !!el[codeIndex] && !!col) {
                            const stopReason = el[codeIndex].replace(':', '');

                            const num = stopReason === 'confirmed' ? 4 : 1;

                            for (let i = 0; i < num; i++) {
                                toReturn.push([
                                    col,
                                    stopReason
                                ])
                            }
                        }
                    }
                })
            }
        })
        return toReturn;
    }

    const doImport = (data: any) => {
        importToDb({
            schema: NaeSSchemaMap.CallLog.schema,
            fieldsToReturn: ['id'],
            data: data,
            skipSocketMessages: true
        })
    }

    const uploadedSheets: string[] = Object.keys(uploadedData);

    return (
        <Fragment>
            <div className='space-y-2'>
                <WhiteCard isCompact={true}>
                    <div>
                        <p className={"text-xl " + (!!uploadedFile ? "text-green-500" : "text-red-500")}>{"Įkelkite XLSX failą"}</p>
                        <input type={"file"} onChange={(e: any) => setUploadedFile(e.target.files[0])} />
                    </div>

                    {!!uploadedFile && <MainButton onClick={uploadData}>
                        {uploading ? t('Kraunama...') : t('Įkelti')}
                    </MainButton>}
                </WhiteCard>
                {!!uploadedData && <Fragment>

                    {uploadedSheets.map((s: string) => {
                        const sheetData: string[][] = transformData(uploadedData[s]);
                        const sampleData = sheetData.slice(1, sheetData.length - 1);

                        return (
                            <WhiteCard isCompact={true}>
                                <div className='flex gap-2'>
                                    <TextCardTitle className='flex-grow'>{s}</TextCardTitle>

                                    <MainButton confirmation={true} iconName="cloud-arrow-up" color='sky' loading={importToDbParams.loading} onClick={() => {
                                        doImport(
                                            sampleData.map(d => {
                                                return (
                                                    {
                                                        id: "new",
                                                        data: {
                                                            duration: 1,
                                                            fromNumber: "auto",
                                                            toNumber: d[0],
                                                            sourceId: "import",
                                                            date: moment().format("YYYY-MM-DD"),
                                                            stopReason: d[1].indexOf('reached') >= 0 || d[1].indexOf('confirmed') >= 0 ? '' : d[1]
                                                        }
                                                    }
                                                )
                                            })
                                        )
                                    }}>Importuoti</MainButton>


                                </div>
                                <Table
                                    thead={
                                        <thead>
                                            <tr>
                                                {sheetData[0].map((s: string) => {
                                                    return (
                                                        <Th>{s}</Th>
                                                    )
                                                })}

                                            </tr>
                                        </thead>
                                    }
                                    tbody={
                                        <tbody>
                                            {sampleData.map((l: string[], line: number) => {
                                                return (
                                                    <tr key={`line-${line}`}>
                                                        {l.map(c => {
                                                            return (
                                                                <Td>{c}</Td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    }
                                />
                            </WhiteCard>
                        )
                    })}
                </Fragment>}
            </div>
        </Fragment>
    )
}
