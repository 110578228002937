import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';

export default function CargoCancel(props: PropsId) {
    const cargo = useCargoHookNae(props.id);

    const t = (s: string) => s;

    const [doSave] = useUSave("cargo");
    const cancelCargo = () => {
        doSave({ status: 900 }, props.id);
    };

    if (!cargo) {
        return <Fragment />
    }

    let disabled = false;
    let tooltipContent = undefined;

    if (cargo.status >= 10) {
        tooltipContent = t('You can\'t cancel cargo once it has been collected from the seller');
        disabled = true;
    }
    if (!cargo.salesOrder) {
        tooltipContent = t('You can\'t cancel cargo until SO is created');
        disabled = true;
    }
    if (cargo.activePoOrders.length > 0) {
        tooltipContent = t('You can\'t cancel when cargo is planned for loading. Please contact responsible TOM.');
        disabled = true;
    }
    if (!!cargo.kzCarriersOrder) {
        tooltipContent = t('You can\'t cancel when cargo is planned for loading. Please contact responsible TOM.');
        disabled = true;
    }

    return (
        <MenuItem
            isDisabled={disabled}
            iconName={"ban"}
            onClick={cancelCargo}
            tooltipContent={tooltipContent}
            textColor={"red"}
            confirmation={true}
        >
            {t("Cancel cargo")}
        </MenuItem>
    )
}
