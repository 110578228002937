import React, { Fragment } from "react";

import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';

import { useCarriersOrderHookNae } from '../../_generated/_custom/hooks/useCarriersOrderHookNae';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

export default function PoCancelWidget(props: PropsId) {
  const element = useCarriersOrderHookNae(props.id);

  const showCancelPopup = () => {
    SFSOpenEditModalWindowProps({
      id: element.id,
      schema: NaeSSchemaMap.CarriersOrder.schema,
      type: "cancel-po",
      onSaveCallback: (_el, back) => {
        cancelOrder();
        back();
      }
    })
  }

  const cancelOrder = () => {
    doSave({ status: 900 }, element.id);
  }

  const [doSave] = useUSave(NaeSSchemaMap.CarriersOrder.schema, ['id', 'penalty']);

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />;
  }
  if (element.status > 3) {
    return <Fragment />;
  }

  return (
    <Fragment>

      <MenuItem
        iconName={"ban"}
        onClick={showCancelPopup}
        textColor={"red"}
      >
        {t("Atšaukti užsakymą su bauda")}
      </MenuItem>
      <MenuItem
        iconName={"ban"}
        onClick={cancelOrder}
        textColor={"red"}
        confirmation={true}
      >
        {t("Atšaukti užsakymą be baudos")}
      </MenuItem>
    </Fragment>
  );
}
