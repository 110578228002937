import React, { Fragment } from 'react'

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { ICargoModelNae, useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { checkUserLogisticSea, checkUserLogisticTrain } from '../../../_generated/_custom/config/NaeSPermissions';
import CargoLdmCalculationTypeWithTooltip from '../../../UserComponents/Cargo/BottomWidget/CargoLdmCalculationTypeWithTooltip';
import CargoRailwayTypeWithHint from '../../../_generated/_custom/models-cache-data/Cargo/CargoRailwayTypeWithHint';
import CargoPreciousMetalsBadge from '../../../_generated/_custom/models-cache-data/Cargo/CargoPreciousMetalsBadge';
import CargoTnvedCodesForCalc from '../../../_generated/_custom/models-cache-data/Cargo/CargoTnvedCodesForCalc';
import CargoStatusComponent from './CargoStatusComponent';
import KzDatesBlock from './KzDatesBlock';
import CargoFtlAutoTypeBadge from '../../../_generated/badges/cargo/CargoFtlAutoTypeBadge';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getPropertyEnumLabel } from '../../../_generated/v3/utils';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';

import {
    checkUserOperational,
    checkUserOperationalPro,
    checkUserChiefSalesOfficer,
    checkUserManager,
    checkUserHeadOfLogistics,
    checkUserForwardingManager,
    checkUserHeadOfLogisticsHelper,
    
    checkUserLogistic,
    checkUserLogisticFtl,
    checkUserAccounting,
    checkUserFinances,
} from '../../../_generated/_custom/config/NaeSPermissions';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

interface Props extends PropsId {
}

export default function CargoFromTerminalBlock(props: Props) {

    const t = (s: string) => s;
    const element = useCargoHookNae(props.id);

    const { userState } = useTemplatesCore()

    const isChiefSalesOfficer = checkUserChiefSalesOfficer(userState);
    const isSalesManager = checkUserManager(userState);
    const isChiefOperationsOfficier = checkUserHeadOfLogistics(userState);
    const isForwaringManager = checkUserForwardingManager(userState);
    const isOperationsDepartmentAdministrator = checkUserHeadOfLogisticsHelper(userState);
    
    const isSea = checkUserLogisticSea(userState);
    const isTrain = checkUserLogisticTrain(userState);

    const isLtl = checkUserLogistic(userState);
    const isFtl = checkUserLogisticFtl(userState);
    const isHeadOfDocumentProcessingDepartment = checkUserOperationalPro(userState);
    const isDocumentProcessingManager = checkUserOperational(userState);
    const isOfficeManager = checkUserAccounting(userState);
    const isAccountatn = checkUserFinances(userState);

    if (!element) {
        return <Fragment />
    }


    let ftlAutoTypeOut = "";
    if (element.typeOut === "ftl") {
        ftlAutoTypeOut = getPropertyEnumLabel(
            "cargo",
            "ftlAutoType",
            element.ftlAutoType
        );
    }

    let fromTerminalIcon = "";
    if (element.typeOut === "ltl") {
        fromTerminalIcon = 'truck';
    } else if (element.typeOut === "ftl") {
        fromTerminalIcon = 'truck-moving';
    } else if (element.typeOut === "avia") {
        fromTerminalIcon = 'plane';
    } else if (element.typeOut === "sea") {
        fromTerminalIcon = 'ship';
    } else if (element.typeOut === "train") {
        const isContainer =
            element.railwayType === 20 ||
            element.railwayType === 30 ||
            element.railwayType === 40 ||
            element.railwayType === 50;
        if (isContainer) {
            fromTerminalIcon = 'container-storage';
        } else {
            fromTerminalIcon = 'train';
        }
    } else if (element.typeOut === "none") {
        fromTerminalIcon = 'ban';
    }

    let refFromTerminal = "";

    if (element.needTemperatureMode === 10) {
        refFromTerminal = "REF: " + element.temperatureSettings;
    } else if (element.needTemperatureMode === 20) {
    } else if (element.needTemperatureMode === 30) {
        refFromTerminal = "REF: " + element.temperatureSettings;
    }

    return (
        <Fragment>
            {!!fromTerminalIcon && (
                <div className={"mb-2 text-center"}>
                    <i className={`fad fa-${fromTerminalIcon} fa-fw text-4xl text-nsecondary`} />
                </div>
            )}
            {element.typeOut === "ftl" && (
                <CargoFtlAutoTypeBadge id={element.id} isCompact={true} />
            )}

            {!(isOperationsDepartmentAdministrator && isSea && isTrain && isLtl && isFtl && isHeadOfDocumentProcessingDepartment && isDocumentProcessingManager && isOfficeManager && isAccountatn) &&
                <LDMBlock cargo={element} />
            }
            <TrainBlock cargo={element} />

            <CargoStatusComponent id={element.id} />

            {!!refFromTerminal && (
                <StatusWidget smallPadding={true} color={"orange"}>
                    {refFromTerminal}
                </StatusWidget>
            )}

            {!(isLtl || isFtl) &&
                <Fragment>
                    {!!element.kzCarriersOrder && <KzDatesBlock id={element.id} />}
                </Fragment>
            }
        </Fragment>
    )
}

const LDMBlock = (props: {
    cargo: ICargoModelNae
}) => {
    const element = props.cargo;

    const { userState } = useTemplatesCore()
    
    return (
        <div>
            <div className='flex gap-2 items-center'>
                <CargoLdmCalculationTypeWithTooltip id={element.id} />
                <FieldLabel>CLM </FieldLabel>
            </div>
            <p>{element.ldm} ({element.ldmCalculationType})</p>
        </div>
    )
}

const TrainBlock = (props: {
    cargo: ICargoModelNae
}) => {
    const element = props.cargo;

    const t = (s: string) => s;

    return (
        <Fragment>
            {element.typeOut === "train" && (
                <Fragment>
                    <div className={"mb-2"}>
                        <div className={"text-blue-500"}>
                            <CargoRailwayTypeWithHint id={element.id} />{" "}
                            {!!element.kzCarriersOrder && (
                                <DfValueView path="kz-carriers-order.carNumber"
                                    id={element.kzCarriersOrder.id}
                                />
                            )}{" "}
                            <CargoPreciousMetalsBadge id={element.id} />
                        </div>
                        {!!element.tnvedCodesForCalc && (
                            <p>
                                {t("TNVED codes and weights")}:
                                <CargoTnvedCodesForCalc id={element.id} />
                            </p>
                        )}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}