import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment';

import { checkUserManager, checkUserAccounting, checkUserOperational } from '../../_generated/_custom/config/NaeSPermissions';
import VoximplantAudioPlayer from '../Voximplant/VoximplantAudioPlayer';
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { voxCallCodes } from '@newageerp/v3.bundles.voximplant-bundle';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';

interface Props {
    elementId: number;
}

interface IData {
    duration: number,
    startDate: string,
    from: string,
    to: string,
    record: string,
    stopReason: string,
}

export const CallFailHandlers: any = {
    'Destination number is busy': 'The number is busy',
    'Request Terminated': 'Calls ended manually',
    'Call was rejected': 'Skambutis buvo atmestas',
    'Invalid number': 'Neteisingas numeris',
    'Destination number is unavailable': 'The number is unreachable',
    'Insufficient funds': 'Nepakanka lėšų',
    'Temporarily Unavailable': 'Laikinai nepasiekiamas',
    'Busy Here': 'Užimtas',
    'Busy': 'Užimtas',
}

export default function VoxCallsList(props: Props) {
    const t = (s: string) => s;
    const { elementId } = props;

    const {userState} = useTemplatesCore()
    const isManager = checkUserManager(userState)
    const isAccounter = checkUserAccounting(userState);
    const isDps = checkUserOperational(userState);

    const [getData, getDataParams] = useURequest<IData>(NaePathsMap.post.VOXsearchByClientFromLog);
    const loadData = () => {
        getData({
            id: elementId,
            isManager: isManager || isAccounter || isDps ? userState.id : 0
        });
    }
    useEffect(loadData, []);

    const data = getDataParams.data.data;

    return (
        <Fragment>

            {getDataParams.loading && <LogoLoader />}
            <div className={"divide-y divide-slate-300"}>
                {data.map((el: IData, index: number) => {
                    return (
                        <div className={"px-2 py-2 space-y-2 mb-4 bg-white rounded-sm"}>
                            <div className={"flex w-full items-center gap-2"}>
                                <i className={"fad fa-phone text-nsecondary"} />
                                <div className={"text-slate-400 text-xs"}>
                                    {el.startDate}
                                </div>
                                <div className={"text-slate-600 text-xs flex-grow"}>
                                    {el.from}{" -> "}{el.to}
                                </div>
                                {el.duration > 0 &&
                                    <div>
                                        {moment().startOf('day')
                                            .seconds(el.duration)
                                            .format('H:mm:ss')}
                                    </div>
                                }
                                {el.duration === 0 && !!el.stopReason && <div className={"text-red-500"}>{el.stopReason in voxCallCodes ? voxCallCodes[el.stopReason] : el.stopReason}</div>}
                            </div>
                            <div className={"w-full"}>
                                {!!el.record && <VoximplantAudioPlayer url={el.record} />}
                            </div>
                        </div>
                    )
                })}
            </div>
        </Fragment>
    )
}
