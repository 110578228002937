import React from "react";

import SalesOrderDashboardLink from "../../../../components/sales-order/SalesOrderDashboardLink";
import SalesOrderClientWithLinkFilialName from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientWithLinkFilialName';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { DfValueView } from "@newageerp/v3.bundles.app-bundle";
import { PoLoadingDatesChangeHistoryBtn } from "../../../../components/cargo/CargoBottomComponents/PoLoadingDatesChangeHistory";
import { ManagerOrderIData } from "../ManagerOrderWrapper";
interface Props {
  data: ManagerOrderIData[];
}


export default function ManagerOrderOnEuRoad(props: Props) {
  const t = (s: string) => s;
  return (
    <WhiteCard isCompact={true} title={t("Loaded cargo")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t('Order')}</Th>
              <Th>{t('Cargo')}</Th>
              <Th>{t('Client')}</Th>
              <Th>{t('Seller')}</Th>
              <Th>{t('Loading Country')}</Th>
              <Th>{t('Responsible')}</Th>
              {/* <th>{t('Actual ready date')}</th> */}
              <Th>{t('Loading date')}</Th>
              <Th>{t('Estimated date of arrival')}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {props.data.sort((a, b) => {
              const aDateStr = a.poLoadDateStr;
              const bDateStr = b.poLoadDateStr;

              var x = aDateStr.toLowerCase();
              var y = bDateStr.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            }).map((c: ManagerOrderIData) => {

              const seller = c.seller

              return (
                <tr
                  key={"order-" + c.id}
                >
                  <Td>
                    <SalesOrderDashboardLink
                      element={c.salesOrder}
                    />
                  </Td>
                  <Td>
                    <SalesOrderDashboardLink
                      element={c.salesOrder}
                      cargo={c}
                    />
                  </Td>
                  <Td>
                    <SalesOrderClientWithLinkFilialName id={c.salesOrder.id} />
                  </Td>
                  <Td>
                    <DfValueView path="cargo.seller.name" id={c.id} />
                  </Td>
                  <Td>
                    <DfValueView path="cargo.seller.country.nameEn" id={c.id} />
                  </Td>
                  <Td>{c.expeditor ? c.expeditor.fullName : " "}</Td>

                  <Td className="whitespace-nowrap flex gap-1">
                    {c.poLoadDateStr}
                    <PoLoadingDatesChangeHistoryBtn
                        ids={c.activePoOrders.map(i => i.id)}
                        asIcon={true}
                      />
                  </Td>
                  <Td>
                    {c.poUnloadDateStr}
                  </Td>
                </tr>
              );
            })}

          </tbody>
        }
      />

    </WhiteCard >
  );
}
