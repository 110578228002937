import React, { useEffect, useState } from "react";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import moment from "moment";

import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { AlertWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { StatusWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { TemplatesLoader, toast, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { LogoLoader, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useUList, useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";
import { useListTableRow } from "@newageerp/v3.bundles.app-bundle";
import classNames from "classnames";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { useAppData } from '@newageerp/v3.app.app-data'

interface IData {
  id: number;
  name: string;
  clientStatus: {
    id: number;
    titleManager: string;
    colorManager: string;
  };
  priority: number;
}

const defObject: IData = {
  id: 0,
  name: "",
  clientStatus: {
    id: 0,
    titleManager: "",
    colorManager: "",
  },
  priority: 0,
};

interface Props {
  type?: number;
  userId: number,
}

export default function FollowUpLeads(props: Props) {
  const appData = useAppData();

  const [activeTab, setActiveTab] = useState("");
  const [clicked, setClicked] = useState(false);

  const { userState } = useTemplatesCore()

  const t = (s: string) => s;

  const [getFollowUps, getFolloUpsData] = useUList<any>(
    NaeSSchemaMap.FollowUp.schema,
    ["parentId", "onDate"]
  );
  const [getLeads, getLeadsParams] = useURequest(
    NaePathsMap.post["AppPluginsClientAssign"]
  );
  const [getData, getDataParams] = useUList<IData>(
    NaeSSchemaMap.Client.schema,
    getKeysFromObject(defObject)
  );

  const loadLeads = () => {
    if (getLeadsParams.loading) {
      return false;
    }
    getLeads({}).then((res: any) => {
      if (res.success === 1) {
        toast.success(
          "The job has been sent for processing. You will receive a notification after its completion."
        );
      }
    });
    setClicked(true);


  };

  const loadFollowUps = () => {
    setClicked(false);
    const type = props.type ? props.type : 0;

    let datesFilter = {};
    if (type === 0) {
      datesFilter = {
        or: [
          ["i.onDate", "IS_NULL", "", true],
          ["i.onDate", "lte", moment().format("YYYY-MM-DD 23:59:59"), true],
        ],
      };
    } else if (type === 1) {
      datesFilter = {
        and: [
          [
            "i.onDate",
            "gte",
            moment().add(1, "days").format("YYYY-MM-DD 00:00:00"),
            true,
          ],
          [
            "i.onDate",
            "lte",
            moment().add(1, "days").format("YYYY-MM-DD 23:59:59"),
            true,
          ],
        ],
      };
    } else if (type === 2) {
      datesFilter = {
        and: [
          [
            "i.onDate",
            "lte",
            moment().add(7, "days").format("YYYY-MM-DD 23:59:59"),
            true,
          ],
        ],
      };
    } else if (type === 3) {
      datesFilter = {
        and: [
          [
            "i.onDate",
            "lte",
            moment().add(30, "days").format("YYYY-MM-DD 23:59:59"),
            true,
          ],
        ],
      };
    } else if (type === 4) {
      datesFilter = {
        and: [["i.onDate", "lte", moment().format("YYYY-MM-DD 00:00:0"), true]],
      };
    }

    getFollowUps(
      [
        {
          and: [
            ["i.creator", "=", props.userId, true],
            ["i.parentSchema", "eq", "client", true],
          ],
        },
        datesFilter,
      ],
      1,
      10000
    );
  };

  useEffect(() => {
    loadFollowUps();
  }, [props.type]);

  useEffect(() => {
    getData(
      [
        {
          and: [
            [
              "i.id",
              "in",
              [-1, ...getFolloUpsData.data.data.map((e: any) => e.parentId)],
              true,
            ],
          ],
        },
      ],
      1,
      10000
    );
  }, [getFolloUpsData.data.data]);

  useEffect(() => {
    SocketService.subscribe("followup-dashboard-" + userState.id);
    return () => {
      SocketService.unsubscribe("followup-dashboard-" + userState.id);
    };
  }, [userState.id]);

  useEffect(() => {
    SocketService.addCallback(
      "followup-reload-dashboard",
      "followup-reload-dashboard",
      loadFollowUps
    );
    return () => {
      SocketService.removeCallback(
        "followup-reload-dashboard",
        "followup-reload-dashboard"
      );
    };
  }, []);

  if (getFolloUpsData.loading || getDataParams.loading) {
    return <LogoLoader />;
  }

  const getFollowDateByClient = (clientId: number) => {
    for (let i = 0; i < getFolloUpsData.data.data.length; i++) {
      if (getFolloUpsData.data.data[i].parentId === clientId) {
        return getFolloUpsData.data.data[i].onDate;
      }
    }
    return "";
  };

  const currentClients = getDataParams.data.data.filter((cl) => {
    return !!getFollowDateByClient(cl.id);
  });
  const potencialClients = getDataParams.data.data.filter((cl) => {
    return !getFollowDateByClient(cl.id);
  });

  const groupedClients = groupMap(currentClients, (el: IData) => {
    const title = (
      el.clientStatus ? el.clientStatus.titleManager : ""
    ).toLowerCase();
    let clientStatusId = (el.clientStatus ? el.clientStatus.id : -1).toString();

    if (title === "potencialus") {
      clientStatusId += "-" + el.priority.toString();
    }

    return clientStatusId;
  });

  const groupedClientsKeys = Object.keys(groupedClients);

  const activeTabToShow = activeTab
    ? activeTab
    : groupedClientsKeys.length > 0
      ? groupedClientsKeys[0]
      : "-1";

  let activeTabFilter = [["i.clientStatus", "=", activeTabToShow, true]];
  if (activeTabToShow.indexOf("-") !== -1) {
    const activeTabToShowSplit = activeTabToShow.split("-");
    activeTabFilter = [
      ["i.clientStatus", "=", activeTabToShowSplit[0], true],
      ["i.leadSource.priority", "=", activeTabToShowSplit[1], true],
    ];
  }

  return (
    <div className="grid grid-cols-2 gap-2">
      <WhiteCard title={t("Reminders")} isCompact={true}>


        <div className={"flex gap-2 flex-wrap"}>
          {groupedClientsKeys.map((cl) => {
            const _data = groupedClients[cl];
            const firstItem: IData = _data[0];

            let title = firstItem.clientStatus
              ? firstItem.clientStatus.titleManager
              : "Not assigned";
            let color = firstItem.clientStatus
              ? firstItem.clientStatus.colorManager
              : "blue";

            if (title.toLowerCase() === "potencialus") {
              const status = appData.status.status(
                'client',
                'priority',
                firstItem.priority
              );
              if (status) {
                title = status.text + " (P)";
                // @ts-ignore
                color = status.variant ? status.variant : 'blue';
              }
            }

            return (
              <button onClick={() => setActiveTab(cl)}>
                <StatusWidget
                  key={"btn-" + cl}
                  className={
                    activeTabToShow === cl ? "text-sm text-underline" : "text-sm"
                  }
                  // @ts-ignore
                  color={color}

                >
                  {t(title)} ({_data.length})
                </StatusWidget>
              </button>
            );
          })}
        </div>

        {!!activeTabToShow && currentClients.length > 0 && (
          <div
            key={NaeSSchemaMap.Client.schema + "-follow-" + activeTabToShow}
          >
            <TemplatesLoader
              templateName="PageInlineList"
              data={{
                schema: NaeSSchemaMap.Client.schema,
                type: "followup",
                extraFilters: [
                  {
                    and: [
                      ["i.id", "in", currentClients.map((cl) => cl.id), true],
                      ...activeTabFilter,
                    ],
                  }
                ]
              }}
              templateData={{
                extraListDataTableHead: <Th>{t('Reminder date')}</Th>,
                extraListDataTableBody: <ReminderDateCol getFollowDateByClient={getFollowDateByClient} />
              }}
            />
          </div>
        )}

      </WhiteCard>
      {(!props.type || props.type === 0) && (
        <WhiteCard title={t("New Potential")} isCompact={true}>

          <div>
            {currentClients.length >= 10 && potencialClients.length === 0 && (
              <AlertWidget color="info" width="w-full" isCompact={true}>
                {t(
                  "Manage your current reminders to get new contacts."
                )}
              </AlertWidget>
            )}
            {currentClients.length < 10 &&
              potencialClients.length === 0 &&
              !clicked && (
                <div>
                  <MainButton
                    color="teal"
                    onClick={loadLeads}
                    className={"w-full"}
                  >
                    {t(
                      getLeadsParams.loading
                        ? "Loading..."
                        : "Get new leads"
                    )}
                  </MainButton>
                </div>
              )}
          </div>

          <div className={"overflow-x-auto"}>
            {potencialClients.length > 0 && (
              <div
              >
                <TemplatesLoader
                  templateName="PageInlineList"
                  data={{
                    schema: NaeSSchemaMap.Client.schema,
                    type: "followup-leads",
                    extraFilters: [
                      {
                        and: [
                          ["i.id", "in", potencialClients.map((cl) => cl.id), true],
                        ],
                      }
                    ]
                  }}
                  templateData={{

                  }}
                />
              </div>
            )}
          </div>
        </WhiteCard>
      )}
    </div>
  );
}

type RemiderDateColProps = {
  getFollowDateByClient: (id: number) => string,
}

const ReminderDateCol = (props: RemiderDateColProps) => {
  const { element } = useListTableRow();
  const dt = moment(props.getFollowDateByClient(element.id)).format('YYYY-MM-DD');
  const dtNow = moment().format('YYYY-MM-DD');

  return <Td className={classNames({ 'text-red-500': dt < dtNow })}>
    {dt}
  </Td>
}