import React, { useEffect, Fragment, useState } from "react";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";

export default function OperativeAdminPriceChanges() {
  const t = (s: string) => s;

  const [reloadState, setReloadState] = useState(0);

  const increaseTimer = () => {
    setReloadState(new Date().getTime());
  }

  const onUpdate = (item: any) => {
    if (item.schema === "SalesOrderPriceChange") {
      increaseTimer();
    }
  }

  useEffect(() => {
    SocketService.addCallback(
      "entity-SalesOrderPriceChange-0",
      "entity-SalesOrderPriceChange-0-admin-price-change",
      increaseTimer
    );
    SocketService.addCallback(
      "data-update-all",
      "data-update-all-admin-price-change",
      onUpdate
    );
    return () => {
      SocketService.removeCallback(
        "data-update-all",
        "data-update-all-admin-price-change"
      );
      SocketService.removeCallback(
        "entity-SalesOrderPriceChange-0",
        "entity-SalesOrderPriceChange-0-admin-price-change",
      );
    };
  }, []);

  return (
    <Fragment>
      <WhiteCard title={t("Price change needs confirmation")} isCompact={true}>

        <TemplatesLoader
          templateName="PageInlineList"
          data={{
            schema: "sales-order-price-change",
            type: "op-admin",

          }}
          key={reloadState}
        />

      </WhiteCard>

    </Fragment>
  );
}
