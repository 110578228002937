import React, { Fragment, useEffect } from "react";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { nl2p } from '@newageerp/v3.bundles.utils-bundle'
import BusinessDevWidget from "../../../Plugins/Client/Components/BusinessDevWidget";
import { MainToolbarTitle, Table, Th, Td, TabContainer } from "@newageerp/v3.bundles.layout-bundle";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin } from "../../../_generated/_custom/config/NaeSPermissions";


type IData =  {
  id: number;
  fullClientPhones: any;
  needBusinessDevNote: string;
}

const defObject: IData = {
  id: 0,
  fullClientPhones: "",
  needBusinessDevNote: "",
};

export default function DashboardBusinessDev() {
  const t = (s: string) => s;

  const { userState } = useTemplatesCore();
  const isAdmin = checkUserAdmin(userState);

  const [getData, getDataParams] = useUList<IData>(
    NaeSSchemaMap.Client.schema,
    getKeysFromObject(defObject)
  );
  const reloadData = () => {
    const filter = isAdmin ? {
      classicMode: true,
      and: [
        ["i.needBusinessDev", "=", true, true],
        ["i.needBusinessDevDelay", "=", false, true],
      ],
    } : {
      classicMode: true,
      and: [
        ["i.needBusinessDev", "=", true, true],
        ["i.needBusinessDevDelay", "=", false, true],
        ["i.needBusinessDevUser", "=", 'CURRENT_USER', true],
      ],
    };

    getData(
      [
        filter,
      ],
      1,
      200,
      [
        { key: 'i.leadSource.category.name', value: 'ASC' },
        { key: 'i.id', value: 'ASC' }
      ]
    );
  };
  useEffect(reloadData, []);

  useEffect(() => {
    SocketService.subscribe("client-bussiness-dev-reload-dashboard");
    return () => {
      SocketService.unsubscribe("client-bussiness-dev-reload-dashboard");
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "client-bussiness-dev-reload-dashboard",
      "client-bussiness-dev-reload-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "client-bussiness-dev-reload-dashboard",
        "client-bussiness-dev-reload-dashboard"
      );
    };
  }, []);

  const uploadedData = getDataParams.data.data;

  return (
    <Fragment>
      <MainToolbarTitle title="Contact search" />
      {!!uploadedData && uploadedData.length > 0 && (
        <WhiteCard>

          <TabContainer
            items={[
              {
                content: <Table
                  thead={<thead>
                    <tr>
                      <Th>{t("Name")}</Th>
                      <Th>{t("Country")}</Th>
                      <Th>{t("Phone numbers")}</Th>
                      <Th>{t('Category')}</Th>
                      <Th>{t('Comment')}</Th>
                      <Th>{t('Actions')}</Th>
                    </tr>
                  </thead>}
                  tbody={
                    <tbody>
                      {(uploadedData.filter((e) => e.fullClientPhones.length > 0)).map((item: IData) => {
                        return (
                          <tr key={`i-0-${item.id}`}>
                            <Td><DfValueView path="client.name" id={item.id} link={true} /></Td>
                            <Td><DfValueView path="client.country.nameLt" id={item.id} /></Td>
                            <Td><Fragment>
                              {nl2p(
                                item.fullClientPhones.join("\n"),
                                "p-" + item.id
                              )}
                            </Fragment></Td>
                            <Td><DfValueView path="client.leadSource.category.name" id={item.id} /></Td>
                            <Td>{nl2p(item.needBusinessDevNote, 'd')}</Td>
                            <Td><BusinessDevWidget canAccept={true} elementId={item.id} /></Td>
                          </tr>
                        )
                      })}
                    </tbody>
                  }
                />,
                tab: {
                  children: 'With phones'
                }
              },
              {
                content: <Table
                  thead={<thead>
                    <tr>
                      <Th>{t("Name")}</Th>
                      <Th>{t("Country")}</Th>
                      <Th>{t('Category')}</Th>
                      <Th>{t('Comment')}</Th>
                      <Th>{t('Actions')}</Th>
                    </tr>
                  </thead>}
                  tbody={
                    <tbody>
                      {(uploadedData.filter((e) => e.fullClientPhones.length === 0)).map((item: IData) => {
                        return (
                          <tr key={`i-0-${item.id}`}>
                            <Td><DfValueView path="client.name" id={item.id} link={true} /></Td>
                            <Td><DfValueView path="client.country.nameLt" id={item.id} /></Td>

                            <Td><DfValueView path="client.leadSource.category.name" id={item.id} /></Td>
                            <Td>{nl2p(item.needBusinessDevNote, 'd')}</Td>
                            <Td><BusinessDevWidget canAccept={false} elementId={item.id} /></Td>
                          </tr>
                        )
                      })}
                    </tbody>
                  }
                />,
                tab: {
                  children: t("Companies without contacts")
                }
              }
            ]}
          />
        </WhiteCard>
      )}
    </Fragment>
  );
}
