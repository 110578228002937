import React, { Fragment } from 'react'
import LogisticStatus3Return from '../../../components/cargo/Components/LogisticStatus3Return';

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import CargoCalculationReturnLogistic from '../../Calculation/Components/CargoCalculationReturnLogistic';
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkUserAdmin } from '../../../_generated/_custom/config/NaeSPermissions';

export default function CargoLogisticStatus3(props: PropsId) {
    const t = (s: string) => s;
    const showPopup = () => {
        SFSOpenEditModalWindowProps({
            id: props.id,
            schema: "cargo",
            type: "cargo-need-info"
        })
    }
    const {userState} = useTemplatesCore()
    const isAdmin = checkUserAdmin(userState);

    const element = useCargoHookNae(props.id);
    const calculation = useCalculationHookNae(element && element.calculation ? element.calculation.id : -1);
    if (!element) {
        return <Fragment />
    }
    if (!calculation) {
        return <Fragment />
    }


    const isLogisticCalculation = (!!element.expeditorCalculation && userState.id === element.expeditorCalculation.id) || isAdmin;
    if (!isLogisticCalculation) {
        return <Fragment />
    }

    if (calculation.status !== 3) {
        return <Fragment />
    }

    return (
        <Fragment>
            <LogisticStatus3Return id={element.id} />

            <MainButton onClick={showPopup} className={"w-full"} iconName={"edit"} color="sky">
                {t('Fill in the information')}
            </MainButton>

            <div className='grid'>
                <CargoCalculationReturnLogistic id={element.id} />
            </div>
        </Fragment>
    )
}
