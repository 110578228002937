import React from 'react'
import { toast, useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'

import { CheckUserPermissionComponent, ENaeSPermissions } from '../../../../../_generated/_custom/config/NaeSPermissions';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../config/NaePaths';

interface Props {
    fieldKey: string,
}

export default function Actions(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const t = (s: string) => s;

    const [doReq] = useURequest(NaePathsMap.post['pluginsKzFtlFtlWinner']);
    const doSave = () => {
        doReq({ id: element.id }).then(() => {
            toast.success("Selected");
        })
    }

    return (
        <CheckUserPermissionComponent permissions={[ENaeSPermissions.UserAdmin, ENaeSPermissions.UserHeadOfLogistics,]}>
            <MainButton iconName='check' onClick={doSave} confirmation={true} color={"lime"}>{t('Select')}</MainButton>
        </CheckUserPermissionComponent>
    )
}
