import React, { useState } from "react";

import moment from "moment";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorKzCarriersOrderNae } from "../../../_generated/_custom/models/ormSelectors";
import { IKzCarriersOrderModelNae } from "../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { NumberCardWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
import { TemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface ElementId {
  id: number;
}
interface Props {
  title?: string;
  data: ElementId[];
}

export default function KzToolbarComponent(props: Props) {
  const [ordersPopup, setOrdersPopup] = useState<any[]>([]);

  // @ts-ignore
  const data: IKzCarriersOrderModelNae[] = useSelector((state) => SelectorKzCarriersOrderNae(state, props.data.map((el) => el.id))
  );

  const t = (s: string) => s;

  let unloadError: number[] = [];
  let locationUpdateError: number[] = [];
  let plannedError: number[] = [];
  let driverError: number[] = [];
  if (!!data && data.length === data.filter((el) => !!el).length) {
    data.forEach((c: IKzCarriersOrderModelNae) => {
      if (c.status === 30) {
        if (
          moment.duration(moment().diff(moment(c.status30Date))).asDays() >= 4
        ) {
          unloadError.push(c.id);
        }
      }
      if (c.status === 20) {
        if (c.locationUpdateDateDanger) {
          locationUpdateError.push(c.id);
        }
      }
      if (c.status < 15) {
        if (
          moment(c.loadDate).format("YYYY-MM-DD") <
          moment().format("YYYY-MM-DD") &&
          !!c.loadDate
        ) {
          plannedError.push(c.id);
        }
      }
      if (
        c.status >= 20 &&
        (c.transportType === 10 || c.transportType === 50)
      ) {
        if (!c.driver) {
          driverError.push(c.id);
        }
      }
    });
  }

  const errors = {
    unloadError,
    locationUpdateError,
    plannedError,
    driverError,
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-6">
        {errors.unloadError.length > 0 && (
          <NumberCardWidget
            color="red"
            title={t("More than 4 days at the unloading")}

            children={errors.unloadError.length}
            onClick={() => setOrdersPopup(errors.unloadError)}
          />
        )}
        {errors.locationUpdateError.length > 0 && (
          <NumberCardWidget
            color="red"
            title={t("Vehicle's location is not updated")}
            children={errors.locationUpdateError.length}
            onClick={() => setOrdersPopup(errors.locationUpdateError)}
          />
        )}
        {errors.plannedError.length > 0 && (
          <NumberCardWidget
            color="red"
            title={t("The loading date has already arrived")}
            children={errors.plannedError.length}
            onClick={() => setOrdersPopup(errors.plannedError)}
          />
        )}
        {errors.driverError.length > 0 && (
          <NumberCardWidget
            color="red"
            title={t("Driver's contacts are not indicated")}
            children={errors.driverError.length}
            onClick={() => setOrdersPopup(errors.driverError)}
          />
        )}
      </div>
      {!!ordersPopup && ordersPopup.length > 0 &&
        <Popup onClick={() => setOrdersPopup([])} title={""} isPopup={true}>
          <TemplatesLoader
            templateName="PageInlineList"
            data={{
              schema: "kz-carriers-order",
              type: "main",
              extraFilters: [{
                "and": [
                  ['i.id', 'in', ordersPopup, true]
                ]
              }]
            }}
          />
        </Popup>}
    </>
  )
}
