import React, { Fragment } from "react";

import { SoDebtsSchema } from '../../../UserComponents/Reports/DebtReports/DebtsReports';
import { NotesContent } from "@newageerp/v3.bundles.notes-bundle";
import { MailsContent } from "@newageerp/v3.bundles.mails-bundle";
import { TabContainer } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';

export default function SoHistoryWidget(props: PropsId) {
  const t = (s: string) => s;

  const items = [
    {
      content: <NotesContent options={{}} schema={"sales-order"} id={props.id} showOnlyMy={true} />,
      tab: {
        children: t("My comments"),
      },
    },
    {
      content: <NotesContent options={{}} schema={"sales-order"} id={props.id} showOnlyMy={false} />,
      tab: {
        children: t("All comments"),
      },
    },
    {
      content: <MailsContent schema={"sales-order"} id={props.id} />,
      tab: {
        children: t("Mails"),
      },
    },
    {
      content: <NotesContent options={{}} id={props.id} schema={SoDebtsSchema} />,
      tab: {
        children: t("Debt comments"),
      },
    },
  ];

  return <WhiteCard isCompact={true}><TabContainer items={items} /></WhiteCard>;
}
