import React, { Fragment } from 'react'
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';

import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkUserLogisticFtl } from '../../../../_generated/_custom/config/NaeSPermissions';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoNeedOperationBadgeForLogist(props: PropsId) {
    const t = (s: string) => s;

    const cargo = useCargoHookNae(props.id);

    const {userState} = useTemplatesCore()
    const isFtlLogistic = checkUserLogisticFtl(userState);

    if (!cargo) {
        return <Fragment />
    }
    if (!(!cargo.operationConfirmation && cargo.status >= 10 && !isFtlLogistic)) {
        return <Fragment />
    }

    return (
        <StatusWidget smallPadding={true} color='red' title={t('Documents were not sent for processing')}>
            {t('Documents ... processing')}
        </StatusWidget>
    )
}
