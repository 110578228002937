import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from '@newageerp/v3.templates.templates-core';
import { SelectorUserNae } from '../../_generated/_custom/models/ormSelectors';

import moment from 'moment';
import { IUserModelNae } from '../../_generated/_custom/hooks/useUserHookNae';
import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'

import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';


interface IDateSet {
    dateFrom: string,
    dateTo: string,
}

export default function WeeklyReportsLinks() {
    const UserData = useSelector(state => SelectorUserNae(state));
    const t = (s: string) => s;

    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
    useEffect(() => {
        setToolbarTitle(t('Weekly report'));
    }, []);

    const [dateSet, setDateSet] = useState<IDateSet>({
        dateFrom: moment().startOf('isoWeek').subtract(3, 'days').format('YYYY-MM-DD'),
        dateTo: moment().startOf('isoWeek').add(3, 'days').format('YYYY-MM-DD'),
    });

    const users = UserData
        .filter((u: IUserModelNae) => u.permissionGroup === 'user-manager' && u.disabled === false)
        .sort((a: IUserModelNae, b: IUserModelNae) => a.fullName.localeCompare(b.fullName));

    const linkClassName = "text-blue-500 hover:underline";

    const { dateFrom, dateTo } = dateSet;

    const nextDates = () => {
        setDateSet({
            dateFrom: moment(dateSet.dateFrom).add(7, 'days').format('YYYY-MM-DD'),
            dateTo: moment(dateSet.dateTo).add(7, 'days').format('YYYY-MM-DD'),
        })
    }

    const prevDates = () => {
        setDateSet({
            dateFrom: moment(dateSet.dateFrom).subtract(7, 'days').format('YYYY-MM-DD'),
            dateTo: moment(dateSet.dateTo).subtract(7, 'days').format('YYYY-MM-DD'),
        })
    }

    // const dateFrom = moment().startOf('isoWeek').subtract(3, 'days').format('YYYY-MM-DD');
    // const dateTo = moment().startOf('isoWeek').add(3, 'days').format('YYYY-MM-DD');

    return <Fragment>
        <WhiteCard>
            <div className={"flex gap-2 items-center"}>
                <ToolbarButton onClick={prevDates} iconName={"angle-left"}/>
                    
                <TextCardTitle>{dateFrom} - {dateTo}</TextCardTitle>

                <ToolbarButton onClick={nextDates} iconName={"angle-right"}/>
            </div>
            <Table
                thead={
                    <thead>
                        <tr>
                            <Th>{t('Responsible')}</Th>
                            <Th>{t('Debt report')}</Th>
                            <Th>{t('Work effectivity report')}</Th>
                            <Th>{t('Calculation conclusion')}</Th>
                            <Th>{t('')}</Th>
                            <Th>{t('')}</Th>
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {users.map((item: IUserModelNae, index: number) => {
                            return (
                                <tr key={`user-${item.id}`}>
                                    <Td>{item.fullName}</Td>
                                    <Td><a className={linkClassName} href={`/c/reports/debts?print=true&manager=${item.id}`} target={"_blank"}>Spausdinti</a></Td>
                                    <Td><a className={linkClassName} href={`/c/reports/calls?groupByHour=true&dateFrom=${dateFrom}&dateTo=${dateTo}&manager=${item.id}&print=true&addAverage=true&addLastPeriod=true&separateByDate=true`} target={"_blank"}>Spausdinti</a></Td>
                                    <Td><a className={linkClassName} href={`/c/reports/calculation-status-comment?set=1&print=true&dateFrom=${dateFrom}&dateTo=${dateTo}&manager=${item.id}`} target={"_blank"}>Su išvadomis</a></Td>
                                    <Td><a className={linkClassName} href={`/c/reports/calculation-status-comment?set=2&print=true&dateFrom=${dateFrom}&dateTo=${dateTo}&manager=${item.id}`} target={"_blank"}>Be išvadų</a></Td>
                                    <Td><a className={linkClassName} href={`/c/reports/calculation-status-comment?set=3&print=true&dateFrom=${dateFrom}&dateTo=${dateTo}&manager=${item.id}`} target={"_blank"}>Be statusų</a></Td>
                                </tr>
                            )
                        })}
                    </tbody>
                }
            />

        </WhiteCard>
    </Fragment>;
}
