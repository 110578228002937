import React, { Fragment } from "react";

import { caclculationTheadColumns } from './ManagerCalculationColumns';
import CalculationSerialNumberWithLinkAndNotesAndStatus from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationSerialNumberWithLinkAndNotesAndStatus";
import CalculationClientNameWithLink from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationClientNameWithLink";
import CalculationCargosLoadingAddressWithIndex from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationCargosLoadingAddressWithIndex";
import CalculationUnloadAddressFullAddress from "../../../../_generated/_custom/models-cache-data/Calculation/CalculationUnloadAddressFullAddress";
import CalculationCargosSerialNumberWithLinkAndNotes from '../../../../_generated/_custom/models-cache-data/Calculation/CalculationCargosSerialNumberWithLinkAndNotes';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import OldTable, { TableSize } from "../../../../core/old-ui/OldTable";
import OldThead from "../../../../core/old-ui/OldThead";
import OldTbody from "../../../../core/old-ui/OldTbody";
import { defaultStrippedRowClassName } from "../../../../core/old-ui/OldTrow";
interface Props {
  data: IData[];
}

interface IData {
  id: number,
}
export default function ManagerCalculationWaiting(props: Props) {
  const t = (s: string) => s;

  const isData = !!props.data;

  return (
    <div className={"relative"}>
      <WhiteCard isCompact={true} title={t('Waiting')}>

        {isData && <OldTable

          thead={<OldThead
            columns={[
              caclculationTheadColumns.serial,
              caclculationTheadColumns.cargosSerial,
              caclculationTheadColumns.client,
              caclculationTheadColumns.loading,
              caclculationTheadColumns.unloading,
            ]} />}
          tbody={
            <OldTbody
              data={props.data}
              callback={(el: IData, index: number) => {
                return (
                  {
                    columns: [
                      {
                        content: <Fragment>
                        <CalculationSerialNumberWithLinkAndNotesAndStatus id={el.id} />
                        <p><DfValueView path="calculation.purpose" id={el.id} /></p>
                        </Fragment>,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      {
                        content: <CalculationCargosSerialNumberWithLinkAndNotes id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      {
                        content: <CalculationClientNameWithLink id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      {
                        content: <CalculationCargosLoadingAddressWithIndex id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                      {
                        content: <CalculationUnloadAddressFullAddress id={el.id} />,
                        props: { className: "text-left", size: TableSize.sm }
                      },
                    ],
                    className: defaultStrippedRowClassName(index)
                  }
                )
              }}
            />
          }

        />}
      </WhiteCard>
    </div>
  );
}
