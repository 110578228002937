import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { getPropertyTitleForSchema } from '../../_generated/v3/utils'
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae'
import CargoCommentLoading from '../../_generated/_custom/models-cache-data/Cargo/CargoCommentLoading'
import CargoCommentProcessing from '../../_generated/_custom/models-cache-data/Cargo/CargoCommentProcessing'
import CargoSalesOrderUnloadAddressCountryWithCityRu from '../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderUnloadAddressCountryWithCityRu'
import CargoSalesOrderUnloadAddressFullAddress from '../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderUnloadAddressFullAddress'
import CargoTnvedCodesOperational from '../../_generated/_custom/models-cache-data/Cargo/CargoTnvedCodesOperational'
import { PropsId } from '../../_generated/_custom/models-cache-data/types'

export default function CargoDpsUzsInfo(props: PropsId) {
    const t = (s: string) => s;

    const element = useCargoHookNae(props.id);
    if (!element) {
        return <Fragment />
    }

    let refFromTerminal = "";

    if (element.needTemperatureMode === 10) {
        refFromTerminal = "REF: " + element.temperatureSettings;
    } else if (element.needTemperatureMode === 20) {
    } else if (element.needTemperatureMode === 30) {
        refFromTerminal = "REF: " + element.temperatureSettings;
    }

    return (
        <WhiteCard isCompact={true}>
            <div className={"space-y-1 text-base"}>
                {label(`uzs/${element.stock}`)}
                <p>
                    <strong>3 grafa</strong>:{" "}
                    <CargoSalesOrderUnloadAddressCountryWithCityRu id={props.id} />
                </p>
                <p>
                    <strong>13 grafa</strong>:{" "}
                    <CargoSalesOrderUnloadAddressFullAddress id={props.id} />
                </p>
                {!!refFromTerminal && <p>18 grafa: {refFromTerminal}</p>}
                {!!element.commentProcessing && (

                    <p>
                        <strong>Komentaras forminimui:</strong>
                        <CargoCommentProcessing id={element.id} />
                    </p>
                )}
                {(!!element.commentLoading || element.outgoingDocumentsChange === 10) && (
                    <p>
                        <strong>{getPropertyTitleForSchema('cargo', 'commentLoading')}:</strong>
                        <br />
                        <CargoCommentLoading id={props.id} />


                        {element.outgoingDocumentsChange === 10 && <div>Prieš kraunant prašome apžiūrėti paletes. Jeigu ant palečių pamatysite dokumentus, prašome juos nuimti nuo paletes ir išmesti.</div>}
                    </p>
                )}
                {element.typeOut === 'train' &&
                    <p>
                        <strong>{t('TNVED kodai ir svoriai')}:</strong>
                        <CargoTnvedCodesOperational id={props.id} />
                    </p>
                }
            </div>

        </WhiteCard>
    )
}

const label = (text: string) => {
    return <p className={"font-semibold text-sm"}>{text}:</p>
}