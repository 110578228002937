import React from 'react'
import { NaeSSchemaMap } from '../../../../../_generated/_custom/config/NaeSSchema';

import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { SFSOpenViewModalWindowProps } from '@newageerp/v3.bundles.popup-bundle'
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';

interface Props {
    fieldKey: string,
}

export default function LinkToClient(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const value = element[props.fieldKey];

    const t = (s: string) => s;

    const [getClient, getClientData] = useUList(NaeSSchemaMap.Client.schema, ['id', 'name']);
    const [getContact, getContactData] = useUList(NaeSSchemaMap.Contact.schema, ['id', 'fullName']);

    const findClient = async () => {
        const clientData = await getClient(
            [
                {
                    "or": [
                        ['i.phones', 'JSON_SEARCH', value, true],
                    ]
                }
            ],
            1,
            1000,
            [
                {
                    key: 'i.id',
                    value: 'DESC'
                }
            ]
        )

        if (clientData.data.records === 0) {
            const contactData = await getContact(
                [
                    {
                        "or": [
                            ['i.phones', 'JSON_SEARCH', value, true],
                        ]
                    }
                ],
                1,
                1000,
                [
                    {
                        key: 'i.id',
                        value: 'DESC'
                    }
                ]
            )

            if (contactData.data.records > 0) {
                SFSOpenViewModalWindowProps({
                    id: contactData.data.data[0].id,
                    schema: NaeSSchemaMap.Contact.schema
                })
            }

        } else {
            SFSOpenViewModalWindowProps({
                id: clientData.data.data[0].id,
                schema: NaeSSchemaMap.Client.schema
            })
        }



    }

    return (
        <button onClick={findClient}>
            {t('Show client')}
        </button>
    )
}
