import React, { Fragment, useState } from "react";

import moment from "moment";


import KzOrderLogisticCargoLines from "../../../../components/dashboard/cargo/kz-order/KzOrderLogisticCargoLines";
import KzOrderCargoLinks from "../../../KzCarriersOrder/Components/KzOrderCargoLinks";
import RailwayOrderTransportInfo from "./Components/RailwayOrderTransportInfo";
import KzLoadDate from "../../../KzCarriersOrder/Components/KzLoadDate";
import KzCarrierLinkWithEye from '../../../KzCarriersOrder/Components/KzCarrierLinkWithEye';
import AviaTerminalName from "./Components/AviaTerminalName";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";

interface Props {
  data: IData[];
}

interface IData {
  id: number;
  loadDate: string,
}

export default function AviaLogisticsPlanned(props: Props) {
  const t = (s: string) => s;
  const [viewCargosId, setViewCargosId] = useState<number>(0);

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Planned")}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t('Number')}</Th>
                {/* <Th>{t("Cargo")}</Th> */}
                <Th>{t('Carrier')}</Th>
                <Th>{t('Planned loading date')}</Th>
                <Th>{t('Vehicle')}</Th>
                <Th>{t('Terminal')}</Th>
                <Th>{t('Country of loading')}</Th>
                <Th>{t('Unloading address')}</Th>
                <Th>{t('My notes')}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data
                .sort((a, b) => {
                  const aDateStr = !!a.loadDate
                    ? moment(a.loadDate).format("YYYY-MM-DD")
                    : "";
                  const bDateStr = !!b.loadDate
                    ? moment(b.loadDate).format("YYYY-MM-DD")
                    : "";

                  var x = aDateStr.toLowerCase();
                  var y = bDateStr.toLowerCase();
                  return x < y ? -1 : x > y ? 1 : 0;
                })
                .map((o: IData) => {
                  return (
                    <Fragment key={"kz-o-" + o.id}>
                      <tr
                        
                        className={viewCargosId === o.id ? "bg-blue-100" : ""}
                      >
                        <Td className={"whitespace-nowrap space-y-2"}>
                          <KzCarrierLinkWithEye id={o.id} viewCargosId={viewCargosId} setViewCargosId={setViewCargosId} />

                          <KzOrderCargoLinks id={o.id} />
                        </Td>
                        {/* <Td>
                          <KzOrderCargoLinks id={o.id} />
                        </Td> */}
                        <Td>
                          <p>
                            <DfValueView path="kz-carriers-order.client.name" id={o.id} />
                          </p>
                          <p>
                            <DfValueView path="kz-carriers-order.contact.fullName"
                              id={o.id}
                            />
                          </p>
                        </Td>
                        <Td>
                          <KzLoadDate id={o.id} />
                        </Td>
                        <Td>
                          <RailwayOrderTransportInfo id={o.id} />
                        </Td>
                        <Td>
                          <AviaTerminalName id={o.id} />
                        </Td>
                        <Td>
                          <DfValueView
                            path="kz-carriers-order.loadingCountry"
                            id={o.id}
                          />
                        </Td>
                        <Td><DfValueView path="kz-carriers-order.cargoUnloadAddress" id={o.id} /></Td>
                        <Td className={"w-[200px]"}>
                          <DfValueView path="kz-carriers-order.internalComment" id={o.id} />
                        </Td>
                      </tr>
                      {viewCargosId === o.id && (
                        <tr>
                          <td colSpan={99}>
                            <KzOrderLogisticCargoLines id={o.id} />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          }
        />
      </WhiteCard>


    </Fragment>
  );
}
