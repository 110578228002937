import React, { useEffect, Fragment } from 'react'
import CargoDocumentsFilesList from './CargoDocumentsFilesList';

import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../../_generated/_custom/config/NaePaths';

interface Props {
    options: any[],
    schema: string,
    elementId: number,

    activePath: string,
    onSelect: (f: any) => void;

    showCheck?: boolean;
    checkedFiles?: any[];

    onCheckMultiple?: (f: any[]) => void;
}

export default function MultipleFilesList(props: Props) {
    const t = (s: string) => s;

    const [getData, getDataParams] = useURequest(NaePathsMap.post["NAEfilesMultipleList"]);

    const { options } = props;

    useEffect(() => {
        const folders = options.map(o => {
            const folder = props.schema + "/" + props.elementId + "/" + o.type;
            return folder;
        });

        getData({ folders });
    }, []);

    const data: any = getDataParams.data.data;

    useEffect(() => {
        const _data: any = getDataParams.data.data;
        if (_data && Object.keys(_data).length > 0 && props.checkedFiles !== undefined && props.checkedFiles.length === 0) {
            let filesToSelect: any[] = [];
            options.forEach((el: any) => {
                const folder = props.schema + "/" + props.elementId + "/" + el.type;
                if (el.checkByDefault) {
                    const _filtered = _data.filter((e: any) => e.folder === folder);
                    if (_filtered.length > 0) {
                        const _files = _filtered[0].contents;
                        filesToSelect = [...filesToSelect, ..._files.filter((f: any) => !f.deleted)];
                    }
                }
            });
            if (props.onCheckMultiple) {
                props.onCheckMultiple(filesToSelect);
            }
        }
    }, [getDataParams.data])

    if (!data || Object.keys(data).length === 0) {
        return <Fragment />
    }

    return (
        <Fragment>
            {options.map((el: any, fW: number) => {
                const folder = props.schema + "/" + props.elementId + "/" + el.type;
                const _files = data.filter((e: any) => e.folder === folder);

                if (_files.length === 0) {
                    return <Fragment />
                }

                return (
                    <CargoDocumentsFilesList
                        element={{ id: props.elementId }}
                        schema={props.schema}
                        options={el}
                        onSelect={props.onSelect}
                        activePath={props.activePath}
                        title={t(el.title)}
                        key={"files-" + fW}

                        showCheck={props.showCheck}
                        checkedFiles={props.checkedFiles}

                        data={_files[0].contents}
                    />
                );
            })}
        </Fragment>
    )
}
