import React, { Fragment } from "react";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useClientHookNae } from "../../../_generated/_custom/hooks/useClientHookNae";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { LogoLoader } from "@newageerp/v3.bundles.layout-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

interface Props {
  elementId: number;
  canAccept: boolean;
}

export default function BusinessDevWidget(props: Props) {
  const { elementId, canAccept } = props;

  const client = useClientHookNae(props.elementId);

  const [saveData, saveDataParams] = useUSave(
    NaeSSchemaMap.Client.schema
  );

  const accept = () => {
    saveData(
      {
        needBusinessDev: false,
        skipRequiredCheck: true,
        mergeMode: true,
      },
      elementId
    );
  };
  const decline = () => {
    saveData(
      {
        needBusinessDev: false,
        noContactsFound: true,
        skipRequiredCheck: true,
        mergeMode: true,
      },
      elementId
    );
  };

  const delay = () => {
    saveData(
      {
        needBusinessDevDelay: true,
        skipRequiredCheck: true,
        mergeMode: true,
      },
      elementId
    );
  };

  const t = (s: string) => s;

  if (!client) {
    return <Fragment />;
  }
  if (!client.needBusinessDev) {
    return <Fragment />;
  }

  return (
    <div className={"space-y-1"}>
      {saveDataParams.loading ? (
        <LogoLoader size={30} />
      ) : (
        <Fragment>
          {canAccept && (
            <MainButton confirmation={true}
              onClick={accept}
              className={"w-full"}
              color={"teal"}
            >
              {t("Contact search completed")}
            </MainButton>
          )}

          {client.fullClientPhones.length > 0 && (
            <MainButton
              className={"w-full"}
              color={"orange"}
              onClick={delay}
            >
              {t("Perkelti skambutį kitai dienai")}
            </MainButton>
          )}

          {client.fullClientPhones.length === 0 && (
            <MainButton
              className={"w-full"}
              onClick={decline}
              color={"red"}
            >
              {t("Kontaktų nėra")}
            </MainButton>
          )}
        </Fragment>
      )}
    </div>
  );
}
