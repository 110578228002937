import React, { Fragment } from "react";

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import CargoNeedHeadLogisticOperationChangesText from '../../../_generated/_custom/models-cache-data/Cargo/CargoNeedHeadLogisticOperationChangesText';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';

export default function OperationalCargoReturnText(props: PropsId) {
  const element = useCargoHookNae(props.id);

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />
  }
  if (!(element.operativeCardIndex === 5 || element.operativeCardIndex === 6)) {
    return <Fragment />;
  }

  return (
    <p>
      {element.operativeCardIndex === 5 && t("Specification is required")}
      {element.operativeCardIndex === 6 &&
        <CargoNeedHeadLogisticOperationChangesText id={props.id} />
      }
    </p>
  );
}
