import React, { useEffect } from "react";
import CargoWorkflowMultimodal from "../../../UserComponents/Calculation/Widget/CargoWorkflowMultimodal";
import NEWidgetFiles from "../../../core/components/widgets/NEWidgetFiles";

import CargoRailwayType from "../../../_generated/_custom/models-cache-data/Cargo/CargoRailwayType";
import CargoTnvedCodesForCalc from "../../../_generated/_custom/models-cache-data/Cargo/CargoTnvedCodesForCalc";
import CargoTerminalName from "../../../_generated/_custom/models-cache-data/Cargo/CargoTerminalName";
import CargoNotesOperations from "../../../_generated/_custom/models-cache-data/Cargo/CargoNotesOperations";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import CalculationSerialNumberWithLinkAndNotes from "../../../_generated/_custom/models-cache-data/Calculation/CalculationSerialNumberWithLinkAndNotes";
import CalculationStatus5LastChange from "../../../_generated/_custom/models-cache-data/Calculation/CalculationStatus5LastChange";
import CargoSerialNumberWithLinkAndNotes from "../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndNotes";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { SocketService } from "@newageerp/v3.utils.socket-service";

let reloadTimeout: any = -1;

export default function LogisticTrainCalculations() {
  const t = (s: string) => s;

  const [getData, getDataParams] = useUList(
    "calculation",
    ["id", "cargos:id"]
  );

  const loadData = () => {
    getData(
      [
        {
          and: [
            ["i.status", ">=", 5, true],
            ["i.status", "!=", 900, true],
            ["i.cargoRailway.calculated", "=", false, true],
          ],
        },
      ],
      1,
      1000,
      [
        {
          key: "i.purpose",
          value: "DESC",
        },
        {
          key: "i.serialNumber",
          value: "ASC",
        },
      ]
    );
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 500);
  };

  useEffect(() => {
    SocketService.subscribe("operative-calculation-dashboard");
    return () => {
      SocketService.unsubscribe("operative-calculation-dashboard");
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "operative-calculation-dashboard",
      "operative-calculation-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "operative-calculation-dashboard",
        "operative-calculation-dashboard"
      );
    };
  }, []);

  return (
    <WhiteCard isCompact={true} title={t("Need quotation")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t("Cargo")}</Th>

              <Th>{t("Responsible person")}</Th>
              <Th>{t("Received for quotation")}</Th>
              <Th>{t("Railway transport type")}</Th>
              <Th className="w-[200px]">{t("TNVED codes and weights")}</Th>
              <Th>{t("Terminal")}</Th>
              <Th>{t("Unloading")}</Th>
              <Th className={"w-[20%]"}>{t("Notes")}</Th>
              <Th></Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {getDataParams.data.data.map((calc: any) => {
              return (
                <tr key={"calc-" + calc.id}>
                  <Td className="space-y-2">

                    <CalculationSerialNumberWithLinkAndNotes id={calc.id} />

                    {calc.cargos.map((c: PropsId) => {
                      return <CargoSerialNumberWithLinkAndNotes id={c.id} key={`cargo-${c.id}`} />
                    })}

                    <DfValueView path="calculation.purpose" id={calc.id} />
                  </Td>
                  <Td>
                    <DfValueView path="calculation.creator.fullName" id={calc.id} />
                  </Td>
                  <Td className="whitespace-nowrap">
                    <CalculationStatus5LastChange id={calc.id} />
                  </Td>
                  <Td>
                    <CargoRailwayType id={calc.cargos[0].id} />
                  </Td>
                  <Td>
                    <div className={"mb-2"}>
                      <CargoTnvedCodesForCalc id={calc.cargos[0].id} />
                    </div>

                    <NEWidgetFiles
                      schema={"cargo"}
                      element={{ id: calc.cargos[0].id }}
                      options={{
                        type: 500,
                        title: "TNVED Failai",
                        hideDeletedFiles: false,
                      }}
                    />
                  </Td>
                  <Td>
                    <CargoTerminalName id={calc.cargos[0].id} />
                  </Td>
                  <Td>
                    <DfValueView path="calculation.address.fullAddress"
                      id={calc.id}
                    />
                  </Td>
                  <Td>
                    <CargoNotesOperations id={calc.cargos[0].id} />
                  </Td>
                  <Td>
                    <CargoWorkflowMultimodal
                      sm={true}
                      id={calc.id}
                    />
                  </Td>
                </tr>
              );
            })}
          </tbody>
        }
      />
    </WhiteCard>
  );
}
