import React, { Fragment } from 'react'

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { checkUserAdmin } from '../../../_generated/_custom/config/NaeSPermissions';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { MainButtonWithSave } from '@newageerp/v3.bundles.buttons-bundle';


export default function CargoLogisticSpecification(props: PropsId) {
    const element = useCargoHookNae(props.id);

    const {userState} = useTemplatesCore()
    const isAdmin = checkUserAdmin(userState);

    const t = (s: string) => s;

    if (!element) {
        return <Fragment />
    }

    const isLogistic = !!element.expeditor && userState.id === element.expeditor.id;
    if (!(isLogistic || isAdmin)) {
        return <Fragment />
    }
    let disabledText = undefined;
    let disabledColor: any = undefined;

    if (!(!!element.files["10"] && element.files["10"] > 0)) {
        disabledText = t('Seller\'s documents were not uploaded');
        disabledColor = 'warning';
    }
    if (element.operationConfirmation) {
        disabledText = t('Cargo was sent for processing');
    }
    if (element.minimumSpecificationStatus >= 0 && element.minimumSpecificationStatus < 30) {
        disabledText = t('Specifikaciją jau ruošiama')
        disabledColor = 'success';
    }
    if (element.minimumSpecificationStatus === 30) {
        disabledText = t('Specifikaciją jau paruošta')
        disabledColor = 'success';
    }

    return (
        <MainButtonWithSave
            confirmation={true}
            disabled={!!disabledText}
            disabledContent={{
                children: disabledText,
                color: disabledColor
            }}
            className={"w-full"}
            color={"sky"}
            iconName={'file'}

            elementId={"new"}
            saveData={{ cargo: { id: element.id } }}
            schema={NaeSSchemaMap.CargoSpecificaction.schema}
        >
            {t("Specification is required")}
        </MainButtonWithSave>
    )
}
