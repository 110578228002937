import React, { Fragment, useEffect } from "react";


import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import CargoSalesOrderClientOrFilialName from "../../../_generated/_custom/models-cache-data/Cargo/CargoSalesOrderClientOrFilialName";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { useCargoInsuranceHookNae } from "../../../_generated/_custom/hooks/useCargoInsuranceHookNae";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { SFSOpenEditModalWindowProps } from "@newageerp/v3.bundles.popup-bundle";
import { StatusWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Tag } from "@newageerp/v3.bundles.badges-bundle";
import { useUList, useUSave } from "@newageerp/v3.bundles.hooks-bundle";

interface IDataCargo {
  id: number,
}

interface Props {
  serviceCooNew: boolean,
  serviceCooRenew: boolean,
  serviceInsurance: boolean,
}

export default function OperativeAdminNeedDocs(props: Props) {
  const t = (s: string) => s;

  const [saveData, saveDataParams] = useUSave("cargo");
  const doSave = (data: any, id: number) => {
    saveData(data, id);
  }

  const [getData, getDataParams] = useUList<IDataCargo>("cargo", ['id']);

  const loadData = () => {
    const _filters = [];
    if (props.serviceCooNew) {
      _filters.push({
        "and": [
          ['i.extraServiceCooSertificateNew', '=', 10, true],
          ['i.extraServiceCooSertificateNewDocsHandled', '=', false, true],
          ['i.operationConfirmation.id', 'IS_NOT_NULL', true, true],
        ]
      });
    }
    if (props.serviceCooRenew) {
      _filters.push({
        "and": [
          ['i.extraServiceCooSertificateRenew', '=', 10, true],
          ['i.extraServiceCooSertificateRenewDocsHandled', '=', false, true],
          ['i.operationConfirmation.id', 'IS_NOT_NULL', true, true],
        ]
      });
    }
    if (props.serviceInsurance) {
      _filters.push({
        "and": [
          ['i.cargoInsurance.status', 'in', [10, 20], true],
          ['i.salesOrder.status', '>=', 10, true],
          // ['i.extraServiceInsuranceDocsHandled', '=', false, true]
        ]
      });
    }
    const filters = [
      {
        "and": [
          ['i.status', '!=', 900, true],
          {
            "or": _filters
          }
        ]
      }
    ];

    getData(
      filters,
      1,
      300,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        }
      ]
    );
  };

  useEffect(loadData, []);

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;
  const data: IDataCargo[] = isData ? getDataParams.data.data : [];

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t('Cargo in need of documents order')}>

        <Table
          thead={<thead>
            <tr>
              <Th>{t('Cargo')}</Th>
              <Th>{t('Sales manager')}</Th>
              <Th>{t('Transport operations manager')}</Th>
              <Th>{t('Client')}</Th>
              <Th>{t('Dokumentų tipas')}</Th>
              <Th>{t('Invoice')}</Th>
              <Th>{t('Ar sutvarkyti dokumentai?')}</Th>
            </tr>
          </thead>
          }
          tbody={
            <tbody>
              {data.map((cargo: IDataCargo, index: number) => {
                return (
                  <OperativeAdminNeedDocsCargoLine
                    index={index} key={"op-cargo-" + cargo.id}
                    id={cargo.id}
                    doSave={doSave}
                    saveLoading={saveDataParams.loading}
                    reload={loadData}
                  />
                );
              })}
            </tbody>
          }
        />

      </WhiteCard>

    </Fragment>
  );
}

interface OperativeAdminNeedDocsCargoLineProps extends PropsId {
  doSave: (data: any, id: number) => void,
  saveLoading: boolean,
  index: number,
  reload: () => void,
}

const OperativeAdminNeedDocsCargoLine = (props: OperativeAdminNeedDocsCargoLineProps) => {
  const element = useCargoHookNae(props.id);
  const elementCargoInsurance = useCargoInsuranceHookNae(element && element.cargoInsurance ? element.cargoInsurance.id : -1);
  const { doSave } = props;

  const t = (s: string) => s;

  const showInsuranceEdit = () => {
    SFSOpenEditModalWindowProps({
      id: elementCargoInsurance.id,
      schema: NaeSSchemaMap.CargoInsurance.schema,
      type: "main",
      onSaveCallback: (el, backFunc) => {
        backFunc();
        props.reload();
      },
    }
    )
  }

  if (!element) {
    return <Fragment />
  }
  return (
    <tr>
      <Td>
        <DfValueView id={props.id} link={true} path={"cargo.serialNumber"} />

      </Td>
      <Td>
        <DfValueView path="cargo.creator.fullName" id={props.id} />
      </Td>
      <Td>
        <DfValueView path="cargo.expeditor.fullName" id={props.id} />
      </Td>
      <Td>
        <CargoSalesOrderClientOrFilialName id={props.id} />
      </Td>
      <Td className="space-y-2">
        {element.extraServiceCooSertificateRenew === 10 && !element.extraServiceCooSertificateRenewDocsHandled && <p>{t('COO sertifikato perdarymas')}</p>}
        {element.extraServiceCooSertificateNew === 10 && !element.extraServiceCooSertificateNewDocsHandled && <p>{t('COO sertifikato išleidimas (naujas)')}</p>}
        {!!elementCargoInsurance && (elementCargoInsurance.status === 10 || elementCargoInsurance.status === 20) && <p>{t('Additional insurance')}</p>}
      </Td>
      <Td>
      {!!elementCargoInsurance && <Fragment>
        {!!elementCargoInsurance.invoices && elementCargoInsurance.invoices.length > 0 && <Tag color="teal" />}
        {!(!!elementCargoInsurance.invoices && elementCargoInsurance.invoices.length > 0) && <Tag color="red" />}
      </Fragment> }
      </Td>
      <Td className="space-y-2">
        {props.saveLoading ? <Fragment /> :
          <Fragment>
            {element.extraServiceCooSertificateRenew === 10 && !element.extraServiceCooSertificateRenewDocsHandled &&
              <MainButton className={"w-full"} color="sky" onClick={() => doSave({ extraServiceCooSertificateRenewDocsHandled: true }, props.id)}>{t('COO sertifikato perdarymas')}</MainButton>
            }
            {element.extraServiceCooSertificateNew === 10 && !element.extraServiceCooSertificateNewDocsHandled &&
              <MainButton className={"w-full"} color="sky" onClick={() => doSave({ extraServiceCooSertificateNewDocsHandled: true }, props.id)}>{t('COO sertifikato išleidimas (naujas)')}</MainButton>
            }
            {!!elementCargoInsurance && elementCargoInsurance.status === 10 &&
              <MainButton className={"w-full"} color="sky" onClick={showInsuranceEdit}>{t('Start process')}</MainButton>
            }
            {!!elementCargoInsurance && elementCargoInsurance.status === 20 &&
              <MainButton className={"w-full"} color="teal" onClick={showInsuranceEdit}>{t('Finish')}</MainButton>
            }
            {!!elementCargoInsurance && elementCargoInsurance.status === 30 &&
              <StatusWidget color="teal">{t('Ordered')}</StatusWidget>
            }
          </Fragment>
        }
      </Td>
    </tr>
  )
}
