import React, { Fragment } from "react";
import { PropsId } from "../types";
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import OldBadge, { BadgeBgColor, BadgeSize } from "../../../../core/old-ui/OldBadge";

export default function CargoPreciousMetalsBadge(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const t = (s: string) => s;

  if (!element) {
    return <Fragment />;
  }
  if (element.preciousMetals === 0) {
    return (
      <OldBadge
        size={BadgeSize.sm}
        bgColor={BadgeBgColor.yellow}
      >
        {t("yra tauriųjų metalų")}
      </OldBadge>
    );
  }
  return <Fragment />;
}
