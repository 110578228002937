import React, { Fragment, useEffect, useState } from "react";
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';

import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";

interface Props {
  selected: number[];
  toggleSelected: (id: number) => void;
  element: any,
  updateElement: (key: string, val: any) => void,
}

interface ICountry {
  id: number,
  countryGeographyGroup: {
    name: string,
  },
  nameEn: string,
  code: string,
}

const defCountry: ICountry = {
  id: 0,
  countryGeographyGroup: {
    name: '',
  },
  nameEn: '',
  code: '',
}

export default function WorkDirectionOut(props: Props) {
  const t = (s: string) => s;

  const [getData, getDataParams] = useUList<ICountry>(NaeSSchemaMap.Country.schema, getKeysFromObject(defCountry));
  const [countries, setCountries] = useState<any>({});

  const loadData = () => {
    getData(
      [],
      1,
      500,
      [
        {
          key: "i.nameEn",
          value: "ASC",
        }
      ]
    )
  };

  useEffect(loadData, []);

  useEffect(() => {
    let _countries: any = {};
    getDataParams.data.data.forEach((c: ICountry) => {
      const groupName = c.countryGeographyGroup
        ? c.countryGeographyGroup.name
        : "XXX";

      if (!(groupName in _countries)) {
        _countries[groupName] = [];
      }
      _countries[groupName].push(c);
    });
    setCountries(_countries);

  }, [getDataParams.data]);

  return (
    <Fragment>
      <p className="font-medium">{t('From')}</p>
      <div className="space-y-2">
        {Object.keys(countries)
          .sort()
          .map((g: string, index: number) => {
            return (
              <div key={"work-direction-out-" + index}>
                <div className="font-medium">{g === "XXX" ? "Others" : g}</div>
                <div className="space-y-1">
                  {countries[g].map((c: ICountry) => {
                    return (
                      <label
                        className={"flex gap-2 items-center"}
                        key={"work-direction-out-id-" + c.id}
                      >
                        <input
                          type={"checkbox"}
                          checked={props.selected && props.selected.indexOf(c.id) >= 0}
                          onChange={() => props.toggleSelected(c.id)}
                        />
                        <p>{c.nameEn} {!!c.code && <Fragment>({c.code})</Fragment>}</p>
                      </label>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
}
