import React from "react";


import { NaeSSchemaMap } from "../../../../../_generated/_custom/config/NaeSSchema";
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { useConvert } from "@newageerp/v3.bundles.hooks-bundle";

interface Props {
  fieldKey: string,
}

export default function PriceChange(props: Props) {
  const { data: tData } = useTemplatesLoader();
  const { element } = tData;
  const value = element[props.fieldKey];

  const t = (s: string) => s;
  const [openPopup] = useConvert({
    from: NaeSSchemaMap.InvoiceOutgoing.schema,
    to: NaeSSchemaMap.InvoiceOutgoingPriceChange.schema,
    id: element.id,
  });

  const tooltip = t("Kainos pakeitimą turi patvirtinti buhalteris");

  return (
    <div>
      <button className="text-sky-500" onClick={openPopup}>{t("Change price")}</button>{" "}
      <Tooltip text={tooltip} />
    </div>
  );
}
