import React, { Fragment } from "react";

import NotesLine from '../../UserComponents/Notes/NotesLine';

import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import SalesOrderBadges from './SalesOrderBadges';
import SalesOrderCargoBadges from "./SalesOrderCargoBadges";
import OldBadge, { BadgeBgColor, BadgeSize } from "../../core/old-ui/OldBadge";
import { RsButton } from "@newageerp/v3.bundles.buttons-bundle";
import CargoHoldUnloadBadge from '../../_generated/_custom/models-cache-data/Cargo/CargoHoldUnloadBadge';
import { KzCustomsInpectionBadge } from "../dashboard/cargo/kz-order/KzOrderLocation";
import { StatusWidget } from "@newageerp/v3.bundles.widgets-bundle";

interface IData {
  id: number,
  isContactDataOk: boolean,
  confirmCancelText: string,
  serialNumber: string,
  status: number,
  hasLoadedCargo: boolean,
  files: any,
  isNeedInvoiceFile: boolean,
  isNeedContractFile: boolean,
}

interface IDataCargo {
  id: number,
  serialNumber: string,
  kzCarriersOrder: {
    id: number,
    status: number,
  },
  status: number,
  files: any,
  outgoingDocumentsChange: number,
  outgoingDocumentsChangePurpose: {
    needConfirmMail: boolean,
  }
  beforeChange: any,
}

interface Props {
  element: IData;
  cargo?: IDataCargo;
  textColor?: string;
}

export default function SalesOrderDashboardLink(props: Props) {
  const { element, cargo } = props;
  const kzCarriersOrder = !!cargo && cargo.kzCarriersOrder;

  const t = (s: string) => s;

  const isContactData =
    !!cargo || element.isContactDataOk;

  const isDanger = cargo && cargo.status === 7;

  const isNeedOutgoingDocuments =
    cargo && cargo.outgoingDocumentsChange && !cargo.files["30"] && cargo.status >= 10;

  const isNeedOutgoingDocumentsMail =
    cargo && cargo.outgoingDocumentsChange && cargo.outgoingDocumentsChangePurpose.needConfirmMail && !cargo.files["35"] && cargo.status >= 10;

  const needInvoice = !cargo && element.isNeedInvoiceFile;

  const needContractFile = !cargo && element.hasLoadedCargo && element.isNeedContractFile;

  const needOrderConfirm = !cargo && element.hasLoadedCargo && !element.files["10"];

  const hasNotCalculatedCargo = false;

  const hasReturnedCargo = !!cargo && !!cargo.beforeChange;

  const isNotConfirmed = !!element.confirmCancelText;

  const mainBtnTextColor = isDanger ? "whiteandred" : (props.textColor ? "whiteandpurple" : "white")

  return (
    <Fragment>
      {cargo ?
        <RsButton defaultClick="modal" id={cargo.id} schema={"cargo"} button={{ children: cargo.serialNumber, color: mainBtnTextColor, skipPadding: true }} /> :
        <RsButton defaultClick="modal" id={element.id} schema={"sales-order"} button={{
          children: <Fragment>{element.serialNumber}{element.status === 5 && (
            <i className={"fad fa-hourglass-half fa-fw ml-1"} />
            // <FontAwesomeIcon className={"ml-1"} icon={faHourglassHalf} />
          )}</Fragment>,
          color: mainBtnTextColor,
          skipPadding: true,
        }}

        />
      }
      {/* <button
        onClick={
          cargo
            ? () => SFSOpenViewModalWindowProps({ id: cargo.id, schema: schemaMap.Cargo.schema })
            : () => SFSOpenViewModalWindowProps({ id: element.id, schema: schemaMap.SalesOrder.schema })
        }
        className={isDanger ? " text-red-500" : (props.textColor ? " " + props.textColor : " text-blue-500")}
      >
        {cargo ? cargo.serialNumber : element.serialNumber}
      </button> */}

      <div className={"grid gap-1 w-min"}>
        {!props.cargo &&
          <SalesOrderBadges id={element.id} />
        }
        {!!props.cargo &&
          <SalesOrderCargoBadges id={props.cargo.id} />
        }

        {!!isNeedOutgoingDocuments && (
          <StatusWidget color="blue" smallPadding={true}>
            {t('Reikalingi išvykimo dokumentai')}
          </StatusWidget>
        )}

        {!!isNeedOutgoingDocumentsMail && !isNeedOutgoingDocuments && (
          <StatusWidget color="blue" smallPadding={true}>
            {t('Reikalingas patvirtinimo laiškas')}
          </StatusWidget>
        )}

        {!isContactData && (
          <StatusWidget color="orange" smallPadding={true}>
            {t('Contact person at the unloading is not indicated')}
          </StatusWidget>

        )}

        {!!cargo && !!kzCarriersOrder && kzCarriersOrder.status === 15 && (
          <StatusWidget color="purple" smallPadding={true}>
            {t('Processing')}
          </StatusWidget>
        )}

        {!!cargo && (
          <CargoHoldUnloadBadge id={cargo.id} />
        )}

        {!!cargo && !!cargo.kzCarriersOrder && (
          <KzCustomsInpectionBadge
            id={cargo.kzCarriersOrder.id}
          />
        )}

        <NotesLine parentId={props.cargo ? props.cargo.id : element.id} parentSchema={props.cargo ? "cargo" : "sales-order"} />
      </div>
    </Fragment>
  );
}


