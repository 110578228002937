import React, { Fragment, useEffect, useState } from 'react'

import axios from "axios";
import { nl2p } from '@newageerp/v3.bundles.utils-bundle'
import { toast, useSelector } from '@newageerp/v3.templates.templates-core';
import { SelectorUserNae } from '../../_generated/_custom/models/ormSelectors';
import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import OldTable from '../../core/old-ui/OldTable';
import OldThead from '../../core/old-ui/OldThead';
import OldTbody from '../../core/old-ui/OldTbody';
import { defaultStrippedRowClassName } from '../../core/old-ui/OldTrow';
import { FieldSelectData } from '@newageerp/v3.bundles.form-bundle';
import { FieldSelect } from "@newageerp/v3.bundles.form-bundle";
import OldSelectFieldMulti from '../../core/old-ui/OldSelectFieldMulti';
import { MainButton, RsButton } from '@newageerp/v3.bundles.buttons-bundle';

interface IData {
    id: number,
    phones: string[],
    name: string,
}

export default function ClientImportPage() {
    const t = (s: string) => s;

    const [uploadedData, setUploadedData] = useState<IData[]>([]);

    const users = useSelector((state) => SelectorUserNae(state))

    const [selected, setSelected] = useState<any>([]);
    const [leadSource, setLeadSource] = useState<any>();
    const [country, setCountry] = useState<any>();
    const [uploading, setUploading] = useState(false);

    const [attachFiles, setAttachFiles] = useState(true);

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const uploadData = () => {
        if (!!uploadedFile) {
            setUploading(true);

            const fData = new FormData();
            fData.append("file", uploadedFile);
            fData.append("bookmark", JSON.stringify(selected.map((f: any) => f.value)));
            fData.append("leadSource", leadSource ? leadSource.toString() : '0');
            fData.append("country", country ? country.toString() : '0');
            fData.append("attachFiles", attachFiles ? '1' : '0');

            axios
                .post("/app/plugins/ClientImport/doImport", fData, {
                    headers: {
                        Authorization: window.localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    toast.success(
                        "The job has been sent for processing. You will receive a notification after its completion."
                      );
                    setUploading(false);
                    setUploadedData(res.data.data);
                });
        }
    }

    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
    useEffect(() => {
        setToolbarTitle(t('Importavimas'));
    }, []);

    return (
        <Fragment>
            <WhiteCard isCompact={true}>
                <div>
                    <p className={"text-xl " + (!!uploadedFile ? "text-green-500" : "text-red-500")}>{"Įkelkite ZIP archyvą"}</p>
                    <input type={"file"} onChange={(e: any) => setUploadedFile(e.target.files[0])} />
                </div>

                <div className='w-72'>
                    <p>{t('Šaltinis')}:</p>
                    <FieldSelectData
                        field={'title'}
                        schema={'lead-source'}
                        onChange={(val: any) => setLeadSource(val)}
                        value={leadSource}
                        sort={[
                            { key: "i.title", value: "ASC" }
                        ]}
                    />
                </div>

                <div className='w-72'>
                    <p>{t('Šalis')}:</p>
                    <FieldSelectData
                        field={'nameEn'}
                        schema={'country'}
                        onChange={(val: any) => setCountry(val)}
                        value={country}
                        sort={[
                            { key: "i.nameEn", value: "ASC" }
                        ]}
                    />
                </div>

                <div className='w-72'>
                    <p>{t('Prisegti failus')}:</p>

                    <FieldSelect
                        value={attachFiles ? 10 : 20}
                        onChange={(v) => setAttachFiles(v === 10)}
                        options={options}
                        className="w-40"
                        icon={attachFiles ? "toggle-large-on" : "toggle-large-off"}
                    />
                </div>

                <div className='w-72'>
                    <p>{t('Sekami')}:</p>
                    <OldSelectFieldMulti
                        // placeholder={"Pasirinkite..."}
                        options={users.map((u: any) => ({
                            value: u.id,
                            label: u.fullName,
                        }))}
                        className={["form-control", "form-select w-[300px]"].join(" ")}
                        isMulti={true}
                        value={selected}
                        onChange={setSelected}
                    />
                </div>

                {!!uploadedFile && <MainButton onClick={uploadData}>
                    {uploading ? t('Uploading...') : t('Upload')}
                </MainButton>}
            </WhiteCard>
            {!!uploadedData && uploadedData.length > 0 && <div className={"grid grid-cols-2 gap-2 mt-4"}>
                <WhiteCard isCompact={true} title={t('Su telefonais')}>
                    <OldTable
                        thead={
                            <OldThead
                                columns={
                                    [
                                        { content: t('Name'), props: { className: "text-left" } },
                                        { content: t('Phone numbers'), props: { className: "text-left" } }
                                    ]
                                }
                            />
                        }

                        tbody={
                            <OldTbody
                                data={
                                    uploadedData.filter(e => e.phones.length > 0)
                                }
                                callback={
                                    (item: IData, index: number) => {
                                        return ({
                                            columns: [
                                                { content: <RsButton id={item.id} schema={"client"} defaultClick="modal" button={{ children: item.name, color: "white", skipPadding: true }} />, props: { className: "text-left" } },
                                                { content: <Fragment>{nl2p(item.phones.join("\n"), 'p-' + item.id)}</Fragment>, props: { className: "text-left" } }
                                            ],
                                            className: defaultStrippedRowClassName(index),
                                        })
                                    }}
                            />
                        }

                    />
                </WhiteCard>
                <WhiteCard isCompact={true} title={t('Be telefonų')}>
                    <OldTable
                        thead={
                            <OldThead
                                columns={
                                    [
                                        { content: t('Name'), props: { className: "text-left" } }
                                    ]
                                }
                            />
                        }

                        tbody={
                            <OldTbody
                                data={
                                    uploadedData.filter(e => e.phones.length === 0)
                                }
                                callback={
                                    (item: IData, index: number) => {
                                        return ({
                                            columns: [
                                                { content: <RsButton id={item.id} schema={"client"} defaultClick={"modal"} button={{ children: item.name, color: "white", skipPadding: true }} />, props: { className: "text-left" } },
                                            ],
                                            className: defaultStrippedRowClassName(index),
                                        })
                                    }}
                            />
                        }

                    />
                </WhiteCard>
            </div>}
        </Fragment>
    )
}

const options = [
    {
        value: 10,
        label: "Yes",
    },
    {
        value: 20,
        label: "No",
    },
];