import React, { Fragment } from 'react'

import { MainToolbarTitle } from "@newageerp/v3.bundles.layout-bundle";
import OperativeAdminNeedDocs from '../OperationalAdmin/OperativeAdminNeedDocs';

export default function TrackingSpecialistDocsPage() {
    const t = (s: string) => s;

    return (
        <Fragment>
            <MainToolbarTitle title='Insurance' />
            <OperativeAdminNeedDocs serviceCooNew={false} serviceCooRenew={false} serviceInsurance={true} />
        </Fragment>
    )
}
