import React, { Fragment } from 'react'
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core'
import BookmarksElementButton from './BookmarksElementButton'

type Props = {
  dataSource: string
}

export default function AfterLine1ElementToolbarResolver(props: Props) {
  const { userState } = useTemplatesCore()
  return (
    <Fragment>
      <BookmarksElementButton />
    </Fragment>
  )
}
