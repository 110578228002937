import React, { Fragment } from "react";

import SalesOrderDashboardLink from "../../../../components/sales-order/SalesOrderDashboardLink";
import moment from "moment";
import SalesOrderClientWithLinkFilialName from '../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientWithLinkFilialName';
import CargoContactedSellerTime from "../../../../Plugins/Cargo/CargoContactedSellerTime";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { DfValueView } from "@newageerp/v3.bundles.app-bundle";
import { PoLoadingDatesChangeHistoryBtn } from "../../../../components/cargo/CargoBottomComponents/PoLoadingDatesChangeHistory";
import { ManagerOrderIData } from "../ManagerOrderWrapper";
import classNames from "classnames";

interface Props {
  data: ManagerOrderIData[];
}
export default function ManagerOrderConfirmed(props: Props) {
  const t = (s: string) => s;
  return (
    <WhiteCard isCompact={true} title={t("Confirmed orders")}>

      <Table
        thead={
          <thead>
            <tr>
              <Th>{t('Order')}</Th>
              <Th>{t('Cargo')}</Th>
              <Th>{t('Client')}</Th>
              <Th>{t('Seller')}</Th>
              <Th>{t('Loading Country')}</Th>
              <Th>{t('Responsible')}</Th>
              <Th>{t('Preparation date')}</Th>
              <Th>{t('Loading date')}</Th>
              <Th>{t('Contacted seller')}</Th>
            </tr>
          </thead>
        }
        tbody={
          <tbody>
            {props.data.sort((a, b) => {
              const aDateStr = a.poLoadDateStr;
              const bDateStr = b.poLoadDateStr;

              var x = aDateStr.toLowerCase();
              var y = bDateStr.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            }).map((c: ManagerOrderIData) => {
              return (
                <Fragment key={"order-" + c.id}>
                  <tr

                    className={
                      c.poLoadDateDiff > 2 && !c.loadDateDiffManagerConfirmed
                        ? "!border-b-2 !border-red-500"
                        : c.loadDateDiff > 0 && !c.loadDateDiffManagerConfirmed
                          ? " !border-b !border-amber-500"
                          : ""
                    }
                  >
                    <Td>
                      <SalesOrderDashboardLink
                        element={c.salesOrder}
                      />
                    </Td>
                    <Td>
                      <SalesOrderDashboardLink
                        element={c.salesOrder}
                        cargo={c}
                      />
                    </Td>
                    <Td>
                      <SalesOrderClientWithLinkFilialName id={c.salesOrder.id} />
                    </Td>
                    <Td>
                      <DfValueView path="cargo.seller.name" id={c.id} />
                    </Td>
                    <Td>
                      <DfValueView path="cargo.seller.country.nameEn" id={c.id} />
                    </Td>
                    <Td>{c.expeditor ? c.expeditor.fullName : " "}</Td>
                    <Td>
                      {!!c.loadDateSeller && (
                        <Fragment>
                          {moment(c.loadDateSeller).format("YYYY-MM-DD")}
                          {c.loadDateDiff > 0 && (
                            <Fragment>
                              <br />
                              <span className={"text-orange-500"}>
                                {moment(c.loadDate).format("YYYY-MM-DD")}
                              </span>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Td>
                    <Td
                      className={classNames(
                        'whitespace-nowrap flex gap-1',
                        { "text-red-500": c.poLoadDateDiff > 2 }
                      )}
                    >
                      {c.poLoadDateStr}
                      <PoLoadingDatesChangeHistoryBtn
                        ids={c.activePoOrders.map(i => i.id)}
                        asIcon={true}
                      />
                    </Td>
                    <Td>{c.contactedSeller ?
                      <Fragment>
                        {!!c.contactedSellerTime ? <CargoContactedSellerTime id={c.id} /> : "YES"}
                      </Fragment>
                      : "NO"}</Td>
                  </tr>


                </Fragment>
              );
            })}
          </tbody>
        }
      />

    </WhiteCard>
  );
}
