import React, { useState, Fragment } from "react";

import moment from "moment";
import KzOrderLogisticCargoLines from "../cargo/kz-order/KzOrderLogisticCargoLines";
import KzLoadDate from "../../../UserComponents/KzCarriersOrder/Components/KzLoadDate";
import KzCarrierLinkWithEye from "../../../UserComponents/KzCarriersOrder/Components/KzCarrierLinkWithEye";

import KzCargoTerminals from "../../../UserComponents/KzCarriersOrder/Components/KzCargoTerminals";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserOperational } from "../../../_generated/_custom/config/NaeSPermissions";

interface IElementRel {
  id: number;
  badKzCarriersDate: boolean;
}
interface IElement {
  id: number;
  loadDate: string;
  cargos: IElementRel[];
}

interface Props {
  data: IElement[];
}

export default function KzLogisticOrderNew(props: Props) {
  const t = (s: string) => s;

  const {userState} = useTemplatesCore();
  const isDps = checkUserOperational(userState);

  const [viewCargosId, setViewCargosId] = useState<number>(0);

  return (
    <Fragment>
      <WhiteCard title={t("Planning")} isCompact={true}>

        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Number")}</Th>
                <Th>{t("Cargo specifics")}</Th>
                <Th>{t("Loading")}</Th>
                <Th>{t("Route")}</Th>
                <Th>{t("Planned loading date")}</Th>
                <Th className={"text-right"}>{t("Total CLM")}</Th>
                <Th className={"text-right"}>{t("Total CBM")}</Th>
                <Th className={"text-right"}>{t("Weight")}</Th>
                <Th>{t("My notes")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data
                .sort((a, b) => {
                  const aDateStr = !!a.loadDate
                    ? moment(a.loadDate).format("YYYY-MM-DD")
                    : "";
                  const bDateStr = !!b.loadDate
                    ? moment(b.loadDate).format("YYYY-MM-DD")
                    : "";

                  var x = aDateStr.toLowerCase();
                  var y = bDateStr.toLowerCase();
                  return x < y ? -1 : x > y ? 1 : 0;
                })
                .map((o: IElement) => {
                  let hasDateError = false;
                  o.cargos.forEach((cargo: IElementRel) => {
                    if (cargo.badKzCarriersDate) {
                      hasDateError = true;
                    }
                  });

                  return (
                    <Fragment key={"kz-o-" + o.id}>
                      <tr
                        className={hasDateError ? "!border-b-2 !border-red-500" : ""}
                      >
                        <Td>
                          <KzCarrierLinkWithEye
                            id={o.id}
                            viewCargosId={viewCargosId}
                            setViewCargosId={setViewCargosId}
                          />

                          <p><DfValueView path="kz-carriers-order.doer.fullName" id={o.id} /> (FWD)</p>
                          <p>---</p>
                          <p><DfValueView path="kz-carriers-order.userDps.fullName" id={o.id} /> (DPS)</p>
                        </Td>
                        <Td>
                          <DfValueView path="kz-carriers-order.cargoNotes" id={o.id} />
                        </Td>
                        <Td>
                          <KzCargoTerminals id={o.id} />
                        </Td>
                        <Td>
                          <DfValueView path="kz-carriers-order.trip" id={o.id} />
                        </Td>
                        <Td>
                          <KzLoadDate id={o.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <DfValueView path="kz-carriers-order.allLdm" id={o.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <DfValueView path="kz-carriers-order.allCbm" id={o.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <DfValueView path="kz-carriers-order.allWeight" id={o.id} />
                        </Td>
                        <Td className={"w-[200px]"}>
                        {isDps && <DfValueView path="kz-carriers-order.dpsNotes" id={o.id} />}
                        {!isDps && <DfValueView path="kz-carriers-order.internalComment" id={o.id} />}
                        </Td>
                      </tr>
                      {viewCargosId === o.id && (
                        <tr className={"bg-info-light"}>
                          <td colSpan={99}>
                            <KzOrderLogisticCargoLines id={o.id} />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          }
        />

      </WhiteCard>
    </Fragment>
  );
}
