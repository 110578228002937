import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';

import LoadAddressStockWorkTime from '../../../_generated/_custom/models-cache-data/LoadAddress/LoadAddressStockWorkTime';
import LoadAddressFullAddress from '../../../_generated/_custom/models-cache-data/LoadAddress/LoadAddressFullAddress';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useLoadAddressHookNae } from '../../../_generated/_custom/hooks/useLoadAddressHookNae';
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle';
import { FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { Tooltip } from '@newageerp/v3.bundles.badges-bundle';

interface Props extends PropsId {
    canViewElement: boolean,
}

export default function BottomWidgetLoadAddress(props: Props) {
    const element = useLoadAddressHookNae(props.id);
    const t = (s: string) => s;
    if (!element) {
        return <Fragment />
    }
    return (
        <div className='space-y-2 py-2'>
            <div>
                {props.canViewElement ?
                    <RsButton id={element.id} schema={NaeSSchemaMap.LoadAddress.schema} defaultClick={"modal"} button={{ children: <LoadAddressFullAddress id={element.id} />, color: "white", skipPadding: true }} /> :
                    <LoadAddressFullAddress id={element.id} />
                }
                {
                    !!element.senderRequirements && (

                        <Tooltip
                            text={element.senderRequirements.replaceAll("\n", "|||")}
                        />
                    )}
            </div>
            {
                !!element.stockWorkTime && (
                    <div>
                        <FieldLabel>{t('Warehouse working hours')}:</FieldLabel>
                        <LoadAddressStockWorkTime id={element.id} />
                    </div>
                )}

        </div>
    )
}
