import React, { Fragment, useState, useEffect } from "react";


interface Props {
  schema: string;
  element: any;
  options: any;
}

export default function NEWidgetFilesSend(props: Props) {
  return <Fragment />;
  // const t = (s: string) => s;

  // const folder = props.schema + "/" + props.element.id;

  // const [emailPopup, setEmailPopup] = useState(false);
  // const toggleEmailPopup = () => setEmailPopup(!emailPopup);
  // const [files, setFiles] = useState<any[]>([]);

  // const loadData = () => {
  //   getData(undefined, {
  //     folder: folder + '/%',
  //   });
  // };

  // useEffect(() => {
  //   if (
  //     !!getDataParams.data &&
  //     !!getDataParams.data.data &&
  //     getDataParams.data.data.length > 0
  //   ) {
  //     setFiles(
  //       getDataParams.data.data.map((f: any) => {
  //         const filePath =
  //           window.location.origin + "/app/files/download?f=" +
  //           encodeURIComponent(
  //             JSON.stringify({
  //               path: f.path,
  //               name: f.filename,
  //             })
  //           ) +
  //           "&token=" +
  //           window.localStorage.getItem("token");

  //         return {
  //           name: f.filename,
  //           path: filePath,
  //           id: f.id
  //         };
  //       })
  //     );
  //   }
  // }, [getDataParams.data]);

  // useEffect(() => {
  //   if (files.length > 0) {
  //     setEmailPopup(true);
  //   }
  // }, [files]);

  // return (
  //   <Fragment>
  //     <div className={"nae-mb-2"}>

  //       <button
  //         className={"btn btn-white w-full align-left"}
  //         onClick={loadData}
  //       >
  //         <FontAwesomeIcon icon={faPaperPlane} />
  //         {t("Siųsti visus failus")}
  //       </button>

  //     </div>
  //     {/* {files.length > 0 &&
  //       <EmailPopup
  //         parentId={files[0].id}
  //         parentData={{
  //           type: 'all-files',
  //           parentSchema: props.schema,
  //           parentElementId: props.element.id,
  //         }}
  //         parentSchema={"file"}
  //         visible={emailPopup}
  //         toggleVisible={toggleEmailPopup}
  //         files={files}
  //       />
  //     } */}
  //   </Fragment>
  // );
}
