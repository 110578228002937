import React, { Fragment, useEffect, useState } from 'react'

import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { useRecoilState } from 'recoil';
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { getPropertyTitleForSchema } from '../../../_generated/v3/utils';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { ShowOnScreen } from '@newageerp/v3.bundles.utils-bundle';
import { TemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle';
import { MainButton, ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { TextCardTitle } from '@newageerp/v3.bundles.typography-bundle';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';
import { FieldInput } from '@newageerp/v3.bundles.form-bundle';


interface IExtraCostCargo {
    id: number,
    serialNumber: string;
    invoiceIssueDate: string;
    total: number;
    currency: {
        currency: string;
    };
}

interface IData {
    id: number,
    serialNumber: string,
    salesOrder: {
        client: {
            name: string
        },
        company: {
            name: string
        },
        clientFilial: {
            name: string,
        }
    },
    seller: {
        name: string,
    },
    extraCostCargos: IExtraCostCargo[]
}
const defObject: IData = {
    id: 0,
    serialNumber: '',
    salesOrder: {
        client: {
            name: ''
        },
        company: {
            name: ''
        },
        clientFilial: {
            name: '',
        }
    },
    seller: {
        name: '',
    },
    extraCostCargos: [
        { id: 0, serialNumber: '', invoiceIssueDate: '', total: 0, currency: { currency: '' } }
    ]
}

export default function DashboardAccountingCargoCosts() {
    const t = (s: string) => s;
    const [search, setSearch] = useState('');

    const [getData, getDataParams] = useUList<IData>("cargo", getKeysFromObject(defObject))

    const openEditPopup = (id: number) => {
        SFSOpenEditModalWindowProps({
            id: "new",
            schema: 'invoice-incoming',
            type: 'for-cargo',
            options: {
                createOptions: {
                    convert: {
                        schema: "cargo",
                        element: { id },
                    },
                },
            },
            onSaveCallback: (_el, back) => {
                loadData();
                back();
            }
        })
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            loadData();
        }
    }

    const loadData = () => {
        if (search) {
            getData(
                [{
                    "or": [
                        ['i.serialNumber', 'contains', search]
                    ]
                }],
                1,
                20
            )
        }
    }

    const data: IData[] = getDataParams.data.data;

    const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
    useEffect(() => {
        setToolbarTitle(t('Cargos exprenses'));
    }, []);

    return (
        <div className='space-y-4'>
            <div>
                <FieldInput
                    iconName="search"
                    className={"w-full"}
                    placeholder={t("Enter the cargo number and press ENTER")}
                    onKeyDown={handleKeyDown}
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                />
            </div>

            {data.map(cargo => {
                return (
                    <WhiteCard isCompact={true} key={"order-" + cargo.id}>
                        <div className={"flex gap-4"}>
                            <div className={"w-[420px]"}>
                                <TextCardTitle>{cargo.serialNumber}</TextCardTitle>
                                {!!cargo.salesOrder &&
                                    <div>
                                        <strong>{t('Kompanija')}</strong>
                                        <p>{cargo.salesOrder?.company?.name}</p>
                                    </div>
                                }
                                {!!cargo.salesOrder &&
                                    <div>
                                        <strong>{t(getPropertyTitleForSchema("sales-order", 'client'))}</strong>
                                        <p>{!!cargo.salesOrder.client ? cargo.salesOrder.client.name : "-"}{!!cargo.salesOrder.clientFilial ? <Fragment> ({cargo.salesOrder.clientFilial.name})</Fragment> : ''}</p>
                                    </div>
                                }
                                {!!cargo.seller &&
                                    <div>
                                        <strong>{t(getPropertyTitleForSchema("cargo", 'seller'))}</strong>
                                        <p>{cargo.seller.name}</p>
                                    </div>
                                }
                            </div>
                            <div className='flex-grow space-y-2'>


                                <ToolbarButton iconName={"plus"} onClick={() => openEditPopup(cargo.id)}>
                                    {t("Create")}
                                </ToolbarButton>


                                <ShowOnScreen once eventListenerElement={window.document.getElementById('layout-toolbar')}>
                                    {({ isVisible }: any) => isVisible ?
                                        <TemplatesLoader
                                            templateName="ListRelContent"
                                            data={{
                                                schema: 'invoice-incoming',
                                                type: 'rel',
                                                rel: 'cargo',
                                                id: cargo.id,
                                                wrap: 0,
                                            }}
                                        />
                                        :
                                        <LogoLoader />
                                    }
                                </ShowOnScreen>

                            </div>
                        </div>
                    </WhiteCard>
                )
            })}
        </div>
    )
}
