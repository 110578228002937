import React, { Fragment, useState } from "react";
import CargoDocumentsBtn from "../../../../dashboard/cargo/CargoDocumentsBtn";

import {
  kzSortCargo,
} from "../../../../CaspianHelperFunctions";


import OutgoingDocumentsChangeWidget from "../../../../../UserComponents/Cargo/Components/OutgoingDocumentsChangeWidget";

import CargoSerialNumberWithLinkAndIcons from '../../../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndIcons';
import CargoUnloadWarehouseDateOrPoUnloadDateStr from '../../../../../_generated/_custom/models-cache-data/Cargo/CargoUnloadWarehouseDateOrPoUnloadDateStr';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorCargoNae } from "../../../../../_generated/_custom/models/ormSelectors";
import { ICargoModelNae, useCargoHookNae } from '../../../../../_generated/_custom/hooks/useCargoHookNae';
import { useKzCarriersOrderHookNae } from "../../../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getPropertyTitleForSchema } from "../../../../../_generated/v3/utils";
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { PropsId } from '../../../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from "../../../../../_generated/_custom/config/NaeSSchema";
import { ToolbarButton, ToolbarButtonWithMenu } from "@newageerp/v3.bundles.buttons-bundle";
import { groupMap } from '@newageerp/v3.bundles.utils-bundle';
import SalesOrderClientOrFilialName from "../../../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientOrFilialName";
import { useSalesOrderHookNae, ISalesOrderModelNae } from '../../../../../_generated/_custom/hooks/useSalesOrderHookNae';
import { useClientHookNae } from '../../../../../_generated/_custom/hooks/useClientHookNae';
import classNames from 'classnames';
import { useClientStatusHookNae } from "../../../../../_generated/_custom/hooks/useClientStatusHookNae";
import { Popup } from '@newageerp/v3.bundles.popup-bundle';
import { CompactRow, FieldLabel, FieldTextarea, Toolbar } from "@newageerp/v3.bundles.form-bundle";

import { checkUserHeadOfLogisticsHelper, checkUserLogisticFtl, checkUserOperational } from "../../../../../_generated/_custom/config/NaeSPermissions";
import CargoCargoCheckForDualUseBadge from "../../../../../_generated/badges/cargo/CargoCargoCheckForDualUseBadge";
import { useURequest, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../../../_generated/_custom/config/NaePaths";
import CommentLoadingField from "./CommentLoadingField";

type Props = {
  kzIds: number[];
} & PropsId;

export default function TableCargos(props: Props) {
  const [showCargoNotes, setShowCargoNotes] = useState(true);
  const toggleShowCargoNotes = () => setShowCargoNotes(!showCargoNotes);

  const element = useKzCarriersOrderHookNae(props.id);
  const elementCargos = element ? element.cargos.map(el => el.id) : [-1];
  // @ts-ignore
  const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />
  }
  if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
    return <Fragment />
  }

  const groupedByRegion = groupMap(cargos, (el) => el.unloadShortAddress);

  return (
    <Fragment>
      <div className="overflow-x-auto">
        <Table

          thead={
            <thead className="sticky top-0 z-10">
              <tr>
                <Th>
                  {t(
                    getPropertyTitleForSchema(
                      "sales-order",
                      'serialNumber'
                    )
                  )}
                </Th>
                <Th>
                  {t(
                    getPropertyTitleForSchema(
                      "cargo",
                      'name'
                    )
                  )}
                </Th>
                <Th textAlignment={"text-right"}>
                  {t(
                    getPropertyTitleForSchema(
                      "cargo",
                      'ldm'
                    )
                  )}
                </Th>
                <Th textAlignment={"text-right"}>
                  {t(
                    getPropertyTitleForSchema(
                      "cargo",
                      'cbm'
                    )
                  )}
                </Th>
                <Th textAlignment={"text-right"}>
                  {t(
                    getPropertyTitleForSchema(
                      "cargo",
                      'weight'
                    )
                  )}
                </Th>
                <Th textAlignment={"text-right"}>
                  <Fragment>
                    {t(
                      getPropertyTitleForSchema(
                        "cargo",
                        'cargoValue'
                      )
                    )}, EUR
                  </Fragment>
                </Th>
                {showCargoNotes &&
                  <Th>
                    <Fragment>
                      {t(
                        getPropertyTitleForSchema(
                          "cargo",
                          'cargoNotes'
                        )
                      )}
                      <br />
                      {t(
                        getPropertyTitleForSchema(
                          "cargo",
                          'commentLoading'
                        )
                      )}
                    </Fragment>
                  </Th>
                }
                <Th>{t("Responsible")}</Th>

                <Th>{t(
                  getPropertyTitleForSchema(
                    "cargo",
                    'stock'
                  )
                )}</Th>
                <Th>
                  {t('Estimated arrival date')}
                </Th>
                <Th>
                  <ToolbarButton
                    onClick={toggleShowCargoNotes}
                    iconName={showCargoNotes ? 'eye-slash' : 'eye'}
                  />
                </Th>
              </tr>
            </thead>
          }

          tbody={
            <tbody>
              <tr className={"total-row font-bold"}>
                <Td ySmall={true} colSpan={2}>{t('Total')}</Td>
                <Td ySmall={true} textAlignment="text-right">
                  <DfValueView path="kz-carriers-order.allLdm" id={props.id} />
                </Td>
                <Td ySmall={true} textAlignment="text-right">
                  <DfValueView path="kz-carriers-order.allCbm" id={props.id} />
                </Td>
                <Td ySmall={true} textAlignment="text-right">
                  <DfValueView path="kz-carriers-order.allWeight" id={props.id} />
                </Td>
                <Td ySmall={true} textAlignment="text-right">
                  <DfValueView path="kz-carriers-order.allCargoValue" id={props.id} />
                </Td>
                <Td ySmall={true} colSpan={99}>
                </Td>
              </tr>
              {element.trip.split("\n").map((region: string) => {
                return (
                  <UnloadRegionLine
                    region={region}
                    groupedCargos={groupedByRegion}
                    showCargoNotes={showCargoNotes}
                    kzIds={props.kzIds}
                    mainKzId={props.id}
                  />
                )
              })}

            </tbody>
          } />

      </div>
    </Fragment >
  );
}

type UnloadRegionLineProps = {
  region: string,
  groupedCargos: any,
  showCargoNotes: boolean,
  kzIds: number[],
  mainKzId: number,
}

const UnloadRegionLine = (props: UnloadRegionLineProps) => {
  const cargos: ICargoModelNae[] = props.region in props.groupedCargos ? props.groupedCargos[props.region] : [];

  const soGroupedCargos = groupMap(cargos, (el) => el.salesOrder.id.toString())
  const soIds = Object.keys(soGroupedCargos);

  return (
    <Fragment>
      <tr>
        <Td colSpan={99} className={"font-medium"} xSmall={true} ySmall={true}>
          {props.region}
        </Td>
      </tr>
      {soIds.map((soId => {
        return <SoLine
          key={`kz-so-${soId}`}
          soCargos={soGroupedCargos[soId]}
          soId={parseInt(soId, 10)}
          showCargoNotes={props.showCargoNotes}
          kzIds={props.kzIds}
          mainKzId={props.mainKzId}
        />
      }))}

    </Fragment>
  )
}

type SoLineProps = {
  soId: number,
  soCargos: ICargoModelNae[],
  showCargoNotes: boolean,

  kzIds: number[],
  mainKzId: number,
}
const SoLine = (props: SoLineProps) => {
  const t = (s: string) => s;
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const { userState } = useTemplatesCore()
  const isFtlLogistic = checkUserLogisticFtl(userState);
  const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);
  const isDps = checkUserOperational(userState);

  const soElement = useSalesOrderHookNae(props.soId);
  const client = useClientHookNae(soElement ? soElement.client.id : -1);
  const clientStatus = useClientStatusHookNae(client && client.clientStatus ? client.clientStatus.id : -1);

  const kzElement = useKzCarriersOrderHookNae(props.mainKzId);
  const canRemove = !!kzElement && kzElement.status <= 15 && !isDps && !isTrackingSpecialist;
  const needRemoveConfirmation = !!kzElement && kzElement.status >= 10;

  const colorForLogist = clientStatus ? clientStatus.colorLogist : 'white';
  const titleForLogist = clientStatus ? clientStatus.titleLogist : '';

  const style = {
    // boxShadow: 'inset 0px 2px rgba(12, 74, 110, .3)' 
  };


  const [saveMultiple, saveMultipleParams] = useURequest(NaePathsMap.post['NAEUSaveMultiple']);
  const removeMultipleCargos = (note?: any) => {
    saveMultiple({
      schema: "cargo",
      fieldsToReturn: ['id'],
      data: props.soCargos.map((cargo) => {
        return (
          {
            id: cargo.id,
            data: { kzCarriersOrder: null, addNote: note, }
          }
        )
      })
    })
  }

  const addMultipleCargos = (kzId: number) => {
    saveMultiple({
      schema: "cargo",
      fieldsToReturn: ['id'],
      data: props.soCargos.map((cargo) => {
        return (
          {
            id: cargo.id,
            data: { kzCarriersOrder: { id: kzId } }
          }
        )
      })
    })
  }

  if (!soElement) {
    return <Fragment />
  }

  return (
    <Fragment>

      <tr className="font-light">
        <Td
          style={style}
          ySmall={true}
          className={
            classNames(
              "max-w-[150px]",
              'border-l-4',
              // @ts-ignore
              TableCargosBorderColors[colorForLogist]
            )
          }
          xSmall={true}
          title={titleForLogist}
        >
          <DfValueView path="sales-order.serialNumber" id={props.soId} link={true} />
        </Td>
        <Td style={style} ySmall={true} xSmall={true}>
          <SalesOrderClientOrFilialName id={props.soId} />
        </Td>
        <Td textAlignment={"text-right"} style={style} ySmall={true}>

        </Td>
        <Td textAlignment={"text-right"} style={style} ySmall={true}>

        </Td>
        <Td textAlignment={"text-right"} style={style} ySmall={true}>

        </Td>
        <Td textAlignment={"text-right"} style={style} ySmall={true}>

        </Td>
        {props.showCargoNotes &&
          <Td style={style}></Td>
        }
        <Td colSpan={2} style={style} ySmall={true}>
          <DfValueView path="sales-order.creator.fullName" id={props.soId} />
        </Td>
        <Td style={style} ySmall={true}>

        </Td>

        <Td style={style} ySmall={true}>
          {!isFtlLogistic && !isTrackingSpecialist && (
            <div className="flex gap-1">
              {props.kzIds.length > 0 &&
                <Fragment>
                  <ToolbarButtonWithMenu
                    button={{
                      className: 'mr-2',
                      iconName: "arrow-turn-down-right",
                      bgColor: "purple-50",
                      small: true,
                    }}
                    menu={
                      {
                        items: props.kzIds.map((kzId) => {
                          return (
                            {
                              children: (<DfValueView id={kzId} path={"kz-carriers-order.serialNumber"} />),
                              onClick: () => addMultipleCargos(kzId)
                            }
                          )
                        })
                      }
                    }
                  />
                </Fragment>
              }

              {canRemove &&
                <ToolbarButton
                  iconName="trash"
                  confirmation={needRemoveConfirmation ? false : true}
                  onClick={() => {
                    if (needRemoveConfirmation) {
                      setShowRemoveConfirmation(true);
                    } else {
                      removeMultipleCargos()
                    }
                  }}
                  loading={saveMultipleParams.loading}
                  bgColor={"bg-purple-50"}
                  small={true}
                />
              }
            </div>
          )}

          {showRemoveConfirmation && <CargoRemovePopup
            element={soElement}
            onClose={() => setShowRemoveConfirmation(false)}
            onSave={removeMultipleCargos}
          />}
        </Td>
      </tr>

      <Fragment>
        {props.soCargos
          .sort(kzSortCargo)
          .map((cargo: ICargoModelNae, _cargoIndex: number) => {
            return (
              <CargoLine
                key={"cargo-rel-" + cargo.id}
                id={cargo.id}
                soElement={soElement}
                showCargoNotes={props.showCargoNotes}
                kzIds={props.kzIds}
                mainKzId={props.mainKzId}
              />
            );
          })}
      </Fragment>
    </Fragment>
  )
}

type CargoLineProps = {
  soElement: ISalesOrderModelNae,
  showCargoNotes?: boolean,

  kzIds: number[],
  mainKzId: number,
} & PropsId

const CargoLine = (props: CargoLineProps) => {
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const element = useCargoHookNae(props.id);
  const client = useClientHookNae(props.soElement.client.id);
  const kzElement = useKzCarriersOrderHookNae(props.mainKzId);

  const t = (s: string) => s;

  const [saveData, saveDataParams] = useUSave("cargo");
  const saveCargo = (note?: any) => {
    if (saveDataParams.loading) {
      return;
    }
    saveData({
      kzCarriersOrder: null,
      addNote: note,
    }, props.id);
  };
  const moveCargo = (kzId: number) => {
    if (saveDataParams.loading) {
      return;
    }
    saveData({ kzCarriersOrder: { id: kzId } }, props.id);
  };

  const { userState } = useTemplatesCore()
  const isFtlLogistic = checkUserLogisticFtl(userState);

  const clientStatus = useClientStatusHookNae(client && client.clientStatus ? client.clientStatus.id : -1);

  const colorForLogist = clientStatus ? clientStatus.colorLogist : 'white';
  const titleForLogist = clientStatus ? clientStatus.titleLogist : '';

  const isTrackingSpecialist = checkUserHeadOfLogisticsHelper(userState);
  const isDps = checkUserOperational(userState);

  if (!element) {
    return <Fragment />
  }

  const isBadDate = element.badKzCarriersDate;

  const canRemove = !!kzElement && kzElement.status <= 15 && !isTrackingSpecialist && !isDps;
  const needRemoveConfirmation = !!kzElement && kzElement.status >= 10;

  return (
    <tr
      className={isBadDate ? "border-b border-amber-500" : ""}
    >
      <Td
        className={
          classNames(
            "max-w-[150px]",
            'border-l-4',
            // @ts-ignore
            TableCargosBorderColors[colorForLogist]
          )
        }
        title={titleForLogist}
        ySmall={true}
      >
        <CargoSerialNumberWithLinkAndIcons id={props.id} />
        <OutgoingDocumentsChangeWidget id={props.id} />
      </Td>
      <Td className="max-w-[200px] truncate" title={element.name}
        ySmall={true}
      >
        <DfValueView path="cargo.name" id={props.id} />
      </Td>
      <Td textAlignment={"text-right"}
        ySmall={true}>
        <div className={"justify-end flex"}>
          <div className="pr-1"><DfValueView path="cargo.ldm" id={element.id} /></div>
          (<DfValueView path="cargo.ldmCalculationType" id={element.id} />)
        </div>
      </Td>
      <Td textAlignment={"text-right"}
        ySmall={true}
      ><DfValueView path="cargo.cbm" id={props.id} /></Td>
      <Td textAlignment={"text-right"}
        ySmall={true}
      ><DfValueView path="cargo.allCargoWeight" id={props.id} /></Td>
      <Td textAlignment={"text-right"}
        ySmall={true}
      >
        <DfValueView path="cargo.cargoValueEur" id={props.id} />
      </Td>
      {props.showCargoNotes &&
        <Td
          ySmall={true}>
          <DfValueView path="cargo.cargoNotes" id={element.id} />
          <hr/>
          {/* <DfValueView path="cargo.commentLoading" id={element.id} /> */}
          <CommentLoadingField id={element.id}/>
          <br />
          <CargoCargoCheckForDualUseBadge id={element.id} isCompact={true} />
        </Td>
      }
      <Td
        ySmall={true}>
        <CargoDocumentsBtn id={props.id} />
      </Td>

      <Td
        ySmall={true}>
        <DfValueView path="cargo.stock" id={props.id} />
      </Td>

      <Td className={"whitespace-nowrap " + (isBadDate ? "text-red-500" : "")} ySmall={true}>
        <CargoUnloadWarehouseDateOrPoUnloadDateStr id={props.id} />
      </Td>


      <Td ySmall={true}>

        {!isFtlLogistic && !isTrackingSpecialist && (
          <div className="flex gap-1">

            {props.kzIds.length > 0 && (
              <Fragment>
                <ToolbarButtonWithMenu
                  button={{
                    className: "mr-2",
                    iconName: "arrow-turn-down-right",
                    bgColor: "sky-50",
                    loading: saveDataParams.loading,
                    small: true,
                  }}
                  menu={
                    {
                      items: props.kzIds.map((kzId) => {
                        return (
                          {
                            children: (<DfValueView id={kzId} path={"kz-carriers-order.serialNumber"} />),
                            onClick: () => moveCargo(kzId)
                          }
                        )
                      })
                    }
                  }
                />
              </Fragment>
            )}

            {canRemove &&
              <ToolbarButton
                iconName="trash"
                confirmation={needRemoveConfirmation ? false : true}
                onClick={() => {
                  if (needRemoveConfirmation) {
                    setShowRemoveConfirmation(true);
                  } else {
                    saveCargo();
                  }
                }}
                loading={saveDataParams.loading}
                small={true}
              />}

          </div>
        )}
        {showRemoveConfirmation && <CargoRemovePopup
          element={element}
          onClose={() => setShowRemoveConfirmation(false)}
          onSave={saveCargo}
        />}
      </Td>
    </tr>
  )
}

export enum TableCargosBorderColors {
  red = 'border-red-600',
  purple = 'border-purple-600',
  blue = 'border-blue-600',
  bluelight = 'border-blue-100',
  sky = 'border-sky-600',
  lime = 'border-lime-600',
  amber = 'border-amber-600',
  orange = 'border-orange-600',
  pink = 'border-pink-600',
  teal = 'border-teal-600',
  slate = 'border-slate-600',
  white = 'border-transparent',
  whiteandpurple = 'border-transparent',
  whiteandred = 'border-transparent',
  bronze = 'border-yellow-700',
  black = 'border-slate-800',
}

type CargoRemovePopupProps = {
  onClose: () => void,
  onSave: (note: any) => void,
  element: (ICargoModelNae | ISalesOrderModelNae),
}

const CargoRemovePopup = (props: CargoRemovePopupProps) => {
  const t = (s: string) => s;
  const [note, setNote] = useState("");
  return (
    <Popup isPopup={true} title={t("Remove confirmation")} onClick={props.onClose}>
      <CompactRow
        control={
          <FieldTextarea
            value={note}
            onChange={e => setNote(e.target.value)}
            className="w-full"
          />
        }
        label={
          <FieldLabel>{t('Reason')}</FieldLabel>
        }
      />

      {!!note &&
        <Toolbar
          onCancel={props.onClose}
          onSave={() => {
            props.onSave(
              {
                content: `Cargo was excluded from planned vehicle ${note}`,
                notify: [props.element.creator.id]
              }
            );
            props.onClose();
          }
          }
        />
      }
    </Popup>
  )
}