import React, { Fragment, useEffect } from "react";

import { useRecoilState } from "recoil";
import LogisticCargoNew from "./LogisticCargoNew";
import LogisticCargoOnEuRoadHot from "./LogisticCargoOnEuRoadHot";
import LogisticCargoPlaning from "./LogisticCargoPlaning";
import LogisticCargoWaitingLoadHot from "./LogisticCargoWaitingLoadHot";
import LogisticCargoReturned from "./LogistiсCargoWaitingReturned";
import { checkUserForwardingManager } from '../../../_generated/_custom/config/NaeSPermissions';
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";

type Props = {
  userId: number,
}

export default function LogisticCargoWrapper(props: Props) {
  const t = (s: string) => s;

  const { userState } = useTemplatesCore()

  const isFm = checkUserForwardingManager(userState);

  useEffect(() => {
    const subscribeLogisticCardIndex = "cargo-logisticCardIndex";

    SocketService.subscribe(subscribeLogisticCardIndex);
    return () => {
      SocketService.unsubscribe(subscribeLogisticCardIndex);
    };
  }, [userState.id]);

  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
  useEffect(() => {
    setToolbarTitle(t(''));
  }, []);

  return (
    <Fragment>
      <div className={"space-y-4"}>

        <LogisticCargoNew userId={props.userId} />

        <LogisticCargoPlaning userId={props.userId} />


        {!isFm &&
          <Fragment>
            <TextCardTitle>{t("Order for carrier (PO)")}</TextCardTitle>

            <LogisticCargoWaitingLoadHot userId={props.userId} />

            <LogisticCargoOnEuRoadHot userId={props.userId} />

            <LogisticCargoReturned userId={props.userId} />

          </Fragment>
        }
      </div>

    </Fragment>
  );
}
