import React, { Fragment } from 'react'

import ConfirmIncomingDocs from './ConfirmIncomingDocs';
import MultipleFilesList from '../../../../UserComponents/KzCarriersOrder/DocumentsPopup/MultipleFilesList';
import DocumentsPopupFileItem from '../../../../UserComponents/KzCarriersOrder/DocumentsPopup/DocumentsPopupFileItem';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae, ICargoModelNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { AlertWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import OldBadge, { BadgeBgColor } from '../../../../core/old-ui/OldBadge';

interface Props extends PropsId {
    localPath: string,
    onSelect: (f: any) => void,
    onClose: () => void
}

export default function CargoIncomingList(props: Props) {
    const { localPath, onSelect, onClose } = props;
    const cargo = useCargoHookNae(props.id);

    const t = (s: string) => s;

    if (!cargo) {
        return <Fragment />
    }

    const fileWidgets = getDocumentsPopupFiles(cargo);

    return (
        <WhiteCard isCompact={true}>

            <div className={"space-y-2"}>
                <MultipleFilesList
                    options={fileWidgets}
                    schema={"cargo"}
                    elementId={cargo.id}
                    onSelect={onSelect}
                    activePath={localPath}
                />
                {cargo.senderMakeEx === 20 && (
                    <Fragment>
                        <div className={"text-base space-y-1"}>
                            <p className={"font-medium text-sm"}>{t('Įgaliojimai')}</p>

                            {!!cargo.sellerJson.attorneyFile ?
                                <DocumentsPopupFileItem
                                    checked={localPath === cargo.sellerJson.attorneyFile.path}
                                    f={cargo.sellerJson.attorneyFile}
                                    onClick={() => onSelect(cargo.sellerJson.attorneyFile)}
                                    showCheck={true} />
                                :
                                <AlertWidget color='danger' isCompact={true}>
                                    {t('The power of attorney is not attached')}
                                </AlertWidget>
                            }
                        </div>

                    </Fragment>
                )}
            </div>
            <ConfirmIncomingDocs id={cargo.id} onClose={onClose} />
        </WhiteCard>
    )
}

export const getDocumentsPopupFiles = (cargo: ICargoModelNae) => {
    return cargo.outgoingDocumentsChange === 10
        ? cargo.needEx1T1File
            ? docsChangeAndEx
            : docsChangeAndNoEx
        : cargo.needEx1T1File
            ? noDocsChangeAndEx
            : noDocsChangeAndNoEx;
}

const docsChangeAndEx = [
    {
        type: 10,
        title: "Seller's documents",
    },
    {
        type: 30,
        title: "Išvykimo dokumentai",
        className: "bg-amber-100",
    },
    {
        type: 20,
        title: "EX / T1",
    },
    {
        type: 40,
        title: "Specification",
        checkByDefault: true,
    },
    {
        type: 50,
        title: "Certificates",
        hideOnEmpty: true,
        checkByDefault: true,
    },
    {
        type: 55,
        title: "Letters of information",
        hideOnEmpty: true,
        checkByDefault: true,
    },
];
const docsChangeAndNoEx = [
    {
        type: 10,
        title: "Seller's documents",
    },
    {
        type: 30,
        title: "Išvykimo dokumentai",
        className: "bg-amber-100",
    },
    {
        type: 40,
        title: "Specification",
        checkByDefault: true,
    },
    {
        type: 50,
        title: "Certificates",
        hideOnEmpty: true,
        checkByDefault: true,
    },
    {
        type: 55,
        title: "Letters of information",
        hideOnEmpty: true,
        checkByDefault: true,
    },
];

const noDocsChangeAndEx = [
    {
        type: 10,
        title: "Seller's documents",
    },
    {
        type: 20,
        title: "EX / T1",
    },
    {
        type: 40,
        title: "Specification",
        checkByDefault: true,
    },
    {
        type: 50,
        title: "Certificates",
        hideOnEmpty: true,
        checkByDefault: true,
    },
    {
        type: 55,
        title: "Letters of information",
        hideOnEmpty: true,
        checkByDefault: true,
    },
];

const noDocsChangeAndNoEx = [
    {
        type: 10,
        title: "Seller's documents",
    },
    {
        type: 40,
        title: "Specification",
        checkByDefault: true,
    },
    {
        type: 50,
        title: "Certificates",
        hideOnEmpty: true,
        checkByDefault: true,
    },
    {
        type: 55,
        title: "Letters of information",
        hideOnEmpty: true,
        checkByDefault: true,
    },
];