import React, { useState, Fragment, useEffect } from "react";

import NEWidgetFiles from "../../../core/components/widgets/NEWidgetFiles";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { LogoLoader } from "@newageerp/v3.bundles.layout-bundle";
import {
  TemplatesLoader,
  getTemplateLoaderData,
  TemplatesParser,
  useTemplatesCore,
} from "@newageerp/v3.templates.templates-core";

import { buildQsDictionary, ShowOnScreen } from "@newageerp/v3.bundles.utils-bundle";
import { MainButton, ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { AlertWidget, PdfItemProps, PdfWidgetItem, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { FieldLabel, FieldInput, WideRow, Wide } from '@newageerp/v3.bundles.form-bundle';
import { Label } from "@newageerp/v3.bundles.form-bundle";
import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { useUList, useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { checkUserHeadOfLogisticsHelper } from "../../../_generated/_custom/config/NaeSPermissions";

interface IDataCargo {
  id: number;
  serialNumber: string;
  seller: {
    name: string;
  };
  soClientJson: {
    clientFilialName: string;
  };
  files: any;
}

interface IDataInvoice {
  id: number;
  type: string;
}

interface IData {
  id: number;
  serialNumber: string;
  kz: boolean;
  client: {
    name: string;
  };
  total: number;
  totalVat: number;
  currency: {
    currency: string;
  };
  cargos: IDataCargo[];
  responseDocsFinish: boolean;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceIssueDate: string;
  cargo: {
    id: number;
    needEx1T1File: boolean;
    files: any;
    checks: any;
    serialNumber: string;
  };
  files: any;
  creator: {
    fullName: string;
  };
  loadDate: string;
  unloadDate: string;
  status: number;
  company: {
    name: string;
  };
  penalty: number;
  invoiceIncomings: IDataInvoice[];

  transportType: number,
  railwayTariffPayer: number,
}

const defObject: IData = {
  id: 0,
  serialNumber: "",
  kz: false,
  client: {
    name: "",
  },
  total: 0,
  totalVat: 0,
  currency: {
    currency: "",
  },
  cargos: [
    {
      id: 0,
      serialNumber: "",
      seller: {
        name: "",
      },
      soClientJson: {
        clientFilialName: "",
      },
      files: "",
    },
  ],
  responseDocsFinish: false,
  invoiceNumber: "",
  invoiceDate: "",
  invoiceIssueDate: "",
  cargo: {
    id: 0,
    needEx1T1File: false,
    files: "",
    checks: "",
    serialNumber: "",
  },
  files: "",
  creator: {
    fullName: "",
  },
  loadDate: "",
  unloadDate: "",
  status: 0,
  company: {
    name: "",
  },
  penalty: 0,
  invoiceIncomings: [{ id: 0, type: '', }],

  transportType: 0,
  railwayTariffPayer: 0,
};

export default function DashboardAccountingCarriers() {
  const t = (s: string) => s;
  const [search, setSearch] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [templatesData, setTemplatesData] = useState<any>({});
  const [templates, setTemplates] = useState([]);

  const {userState} = useTemplatesCore();
  const isTrackingSpec = checkUserHeadOfLogisticsHelper(userState);

  const openEditPopup = (id: number, schema: string, type: string) => {
    SFSOpenEditModalWindowProps({
      id: "new",
      schema: "invoice-incoming",
      type: 'for-carrier',
      options: {
        createOptions: {
          convert: {
            schema: schema,
            element: { id },
            type,
          },
        },
      },
      onSaveCallback: (_el, back) => {
        loadData();
        back();
      },
    });
  };

  const [getData, getDataParams] = useUList(
    NaeSSchemaMap.CarriersOrder.schema,
    getKeysFromObject(defObject)
  );
  const [getDataKz, getDataParamsKz] = useUList(
    NaeSSchemaMap.KzCarriersOrder.schema,
    getKeysFromObject(defObject)
  );

  const [saveData] = useUSave(NaeSSchemaMap.CarriersOrder.schema);
  const [saveDataKz] = useUSave(NaeSSchemaMap.KzCarriersOrder.schema);

  useEffect(() => {
    getTemplateLoaderData("/app/nae-core/react-templates/get/DashboardAccountingCarriers", {}).then((res) => {
      if (res.status === 200) {
        setIsLoaded(true);
        setTemplates(res.data.data);
        setTemplatesData(res.data.templatesData);
      }
    });
  }, []);

  const loadData = () => {
    if (!!search) {
      getData(
        [
          buildQsDictionary(templatesData.poQs, search),
          {
            or: [
              ["i.status", "!=", 900, true],
              ["i.penalty", ">", 0, true],
            ],
          },
        ],
        1,
        5,
        [
          {
            key: "i.id",
            value: "DESC",
          },
        ]
      );
      getDataKz(
        [
          buildQsDictionary(templatesData.kzQs, search),
          {
            and: [["i.status", "!=", 900, true]],
          },
        ],
        1,
        5,
        [
          {
            key: "i.id",
            value: "DESC",
          },
        ]
      );
    }
  };
  const onSave = (isKz: boolean, id: number, responseDocsFinish: boolean) => {
    if (isKz) {
      saveDataKz(
        {
          responseDocsFinish: responseDocsFinish,
        },
        id
      ).then(loadData);
    } else {
      saveData(
        {
          responseDocsFinish: responseDocsFinish,
        },
        id
      ).then(loadData);
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      loadData();
    }
  };

  const isData =
    !getDataParams.loading &&
    !!getDataParams &&
    !!getDataParams.data &&
    getDataParams.data.data.length > 0;
  const data = isData ? getDataParams.data.data : [];

  const isDataKz =
    !getDataParamsKz.loading &&
    !!getDataParamsKz &&
    !!getDataParamsKz.data &&
    getDataParamsKz.data.data.length > 0;
  const dataKz = isDataKz ? getDataParamsKz.data.data : [];

  const unionData = [
    ...data.map((el: any) => ({ ...el, kz: false })),
    ...dataKz.map((el: any) => ({ ...el, kz: true })),
  ];

  if (!isLoaded) {
    return <Fragment />;
  }

  return (
    <div className="space-y-2">
      <TemplatesParser templates={templates} />

      <FieldInput
        iconName="search"
        className={"w-full"}
        placeholder={t("Enter the order number and press ENTER")}
        onKeyDown={handleKeyDown}
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />

      <div className={"grid gap-2"}>
        {(getDataParams.loading || getDataParamsKz.loading) && <LogoLoader />}
        {unionData.map((order: IData) => {
          const schema = order.kz
            ? NaeSSchemaMap.KzCarriersOrder.schema
            : NaeSSchemaMap.CarriersOrder.schema;
          const filesReadonly = order.responseDocsFinish;

          const invociesCount = order.invoiceIncomings.filter(t => t.type === 'invoice').length;

          let canFinish = invociesCount > 0;
          let canFinishDocs = true;

          let canFinishDocsErrors = [];

          if (order.kz) {
            // if (!order.files["20"] || order.files["20"] === 0) {
            //   canFinishDocs = false;
            //   canFinishDocsErrors.push(order.serialNumber);
            // }

            order.cargos.forEach((c) => {
              if (!c.files["60"] || c.files["60"] === 0) {
                canFinishDocs = false;
                canFinishDocsErrors.push(c.serialNumber);
              }
              if (!c.files["70"] || c.files["70"] === 0) {
                canFinishDocs = false;
                canFinishDocsErrors.push(c.serialNumber);
              }
            });
          } else {
            if (
              (!order.files["20"] || order.files["20"] === 0) &&
              order.status !== 900
            ) {
              canFinishDocs = false;
              canFinishDocsErrors.push(order.serialNumber);
            }
            // if (!order.files["30"] || order.files["30"] === 0) {
            //   canFinishDocs = false;
            //   canFinishDocsErrors.push(order.serialNumber);
            // }
          }

          const pdfWidget: PdfItemProps = {
            id: order.id,
            schema: schema,
            template: 'pdf',
            title: 'PDF',
          }

          return (
            <WhiteCard isCompact={true} key={"order-" + order.id}>
              <div className={"row"}>
                <div className={"col-sm-3"}>
                  <div className={"grid gap-1"}>
                    <div className={"flex gap-2 items-center"}>
                      <TextCardTitle className="flex-grow">
                        {order.serialNumber}
                      </TextCardTitle>
                      <span style={{ width: 180 }}>
                        <PdfWidgetItem {...pdfWidget} />
                      </span>
                    </div>

                    <ShowOnScreen once eventListenerElement={window.document.getElementById('layout-toolbar')}>
                      {({ isVisible }: any) => isVisible ?
                        <TemplatesLoader
                          templateName="DashboardAccountingCarriersViewForm"
                          data={{ id: order.id, schema }}
                        /> :
                        <LogoLoader />
                      }
                    </ShowOnScreen>

                    {order.responseDocsFinish ? (
                      <Fragment>
                        <AlertWidget
                          isCompact={true}
                          color="slate"
                        >
                          {t("The order is locked.")}
                        </AlertWidget>

                        <MainButton
                          className={"mt-4"}
                          onClick={() => onSave(order.kz, order.id, false)}
                          confirmation={true}
                          color={"bluelight"}
                          iconName="check"
                        >
                          {t("Unlock")}
                        </MainButton>
                      </Fragment>
                    ) : !canFinish || !canFinishDocs ? (
                      <Fragment>
                        {!canFinish && (
                          <AlertWidget
                            isCompact={true}
                            color="slate"
                          >
                            {t("Fill in the data to finish")}
                          </AlertWidget>
                        )}
                        {!canFinishDocs && (
                          <AlertWidget
                            isCompact={true}
                            color="slate"
                          >
                            {t("Upload files to finish")} (
                            {canFinishDocsErrors.join(", ")})
                          </AlertWidget>
                        )}

                        <MainButton
                          color="bluelight"
                          className={"mt-2"}
                          onClick={loadData}
                        >
                          {t("Check")}
                        </MainButton>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <MainButton
                          className={"mt-4"}
                          onClick={() => onSave(order.kz, order.id, true)}
                          confirmation={true}
                          color={"teal"}
                          iconName="check"
                        >
                          {t("Finish")}
                        </MainButton>
                      </Fragment>
                    )}
                  </div>
                </div>
                <div className={"col-sm-9"}>
                  <Fragment>
                    <div className="flex items-center">
                      <TextCardTitle className="flex-grow">
                        {t("Invoices")}
                      </TextCardTitle>

                      <div className="flex gap-4">
                        {!order.responseDocsFinish && !isTrackingSpec && (
                          <ToolbarButton

                            onClick={() =>
                              openEditPopup(
                                order.id,
                                schema,
                                order.penalty > 0 ? "penalty" : "invoice"
                              )
                            }
                            iconName="plus"
                            disabled={invociesCount > 0}
                          >
                            {t("Add invoice")}
                          </ToolbarButton>
                        )}


                      </div>
                    </div>
                    <ShowOnScreen
                      once
                      eventListenerElement={window.document.getElementById(
                        "layout-toolbar"
                      )}
                    >
                      {({ isVisible }: any) =>
                        isVisible ? (
                          <TemplatesLoader
                            templateName="ListRelContent"
                            data={{
                              schema: "invoice-incoming",
                              type: "rel",
                              rel:
                                schema === "carriers-order"
                                  ? "carriersOrder"
                                  : "kzCarriersOrder",
                              id: order.id,
                              wrap: 0,
                            }}
                          />
                        ) : (
                          <LogoLoader />
                        )
                      }
                    </ShowOnScreen>
                  </Fragment>

                  <div className={"space-y-2"}>
                    {!order.kz && (
                      <Fragment>
                        {!!order.cargo &&
                          !!order.cargo.checks.needFillExFileAccounting &&
                          order.status !== 900 && (
                            <NEWidgetFiles
                              schema={"cargo"}
                              element={{ id: order.cargo.id }}
                              options={{
                                type: 20,
                                title: "EX / T1",
                                readOnly: filesReadonly,
                              }}
                            />
                          )}

                        {order.status !== 900 && (
                          <NEWidgetFiles
                            schema={schema}
                            element={order}
                            options={{
                              type: 20,
                              title: "Važtaraštis",
                              readOnly: filesReadonly,
                            }}
                          />
                        )}
                        {/* <NEWidgetFiles
                          schema={schema}
                          element={order}
                          options={{
                            type: 30,
                            title: "Carrier's invoice",
                            readOnly: filesReadonly,
                          }}
                        /> */}
                      </Fragment>
                    )}
                    {order.kz && (
                      <Fragment>
                        {/* <NEWidgetFiles
                          schema={schema}
                          element={order}
                          options={{
                            type: 20,
                            title: "Sąskaita",
                            readOnly: filesReadonly,
                          }}
                        /> */}
                        <div className="space-y-2">
                          <div>
                            <div className="grid grid-cols-2">
                              <Label>Waybill (for client)</Label>
                              <Label>Waybill (after delivery)</Label>
                            </div>
                          </div>
                          {order.cargos.map((cargo: IDataCargo) => {
                            return (
                              <div>
                                <Wide>
                                  <Label>{cargo.serialNumber}</Label>
                                  <div className="grid grid-cols-2">
                                    <WideRow
                                      control={<p>{cargo.seller.name}</p>}
                                      label={
                                        <FieldLabel>Seller</FieldLabel>
                                      }
                                    />
                                    <WideRow
                                      control={
                                        <p>
                                          {cargo.soClientJson.clientFilialName}
                                        </p>
                                      }
                                      label={<FieldLabel>Consignee</FieldLabel>}
                                    />
                                  </div>
                                </Wide>
                                <div className="grid grid-cols-2">
                                  <NEWidgetFiles
                                    key={"cargo-" + cargo.id}
                                    schema={"cargo"}
                                    element={cargo}
                                    options={{
                                      type: 60,
                                      readOnly: filesReadonly,
                                    }}
                                  />
                                  <NEWidgetFiles
                                    key={"cargo-" + cargo.id}
                                    schema={"cargo"}
                                    element={cargo}
                                    options={{
                                      type: 70,

                                      readOnly: filesReadonly,
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </WhiteCard>
          );
        })}
      </div>
    </div>
  );
}
