import React, { Fragment, useEffect, useState } from "react";

import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { checkUserManager } from '../../../_generated/_custom/config/NaeSPermissions';
import { useRecoilState } from "recoil";
import { StatusWidget } from "@newageerp/v3.bundles.widgets-bundle";
import { RsButton as RsButtonTpl } from "@newageerp/v3.bundles.buttons-bundle";
import { ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { selectorBySchemaSlug } from "../../../_generated/_custom/models/ormSelectors";
import { Compact, CompactRow, FieldSelect } from "@newageerp/v3.bundles.form-bundle";
import { FieldLabel } from "@newageerp/v3.bundles.form-bundle";
import { IUserModelNae } from '../../../_generated/_custom/hooks/useUserHookNae';
import { useSelector, useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { IClientStatusModelNae } from '../../../_generated/_custom/hooks/useClientStatusHookNae';
import { LogoLoader, UserSpaceWrapperToolbarState } from "@newageerp/v3.bundles.layout-bundle";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";

interface Item {
  id: number;
  clientName: string;
  doer: string;
  doerId: number;
  currentStatus: {
    title: string;
    color: string;
  };
  prevStatus: {
    title: string;
    color: string;
  };
  currentProfitPerOrder: number;
  currentProfitTurnover: number;
  prevProfitPerOrder: number;
  prevProfitTurnover: number;
  diff: number;
  comment: string;
  commentId: number;
}


export default function ClientsStatusReports() {
  const t = (s: string) => s;

  const [selectedUser, setSelectedUser] = useState<number | undefined>();
  const [selectedNowStatus, setSelectedNowStatus] = useState<string | undefined>();
  const [selectedPastStatus, setSelectedPastStatus] = useState<string | undefined>();

  const [sortStatus, setSortStatus] = useState<string>('growth');

  const usersSelector: any = selectorBySchemaSlug('user')
  // @ts-ignore
  const users: any[] = useSelector((state) => usersSelector(state))

  const clientStatusSelector: any = selectorBySchemaSlug('client-status')
  // @ts-ignore
  const clientStatuses: any[] = useSelector((state) => clientStatusSelector(state))

  const [, setToolbarTitle] = useRecoilState(UserSpaceWrapperToolbarState)
  useEffect(() => {
    setToolbarTitle(t('Client status changes'));
  }, []);

  const [data, setData] = useState<Item[]>([]);

  const { userState } = useTemplatesCore()
  const isManager = checkUserManager(userState);

  const [getData, getDataParams] = useURequest<Item>(
    NaePathsMap.post["pluginsClientStatusGetReports"]
  );

  const loadData = () => {
    if (getDataParams.loading) {
      return;
    }
    getData().then((res: any) => setData(res.data.data));
  };
  useEffect(loadData, []);

  const showCommentsPopup = (id: number) => {
    SFSOpenEditModalWindowProps({
      id: id,
      schema: NaeSSchemaMap.ClientStatusData.schema,
      type: "comment",
      fieldsToReturnOnSave: ["id", "comment"],
      onSaveCallback: (el, closeFunc) => {
        const _data = JSON.parse(JSON.stringify(data));
        const newData = _data.map((i: Item) => {
          if (i.commentId === id) {
            return { ...i, comment: el.comment };
          }
          return i;
        })
        setData(newData);

        closeFunc();
      },
    });
  };

  const filteredData = data.filter((el: Item) => {
    let isOk = true;
    if (selectedUser) {
      if (el.doerId !== selectedUser) {
        isOk = false;
      }
    }
    if (selectedNowStatus) {
      if (el.currentStatus.title !== selectedNowStatus) {
        isOk = false;
      }
    }
    if (selectedPastStatus) {
      if (el.prevStatus.title !== selectedPastStatus) {
        isOk = false;
      }
    }
    return isOk;
  }).sort((a, b) => {
    if (sortStatus === 'growth') {
      if (a.diff > b.diff) {
        return -1;
      } else if (a.diff < b.diff) {
        return 1;
      }
      return 0;
    } else {
      if (a.diff > b.diff) {
        return 1;
      } else if (a.diff < b.diff) {
        return -1;
      }
      return 0;
    }
  });

  return (
    <div className="space-y-4">
      <WhiteCard title={t('Filter')}>
        <div className="grid grid-cols-4 gap-2">
          <Compact>
            <CompactRow
              label={<FieldLabel>{t('Responsible')}</FieldLabel>}
              control={<FieldSelect
                options={
                  [{
                    value: '',
                    label: '',
                  },
                  ...users.filter((u: IUserModelNae) => u.disabled === false && u.permissionGroup === 'user-manager').sort((object1, object2) => {
                    const key = 'fullName';
                    const obj1 = object1[key].toUpperCase()
                    const obj2 = object2[key].toUpperCase()

                    if (obj1 < obj2) {
                      return -1
                    }
                    if (obj1 > obj2) {
                      return 1
                    }
                    return 0
                  }).map((u: IUserModelNae) => ({
                    value: u.id,
                    label: u.fullName,
                  }))]
                }
                value={selectedUser}
                onChange={setSelectedUser}
              />}
            />
          </Compact>
          <Compact>
            <CompactRow
              label={<FieldLabel>{t('Past status')}</FieldLabel>}
              control={<FieldSelect
                options={
                  [{
                    value: '',
                    label: '',
                  },
                  ...clientStatuses.map((u: IClientStatusModelNae) => ({
                    value: u.titleManager,
                    label: u.titleManager,
                  }))]
                }
                value={selectedPastStatus}
                onChange={setSelectedPastStatus}
              />}
            />
          </Compact>
          <Compact>
            <CompactRow
              label={<FieldLabel>{t('Now status')}</FieldLabel>}
              control={<FieldSelect
                options={
                  [{
                    value: '',
                    label: '',
                  },
                  ...clientStatuses.map((u: IClientStatusModelNae) => ({
                    value: u.titleManager,
                    label: u.titleManager,
                  }))]
                }
                value={selectedNowStatus}
                onChange={setSelectedNowStatus}
              />}
            />
          </Compact>
          <Compact>
            <CompactRow
              label={<FieldLabel>{t('Sort')}</FieldLabel>}
              control={<FieldSelect
                options={
                  [
                    {
                      value: 'growth',
                      label: 'Growth',
                    },
                    {
                      value: 'recession',
                      label: 'Recession',
                    },
                  ]
                }
                value={sortStatus}
                onChange={setSortStatus}
              />}
            />
          </Compact>
        </div>
      </WhiteCard>
      <WhiteCard>
        <div className="overflow-x-auto">
          <Table
            thead={
              <thead>
                <tr>
                  <Th>{t("Client")}</Th>
                  {!isManager && <Th>{t("Responsible")}</Th>}

                  <Th>{t("Past")}</Th>
                  <Th textAlignment={"text-right"}>{t("Profit")}</Th>
                  <Th textAlignment={"text-right"}>
                    {t("Profitability")}
                  </Th>
                  <Th>{t("Status")}</Th>

                  <Th>{t("Now")}</Th>
                  <Th textAlignment={"text-right"}>{t("Profit")}</Th>
                  <Th textAlignment={"text-right"}>
                    {t("Profitability")}
                  </Th>
                  <Th>{t("Status")}</Th>
                  <Th>{t("Comment")}</Th>
                </tr>
              </thead>
            }
            tbody={
              <thead>
                {filteredData.map((d: Item) => {
                  return (
                    <tr key={`row-${d.id}-${d.comment}`}>
                      <Td>
                        <RsButtonTpl
                          defaultClick={"modal"}
                          id={d.id}
                          schema={NaeSSchemaMap.Client.schema}
                          button={{
                            children: d.clientName,
                            color: "white",
                            skipPadding: true,
                          }}
                        />
                      </Td>
                      {!isManager && <Td>{d.doer}</Td>}

                      <Td>{""}</Td>
                      <Td textAlignment={"text-right"}>
                        {(d.prevProfitTurnover ? d.prevProfitTurnover : 0).toFixed(2)}
                      </Td>
                      <Td textAlignment={"text-right"}>
                        {(d.prevProfitPerOrder ? d.prevProfitPerOrder : 0).toFixed(2)}
                      </Td>
                      <Td>
                        <StatusWidget
                          // @ts-ignore
                          color={d.prevStatus.color}
                        >
                          {d.prevStatus.title}
                        </StatusWidget>
                      </Td>

                      <Td>{""}</Td>
                      <Td textAlignment={"text-right"}>
                        {(d.currentProfitTurnover ? d.currentProfitTurnover : 0).toFixed(2)}
                      </Td>
                      <Td textAlignment={"text-right"}>
                        {(d.currentProfitPerOrder ? d.currentProfitPerOrder : 0).toFixed(2)}
                      </Td>
                      <Td>
                        <StatusWidget
                          // @ts-ignore
                          color={d.currentStatus.color}
                        >
                          {d.currentStatus.title}
                        </StatusWidget>

                      </Td>
                      <Td>
                        {d.comment}

                        <ToolbarButton
                          onClick={() => showCommentsPopup(d.commentId)}
                          iconName="edit"
                        />

                      </Td>
                    </tr>
                  );
                })}
              </thead>
            }
          />
        </div>
      </WhiteCard>
      {getDataParams.loading && <LogoLoader />}
    </div>
  );
}
