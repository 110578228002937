import React, { Fragment } from "react";


import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useKzCarriersOrderHookNae } from "../../../_generated/_custom/hooks/useKzCarriersOrderHookNae";
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";

export default function CargoManagerData(props: PropsId) {
  const t = (s: string) => s;

  const openPopup = () => {
    SFSOpenEditModalWindowProps({
      id: element.id,
      schema: "cargo",
      type: "manager-cargo-change",
    });
  };

  const element = useCargoHookNae(props.id);
  const kzOrder = useKzCarriersOrderHookNae(
    element && element.kzCarriersOrder ? element.kzCarriersOrder.id : -1
  );
  const kzOrderStatus = kzOrder ? kzOrder.status : -1;

  if (!element) {
    return <Fragment />;
  }
  if (!element.needTerminal) {
    return <Fragment />;
  }
  if (!!element.kzCarriersOrder && kzOrderStatus >= 15) {
    return <Fragment />;
  }
  if (element.status === 900) {
    return <Fragment />;
  }

  return (
    <MainButton
      className={"w-full"}
      color={"bluelight"}
      onClick={openPopup}
      iconName={"edit"}
    >
      {t("Change cargo details")}
    </MainButton>
  );
}
