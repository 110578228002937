import React, { Fragment, useState } from "react";


import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCarriersOrderHookNae } from "../../../_generated/_custom/hooks/useCarriersOrderHookNae";
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import { PoLoadingDatesChangeHistoryBtn } from "./PoLoadingDatesChangeHistory";

export default function PoDatesBlock(props: PropsId) {
  const t = (s: string) => s;
  const element = useCargoHookNae(props.id);

  if (!element) {
    return <Fragment />;
  }

  return (
    <div className={"space-y-1 mt-2"}>
      {element.poLoadDateStr && (
        <div className="space-x-1">
          <Tooltip
            text={t("Loading date (from the seller)")}
          />
          <i className={"text-lg fa-fw fad fa-truck-loading "} />
          <span>{element.poLoadDateStr}</span>
        </div>
      )}
      {element.poUnloadDateStr && (
        <div className="space-x-1">
          <Tooltip
            text={t("Unloading date (at warehouse)")}
          />
          <i className={"text-lg fa-fw fad fa-ramp-loading"} />
          <span>{element.poUnloadDateStr}</span>
        </div>
      )}
      <PoLoadingDatesChangeHistoryBtn
        ids={element.activePoOrders.map(i => i.id)}
      />
      {element.activePoOrders.map((d) => (
        <PoCarNumberData id={d.id} key={`po-car-${d.id}`} />
      ))}

    </div>
  );
}

const PoCarNumberData = (props: PropsId) => {
  const t = (s: string) => s;

  const po = useCarriersOrderHookNae(props.id);
  if (!po || po.status === 900) {
    return <Fragment />;
  }
  return (
    <div className="mt-2">
      <p className="font-semibold">{t("Vehicle number / Partners name")}</p>
      <p>{po.carNumber}</p>
    </div>
  );
};
