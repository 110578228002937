import React, { Fragment, useEffect } from "react";



import CargoOperativeAdminCalculationFinish from "../../../components/cargo/CargoOperativeAdminCalculationFinish";
import CargoUrgentChangesTable from "../../../components/cargo/Components/CargoUrgentChangesTable";
import CargoCalculationSerialNumberWithLinkAndNotes from "../../../_generated/_custom/models-cache-data/Cargo/CargoCalculationSerialNumberWithLinkAndNotes";
import CalculationCanSeparateCargosBadge from "../../../_generated/_custom/models-cache-data/Calculation/CalculationCanSeparateCargosBadge";
import CargoSerialNumberWithLinkAndNotes from "../../../_generated/_custom/models-cache-data/Cargo/CargoSerialNumberWithLinkAndNotes";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";

import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";

import RelatedCargosLine from "../../Cargo/Components/RelatedCargosLine";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useCalculationHookNae } from "../../../_generated/_custom/hooks/useCalculationHookNae";
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";


interface IDataCargo {
  id: number;
  serialNumber: string;
  name: string;
  places: string;
  dimensions: string;
  weight: string;
  notesOperations: string;

  ldm: number;
  ldmCalculationType: number;
  ldmCalculated: boolean;

  beforeChange: any;
  calculationJson: {
    id: number;
    status5LastChange: string;
    serialNumber: string;
    cargos: number;
    canSeparateCargos: number;
  };

  loadAddress: {
    id: number;
    fullAddress: string;
  };
  loadAddressAdditional: {
    id: number;
    fullAddress: string;
  };
  loadAddressAdditional2: {
    id: number;
    fullAddress: string;
  };
}
const defObject: IDataCargo = {
  id: 0,
  serialNumber: "",
  name: "",
  places: "",
  dimensions: "",
  weight: "",
  notesOperations: "",

  ldm: 0,
  ldmCalculationType: 0,
  ldmCalculated: false,

  beforeChange: "",
  calculationJson: {
    id: 0,
    status5LastChange: "",
    serialNumber: "",
    cargos: 0,
    canSeparateCargos: 0,
  },

  loadAddress: {
    id: 0,
    fullAddress: "",
  },
  loadAddressAdditional: {
    id: 0,
    fullAddress: "",
  },
  loadAddressAdditional2: {
    id: 0,
    fullAddress: "",
  },
};

let reloadTimeout: any = -1;

export default function OperativeAdminCalculations() {
  // const [previewPopupId, setPreviewPopupId] = useState<number>(0);

  const t = (s: string) => s;

  const [getData, getDataParams] = useUList<IDataCargo>("cargo", getKeysFromObject(defObject));

  const loadData = () => {
    getData(
      [
        {
          "and": [
            ['i.needLdm', '=', true, true],
            ['i.ldmCalculated', '=', false, true],
            ['i.calculation.status', '>=', 5, true],
            ['i.status', '!=', 900, true],
          ]
        }
      ],
      1,
      500,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        }
      ]
    );
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 1500);
  };

  useEffect(() => {
    SocketService.subscribe("operative-calculation-dashboard");
    return () => {
      SocketService.unsubscribe("operative-calculation-dashboard");
    };
  }, []);

  useEffect(() => {
    SocketService.addCallback(
      "operative-calculation-dashboard",
      "operative-calculation-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "operative-calculation-dashboard",
        "operative-calculation-dashboard"
      );
    };
  }, []);

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;


  return (
    <Fragment>
      <WhiteCard title={t("Needs quotation")}  isCompact={true}>
        {isData && (
          <Table
            thead={
              <thead>
                <tr>
                  <Th>{t("Number")}</Th>
                  <Th>{t("Responsible person")}</Th>
                  <Th>{t("Cargo")}</Th>
                  <Th>{t("Quotation")}</Th>
                  <Th>{t("Received for quotation")}</Th>
                  <Th>{t("Colli quantity")}</Th>
                  <Th>{t("Dimensions")}</Th>
                  <Th>{t("Weight")}</Th>
                  <Th>{t("Notes")}</Th>
                  <Th className={"text-right"}>{t("CLM")}</Th>
                  <Th className={"text-right"}>{t("Quotation scheme")}</Th>
                  <Th></Th>
                </tr>
              </thead>
            }
            tbody={
              <tbody>
                {getDataParams.data.data
                  .filter((cargo: IDataCargo) => !cargo.beforeChange)
                  .map((cargo: IDataCargo) => {
                    const calculation = cargo.calculationJson;
                    return (
                      <tr key={"op-cargo-" + cargo.id}>
                        <Td>
                          <CargoSerialNumberWithLinkAndNotes id={cargo.id} />

                          <CalculationCanSeparateCargosBadge
                            id={calculation.id}
                          />

                          <RelatedCargosLine id={cargo.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.creator.fullName" id={cargo.id} />
                          <br />
                          <DfValueView path='cargo.calculation.purpose' id={cargo.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.name" id={cargo.id} />
                        </Td>
                        <Td>
                          <div className="flex gap-1 items-center">
                            <CargoCalculationSerialNumberWithLinkAndNotes
                              id={cargo.id}
                            />
                            <RelatedCargosCount id={cargo.id} />

                          </div>
                        </Td>
                        <Td className="whitespace-nowrap">
                          <DfValueView
                            path="cargo.calculation.status5LastChange"
                            id={cargo.id}
                          />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.places" id={cargo.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.dimensions" id={cargo.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.weight" id={cargo.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.notesOperations" id={cargo.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <DfValueView path="cargo.ldm" id={cargo.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <DfValueView path="cargo.ldmCalculationType" id={cargo.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <CargoOperativeAdminCalculationFinish
                            id={cargo.id}
                            sm={true}
                          />
                        </Td>
                      </tr>
                    );
                  })}
              </tbody>
            }
          />
        )}
      </WhiteCard>
      <WhiteCard title={t("Data has changed")} isCompact={true}>
        {isData && (
          <Table
            thead={
              <thead>
                <tr>
                  <Th>{t("Number")}</Th>
                  <Th>{t("Cargo")}</Th>
                  <Th>{t("Date")}</Th>
                  <Th>{t("Notes")}</Th>
                  <Th className={"text-right"}>{t("CLM")}</Th>
                  <Th className={"text-right"}>{t("Quotation scheme")}</Th>
                  <Th></Th>
                </tr>
              </thead>
            }
            tbody={
              <tbody>
                {getDataParams.data.data
                  .filter((cargo: IDataCargo) => !!cargo.beforeChange)
                  .map((cargo: IDataCargo) => {
                    const calculation = cargo.calculationJson;

                    return (
                      <tr key={"op-cargo-" + cargo.id}>
                        <Td>
                          <CargoSerialNumberWithLinkAndNotes id={cargo.id} />

                          <CalculationCanSeparateCargosBadge
                            id={calculation.id}
                          />

                          <RelatedCargosLine id={cargo.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.name" id={cargo.id} />
                        </Td>
                        <Td style={{ maxWidth: 300, minWidth: 300 }}>
                          <CargoUrgentChangesTable id={cargo.id} />
                        </Td>
                        <Td>
                          <DfValueView path="cargo.notesOperations" id={cargo.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <DfValueView path="cargo.ldm" id={cargo.id} />
                        </Td>
                        <Td className={"text-right"}>
                          <DfValueView path="cargo.ldmCalculationType" id={cargo.id} />
                        </Td>
                        <Td style={{ maxWidth: 300 }}>
                          <div className="w-[300px] space-y-2">
                            <MainEditContentInline
                              schema={"cargo"}
                              type={"cargo-cdm"}
                              id={cargo.id.toString()}
                              isCompact={true}
                            />
                            <CargoOperativeAdminCalculationFinish
                              id={cargo.id}
                              sm={true}
                            />
                          </div>
                        </Td>
                      </tr>
                    );
                  })}
              </tbody>
            }
          />
        )}
      </WhiteCard>
    </Fragment>
  );
}

const RelatedCargosCount = (props: PropsId) => {
  const cargo = useCargoHookNae(props.id);
  const calculation = useCalculationHookNae(
    cargo && cargo.calculation ? cargo.calculation.id : -1
  );
  const so = useSalesOrderHookNae(
    cargo && cargo.salesOrder ? cargo.salesOrder.id : -1
  );
  if (so && so.cargos.length > 1) {
    return <span className={"text-red-500 ml-1"}>({so.cargos.length})</span>;
  }
  if (calculation && calculation.cargos.length > 1 && !so) {
    return (
      <span className={"text-red-500 ml-1"}>({calculation.cargos.length})</span>
    );
  }
  return <Fragment />;
};
