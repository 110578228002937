import React, { Fragment } from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';

import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoAlco(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const t = (s: string) => s;

    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            {element.specCargo === 10 &&
                <StatusWidget color='blue' smallPadding={true}>
                    {t('Alcohol')}{" "}
                    {element.alcoSubType === 10
                        ? " (Light, up to 17%)"
                        : element.alcoSubType === 20
                            ? " Strong, up from 18%"
                            : ""}
                </StatusWidget>
            }
            {element.needAlkoGlueWithStamps === 10 && (
                <StatusWidget color='sky' smallPadding={true}>
                    {t('Labeling')}{" "}
                    {(element.needAlkoGlueWithStampsType === 10 ||
                        element.needAlkoGlueWithStampsType2 === 10 ||
                        element.needAlkoGlueWithStampsType3 === 10) && (
                            <Fragment>
                                (
                                {element.needAlkoGlueWithStampsType === 10 &&
                                    "excise stamps"}
                                {element.needAlkoGlueWithStampsType === 10 &&
                                    element.needAlkoGlueWithStampsType2 === 10 &&
                                    ", "}
                                {element.needAlkoGlueWithStampsType2 === 10 &&
                                    "information stickers"}
                                    {element.needAlkoGlueWithStampsType3 === 10 &&
                                    ", QR codes"}
                                )
                            </Fragment>
                        )}
                    {element.needAlkoGlueWithStampsBottles > 0 && (
                        <Fragment>
                            {" "}
                            {element.needAlkoGlueWithStampsBottles} but.
                        </Fragment>
                    )}
                </StatusWidget>
            )}
        </Fragment>
    )
}
