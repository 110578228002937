import React, { Fragment } from "react";

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSelector, useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SelectorCargoNae } from "../../../_generated/_custom/models/ormSelectors";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { ICargoModelNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { MainButtonWithSave, RsButton as RsButtonTpl } from "@newageerp/v3.bundles.buttons-bundle";
import { checkUserAdmin, checkUserManager } from "../../../_generated/_custom/config/NaeSPermissions";
import { TextMultiline } from "@newageerp/v3.bundles.data-bundle";

interface Props extends PropsId {
  sm?: boolean;
}

export default function CalculationSendToCalculate(props: Props) {
  const element = useCalculationHookNae(props.id);
  const elementCargos = element ? element.cargos.map(el => el.id) : [-1];

  // @ts-ignore
  const cargos: ICargoModelNae[] = useSelector((state) => SelectorCargoNae(state, elementCargos));

  const t = (s: string) => s;

  const {userState} = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isManager = checkUserManager(userState);

  if (!element) {
    return <Fragment />
  }
  if (cargos.filter((e: ICargoModelNae) => e === null).length > 0) {
    return <Fragment />
  }
  if (!(isAdmin || isManager)) {
    return <Fragment />
  }
  if (element.status !== 0) {
    return <Fragment />
  }

  let disabledContentLines = [];
  let disabledContent: any = undefined;
  if (element.fillErrors && element.fillErrors.length > 0) {
    disabledContentLines.push(<Fragment>
      <TextMultiline text={element.fillErrors.join("\n")} />
    </Fragment>
    );
  }
  cargos.forEach(cargo => {
    if (cargo.fillErrors && cargo.fillErrors.length > 0) {
      disabledContentLines.push(<div className="space-y-1">
        <div>
          <TextMultiline text={cargo.fillErrors.join("\n")} />
        </div>
        <div>
          <RsButtonTpl
            defaultClick={"modal"}
            id={cargo.id}
            schema={"cargo"}
            button={{
              children: cargo.serialNumber,
              color: "white",
              skipPadding: true,
            }}
            isEdit={true}
          />
        </div>
      </div>
      );
    }
  })

  if (disabledContentLines.length > 0) {
    disabledContent = (
      <div className="space-y-2">
        {disabledContentLines.map((l, lIndex) => <Fragment key={`l-${lIndex}`}>{l}</Fragment>)}
      </div>
    )
  }

  const disabled = !!disabledContent;
  const disabledContentProp : any = props.sm ? {
    children: "Eliminate defects",
    color: "danger"
  } : {
    title: 'Please fill in missing information',
    children: disabledContent,
    color: "danger"
  };

  if (element.purpose === 30) {
    return (
      <MainButtonWithSave
        elementId={props.id}
        saveData={{
          status: 3,
          addNote: {
            content: "Sent for filling",
          },
        }}
        schema={"calculation"}
        className={"w-full"}
        iconName={"info"}
        confirmation={true}
        disabled={disabled}
        disabledContent={disabledContentProp}
        color={"sky"}
      >
        {props.sm ? t("Send") : t("Send for filling")}
      </MainButtonWithSave>
    );
  }

  return (
    <MainButtonWithSave
      elementId={props.id}
      saveData={{
        status: 5,
        addNote: {
          content: "Sent for quotation",
        },
      }}
      schema={"calculation"}
      className={"w-full"}
      iconName={"calculator"}
      confirmation={true}
      disabled={disabled}
      disabledContent={disabledContentProp}
      color={"sky"}
    >
      {props.sm ? t("Send") : t("Send for quotation")}
    </MainButtonWithSave>
  );
}
