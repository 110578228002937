import React, { Fragment } from "react";
import CargoUrgentChangesTable from "../../../components/cargo/Components/CargoUrgentChangesTable";

import { PropsId } from "../../../_generated/_custom/models-cache-data/types";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { useLoadAddressHookNae } from '../../../_generated/_custom/hooks/useLoadAddressHookNae';
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";

export default function CargoManagerUrgentChanges(props: PropsId) {
  const element = useCargoHookNae(props.id);
  const calculation = useCalculationHookNae(
    element && element.calculation ? element.calculation.id : -1
  );
  const loadAddress1 = useLoadAddressHookNae(
    element && element.loadAddress ? element.loadAddress.id : -1
  );
  const loadAddress2 = useLoadAddressHookNae(
    element && element.loadAddressAdditional ? element.loadAddressAdditional.id : -1
  );
  const loadAddress3 = useLoadAddressHookNae(
    element && element.loadAddressAdditional2 ? element.loadAddressAdditional2.id : -1
  );

  const t = (s: string) => s;

  const [saveData] = useUSave("cargo");
  const doSave = (data: any) => {
    saveData(data, element.id);
  };

  if (!element) {
    return <Fragment />;
  }
  if (!calculation) {
    return <Fragment />;
  }

  let elementForChanges = JSON.parse(JSON.stringify(element));
  if (element.loadAddress && !!loadAddress1) {
    elementForChanges.loadAddress = loadAddress1;
  }
  if (element.loadAddressAdditional && !!loadAddress2) {
    elementForChanges.loadAddressAdditional = loadAddress2;
  }
  if (element.loadAddressAdditional2 && !!loadAddress3) {
    elementForChanges.loadAddressAdditional2 = loadAddress3;
  }

  const hasNotCalculatedCargo =
    calculation.status === 5 && !!element.beforeChange;
  const hasReturnedCargo = !!element.beforeChange;

  if (hasNotCalculatedCargo) {
    return (
      <Fragment>
        <WhiteCard isCompact={true} className={"border-2 border-orange-500"}>
          <p>{t("Sales order information was update.")}:</p>

          <CargoUrgentChangesTable id={props.id} />
        </WhiteCard>
      </Fragment>
    );
  } else if (hasReturnedCargo) {
    return (
      <Fragment>
        <WhiteCard isCompact={true} className={"border-2 border-orange-500"}>
          <p>{t("Sales order information was updated")}:</p>
          <CargoUrgentChangesTable id={props.id} />
          {element.status >= 5 && element.cargoCalculationCompleted && (
            <MainButton
              color="teal"
              onClick={() => doSave({ beforeChange: [] })}
              className={"align-left w-full"}
            >
              {t("I confirm the changes")}
            </MainButton>
          )}
        </WhiteCard>
      </Fragment>
    );
  }

  return <Fragment />;
}
