import React from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';

import InvoicePdfWithGenerate from './Components/InvoicePdfWithGenerate';

interface Props {
    fieldKey: string,
}

export default function Pdf(props: Props) {
    const t = (s: string) => s;

    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    return <InvoicePdfWithGenerate id={element.id}/>

}
