import React, { useState, Fragment } from "react";

import moment from "moment";
import KzOrderLogisticCargoLines from "../cargo/kz-order/KzOrderLogisticCargoLines";
import KzLoadDate from "../../../UserComponents/KzCarriersOrder/Components/KzLoadDate";
import KzCarrierLinkWithEye from '../../../UserComponents/KzCarriersOrder/Components/KzCarrierLinkWithEye';
import BadgeKzCarriersOrderArrivedAtLoad from '../../../_generated/_custom/models-cache-data/KzCarriersOrder/BadgeKzCarriersOrderArrivedAtLoad';
import BadgeKzCarriersOrderContactedSeller from "../../../_generated/_custom/models-cache-data/KzCarriersOrder/BadgeKzCarriersOrderContactedSeller";
import ActionKzCarriersOrderArrivedAtLoad from '../../../_generated/_custom/models-cache-data/KzCarriersOrder/ActionKzCarriersOrderArrivedAtLoad';
import KzCargoTerminals from "../../../UserComponents/KzCarriersOrder/Components/KzCargoTerminals";
import KzFtlCalculationsOrClient from '../../../UserComponents/KzCarriersOrder/Components/KzFtlCalculationsOrClient';
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserForwardingManager, checkUserHeadOfLogistics, checkUserOperational } from "../../../_generated/_custom/config/NaeSPermissions";
import KzOrderLocation from "../cargo/kz-order/KzOrderLocation";
import KzOrderLocationDate from "../cargo/kz-order/KzOrderLocationDate";
import KzOrderLocationHistory from "../cargo/kz-order/KzOrderLocationHistory";

interface IElement {
  id: number,
  loadDate: string,
}
interface Props {
  data: IElement[];
}

export default function KzLogisticOrderFormation(props: Props) {
  const t = (s: string) => s;
  const { userState } = useTemplatesCore();
  const isDps = checkUserOperational(userState);
  const isFm = checkUserForwardingManager(userState);
  const isCoo = checkUserHeadOfLogistics(userState);

  const [viewCargosId, setViewCargosId] = useState<number>(0);

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Processing")}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t('Number')}</Th>
                <Th>{t('Carrier')}</Th>
                <Th>{t('Cargo specifics')}</Th>
                <Th>{t("Loading")}</Th>
                <Th>{t('Route')}</Th>
                <Th>{t('Vehicle numbers')}</Th>
                <Th>{t('Planned loading date')}</Th>
                <Th className={"text-right"}>{t('Total CLM')}</Th>
                <Th className={"text-right"}>{t('Total CBM')}</Th>
                <Th className={"text-right"}>{t('Weight')}</Th>
                {isFm && <Th>{t('Vehicle location')}</Th>}
                <Th>{t('My notes')}</Th>
                {(isDps || isCoo) && <Th>{t('Vehicle location')}</Th>}
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {props.data.sort((a, b) => {
                const aDateStr = !!a.loadDate ? moment(a.loadDate).format("YYYY-MM-DD") : '';
                const bDateStr = !!b.loadDate ? moment(b.loadDate).format("YYYY-MM-DD") : '';

                var x = aDateStr.toLowerCase();
                var y = bDateStr.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
              }).map((o: IElement) => {
                return (
                  <Fragment key={"kz-o-" + o.id}>
                    <tr>
                      <Td>
                        <KzCarrierLinkWithEye id={o.id} viewCargosId={viewCargosId} setViewCargosId={setViewCargosId} />

                        <p><DfValueView path="kz-carriers-order.doer.fullName" id={o.id} /> (FWD)</p>
                        <p>---</p>
                        <p><DfValueView path="kz-carriers-order.userDps.fullName" id={o.id} /> (DPS)</p>

                        <BadgeKzCarriersOrderContactedSeller id={o.id} />

                        <BadgeKzCarriersOrderArrivedAtLoad id={o.id} />
                      </Td>
                      <Td>
                        <KzFtlCalculationsOrClient id={o.id} />
                      </Td>
                      <Td>
                        <DfValueView path="kz-carriers-order.cargoNotes" id={o.id} />
                      </Td>
                      <Td>
                        <KzCargoTerminals id={o.id} />
                      </Td>
                      <Td>
                        <DfValueView path="kz-carriers-order.trip" id={o.id} />
                      </Td>
                      <Td>
                        <DfValueView path="kz-carriers-order.carNumber" id={o.id} />
                      </Td>
                      <Td>
                        <KzLoadDate id={o.id} />

                        <div className="mt-2">
                          <ActionKzCarriersOrderArrivedAtLoad id={o.id} />
                        </div>
                      </Td>
                      <Td className={"text-right"}>
                        <DfValueView path="kz-carriers-order.allLdm" id={o.id} />
                      </Td>
                      <Td className={"text-right"}>
                        <DfValueView path="kz-carriers-order.allCbm" id={o.id} />
                      </Td>
                      <Td className={"text-right"}>
                        <DfValueView path="kz-carriers-order.allWeight" id={o.id} />
                      </Td>
                      {isFm && <Td>
                        <KzOrderLocation forceReadOnly={true} id={o.id} />
                        <KzOrderLocationDate id={o.id} />

                        <KzOrderLocationHistory id={o.id} />
                      </Td>}
                      <Td className={"w-[200px]"}>
                        {isDps && <DfValueView path="kz-carriers-order.dpsNotes" id={o.id} />}
                        {!isDps && <DfValueView path="kz-carriers-order.internalComment" id={o.id} />}
                      </Td>
                      {(isDps || isCoo) && <Td>
                        <KzOrderLocation allowDpsUpdate={true} id={o.id} />
                        <KzOrderLocationDate id={o.id} />

                        <KzOrderLocationHistory id={o.id} />
                      </Td>}
                    </tr>
                    {viewCargosId === o.id && (
                      <tr className={"bg-info-light"}>
                        <td colSpan={99}>
                          <KzOrderLogisticCargoLines id={o.id} />
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          }
        />
      </WhiteCard>

    </Fragment>
  );
}
