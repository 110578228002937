import React, { useEffect, useState } from 'react'
import { Button } from '@newageerp/crm-ui'
import { useUIBuilder } from '@newageerp/v3.app.mvc.ui-builder';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';

type Props = {
    sourceSchema: string,
    user: number
}
export default function BookmarksListButton({sourceSchema, user}: Props) {
    const { settings } = useUIBuilder();
    const [data, setData] = useState([]);

    const loadData = async() => {
        const res = await fetch(
            `${settings.addons.bookmarks.host}/list`,
            {
                method: 'POST',
                body: JSON.stringify({
                    sourceSchema,
                    user
                })
            }
        )
        const resJson = await res.json();
        setData(resJson.data.map((el: any) => el.source_id));
    }

    useEffect(() => {
        loadData().catch(console.error)
    }, []);

    // filter
    const _path = `${sourceSchema}-${user}-bookmarks`;

    const listDataSource = useListDataSource();
    const isActiveFilter = !!listDataSource.filter.extraFilter && !!listDataSource.filter.extraFilter[_path];

    const toggleFilter = () => {
        if (!isActiveFilter) {
            listDataSource.filter.addExtra(_path, {
                and: [['i.id', 'in', data, true]],
            });
        } else {
            listDataSource.filter.addExtra(_path, undefined);
        }
    }

    return (
        <Button.ToolbarButton 
            iconName='bookmark' 
            disabled={data.length === 0}
            onClick={toggleFilter}
            bgColor={isActiveFilter ? 'bg-teal-50' : undefined}
            textColor={isActiveFilter ? 'text-teal-700' : undefined}
        />
    )
}
