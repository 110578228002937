import React, { Fragment } from "react";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";

import { MenuItemWithEdit } from "@newageerp/v3.bundles.modal-bundle";

export default function SalesOrderManagerNotes(props: PropsId) {
  const element = useSalesOrderHookNae(props.id);

  const t = (s: string) => s;

  if (!element || element.status <= 0) {
    return <Fragment />
  }

  return (
    <MenuItemWithEdit
      elementId={props.id}
      schema={"sales-order"}
      forcePopup={true}
      type={"notes"}
      iconName="edit"
    >
      {t("Update notes")}
    </MenuItemWithEdit>

  )
}
