import React, { Fragment } from 'react'

import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';
import moment from 'moment'
import { useCargoHoldHookNae } from '../../_generated/_custom/hooks/useCargoHoldHookNae';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import classNames from 'classnames';

type Props = {
    mode?: 'direct' | 'multiple'
    mainId?: number
} & PropsId

export default function CargoManagerHold(props: Props) {
    const element = useCargoHookNae(props.id);
    const elementHold = useCargoHoldHookNae(element && element.cargoHold ? element.cargoHold.id : -1);

    const t = (s: string) => s;

    const [saveData, saveDataParams] = useUSave(NaeSSchemaMap.CargoHold.schema)
    const doSave = (data: any) => {
        if (saveDataParams.loading) return;
        return saveData(data, element.cargoHold.id);
    }

    if (!element) {
        return <Fragment />
    }

    let disabled = false;
    let tooltipContent = undefined;

    if (!!element.kzCarriersOrder) {
        disabled = true;
        tooltipContent = t("The cargo is planned for delivery. Adjustment is not possible");
    }

    if (!element.needTerminal) {
        return <Fragment />
    }
    if (!element.salesOrder) {
        return <Fragment />
    }
    if (element.status === 900) {
        return <Fragment />;
    }

    const isPostpone = !!elementHold && elementHold.status === 0;

    const onCreate = () => {
        // console.log({'a': element.cargoHold})
        if (element.cargoHold) {
            doSave({
                status: 0,
                startDate: moment().format('YYYY-MM-DD'),
                finishDate: null,
            }).then(() => {
                SFSOpenEditModalWindowProps({
                    id: element.cargoHold.id,
                    schema: NaeSSchemaMap.CargoHold.schema,
                })
            })
        } else {
            SFSOpenEditModalWindowProps({
                id: 'new',
                schema: NaeSSchemaMap.CargoHold.schema,
                options: {
                    createOptions: {
                        convert: {
                            element,
                            schema: "cargo"
                        }
                    }
                },
            })
        }
    }


    const onStop = () => {
        doSave({
            status: 10,
            finishDate: moment().format('YYYY-MM-DD')
        })
    }

    return (
        <Fragment>
            {isPostpone ?
                <MenuItem
                    isDisabled={disabled}
                    iconName={"play"}
                    iconLoading={saveDataParams.loading}
                    onClick={onStop}
                    tooltipContent={tooltipContent}
                    confirmation={true}
                    className={classNames(
                        {'font-medium': props.mainId === element.id}
                    )}
                >
                    {element.serialNumber}: {t("Allow delivery")}
                </MenuItem>
                :
                <MenuItem
                    isDisabled={disabled}
                    iconName={"pause"}
                    iconLoading={saveDataParams.loading}
                    className={classNames(
                        {'font-medium': props.mainId === element.id}
                    )}
                    onClick={onCreate}
                    tooltipContent={tooltipContent}
                    confirmation={{
                        children: <Fragment><p>{t('Your order will not be scheduled for shipment from the warehouse.')}</p><p>{t('When the order is ready for pickup, click \'Allow delivery\'')}</p></Fragment>,
                        onYes: () => { }
                    }}
                >
                    {element.serialNumber}: {t("Postpone delivery")}
                </MenuItem>
            }
        </Fragment>
    )
}
