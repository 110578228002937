import React, { Fragment, useState } from "react";

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { AlertWidget, WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { FieldLabel, FieldTextarea, Compact, CompactRow } from "@newageerp/v3.bundles.form-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { checkUserAdmin, checkUserChiefSalesOfficer, checkUserManager} from "../../../_generated/_custom/config/NaeSPermissions";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";


export default function SalesOrderConfirmOrder(props: PropsId) {
  const element = useSalesOrderHookNae(props.id);

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isManager = checkUserManager(userState);
  const isChief = checkUserChiefSalesOfficer(userState);

  const canConfirmOrder = isAdmin || isChief;
  const canConfirmOwnOrder = userState.scopes.indexOf("user-can-confirm-own-order") >= 0;

  const t = (s: string) => s;
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [returnText, setReturnText] = useState("");

  const [saveData, saveDataParams] = useUSave("sales-order");
  const doSave = (data: any) => {
    if (saveDataParams.loading) return;
    saveData(data, element.id);
  }

  if (!element) {
    return <Fragment />
  }
  if (element.status !== 5) {
    return <Fragment />
  }

  if (!(canConfirmOrder || (canConfirmOwnOrder && isManager))) {
    return <Fragment />
  }

  return (
    <Fragment>
      {showCancelForm ? (
        <WhiteCard isCompact={true} title={t("Order rejection")}>

          <Compact>
            <CompactRow
              label={<FieldLabel>{t('The reason')}</FieldLabel>}
              control={<FieldTextarea rows={2} className={"w-full"}
                value={returnText}
                onChange={(e) => setReturnText(e.target.value)} />}
            />
          </Compact>


          <div className={"flex gap-2"}>
            <MainButton
              color="blue"
              onClick={() =>
                doSave({
                  status: 0,
                  confirmCancelText: returnText,
                  addNote: {
                    content: "The order was rejected: " + returnText,
                    notify: [element.creator.id],
                  },
                })
              }
            >
              {t("Reject")}
            </MainButton>

            <MainButton
              color="slate"
              onClick={() => setShowCancelForm(false)}
            >
              {t("Cancel")}
            </MainButton>
          </div>

        </WhiteCard>
      ) : (
        <WhiteCard isCompact={true} title={t("Order confirmation")}>

          {!!element.confirmCancelText && (
            <AlertWidget isCompact={true} color={"warning"}>
              {t("The order was rejected")}: {element.confirmCancelText}
            </AlertWidget>
          )}

          <div className={"flex gap-2"}>

            <MainButton color="teal" className={"w-full"} iconName={"check"} loading={saveDataParams.loading} confirmation={true} onClick={() => doSave({ status: 10 })}>
              {t("Confirm")}
            </MainButton>

            <MainButton onClick={() => setShowCancelForm(true)} color={"amber"} className={"w-full"} iconName="times-hexagon">
              {t("Reject")}
            </MainButton>
          </div>
        </WhiteCard>
      )}
    </Fragment>
  );
}
