import React, { Fragment } from 'react'

import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import { SFSOpenEditModalWindowProps } from '@newageerp/v3.bundles.popup-bundle';

export default function CargoManagerTemperatureSettings(props: PropsId) {
    const element = useCargoHookNae(props.id);

    const onClick = () => {
        SFSOpenEditModalWindowProps({
            id: props.id,
            schema: "cargo",
            type: "manager-tempearature"
        })
    }

    const t = (s: string) => s;

    if (!element) {
        return <Fragment />
    }

    let disabled = false;
    let tooltipContent = undefined;

    if (!!element.kzCarriersOrder) {
        disabled = true;
        tooltipContent = t("Krovinys planuojamas išvežimui. Koregavimas neįmanomas");
    }
    if (element.status === 900) {
        return <Fragment />
    }

    return (
        <Fragment>
            <MenuItem
                isDisabled={disabled}
                iconName={"temperature-list"}
                onClick={onClick}
                tooltipContent={tooltipContent}
            >
                {t("Temperature mode settings")}
            </MenuItem>
        </Fragment>
    )
}
