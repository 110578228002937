import React, { Fragment, useEffect } from "react";
import ManagerCalculationCalculated from "./ComponentsCalculation/ManagerCalculationCalculated";
import ManagerCalculationDraft from "./ComponentsCalculation/ManagerCalculationDraft";
import ManagerCalculationHot from "./ComponentsCalculation/ManagerCalculationHot";
import ManagerCalculationWaiting from "./ComponentsCalculation/ManagerCalculationWaiting";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";


interface IData {
  id: number,
  status25LastChangeDiffDays: number,
  status10LastChangeDiffDays: number,
  status: number,
  salesOrder: IDataSo
}

interface IDataSo {
  id: number,
}

const defObject: IData = {
  id: 0,
  status25LastChangeDiffDays: 0,
  status10LastChangeDiffDays: 0,
  status: 0,
  salesOrder: { id: 0 }
}

let reloadTimeout: any = -1;

type Props = {
  userId: number,
}

export default function ManagerCalculationWrapper(props: Props) {
  const t = (s: string) => s;

  const { userState } = useTemplatesCore()

  const [schemaGetData, schemaGetDataParams] = useUList<IData>("calculation", getKeysFromObject(defObject));

  useEffect(() => {
    SocketService.subscribe("calculation-dashboard-" + userState.id);
    return () => {
      SocketService.unsubscribe("calculation-dashboard-" + userState.id);
    }
  }, [userState.id]);

  useEffect(() => {
    SocketService.addCallback(
      "calculation-reload-dashboard",
      "calculation-reload-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "calculation-reload-dashboard",
        "calculation-reload-dashboard"
      );
    };
  }, []);

  const loadData = () => {
    schemaGetData(
      [
        {
          "and": [
            ['i.creator', '=', props.userId, true],
            ['i.status', 'in', [0, 3, 5, 10, 25], true],
            ['i.salesOrder.id', 'IS_NULL', null, true]
          ]
        }
      ],
      1,
      500,
      [{
        key: "i.serialNumber",
        value: "ASC",
      }]
    )
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 500);
  };

  const data: IData[] = schemaGetDataParams.data.data;

  return (
    <Fragment>
      <div className={"space-y-4"}>
        <ManagerCalculationDraft
          data={data.filter((el: IData) => el.status === 0)}
        />
        <ManagerCalculationWaiting
          data={data.filter((el: IData) => el.status === 5 || el.status === 3)}
        />
        <ManagerCalculationCalculated
          data={data.filter((el: IData) => el.status === 10)}
        />
        <ManagerCalculationHot
          data={data.filter((el: IData) => el.status === 25 && !el.salesOrder)}
        />
        {/* <TabContainer
          items={[
            {
              content: <ManagerCalculationDraft
                data={data.filter((el: IData) => el.status === 0)}
              />,
              tab: {
                children: t('Quotations being prepared')
              }
            },
            {
              content: <ManagerCalculationWaiting
                data={data.filter((el: IData) => el.status === 5 || el.status === 3)}
              />,

              tab: {
                children: t('Waiting')
              }
            },
            {
              content: <ManagerCalculationCalculated
                data={data.filter((el: IData) => el.status === 10)}
              />,

              tab: {
                children: t('Calculated')
              }
            },
            {
              content: <ManagerCalculationHot
                data={data.filter((el: IData) => el.status === 25 && !el.salesOrder)}
              />,
              tab: {
                children: t('"Hot" quotations')
              }
            }
          ]}
        /> */}

      </div>
    </Fragment>
  );
}
