import React, { Fragment, useState } from "react";

import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { FieldTextarea } from "@newageerp/v3.bundles.form-bundle";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../../_generated/_custom/config/NaePaths";

type Props = {
  onCloseForm: () => void;
  transparent?: boolean;
  ids: number[]
}

export default function LogisticCargoReturnForm(props: Props) {
  const [returnText, setReturnText] = useState("");
  const t = (s: string) => s;

  const [saveMultiple, saveMultipleParams] = useURequest(NaePathsMap.post['NAEUSaveMultiple']);

  const onReturn = async () => {
    await saveMultiple({
      schema: "cargo",
      fieldsToReturn: ['id'],
      data: props.ids.map((id) => {
        return (
          {
            id: id,
            data: {
              status: 7,
              loadingInfoReturnText: returnText,
              addNote: {
                content: 'The cargo has returned. The reason for the return should be investigated.',
                notify: ['creator'],
              },
            }
          }
        )
      })
    })
    props.onCloseForm();

  };

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("Reason for return")}>

        <FieldTextarea
          className="w-full"
          rows={2}
          value={returnText}
          onChange={(e) => setReturnText(e.target.value)}
        />

        <div className={"flex gap-2"}>
          <MainButton color="sky" onClick={onReturn}>
            {t("Send")}
          </MainButton>
          <MainButton
            color="slate"
            onClick={() => {
              setReturnText("");
              props.onCloseForm();
            }}
          >
            {t("Cancel")}
          </MainButton>
        </div>
      </WhiteCard>
    </Fragment>
  );
}
