import React, { Fragment, useEffect, useState } from "react";
import KzLogisticOrderUnload from "./KzLogisticOrderUnload";
import KzLogisticOrderRoad from "./KzLogisticOrderRoad";
import KzLogisticOrderPlanned from "./KzLogisticOrderPlanned";
import KzLogisticOrderNew from "./KzLogisticOrderNew";
import KzLogisticOrderFormation from "./KzLogisticOrderFormation";

import KzToolbarComponent from '../../../UserComponents/KzCarriersOrder/Components/KzToolbarComponent';
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useQuery } from "../../../core/old-ui/OldTable";
import { checkUserHeadOfLogistics, checkUserAdmin } from '../../../_generated/_custom/config/NaeSPermissions';
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";


interface Props {
  full: boolean;
  query?: any;
  skipDirect?: boolean;
}

export interface IKzDashboardDataOrderCargo {
  id: number;
  badKzCarriersDate: boolean,
}

export interface IKzDashboardDataOrder {
  id: number;
  status: number;
  orderTerminals: number,
  loadDate: string,
  status20Date: string,
  cargos: IKzDashboardDataOrderCargo[],
}

const defObject: IKzDashboardDataOrder = {
  id: 0,
  status: 0,
  orderTerminals: 0,
  loadDate: '',
  status20Date: '',
  cargos: [
    {
      id: 0,
      badKzCarriersDate: false,
    }
  ]

};

let reloadTimeout: any = -1;

export default function KzLogisticOrdersWrapper(props: Props) {
  const t = (s: string) => s;

  let query = useQuery();
  const queryUserId = query.get('_u') ? query.get('_u') : undefined;

  const {userState} = useTemplatesCore()
  const [getData, getDataParams] = useUList<IKzDashboardDataOrder>(NaeSSchemaMap.KzCarriersOrder.schema, getKeysFromObject(defObject));

  const isHeadOfLogistics = checkUserHeadOfLogistics(userState);
  const isAdmin = checkUserAdmin(userState);

  const loadData = () => {
    let userFilters = [
      ['i.status', 'in', [0, 10, 15, 20, 30], true]
    ];
    if (queryUserId) {
      userFilters.push(
        ['i.creator', '=', queryUserId, true]
      );
    } else if (isHeadOfLogistics || isAdmin) {
      userFilters.push(
        ['i.transportType', 'in', [10, 50], true]
      );
    } else {
      userFilters.push(
        ['i.creator', '=', 'CURRENT_USER', true]
      );
    }

    const filters = props.query ? props.query : [
      {
        "and": userFilters
      }
    ];
    getData(
      filters,
      1,
      500,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        }
      ]
    )

  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 500);
  };

  useEffect(() => {
    SocketService.subscribe("kz-carriers-dashboard");
    return () => {
      SocketService.unsubscribe("kz-carriers-dashboard");
    };
  }, [userState.id]);

  useEffect(() => {
    SocketService.addCallback(
      "kz-carriers-dashboard",
      "kz-carriers-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "kz-carriers-dashboard",
        "kz-carriers-dashboard"
      );
    };
  }, []);


  const data: IKzDashboardDataOrder[] = getDataParams.data.data.filter((o: IKzDashboardDataOrder) => {
    if (props.skipDirect) {
      return o.orderTerminals > 0;
    }
    return true;
  });

  return (
    <Fragment>

      <div className={"space-y-2"}>
        {props.full &&
          <KzToolbarComponent title={t("Order for carrier (IK)")} data={getDataParams.data.data} />
        }

        {props.full && (
          <Fragment>

            <KzLogisticOrderUnload
              data={data.filter(
                (c: IKzDashboardDataOrder) => c.status === 30
              )}
            />



          </Fragment>
        )}

        <KzLogisticOrderRoad
          data={data.filter(
            (c: IKzDashboardDataOrder) => c.status === 20
          )}
        />

        <KzLogisticOrderFormation
          data={data.filter(
            (c: IKzDashboardDataOrder) => c.status === 15
          )}
        />


        <KzLogisticOrderPlanned
          data={data.filter(
            (c: IKzDashboardDataOrder) => c.status === 10
          )}
        />

        {/* {isLogisticsCoordinator &&
          <ForCalculationOrdersWrapper />
        } */}

        {props.full && (

          <KzLogisticOrderNew
            data={data.filter(
              (c: IKzDashboardDataOrder) => c.status === 0
            )}
          />

        )}

      </div>
    </Fragment>
  );
}
