import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoOutgoingDocumentsChangeWithFileCheck(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const t = (s: string) => s;
    
    if (!element) {
        return <Fragment />
    }

    return (
        <Fragment>
            {element.outgoingDocumentsChange === 10 ?
                <Fragment>
                    {"Yes"} {!!element.files['30'] ?
                        <StatusWidget color='teal' smallPadding={true}>
                            {t('Uploaded')}
                        </StatusWidget>
                        :
                        <StatusWidget color='red' smallPadding={true}>
                            {t('Not uploaded')}
                        </StatusWidget>
                    }
                </Fragment> :
                "No"}
        </Fragment>
    )
}
