import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoDirectCarBadge(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const t = (s: string) => s;
    
    if (!element) {
        return <Fragment />
    }
    if (element.needTerminal) {
        return <Fragment />
    }
    return (
        <StatusWidget color='red' smallPadding={true}>
            {t('Direct')}
        </StatusWidget>
    )
}
