import React, { Fragment, useEffect } from "react";


import { nl2p } from '@newageerp/v3.bundles.utils-bundle'
import NEWidgetFiles from "../../../core/components/widgets/NEWidgetFiles";
import { IKZWfData } from "../../../components/kz-carriers-order/KzCarriersOrderWorkflowWidget";
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { MainEditContentInline } from '@newageerp/v3.bundles.app-bundle'
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserLogisticTrain } from "../../../_generated/_custom/config/NaeSPermissions";
import { Table, Td, Th } from "@newageerp/v3.bundles.layout-bundle";
import {useNaeRecord} from '@newageerp/v3.app.mvc.record-provider'

interface IDataCargo {
  id: number;
  typeOut: string;
  hasTnved: boolean;
  tnvedCodesOperational: string;
  serialNumber: string;
}
interface IData {
  id: number;
  transportType: number;
  cargos: IDataCargo[];
}

const defObject: IData = {
  id: 0,
  transportType: 0,
  cargos: [
    {
      id: 0,
      typeOut: "",
      hasTnved: false,
      tnvedCodesOperational: "",
      serialNumber: "",
    },
  ],
};

interface Props {
  elementId: number;
}

export default function KzCarriersOrderCargoTnvedWidget(props: Props) {
  const element: IKZWfData = useNaeRecord().element;

  const { userState } = useTemplatesCore()
  const isAdmin = checkUserAdmin(userState);
  const isTrainLogistic = checkUserLogisticTrain(userState);

  const { elementId } = props;

  const t = (s: string) => s;

  if (!element) {
    return <Fragment />;
  }
  if (element.transportType !== 20) {
    return <Fragment />;
  }
  if (element.cargos.length === 0) {
    return <Fragment />;
  }
  if (!(isAdmin || isTrainLogistic)) {
    return <Fragment />;
  }

  const cargo = element.cargos[0];

  if (
    cargo.typeOut === "train" &&
    // element.allCargosOneUnload === 10 &&
    cargo.hasTnved
  ) {
    return (
      <Fragment>
        <WhiteCard isCompact={true}>
          <MainEditContentInline
            schema={"cargo"}
            type={"cargo-tnved-ik"}
            id={cargo.id.toString()}
            isCompact={true}
          />
        </WhiteCard>


        <WhiteCard isCompact={true} title={t("Corrected data")}>
          <Table
            thead={
              <thead>
                <tr>
                  <Th>{t("Cargo")}</Th>
                  <Th>{t("Codes")}</Th>
                </tr>
              </thead>
            }
            tbody={
              <tbody>
                {element.cargos.map((c) => {
                  return (
                    <tr key={"cargo-tnved-" + c.id}>
                      <Td>{c.serialNumber}</Td>
                      <Td>{nl2p(c.tnvedCodesOperational, "tnv-" + c.id)}</Td>
                    </tr>
                  );
                })}
              </tbody>
            }
          />

        </WhiteCard>

        <NEWidgetFiles
          schema={"cargo"}
          element={{ id: cargo.id }}
          options={{
            type: 500,
            title: "TNVED Failai",
            hideDeletedFiles: !isAdmin,
          }}
        />
      </Fragment>
    );
  }
  return <Fragment />;
}
