import React, { Fragment } from 'react'
import { PropsId } from '../types';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import OldBadge, { BadgeBgColor } from '../../../../core/old-ui/OldBadge';

export default function CargoType50Badge(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const t = (s: string) => s;
    
    if (!element) {
        return <Fragment />
    }
    if (element.typeIn !== 'none') {
        return <Fragment />
    }

    return (
        <OldBadge bgColor={BadgeBgColor.purple} brightness={100} size={"sm"}>
            {t("Seller delivers")}
        </OldBadge>
    )
}
