import React, { Fragment, useEffect } from "react";

import CargoLogisticLink from "../../../components/cargo/CargoLogisticLink";
import CargoDirectCarBadge from "../../../_generated/_custom/models-cache-data/Cargo/CargoDirectCarBadge";
import SalesOrderClientOrFilialName from "../../../_generated/_custom/models-cache-data/SalesOrder/SalesOrderClientOrFilialName";
import UserFullName from "../../../_generated/_custom/models-cache-data/User/UserFullName";
import CargoValueWithCurrency from "../../../_generated/_custom/models-cache-data/Cargo/CargoValueWithCurrency";
import CargoOutgoingDocumentsChangeWithFileCheck from "../../../_generated/_custom/models-cache-data/Cargo/CargoOutgoingDocumentsChangeWithFileCheck";
import { PropsId } from "../../../_generated/_custom/models-cache-data/types";

import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";

import { useCargoHookNae } from "../../../_generated/_custom/hooks/useCargoHookNae";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import CargoSellerDidEx from "../../../Plugins/Cargo/CargoSellerDidEx";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";
import { DfValueView } from "@newageerp/v3.bundles.app-bundle";
import CargoCargoCheckForDualUseBadge from "../../../_generated/badges/cargo/CargoCargoCheckForDualUseBadge";
import CargoCargoDocumentsCollectedBadge from "../../../_generated/badges/cargo/CargoCargoDocumentsCollectedBadge";
import CargoWarehouseExOrder from "../../../Plugins/Cargo/CargoWarehouseExOrder";

type Props = {
  userId: number
}

let reloadTimeout: any = -1;

export default function OperativeCargoNew(props: Props) {
  const t = (s: string) => s;

  const [getData, getDataParams] = useUList<PropsId>("cargo", ['id']);
  const loadData = () => {
    let filter: any = [
      ['i.operativeCardIndex', '=', 1, true],
      ['i.status', '!=', 900, true],
      ['i.operational', '=', props.userId, true]
    ];

    getData(
      [{ "and": filter }],
      1,
      200,
      [
        { key: 'i.serialNumber', 'value': 'ASC' }
      ]
    )
  }
  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(loadData, 500);
  };

  useEffect(() => {
    SocketService.addCallback(
      "cargo-operativeCardIndex-1",
      "cargo-operativeCardIndex-1",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "cargo-operativeCardIndex-1",
        "cargo-operativeCardIndex-1"
      );
    };
  }, []);

  return (
    <Fragment>
      <WhiteCard isCompact={true} title={t("New cargo")}>
        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Cargo")}</Th>
                <Th>{t("Sales order")}</Th>
                <Th>{t("Seller")}</Th>
                <Th>{t("Consignee")}</Th>
                <Th>{t("Transport operations manager")}</Th>
                <Th>{t("Sales manager")}</Th>
                <Th>{t('Who will prepare EX?')}</Th>
                <Th>{t("Will the outgoing documents change?")}</Th>
                <Th>{t('Dual use')}</Th>
                <Th>{t('Documents collected')}</Th>
                <Th>{t("Cargo value")}</Th>
              </tr>
            </thead>
          }
          tbody={
            <tbody>
              {getDataParams.data.data.map((el: PropsId, index: number) => {
                return <OperativeCargoNewLine key={`cargo-${el.id}`} id={el.id} />;
              })}
            </tbody>
          }
        />
      </WhiteCard>
    </Fragment>
  );
}

const OperativeCargoNewLine = (props: PropsId) => {
  const element = useCargoHookNae(props.id);
  if (!element) {
    return <Fragment />;
  }
  return (
    <tr>
      <Td>
        <CargoLogisticLink id={element.id} />
        <CargoDirectCarBadge id={element.id} />
      </Td>
      <Td><DfValueView path="cargo.salesOrder.serialNumber" link={true} id={element.id} /></Td>
      <Td>{element.seller && <DfValueView path="sender.name" link={true} id={element.seller.id} />}</Td>
      <Td>
        {element.salesOrder && (
          <SalesOrderClientOrFilialName link={true} id={element.salesOrder.id} />
        )}
      </Td>
      <Td>
        {!!element.expeditor && <UserFullName id={element.expeditor.id} />}
      </Td>
      <Td>{!!element.creator && <UserFullName id={element.creator.id} />}</Td>
      <Td>
        <CargoSellerDidEx id={element.id} />
        <CargoWarehouseExOrder id={element.id}/>
      </Td>
      <Td>
        <CargoOutgoingDocumentsChangeWithFileCheck id={element.id} />
      </Td>
      <Td>
        <CargoCargoCheckForDualUseBadge id={element.id} isCompact={true} />
      </Td>
      <Td>
        <CargoCargoDocumentsCollectedBadge id={element.id} isCompact={true} />
      </Td>
      <Td>
        <CargoValueWithCurrency id={element.id} />
      </Td>
    </tr>
  );
};
