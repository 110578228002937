import React, { Fragment } from 'react'
import { PropsId } from '../types';
import UserFullName from '../User/UserFullName';
import { useCargoHookNae } from '../../hooks/useCargoHookNae';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

export default function CargoNeedSpecificationBadge(props: PropsId) {
    const element = useCargoHookNae(props.id);
    const t = (s: string) => s;
    
    if (!element) {
        return <Fragment />
    }
    const isWaiting = element.minimumSpecificationStatus >= 0 && element.minimumSpecificationStatus < 30;

    if (!isWaiting) {
        return <Fragment />
    }
    return (
        <StatusWidget color='blue'>
            {t("Waiting for specification")}<br/>{t("from")} {!!element.operational && <UserFullName id={element.operational.id} />}
        </StatusWidget>
    )
}
