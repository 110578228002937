import React, { useState, Fragment, useEffect } from "react";
import moment from "moment";

import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from "../../../_generated/_custom/config/NaeSSchema";
import { useCargoHookNae } from '../../../_generated/_custom/hooks/useCargoHookNae';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { checkUserAdmin, checkUserLogisticFtl } from "../../../_generated/_custom/config/NaeSPermissions";
import { useUSave } from "@newageerp/v3.bundles.hooks-bundle";

interface Props extends PropsId {
  sm?: boolean;
  extraTitle?: string,
}

export default function CargoLoadBtn(props: Props) {
  const element = useCargoHookNae(props.id);

  const { userState } = useTemplatesCore()

  const isLogisticFtl = checkUserLogisticFtl(userState);
  const isAdmin = checkUserAdmin(userState);

  const t = (s: string) => s;

  const [saveData] = useUSave("cargo");
  const doSave = (data: any) => {
    saveData(data, element.id);
  }


  const showSendEmail = () => {
    const options = {
      extraData: {
        id: element.id,
        schema: "cargo",
        template: "cargo-loaded"
      },

      onSend: () => {
        doSave({
          status: 10,
          loadDateSellerFact: moment().format("YYYY-MM-DD"),
          _events: [
            'evt.userpoints.onCargoLoad'
          ]
        });
      }
    };
    const event = new CustomEvent(
      'SFSOpenEmailForm',
      {
        detail: options
      }
    );
    window.dispatchEvent(event);
  }

  if (!element) {
    return <Fragment />
  }
  const isLogistic = ((!!element.expeditor && userState.id === element.expeditor.id) || (element.typeIn === 'ftl' && isLogisticFtl) || isAdmin);

  if (!(element.status === 5 && !!element.salesOrder && isLogistic && (element.logisticCardIndex === 3 || element.logisticCardIndex === 4))) {
    return <Fragment />
  }

  return (
    <MainButton
      confirmation={true}
      onClick={showSendEmail}
      className={"whitespace-nowrap w-full"}
      iconName="truck-loading"
      color="teal"
    >
      {`${t("Loaded")}${props.extraTitle ? ` ${props.extraTitle}` : ''}`}
    </MainButton>
  );
}
