import React, { Fragment, useState } from 'react'

import CargoDocumentsFilesList from '../../../../UserComponents/KzCarriersOrder/DocumentsPopup/CargoDocumentsFilesList';
import { getDocumentsPopupFiles } from './CargoIncomingList';
import MultipleFilesList from '../../../../UserComponents/KzCarriersOrder/DocumentsPopup/MultipleFilesList';
import DocumentsPopupFileItem from '../../../../UserComponents/KzCarriersOrder/DocumentsPopup/DocumentsPopupFileItem';
import { NaeSSchemaMap } from '../../../../_generated/_custom/config/NaeSSchema';
import { PropsId } from '../../../../_generated/_custom/models-cache-data/types';
import { useCargoHookNae } from '../../../../_generated/_custom/hooks/useCargoHookNae';
import { AlertWidget, StatusWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { MainButton, MainButtonWithSave } from '@newageerp/v3.bundles.buttons-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { useCargoOperationConfirmationHookNae } from '../../../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae';

interface Props extends PropsId {
    localPath: string,
    onSelect: (f: any) => void,
    onClose: () => void
}


export default function CargoStockList(props: Props) {
    const { localPath, onSelect, onClose } = props;

    const cargo = useCargoHookNae(props.id);
    const cargoConfirm = useCargoOperationConfirmationHookNae(cargo && cargo.operationConfirmation ? cargo.operationConfirmation.id : -1)

    const [checkedFiles, setCheckedFiles] = useState<any[]>(cargoConfirm.filesToStock);
    const onCheck = (f: any) => {
        if (checkedFiles.indexOf(f) >= 0) {
            setCheckedFiles([...checkedFiles.filter(_f => _f.id !== f.id)]);
        } else {
            setCheckedFiles([...checkedFiles, f]);
        }
    }

    const onCheckMultiple = (f: any[]) => {
        if (f.length > 0) {
            setCheckedFiles([...checkedFiles, ...f]);
        }
    }

    const [saveData, saveDataParams] = useUSave("cargo");

    const doSave = (data: any) => {
        return saveData(data, cargo.id);
    };

    const t = (s: string) => s;

    if (!cargo) {
        return <Fragment />
    }

    const fileWidgets = getDocumentsPopupFiles(cargo);

    return (
        <Fragment>
            <WhiteCard isCompact={true}>
                <div className={"space-y-2"}>
                    {stockFiles.map((el: any, fW: number) => {
                        return (
                            <CargoDocumentsFilesList
                                element={cargo}
                                schema={"cargo"}
                                options={el}
                                onSelect={onSelect}
                                activePath={localPath}
                                title={el.title}
                                key={"files-" + fW}
                            />
                        );
                    })}

                </div>
                {/* <ConfirmIncomingDocs cargo={cargo} onClose={onClose} /> */}

            </WhiteCard>
            <WhiteCard isCompact={true} title={t('Documents for the warehouse')}>

                <div className={"space-y-2"}>
                    <MultipleFilesList
                        options={fileWidgets}
                        schema={"cargo"}
                        elementId={cargo.id}
                        onSelect={onCheck}
                        activePath={localPath}

                        showCheck={true}
                        checkedFiles={checkedFiles}
                        onCheckMultiple={onCheckMultiple}
                    />

                    <CargoDocumentsFilesList
                        element={cargo}
                        schema={"cargo"}
                        options={{ type: 'stock-extra-docs', title: "Additional documents for the warehouse", }}
                        onSelect={onCheck}
                        activePath={localPath}
                        title={t('Additional documents for the warehouse')}
                        showCheck={true}
                        checkedFiles={checkedFiles}
                    />

                    {cargo.senderMakeEx === 20 && (
                        <Fragment>
                            <div className={"text-base space-y-1"}>
                                <p className={"font-medium text-sm"}>{t('Authorizations')}</p>
                                {cargo.sellerJson.attorneySent ?
                                    <StatusWidget color='teal'>{t('Authorization was sent')}</StatusWidget> :
                                    <StatusWidget color='pink'>{t('Authorization was not sent')}</StatusWidget>}

                                {!!cargo.sellerJson.attorneyFile ?
                                    <DocumentsPopupFileItem
                                        checked={checkedFiles.filter(_f => _f.id === cargo.sellerJson.attorneyFile.id).length > 0}
                                        f={cargo.sellerJson.attorneyFile}
                                        onClick={() => onCheck(cargo.sellerJson.attorneyFile)}
                                        showCheck={true} />
                                    :
                                    <AlertWidget color='danger' isCompact={true}>
                                        {t('The power of attorney is not attached')}
                                    </AlertWidget>}

                            </div>

                        </Fragment>
                    )}
                </div>

                <MainButtonWithSave
                    elementId={cargoConfirm.id}
                    schema={NaeSSchemaMap.CargoOperationConfirmation.schema}
                    saveData={{

                        filesToStock: checkedFiles,
                        dpsProConfirmed: true,
                    }}
                    onSaveCallback={onClose}
                    iconName={"check"}
                    className={"flex-grow w-full"}
                    loading={saveDataParams.loading}
                    color='sky'
                >
                    {t('Confirm stock files')}
                </MainButtonWithSave>

            </WhiteCard>

        </Fragment>
    )
}

const stockFiles = [
    {
        type: 'stock-docs',
        title: "Warehouse documents",
        className: "bg-amber-100",
    }
]
