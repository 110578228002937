import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "accounting-discrepancies",
        "key": "carriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "Carriers order (PO)",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "confirmed",
        "type": "boolean",
        "format": "",
        "title": "Confirmed",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created at",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Carriers order (KZ)",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "Reason",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "accounting-discrepancies",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "AccountingDiscrepancies",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "adr-class",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdrClass",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "adr-class",
        "key": "internalPoints",
        "type": "number",
        "format": "float",
        "title": "Internal points",
        "additionalProperties": [],
        "description": "",
        "className": "AdrClass",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "adr-class",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "AdrClass",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "adr-class",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "AdrClass",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "attorney",
        "key": "dueDate",
        "type": "string",
        "format": "date",
        "title": "Due date",
        "additionalProperties": [],
        "description": "",
        "className": "Attorney",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "attorney",
        "key": "file",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "file"
            }
        ],
        "description": "",
        "className": "Attorney",
        "isDb": true,
        "dbType": "json",
        "as": "file",
        "naeType": "file"
    },
    {
        "schema": "attorney",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Attorney",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "attorney",
        "key": "sender",
        "type": "rel",
        "format": "sender",
        "title": "Seller",
        "additionalProperties": [],
        "description": "",
        "className": "Attorney",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "attorney",
        "key": "sentToTerminal",
        "type": "boolean",
        "format": "",
        "title": "Sent to terminal",
        "additionalProperties": [],
        "description": "",
        "className": "Attorney",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "attorney",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "Attorney",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bank",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "accountNumberEur",
        "type": "string",
        "format": "",
        "title": "Account number EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "accountNumberUsd",
        "type": "string",
        "format": "",
        "title": "Account number USD",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "bank",
        "key": "fvsType",
        "type": "string",
        "format": "",
        "title": "FVS type",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "fvsZurnalas",
        "type": "string",
        "format": "",
        "title": "FVS journal",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bank",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "swift",
        "type": "string",
        "format": "",
        "title": "SWIFT",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "base-entity",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "base-entity",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "base-entity",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "base-entity",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "base-entity",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bookmark",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bookmark",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bookmark",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bookmark",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bookmark",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "bookmark",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "calculation",
        "key": "addNote",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "calculation",
        "key": "address",
        "type": "rel",
        "format": "unload-address",
        "title": "Unloading",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "allCargosOneUnload",
        "type": "integer",
        "format": "",
        "title": "How you want your cargo reach the client?",
        "additionalProperties": [],
        "description": "<p>Choose wanted transportation principle. All clients cargos will be transported with other client cargos, or client will receive all his cargos in his private vehicle.<\/p>",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "How partial cargo with other clients cargos ",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "In a separate vehicle without other clients cargos",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "calculation",
        "key": "calcedPurpose",
        "type": "integer",
        "format": "",
        "title": "Calculated purpose (data unknown)",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "calculation",
        "key": "canSeparateCargos",
        "type": "integer",
        "format": "",
        "title": "Can cargo leave in a different truck?",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "calculation",
        "key": "cargo",
        "type": "string",
        "format": "",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "cargoRailway",
        "type": "rel",
        "format": "cargo-railway",
        "title": "Railway",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "cargoRelType",
        "type": "integer",
        "format": "",
        "title": "Transportation type",
        "additionalProperties": [],
        "description": "<p>Carriage by road (LTL \/ Partial cargo) - partial cargo carriage by road.<\/p><p>Carriage by road (FTL \/ Full cargo) - full cargo carriage by road.<\/p><p>Air - cargos air shipment.<\/p><p>Multimodal - cargo carriage being processed with more than one type of transport.<\/p><p>Seller delivers to our warehouse - seller provides cargo to our warehouse.<\/p>",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Carriage by road (LTL \/ Partial cargo)",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo)",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Air",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Multimodal",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Seller provides",
                "value": 50,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "calculation",
        "key": "cargoSellers",
        "type": "string",
        "format": "",
        "title": "Seller",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "cargos",
        "type": "array",
        "format": "cargo",
        "title": "Cargos",
        "additionalProperties": [
            {
                "mapped": "calculation"
            }
        ],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "calculation",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "clientFilial",
        "type": "rel",
        "format": "client-filial",
        "title": "Alternative name",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "<p>Choose contact, that will receive offer.<\/p>",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "convertedToOrders",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "calculation",
        "key": "copyFrom",
        "type": "rel",
        "format": "calculation",
        "title": "CopyFrom",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "copyFromNumber",
        "type": "string",
        "format": "",
        "title": "Get the value of copyFromNumber",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "cost",
        "type": "string",
        "format": "text",
        "title": "Self cost",
        "additionalProperties": [
            {
                "showFull": 1
            },
            {
                "markdown": true
            }
        ],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "calculation",
        "key": "costHistoryRecords",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "calculation",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "calculation",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "deliveryTerms",
        "type": "string",
        "format": "text",
        "title": "Delivery Terms",
        "additionalProperties": [],
        "description": "<p>Delivery terms will be showed in commercial offer.<\/p>",
        "className": "Calculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "calculation",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "expeditor",
        "type": "string",
        "format": "",
        "title": "Expeditor",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "fillErrors",
        "type": "array",
        "format": "string",
        "title": "Get the value of fillErrors",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "calculation",
        "key": "hasCost",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "calculation",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "calculation",
        "key": "lastStatusChange",
        "type": "string",
        "format": "",
        "title": "Last status change",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "matchFinalPrice",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "calculation",
        "key": "matchMinimalMargin",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "calculation",
        "key": "matchSelfCost",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "calculation",
        "key": "notes",
        "type": "array",
        "format": "note",
        "title": "Notifications",
        "additionalProperties": [
            {
                "key": "filterSkip"
            },
            {
                "mapped": "calculation"
            }
        ],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "calculation",
        "key": "notifications",
        "type": "array",
        "format": "notification",
        "title": "Notifications",
        "additionalProperties": [
            {
                "mapped": "calculation"
            },
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "calculation",
        "key": "pdfFileName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "plannedCost",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "calculation",
        "key": "purpose",
        "type": "integer",
        "format": "",
        "title": "Purpose",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Commercial offer",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Order execution",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Order execution (data unknown)",
                "value": 30,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "calculation",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Get the value of salesOrder",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "calculation",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "calculation",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "calculation",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "calculation",
        "key": "status10LastChangeDiffDays",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "calculation",
        "key": "status25LastChangeDiffDays",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "calculation",
        "key": "status5LastChange",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "calculation",
        "key": "statusChanges",
        "type": "array",
        "format": "string",
        "title": "Status changes",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "calculation",
        "key": "statusComment",
        "type": "string",
        "format": "text",
        "title": "Status comment",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "calculation",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "calculation",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "Calculation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "calculation-cost-history",
        "key": "calculation",
        "type": "rel",
        "format": "calculation",
        "title": "Quotation",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation-cost-history",
        "key": "cost",
        "type": "string",
        "format": "text",
        "title": "Self cost",
        "additionalProperties": [
            {
                "showFull": 1
            }
        ],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "calculation-cost-history",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "calculation-cost-history",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation-cost-history",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "calculation-cost-history",
        "key": "extraData",
        "type": "array",
        "format": "string",
        "title": "ExtraData",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "calculation-cost-history",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "calculation-cost-history",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "calculation-cost-history",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "CalculationCostHistory",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "call-log",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "call-log",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "call-log",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "call-log",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "call-log",
        "key": "duration",
        "type": "integer",
        "format": "",
        "title": "Duration",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "call-log",
        "key": "fromNumber",
        "type": "string",
        "format": "",
        "title": "Get the value of fromNumber",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "call-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "call-log",
        "key": "recordUrl",
        "type": "string",
        "format": "",
        "title": "Call recording",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "call-log",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "call-log",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "Get the value of sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "call-log",
        "key": "stopReason",
        "type": "string",
        "format": "",
        "title": "Get the value of stopReason",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "call-log",
        "key": "toNumber",
        "type": "string",
        "format": "",
        "title": "Called number",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "call-log",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "CallLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "car-service-act",
        "key": "car",
        "type": "rel",
        "format": "car-service-client-car",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act",
        "key": "client",
        "type": "rel",
        "format": "car-service-client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act",
        "key": "contact",
        "type": "rel",
        "format": "car-service-client-contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "car-service-act",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Data",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-act",
        "key": "debtComment",
        "type": "string",
        "format": "text",
        "title": "Debt comment",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "car-service-act",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act",
        "key": "extraProducts",
        "type": "string",
        "format": "",
        "title": "Extra materials",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-act",
        "key": "extraProductsPrice",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-act",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-act",
        "key": "invoiceNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-act",
        "key": "invoicePayToDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-act",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-act",
        "key": "payments",
        "type": "array",
        "format": "car-service-payment",
        "title": "Get the value of payments",
        "additionalProperties": [
            {
                "mapped": "carServiceAct"
            }
        ],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-act",
        "key": "products",
        "type": "array",
        "format": "car-service-act-product",
        "title": "Products",
        "additionalProperties": [
            {
                "mapped": "carServiceAct"
            }
        ],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-act",
        "key": "profit",
        "type": "number",
        "format": "float",
        "title": "Profit",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-act",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-act",
        "key": "services",
        "type": "array",
        "format": "car-service-act-service",
        "title": "Services",
        "additionalProperties": [
            {
                "mapped": "carServiceAct"
            }
        ],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-act",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "car-service-act",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "car-service-act",
        "key": "stockItems",
        "type": "array",
        "format": "car-service-stock-item",
        "title": "Get the value of stockItems",
        "additionalProperties": [
            {
                "mapped": "carServiceAct"
            }
        ],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-act",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalCost",
        "type": "number",
        "format": "float",
        "title": "Get the value of totalCost",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalDebt",
        "type": "number",
        "format": "float",
        "title": "Debt, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalProducts",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalProductsRaw",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalRaw",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalServices",
        "type": "number",
        "format": "float",
        "title": "Works",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalServicesRaw",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "VAT amount",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "totalWVat",
        "type": "number",
        "format": "float",
        "title": "Amount with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-act",
        "key": "truck",
        "type": "string",
        "format": "",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-act",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "car-service-act",
        "key": "vatPercent",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "car-service-act",
        "key": "workList",
        "type": "string",
        "format": "text",
        "title": "Work list",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceAct",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "car-service-act-product",
        "key": "carServiceAct",
        "type": "rel",
        "format": "car-service-act",
        "title": "Service act",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act-product",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-act-product",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActProduct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act-product",
        "key": "product",
        "type": "rel",
        "format": "car-service-product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act-product",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActProduct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act-product",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-act-product",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-act-service",
        "key": "carServiceAct",
        "type": "rel",
        "format": "car-service-act",
        "title": "Service act",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActService",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act-service",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActService",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-act-service",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActService",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act-service",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActService",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-act-service",
        "key": "service",
        "type": "rel",
        "format": "car-service-service",
        "title": "Service",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActService",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-act-service",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceActService",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-client",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClient",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "car-service-client",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClient",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "car-service-client",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Code",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClient",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CarServiceClient",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "car-service-client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClient",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-client",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClient",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClient",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-car",
        "key": "client",
        "type": "rel",
        "format": "car-service-client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientCar",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-client-car",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientCar",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-car",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientCar",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-client-car",
        "key": "number",
        "type": "string",
        "format": "",
        "title": "Registration number",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientCar",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-car",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Model",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientCar",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-car",
        "key": "year",
        "type": "string",
        "format": "",
        "title": "Year of production",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientCar",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-contact",
        "key": "client",
        "type": "rel",
        "format": "car-service-client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-client-contact",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-contact",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-client-contact",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-contact",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-contact",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-client-contact",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-client-contact",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone number",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-client-contact",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceClientContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "payToDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "products",
        "type": "array",
        "format": "car-service-invoice-incoming-product",
        "title": "Products",
        "additionalProperties": [
            {
                "mapped": "carServiceInvoiceIncoming"
            }
        ],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "supplier",
        "type": "rel",
        "format": "car-service-supplier",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "VAT amount",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-incoming",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Amount with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "carServiceInvoiceIncoming",
        "type": "rel",
        "format": "car-service-invoice-incoming",
        "title": "Invoice - incoming",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "product",
        "type": "rel",
        "format": "car-service-product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "quantity",
        "type": "integer",
        "format": "",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-incoming-product",
        "key": "type",
        "type": "integer",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceIncomingProduct",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Reserves",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Raw materials",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Tools",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "car-service-invoice-outgoing",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-outgoing-product",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceOutgoingProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-outgoing-service",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceOutgoingService",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Get the value of date",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "payToDate",
        "type": "string",
        "format": "date",
        "title": "Get the value of payToDate",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "products",
        "type": "array",
        "format": "car-service-invoice-return-product",
        "title": "Get the value of products",
        "additionalProperties": [
            {
                "mapped": "carServiceInvoiceReturn"
            }
        ],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Get the value of serialNumber",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Get the value of status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "supplier",
        "type": "rel",
        "format": "car-service-supplier",
        "title": "Get the value of supplier",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Get the value of total",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "VAT amount",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-return",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Get the value of total",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturn",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-return-product",
        "key": "carServiceInvoiceReturn",
        "type": "rel",
        "format": "car-service-invoice-return",
        "title": "Get the value of carServiceInvoiceReturn",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturnProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-invoice-return-product",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturnProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-return-product",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Get the value of price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturnProduct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-return-product",
        "key": "product",
        "type": "rel",
        "format": "car-service-product",
        "title": "Get the value of product",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturnProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-invoice-return-product",
        "key": "quantity",
        "type": "integer",
        "format": "",
        "title": "Get the value of quantity",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturnProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-invoice-return-product",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturnProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-invoice-return-product",
        "key": "type",
        "type": "integer",
        "format": "",
        "title": "Get the value of type",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceInvoiceReturnProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-payment",
        "key": "carServiceAct",
        "type": "rel",
        "format": "car-service-act",
        "title": "Service act",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-payment",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "car-service-payment",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Get the value of currencyRate",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-payment",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-payment",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "car-service-payment",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-payment",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServicePayment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-product",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "car-service-product",
        "key": "allBarcodes",
        "type": "string",
        "format": "",
        "title": "Barcodes",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "car-service-product",
        "key": "barcodes",
        "type": "array",
        "format": "car-service-product-barcode",
        "title": "Get the value of barcodes",
        "additionalProperties": [
            {
                "mapped": "product"
            }
        ],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-product",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Code",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-product",
        "key": "costList",
        "type": "rel",
        "format": "car-service-product-cost-list",
        "title": "Markup",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-product",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "car-service-product",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-product",
        "key": "productGroup",
        "type": "rel",
        "format": "car-service-product-group",
        "title": "Group",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-product",
        "key": "reservedCount",
        "type": "number",
        "format": "float",
        "title": "Reserved",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-product",
        "key": "serviceActProducts",
        "type": "array",
        "format": "car-service-act-product",
        "title": "Get the value of serviceActProducts",
        "additionalProperties": [
            {
                "mapped": "product"
            }
        ],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "car-service-product",
        "key": "stockMaxCost",
        "type": "number",
        "format": "float",
        "title": "Warehouse self cost",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-product",
        "key": "stockQuantity",
        "type": "number",
        "format": "float",
        "title": "Warehouse inventory",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-product",
        "key": "stockRecommendedPrice",
        "type": "number",
        "format": "float",
        "title": "Sell price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-product",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProduct",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-product-barcode",
        "key": "barcode",
        "type": "string",
        "format": "",
        "title": "Barcode",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductBarcode",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-product-barcode",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Suppliers code",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductBarcode",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-product-barcode",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductBarcode",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-product-barcode",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Suppliers product name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductBarcode",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-product-barcode",
        "key": "product",
        "type": "rel",
        "format": "car-service-product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductBarcode",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-product-barcode",
        "key": "supplier",
        "type": "rel",
        "format": "car-service-supplier",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductBarcode",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-product-cost-list",
        "key": "costOver100",
        "type": "number",
        "format": "float",
        "title": "100 EUR +",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductCostList",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-product-cost-list",
        "key": "costTo10",
        "type": "number",
        "format": "float",
        "title": "0 - 10 EUR",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductCostList",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-product-cost-list",
        "key": "costTo100",
        "type": "number",
        "format": "float",
        "title": "50 - 100 EUR",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductCostList",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-product-cost-list",
        "key": "costTo50",
        "type": "number",
        "format": "float",
        "title": "10 - 50 EUR",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductCostList",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-product-cost-list",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductCostList",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-product-cost-list",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductCostList",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-product-group",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Code",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductGroup",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-product-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductGroup",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-product-group",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceProductGroup",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-service",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Code",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceService",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-service",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceService",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-service",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceService",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-service",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceService",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-stock-item",
        "key": "carServiceAct",
        "type": "rel",
        "format": "car-service-act",
        "title": "Service act",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-stock-item",
        "key": "carServiceInvoiceIncoming",
        "type": "rel",
        "format": "car-service-invoice-incoming",
        "title": "Invoice incoming",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-stock-item",
        "key": "carServiceProduct",
        "type": "rel",
        "format": "car-service-product",
        "title": "Product",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "car-service-stock-item",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "Self cost",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-stock-item",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "car-service-stock-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-stock-item",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "car-service-stock-item",
        "key": "profit",
        "type": "number",
        "format": "float",
        "title": "Profit",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceStockItem",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "car-service-supplier",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplier",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Code",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplier",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplier",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "car-service-supplier",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplier",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-supplier",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplier",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplier",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone number",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "car-service-supplier-contact",
        "key": "supplier",
        "type": "rel",
        "format": "car-service-supplier",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "CarServiceSupplierContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "activeCargoLoadingCalculations",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "activeCargoLoadingCalculationsOut",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "activeCarriersOrdersJson",
        "type": "array",
        "format": "string",
        "title": "ICargoCarriersOrder",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "activePoOrders",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "addNote",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "adrClass",
        "type": "rel",
        "format": "adr-class",
        "title": "ADR class",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "alcoSubType",
        "type": "integer",
        "format": "",
        "title": "How strong is alcohol?",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Unknown",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Light alco (up to 17%) ",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Strong alco (up from 18%)",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "allCargoPlaces",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "allCargoWeight",
        "type": "number",
        "format": "float",
        "title": "Weight",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "aviaGoodsLoadingType",
        "type": "integer",
        "format": "",
        "title": "Goods loading type for air transportation",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Non stackable",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Stackable",
                "value": 20,
                "color": "teal"
            },
            {
                "label": "Tiltable",
                "value": 30,
                "color": "teal"
            },
            {
                "label": "Stackable & tiltable",
                "value": 40,
                "color": "teal"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "badKzCarriersDate",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "badges",
        "type": "array",
        "format": "string",
        "title": "Badges",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "beforeChange",
        "type": "array",
        "format": "string",
        "title": "Changes",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "calculatedStatus",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "cargo",
        "key": "calculation",
        "type": "rel",
        "format": "calculation",
        "title": "Quotation",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "calculationJson",
        "type": "array",
        "format": "string",
        "title": "ICargoCalculation",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "calculationPoints",
        "type": "number",
        "format": "float",
        "title": "Quotation points",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "cancelPoOrder",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "cancelSoOrder",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "cargoCalculationCompleted",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "cargoCheckForDualUse",
        "type": "integer",
        "format": "",
        "title": "Get the value of cargoCheckForDualUse",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Clean Cargo",
                "value": 1,
                "color": "green"
            },
            {
                "label": "Non Dual Use",
                "value": 0,
                "color": "green"
            },
            {
                "label": "Dual Use LT",
                "value": 10,
                "color": "orange"
            },
            {
                "label": "Dual Use LV",
                "value": 20,
                "color": "red"
            },
            {
                "label": "not checked for DU",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "7KP",
                "value": 40,
                "color": "amber"
            },
            {
                "label": "Manufacturers declaration",
                "value": 50,
                "color": "amber"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "cargoDescription",
        "type": "rel",
        "format": "cargo-description",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "cargoDocumentsCollected",
        "type": "integer",
        "format": "",
        "title": "Get the value of cargoDocumentsCollected",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Documents were not collected",
                "value": 0,
                "color": "red"
            },
            {
                "label": "Documents were collected",
                "value": 10,
                "color": "green"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "cargoExOrder",
        "type": "rel",
        "format": "cargo-ex-order",
        "title": "Get the value of cargoExOrder",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "cargoHold",
        "type": "rel",
        "format": "cargo-hold",
        "title": "Postpone delivery",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "cargoInsurance",
        "type": "rel",
        "format": "cargo-insurance",
        "title": "Insurance",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "cargoLoadingCalculations",
        "type": "array",
        "format": "cargo-loading-calculation",
        "title": "Cargo carriage quotations",
        "additionalProperties": [
            {
                "mapped": "cargo"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "cargoLoadingCalculationsArchiveJson",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "cargoLoadingCalculationsJson",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "cargoNotes",
        "type": "string",
        "format": "",
        "title": "Cargo properties",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "cargoRailway",
        "type": "rel",
        "format": "cargo-railway",
        "title": "Railway",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "cargoSortString",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "cargoStatus",
        "type": "rel",
        "format": "cargo-status",
        "title": "Cargo status",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "<p>If preliminary you know cargo status - choose.<\/p><p>If you know choose - Export (EX)<\/p><p>Export (EX) - cargo processed or will be processed EX declaration.<\/p><p>Transit (T1) - cargo with T1 document.<\/p><p>Mixed (EX\/T1) - cargos processed or will be processed EX declaration and T1 document.<\/p><p>Without export documents (commited to EU receiver).<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "cargoStockHtmlInfo",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "cargoUrgentTaskReason",
        "type": "string",
        "format": "",
        "title": "Urgent task",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "cargoUrgentTaskReasonDate",
        "type": "string",
        "format": "",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "cargoValue",
        "type": "number",
        "format": "float",
        "title": "Cargo value",
        "additionalProperties": [],
        "description": "<p><strong>Sales manager: <\/strong><\/p><p>Write preliminary cargos worth. If you don't know, leave clear. This field affects on cargos self cost. <\/p><p><strong>Operation departments manager:<\/strong><\/p><p>Write exact cargos worth, that is in cargos invoice or in translation.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "cargoValueCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "cargoValueEur",
        "type": "number",
        "format": "float",
        "title": "Cargo value, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "carriersOrders",
        "type": "array",
        "format": "carriers-order",
        "title": "Carriers orders",
        "additionalProperties": [
            {
                "mapped": "cargo"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "cbm",
        "type": "number",
        "format": "float",
        "title": "CBM",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "checks",
        "type": "array",
        "format": "bool",
        "title": "Checks",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "clientCountryId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "commentLoading",
        "type": "string",
        "format": "text",
        "title": "Information for warehouse loading department",
        "additionalProperties": [],
        "description": "<p>Filled information will be transferred directly to the terminal like instruction in process of loading\/documents registration.<\/p><p>Write what extra activities must be in process of loading\/documents registration.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo",
        "key": "commentProcessing",
        "type": "string",
        "format": "text",
        "title": "Information for documents processing",
        "additionalProperties": [
            {
                "tag": {
                    "schema": "comment-processing",
                    "key": "name"
                }
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "contactedSeller",
        "type": "boolean",
        "format": "",
        "title": "Contacted seller",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "contactedSellerTime",
        "type": "string",
        "format": "date-time",
        "title": "Contacted with seller",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "countryForSearch",
        "type": "integer",
        "format": "",
        "title": "Loading",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "countryForSearchOut",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "customsCode",
        "type": "integer",
        "format": "",
        "title": "Customs code quantity",
        "additionalProperties": [],
        "description": "<p><strong>Sales manager<\/strong> -<\/p><p>Write preliminary general customs codes number.<\/p><p>If you don't know, leave clear.<\/p><p>This field affects on cargos self cost.<\/p><p><br><\/p><p><strong>Operation departments manager<\/strong> -<\/p><p>Write exact customs codes number,<\/p><p>that is in cargos invoice or in translation.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "deletedCargoLoadingCalculations",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "deliveryTime",
        "type": "number",
        "format": "float",
        "title": "Delivery time",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "dimensions",
        "type": "string",
        "format": "text",
        "title": "Dimensions",
        "additionalProperties": [],
        "description": "<p>Every different dimension write in a new line.<\/p><p>If you want write dimension, write lenghtXwidthXheight (ex:120x80x150).<\/p><p>If you want write cubage, write number of cubes and cbm (ex:28cbm)<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "dpsConfirmationChanges",
        "type": "array",
        "format": "cargo-operation-confirmation-changes",
        "title": "Get the value of dpsConfirmationChanges",
        "additionalProperties": [
            {
                "mapped": "cargo"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "expeditor",
        "type": "rel",
        "format": "user",
        "title": "Expeditor",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "expeditorCalculation",
        "type": "rel",
        "format": "user",
        "title": "Quotation expeditor",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "expeditorCalculationOut",
        "type": "rel",
        "format": "user",
        "title": "Transport operations manager (quotation)",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "expeditorForReport",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "expeditorOut",
        "type": "rel",
        "format": "user",
        "title": "Export expeditor",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "extraCostCargos",
        "type": "array",
        "format": "invoice-incoming",
        "title": "Extra cost",
        "additionalProperties": [
            {
                "mapped": "cargo"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "extraServiceCooSertificateNew",
        "type": "integer",
        "format": "",
        "title": "COO certificate release (new) (450 EUR)",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "extraServiceCooSertificateNewDocsHandled",
        "type": "boolean",
        "format": "",
        "title": "Get the value of extraServiceCooSertificateNewDocsHandled",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "extraServiceCooSertificateRenew",
        "type": "integer",
        "format": "",
        "title": "COO certificate renewal (190 EUR)",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "extraServiceCooSertificateRenewDocsHandled",
        "type": "boolean",
        "format": "",
        "title": "Get the value of extraServiceCooSertificateRenewDocsHandled",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "extraServiceCraneHandling",
        "type": "integer",
        "format": "",
        "title": "Reload using crane",
        "additionalProperties": [],
        "description": "<p>For crane additional expenses 150 EUR<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "extraServiceDeliveryClient",
        "type": "integer",
        "format": "",
        "title": "Delivery to client",
        "additionalProperties": [],
        "description": "<p>For LTL goods additional expenses 50 EUR<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "extraServiceDeliveryClientAddress",
        "type": "string",
        "format": "",
        "title": "Client address",
        "additionalProperties": [],
        "description": "<p>If you know, fill in address, if you dont know leave this window empty.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "extraTotalCustomsInspection",
        "type": "number",
        "format": "float",
        "title": "Custom inspection",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "extraTotalDowntime",
        "type": "number",
        "format": "float",
        "title": "For downtime",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "extraTotalPenalty",
        "type": "number",
        "format": "float",
        "title": "Penalty",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "filesSentToTerminal",
        "type": "boolean",
        "format": "",
        "title": "Get the value of filesSentToTerminal",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "fillErrors",
        "type": "array",
        "format": "string",
        "title": "Get the value of fillErrors",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "ftlAutoType",
        "type": "integer",
        "format": "",
        "title": "Auto type",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Standard tent",
                "value": 0,
                "color": "skySolid"
            },
            {
                "label": "Refrigerator",
                "value": 10,
                "color": "orangeSolid"
            },
            {
                "label": "Road train",
                "value": 20,
                "color": "orangeSolid"
            },
            {
                "label": "Mega",
                "value": 30,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "ftlCalculationStop",
        "type": "array",
        "format": "string",
        "title": "Get the value of ftlCalculationStop",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "ftlOverloadType",
        "type": "integer",
        "format": "",
        "title": "Reload type?",
        "additionalProperties": [],
        "description": "<p>Choosing manual reloading definitely write accurate weight (Kg)<\/p><p>That affects on self cost.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Pallet",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Manual",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "ftlType",
        "type": "integer",
        "format": "",
        "title": "With reload?",
        "additionalProperties": [],
        "description": "<p>With reloading - cargo in our warehouse will be reloaded to the other vehicle.<\/p><p>Direct - cargo from seller to client will be delivered without reloading.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "With reloading",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Direct",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "hasPreciousMetals",
        "type": "boolean",
        "format": "",
        "title": "Precious metals needed",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "hasStatus3Changes",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "hasTnved",
        "type": "boolean",
        "format": "",
        "title": "Railway tariff needed",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "insuranceInvoiceCalcDiff",
        "type": "number",
        "format": "float",
        "title": "Insurance difference",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "insurancePercent",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "internalPoints",
        "type": "number",
        "format": "float",
        "title": "Internal points",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "isExtraSvh",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "isFtlCarLoaded",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "kzCarriersOrderJson",
        "type": "array",
        "format": "string",
        "title": "ICargoKzCarriersOrder",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "kzEx1FilesSend",
        "type": "array",
        "format": "",
        "title": "Get the value of kzEx1FilesSend",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "lastCallDate",
        "type": "string",
        "format": "",
        "title": "Last call",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "lastCargoLoadingPrice",
        "type": "string",
        "format": "",
        "title": "Get the value of lastCargoLoadingPrice",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "ldm",
        "type": "number",
        "format": "float",
        "title": "CLM",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "ldmCalculated",
        "type": "boolean",
        "format": "",
        "title": "ldmCalculated",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "ldmCalculationType",
        "type": "integer",
        "format": "",
        "title": "Quotation scheme",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "ldmWarehouse",
        "type": "number",
        "format": "float",
        "title": "Warehouse colli quantity",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "loadAddress",
        "type": "rel",
        "format": "load-address",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "loadAddressAdditional",
        "type": "rel",
        "format": "load-address",
        "title": "Additional address",
        "additionalProperties": [],
        "description": "<p>Write if loading will be in two different places.<\/p><p>or need to drive to the customs stakeholders after loading<\/p><p>to process customs documents.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "loadAddressAdditional2",
        "type": "rel",
        "format": "load-address",
        "title": "Additional address",
        "additionalProperties": [],
        "description": "<p>Fill in only if loading will be needed from three places.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "loadAddressAdditional2Json",
        "type": "array",
        "format": "string",
        "title": "ICargoLoadAddress",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "loadAddressAdditionalJson",
        "type": "array",
        "format": "string",
        "title": "ICargoLoadAddress",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "loadAddressIndex",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "loadAddressIndexCache",
        "type": "string",
        "format": "",
        "title": "loadAddressIndexCache",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "loadAddressJson",
        "type": "array",
        "format": "string",
        "title": "ICargoLoadAddress",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "loadDate",
        "type": "string",
        "format": "date",
        "title": "Load date",
        "additionalProperties": [],
        "description": "<p>Write in preliminary cargos ready date, that you get after discussion with client.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo",
        "key": "loadDateDiff",
        "type": "integer",
        "format": "",
        "title": "Load date diff",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "loadDateDiffManagerConfirmed",
        "type": "boolean",
        "format": "",
        "title": "Load difference confirmed",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "loadDateDiffManagerNeedConfirm",
        "type": "boolean",
        "format": "",
        "title": "Load difference need confirmation",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "loadDateSeller",
        "type": "string",
        "format": "date",
        "title": "Cargo ready date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo",
        "key": "loadDateSellerFact",
        "type": "string",
        "format": "date",
        "title": "Take from seller date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo",
        "key": "loadingAddress",
        "type": "string",
        "format": "",
        "title": "Loading",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "loadingAddressAddCargoStatusCalc",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "loadingAddressFull",
        "type": "string",
        "format": "",
        "title": "Loading",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "loadingAddressFullWoSeller",
        "type": "string",
        "format": "",
        "title": "Loading",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "loadingCountry",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "loadingCountryRegion",
        "type": "rel",
        "format": "country-region",
        "title": "Region",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "loadingExactAddress",
        "type": "string",
        "format": "text",
        "title": "Exact loading address",
        "additionalProperties": [],
        "description": "<p>If you know exact loading address or loading index, write it.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo",
        "key": "loadingInfoCompleted",
        "type": "integer",
        "format": "",
        "title": "Loading information",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Full, can be loaded",
                "value": 5,
                "color": "slate"
            },
            {
                "label": "Lack of information",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "loadingInfoReturnText",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "loadingPriceCalculated",
        "type": "boolean",
        "format": "",
        "title": "loadingPriceCalculated",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "loadingPriceCalculatedOut",
        "type": "boolean",
        "format": "",
        "title": "loadingPriceCalculated",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "logisticCardIndex",
        "type": "integer",
        "format": "",
        "title": "Dashboard index",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "managerCardIndex",
        "type": "integer",
        "format": "",
        "title": "Dashboard index",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "minimumSpecificationStatus",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "modalType",
        "type": "integer",
        "format": "",
        "title": "What type?",
        "additionalProperties": [],
        "description": "<p><strong>Air + Carriage by road (LTL \/ Partial cargo)<\/strong> - From sellers warehouse towards our warehouse cargo will be delivered by air. From our warehouse towards client cargo will be transferred like, partial cargo carriage by road.<\/p><p><strong>Carriage by road (FTL \/ Full cargo) + Railway<\/strong> - From sellers warehouse towards our warehouse cargo will be delivered in a separate vehicle (FTL). From our warehouse towards client cargo will be delivered by railway.<\/p><p><strong>Sea + Carriage by road (LTL \/ Partial cargo)<\/strong> - From sellers warehouse towards our warehouse cargo will be delivered by sea. From our warehouse cargo will be delivered like partial cargo (LTL).<\/p><p><strong>Sea + Carriage by road (FTL \/ Full cargo)<\/strong> - From sellers warehouse towards our warehouse cargo will be delivered by sea. From our warehouse towards client cargo will be delivered in a separate vehicle (FTL).<\/p><p><strong>Carriage by road (LTL \/ Partial cargo) + Railway<\/strong> - From sellers warehouse towards our warehouse cargo will be delivered like partial cargo (LTL). From our warehouse towards client cargo will be delivered by railway.<\/p><p><strong>Sea + Railway<\/strong> - From sellers warehouse towards our warehouse cargo will be delivered by sea. From our warehouse towards client will be delivered by railway.<\/p><p><strong>Carriage by road (FTL \/ Full cargo) + Carriage by road (LTL \/ Partial cargo)<\/strong> - From sellers warehouse towards our warehouse cargo will be delivered in a separate vehicle (FTL). From our warehouse towards client cargo will be delivered like partial cargo carriage by road.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Air + Carriage by road (LTL \/ Partial cargo)",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo) + Railway",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Carriage by road (LTL \/ Partial cargo) + Railway",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Sea + Railway",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Sea + Carriage by road (LTL \/ Partial cargo)",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Sea + Carriage by road (FTL \/ Full cargo)",
                "value": 50,
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo) + Carriage by road (LTL \/ Partial cargo)",
                "value": 60,
                "color": "slate"
            },
            {
                "label": "Carriage by road (LTL \/ Partial cargo) + Avia",
                "value": 70,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "needAlkoGlueWithStamps",
        "type": "integer",
        "format": "",
        "title": "Need marking?",
        "additionalProperties": [],
        "description": "<p>Will client be needed excise marks and\/or stickers glue service?<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "needAlkoGlueWithStampsBottles",
        "type": "integer",
        "format": "",
        "title": "How many bottles (at all)",
        "additionalProperties": [],
        "description": "<p>Write preliminary bottles amount.<\/p><p>If you don't know, leave clear.<\/p><p>This field affects on cargos self cost (excise parcel or marks with information glue - 0,04ct\/bott.)<\/p><p>By ordering both services (0,08ct\/bott).<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "needAlkoGlueWithStampsType",
        "type": "integer",
        "format": "",
        "title": "Excise parcel glue",
        "additionalProperties": [],
        "description": "<p>Excise parcel glue - 0,04ct for one bottle.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Unknown",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "needAlkoGlueWithStampsType2",
        "type": "integer",
        "format": "",
        "title": "Put sticker with information",
        "additionalProperties": [],
        "description": "<p>Put sticker with information - 0,04ct for one bottle.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Unknown",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "needAlkoGlueWithStampsType3",
        "type": "integer",
        "format": "",
        "title": "Put QR code",
        "additionalProperties": [],
        "description": "<p>Put QR code - 0,04ct for one bottle.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Unknown",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "needCargoCalculation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needCertificateFile",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needEx1T1File",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needIE599File",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needLdm",
        "type": "boolean",
        "format": "",
        "title": "needLdm",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needLoadDateConfirm",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needLogisticCalculationOut",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needMakeEx",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "needSpecification",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "needTemperatureMode",
        "type": "integer",
        "format": "",
        "title": "Need temperature mode?",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Need for full trip",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Carrying from seller to our warehouse",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Only carrying from our warehouse",
                "value": 30,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "needTerminal",
        "type": "boolean",
        "format": "",
        "title": "Terminal needed",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "notes",
        "type": "array",
        "format": "note",
        "title": "Notifications",
        "additionalProperties": [
            {
                "key": "filterSkip"
            },
            {
                "mapped": "cargo"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "notesLogistic",
        "type": "string",
        "format": "text",
        "title": "Quotation comments (on the way from seller)",
        "additionalProperties": [],
        "description": "<p>Describe what actions can be done with cargo (pallets change, pallets delete and etc.).<\/p><p>If there is no actions that can be done with cargo, leave it clear.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo",
        "key": "notesOperations",
        "type": "string",
        "format": "text",
        "title": "Quotation comments (on the way to client)",
        "additionalProperties": [],
        "description": "<p>Describe what actions can be done with cargo (pallets change, pallets delete and etc.).<\/p><p>If there is no actions that can be done with cargo, leave it clear.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo",
        "key": "notifications",
        "type": "array",
        "format": "notification",
        "title": "Notifications",
        "additionalProperties": [
            {
                "mapped": "cargo"
            },
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "onRoadPoints",
        "type": "number",
        "format": "float",
        "title": "On road points",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "onlyFullWeight",
        "type": "integer",
        "format": "",
        "title": "Only full weight",
        "additionalProperties": [],
        "description": "<p>Only mark if you know full weight.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 5,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "operationConfirmation",
        "type": "rel",
        "format": "cargo-operation-confirmation",
        "title": "DPS Confirmation",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "operational",
        "type": "rel",
        "format": "user",
        "title": "Operation department manager",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "operationalPoints",
        "type": "number",
        "format": "float",
        "title": "Operative points",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "operativeCardIndex",
        "type": "integer",
        "format": "",
        "title": "Dashboard index",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "otherCargosCount",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "otherCargosSerialNumbers",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "otherTerminalIds",
        "type": "array",
        "format": "int",
        "title": "getOtherTerminalIds",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "outgoingDocumentsChange",
        "type": "integer",
        "format": "",
        "title": "Will outgoing documents change",
        "additionalProperties": [],
        "description": "<p>Mark, if cargo will leave warehouse with another documents, not those one that sender gave.<\/p><p>A little documents change (mistake correction) is change.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "outgoingDocumentsChangePurpose",
        "type": "rel",
        "format": "outgoing-documents-change-purpose",
        "title": "What changes will be in documents",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "outgoingDocumentsChangeType",
        "type": "integer",
        "format": "",
        "title": "What changes will be in documents",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Changing customs codes (first 6 out of 10 numbers)",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Changing customs codes (last 4 out of 10 numbers)",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Correction because of ads\/gifted products",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Changing cargos sender\/receiver",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Changing delivery terms",
                "value": 50,
                "color": "slate"
            },
            {
                "label": "Changing documents language",
                "value": 60,
                "color": "slate"
            },
            {
                "label": "Changing price",
                "value": 70,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "ownerCompanyId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "places",
        "type": "string",
        "format": "text",
        "title": "Places quantity",
        "additionalProperties": [],
        "description": "<p>Separate pallets, boxes or packaging amount.<\/p><p>(Like example: If 2 pallets is one on another, its 1 place.)<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo",
        "key": "plannedKzDateStr",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "poCarNumber",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "poCarNumbers",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "poLoadDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "poLoadDateDiff",
        "type": "integer",
        "format": "",
        "title": "Po load date diff",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "poLoadDateStr",
        "type": "string",
        "format": "",
        "title": "PO loading date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "poSerialNumber",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "poUnloadDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "poUnloadDateStr",
        "type": "string",
        "format": "",
        "title": "PO unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "preciousMetals",
        "type": "integer",
        "format": "",
        "title": "Have precious metals?",
        "additionalProperties": [],
        "description": "<p>Precious metals, like aluminium, brass etc. making a difference on railway tariff<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Yes",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "railwayType",
        "type": "integer",
        "format": "",
        "title": "Railway type vehicle",
        "additionalProperties": [],
        "description": "<p>**Konteineris 20 pėdų (bendro geležinkelio parko)** - Vidinis ilgis: 5,91 m, Vidinis plotis: 2,34 m, Vidinis aukštis: 2,36 m, Euro pl. talpa: apie 11pll (vienu aukštu), Maksimali apkrova: 21,8 t **Konteineris 40 pėdų (bendro geležinkelio parko)** - Vidinis ilgis: 12,02 m, Vidinis plotis: 2,34 m, Vidinis aukštis: 2,36 m, Euro pl. talpa: apie 25pll (vienu aukštu), Maksimali apkrova: 26,5 t **Konteineris 20 pėdų (High Cube)** - Vidinis ilgis: 5,91 m, Vidinis plotis: 2,34 m, Vidinis aukštis: 2,68 m, Euro pl. talpa: apie 11pll (vienu aukštu), Maksimali apkrova: 27,5 t **Konteineris 40 pėdų (High Cube)** - Vidinis ilgis: 12,02 m, Vidinis plotis: 2,34 m, Vidinis aukštis: 2,36 m, Euro pl. talpa: apie 25pll (vienu aukštu), Maksimali apkrova: 28,6 t **Vagonas 138m3** - Vidinis ilgis: 15,7 m, Vidinis plotis: 2,76 m, Vidinis aukštis: 2,8 m, Euro pl. talpa: apie 38pll (vienu aukštu), Maksimali apkrova: 66,7 t, Dūrų angos išmatavimai: 2,71 x 3,97 m **Vagonas 150m3** - Vidinis ilgis:17,4m, Vidinis plotis:2,79m, Vidinis aukštis:3,1m, Euro pl. talpa:apie 42pll(vienu aukštu), Maksimali apkrova:68t, Dūrų angos išmatavimai: 3,9 x 2,8 m **Vagonas 158m3** - Vidinis ilgis: 17,6m, Vidinis plotis: 2,74 m, Vidinis aukštis: 3,4 m., Euro pl. talpa: apie 43pll (vienu aukštu), Maksimali apkrova: 66,7 t, Dūrų angos išmatavimai: 2,71 x 3,97 m **Vagonas Termosas 918 (IVT)** - Vidinis ilgis:20,45m, Vidinis plotis:2,54m, Vidinis aukštis:2,62m, Euro pl. talpa:51apie pll(vienu aukštu), Maksimali apkrova:50t, Dūrų angos išmatavimai: 2,2 x 2 m **CMGV 240m3 \"raketovozas\"** - Vidinis ilgis:23,34m, Vidinis plotis:3,09m, Vidinis aukštis:3,67m, Euro pl. talpa:apie 67pll(vienu aukštu), Maksimali apkrova:49t, Dūrų angos išmatavimai: 3,09 x 3,5 m<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Wagon 138m3",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Wagon 158m3",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Container 20 feet",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Container 40 feet",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Container 20 feet “High cube”",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Container 40 feet “High cube”",
                "value": 50,
                "color": "slate"
            },
            {
                "label": "Wagon 150m3",
                "value": 60,
                "color": "slate"
            },
            {
                "label": "Wagon 250m3 (CMGV model)",
                "value": 70,
                "color": "slate"
            },
            {
                "label": "Isothermal Wagon 136m3 (800model)",
                "value": 80,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "readyForLoad",
        "type": "boolean",
        "format": "",
        "title": "Ready for load (in Warehouse)",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "ref",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "refForCalc",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "referenceNumber",
        "type": "string",
        "format": "",
        "title": "Reference nr.",
        "additionalProperties": [],
        "description": "<p>Unique number (code) - that senders appoint to their cargos.<\/p><p>If there is no unique code, companies name being used.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "returnCalculation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "salesOrderJson",
        "type": "array",
        "format": "string",
        "title": "ICargoSo",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "seaContainerType",
        "type": "integer",
        "format": "",
        "title": "Sea container type",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "LCL\/Partial cargo",
                "value": 10,
                "color": "purple"
            },
            {
                "label": "FCL Container 20 feet",
                "value": 20,
                "color": "purple"
            },
            {
                "label": "FCL Container 40 feet",
                "value": 30,
                "color": "purple"
            },
            {
                "label": "FCL Container 20 feet \"High cube\"",
                "value": 40,
                "color": "purple"
            },
            {
                "label": "FCL Container 40 feet \"High cube\"",
                "value": 50,
                "color": "purple"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "seller",
        "type": "rel",
        "format": "sender",
        "title": "Seller",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "sellerJson",
        "type": "array",
        "format": "string",
        "title": "ICargoSeller",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "senderLoadDate",
        "type": "string",
        "format": "date",
        "title": "Loading date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo",
        "key": "senderMakeEx",
        "type": "integer",
        "format": "",
        "title": "Who will prepare EX?",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Consignor",
                "value": 10,
                "color": "greenSolid"
            },
            {
                "label": "Warehouse",
                "value": 20,
                "color": "redSolid"
            },
            {
                "label": "Partners",
                "value": 30,
                "color": "greenSolid"
            },
            {
                "label": "Remotely",
                "value": 40,
                "color": "redSolid"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "senderMakeExCost",
        "type": "integer",
        "format": "",
        "title": "Will shipper prepare EX?",
        "additionalProperties": [],
        "description": "<p>Selection makes influence on self cost.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Unknown",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "senderMakeFito",
        "type": "integer",
        "format": "",
        "title": "Did seller wrote down truck numbers in certificate?",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "senderTransportType",
        "type": "integer",
        "format": "",
        "title": "How cargo will leave the warehouse?",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Vehicle (LTL)",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Vehicle (FTL)",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Railway",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Air",
                "value": 30,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "senderUnLoadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "soCalcClientId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "soClientFilialName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "soClientId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "soClientJson",
        "type": "array",
        "format": "string",
        "title": "ICargoSoClient",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "soClientNameTable",
        "type": "string",
        "format": "",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "soContactJson",
        "type": "array",
        "format": "string",
        "title": "ICargoSoContact",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "soUnloadAddressJson",
        "type": "array",
        "format": "string",
        "title": "ICargoSoAddress",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "specCargo",
        "type": "integer",
        "format": "",
        "title": "Cargo specific",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Standard",
                "value": 5,
                "color": "slate"
            },
            {
                "label": "Alcohol (beer)",
                "value": 6,
                "color": "slate"
            },
            {
                "label": "Alcohol",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "With phytosanitary certificate",
                "value": 30,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "specifications",
        "type": "array",
        "format": "cargo-specificaction",
        "title": "Get the value of specifications",
        "additionalProperties": [
            {
                "mapped": "cargo"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "cargo",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "cargo",
        "key": "statusChanges",
        "type": "array",
        "format": "string",
        "title": "Status changes",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "sticker",
        "type": "rel",
        "format": "cargo-sticker",
        "title": "Need marking?",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "stock",
        "type": "string",
        "format": "",
        "title": "Warehouse",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "temperatureSettings",
        "type": "string",
        "format": "",
        "title": "Temperature mode",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Through terminal",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo",
        "key": "terminalJson",
        "type": "array",
        "format": "string",
        "title": "ICargoSoAddress",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo",
        "key": "terminalManual",
        "type": "integer",
        "format": "",
        "title": "Confirm terminal changes",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No, return automatic selection",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "tnvedCodes",
        "type": "string",
        "format": "",
        "title": "Write TNVED codes and weights",
        "additionalProperties": [],
        "description": "<p>New value write in new line.<\/p><p>For railway tariff (prices) calculation, customs codes are needed (TNVED, HS).<\/p><p>For every single code, separate weights.<\/p><p>Without customs codes, price can't be calculated.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "tnvedCodesForCalc",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "tnvedCodesOperational",
        "type": "string",
        "format": "",
        "title": "Write TNVED codes and weights",
        "additionalProperties": [],
        "description": "<p>New value write in new line.<\/p><p>For railway tariff (prices) calculation, customs codes are needed (TNVED, HS).<\/p><p>For every single code, separate weights.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "totalInvoiceRatio",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "type",
        "type": "integer",
        "format": "",
        "title": "Transportation type",
        "additionalProperties": [],
        "description": "<p>Carriage by road (LTL \/ Partial cargo) - partial cargos carriage by road.<\/p><p>Carriage by road (FTL \/ Full cargo) - full cargos carriage by road.<\/p><p>Air - cargos air shipment.<\/p><p>Multimodal - cargos carriage processing with more than one type of vehicle.<\/p><p>Seller delivers to our warehouse - seller provides cargo in our warehouse.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Carriage by road (LTL \/ Partial cargo)",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo)",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Air",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Multimodal",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Seller delivers to our warehouse",
                "value": 50,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "cargo",
        "key": "typeIn",
        "type": "string",
        "format": "",
        "title": "Import type",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Carriage by road (LTL \/ Partial cargo)",
                "value": "ltl",
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo)",
                "value": "ftl",
                "color": "slate"
            },
            {
                "label": "Air",
                "value": "avia",
                "color": "slate"
            },
            {
                "label": "Sea",
                "value": "sea",
                "color": "slate"
            },
            {
                "label": "Other",
                "value": "none",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "cargo",
        "key": "typeOut",
        "type": "string",
        "format": "",
        "title": "Export type",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Carriage by road (LTL \/ Partial cargo)",
                "value": "ltl",
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo)",
                "value": "ftl",
                "color": "slate"
            },
            {
                "label": "Air",
                "value": "avia",
                "color": "slate"
            },
            {
                "label": "Sea",
                "value": "sea",
                "color": "slate"
            },
            {
                "label": "Railway",
                "value": "train",
                "color": "slate"
            },
            {
                "label": "Other",
                "value": "none",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "cargo",
        "key": "unloadCountryId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "unloadCountryIdOut",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "cargo",
        "key": "unloadRegion",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "unloadShortAddress",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo",
        "key": "unloadWarehouseDate",
        "type": "string",
        "format": "date",
        "title": "Arrival in warehouse date",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo",
        "key": "volume",
        "type": "number",
        "format": "float",
        "title": "Cubage",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo",
        "key": "waitingLoad",
        "type": "boolean",
        "format": "",
        "title": "Waiting for load from seller",
        "additionalProperties": [],
        "description": "",
        "className": "Cargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo",
        "key": "weight",
        "type": "string",
        "format": "text",
        "title": "Weight (kg)",
        "additionalProperties": [],
        "description": "<p><strong>Sales manager<\/strong> - specify the weight of each seat separately<\/p><p><br><\/p><p><strong>Operation departments manager<\/strong> -Write exact cargos worth, that is in cargos invoice or in translation.<\/p>",
        "className": "Cargo",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "comment",
        "type": "string",
        "format": "text",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo-contact-seller-log",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "CargoContactSellerLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-description",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoDescription",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-description",
        "key": "internalPoints",
        "type": "number",
        "format": "float",
        "title": "Internal points",
        "additionalProperties": [],
        "description": "",
        "className": "CargoDescription",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-description",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CargoDescription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-description",
        "key": "nameEn",
        "type": "string",
        "format": "",
        "title": "Name (EN)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoDescription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-description",
        "key": "nameRu",
        "type": "string",
        "format": "",
        "title": "Name (RU)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoDescription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-description",
        "key": "points",
        "type": "number",
        "format": "float",
        "title": "Points",
        "additionalProperties": [],
        "description": "",
        "className": "CargoDescription",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-description",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoDescription",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo-ex-order",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Get the value of cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoExOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-ex-order",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "CargoExOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-ex-order",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "CargoExOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-ex-order",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CargoExOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-ex-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoExOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-ex-order",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CargoExOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo-ex-order",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "CargoExOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-hold",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoHold",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-hold",
        "key": "comment",
        "type": "string",
        "format": "text",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "CargoHold",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-hold",
        "key": "finishDate",
        "type": "string",
        "format": "date",
        "title": "Finish date",
        "additionalProperties": [],
        "description": "<p>Specify the approximate date when the cargo will be ready to delivery<\/p>",
        "className": "CargoHold",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo-hold",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoHold",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-hold",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CargoHold",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo-hold",
        "key": "startDate",
        "type": "string",
        "format": "date",
        "title": "Start date",
        "additionalProperties": [],
        "description": "",
        "className": "CargoHold",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo-hold",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CargoHold",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "cargo-insurance",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoInsurance",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-insurance",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Certificate",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "CargoInsurance",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "cargo-insurance",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoInsurance",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-insurance",
        "key": "invoices",
        "type": "array",
        "format": "string",
        "title": "Proforma invoice",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "CargoInsurance",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "cargo-insurance",
        "key": "moment",
        "type": "string",
        "format": "",
        "title": "Time of order",
        "additionalProperties": [],
        "description": "",
        "className": "CargoInsurance",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-insurance",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoInsurance",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo-insurance",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CargoInsurance",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "cargo-insurance",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "CargoInsurance",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "allCargoPlaces",
        "type": "number",
        "format": "float",
        "title": "Places quantity",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "allCargoWeight",
        "type": "number",
        "format": "float",
        "title": "Weight",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "calcType",
        "type": "string",
        "format": "",
        "title": "Get the value of calcType",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "cargoDimensions",
        "type": "string",
        "format": "",
        "title": "Dimensions",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "cargoLoadingAddress",
        "type": "string",
        "format": "",
        "title": "Loading",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "cargoUnloadingAddress",
        "type": "string",
        "format": "",
        "title": "Unloading",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "cbm",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "client",
        "type": "rel",
        "format": "carrier",
        "title": "Carrier",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "deleted",
        "type": "boolean",
        "format": "",
        "title": "Get the value of deleted",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "dimensions",
        "type": "string",
        "format": "text",
        "title": "Dimensions",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "loadingCountry",
        "type": "string",
        "format": "",
        "title": "Loading country",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "loadingText",
        "type": "string",
        "format": "text",
        "title": "Loading",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "places",
        "type": "string",
        "format": "text",
        "title": "Places quantity",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "seller",
        "type": "rel",
        "format": "sender",
        "title": "Seller",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "typeIn",
        "type": "string",
        "format": "",
        "title": "Arrival type?",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Carriage by road (LTL \/ Partial cargo)",
                "value": "ltl",
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo)",
                "value": "ftl",
                "color": "slate"
            },
            {
                "label": "Air",
                "value": "avia",
                "color": "slate"
            },
            {
                "label": "Sea",
                "value": "sea",
                "color": "slate"
            },
            {
                "label": "Other",
                "value": "none",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "unloadingText",
        "type": "string",
        "format": "text",
        "title": "Unloading",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "volumeWeight",
        "type": "number",
        "format": "float",
        "title": "Volume weight (kg)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "weight",
        "type": "string",
        "format": "text",
        "title": "Weight (kg)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-loading-calculation",
        "key": "weightTotal",
        "type": "number",
        "format": "float",
        "title": "Weight (kg)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "cargo-loading-calculation-notes-suggest",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculationNotesSuggest",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-loading-calculation-notes-suggest",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculationNotesSuggest",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-loading-calculation-notes-suggest",
        "key": "role",
        "type": "string",
        "format": "",
        "title": "Role",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculationNotesSuggest",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "",
                "value": "",
                "color": "slate"
            },
            {
                "label": "LTL",
                "value": "user-logistic",
                "color": "slate"
            },
            {
                "label": "FTL",
                "value": "user-logistic-ftl",
                "color": "slate"
            },
            {
                "label": "AIR\/SEA",
                "value": "user-logistic-sea",
                "color": "slate"
            },
            {
                "label": "TRAIN",
                "value": "user-logistic-train",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "cargo-loading-calculation-notes-suggest",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "CargoLoadingCalculationNotesSuggest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "confirmed",
        "type": "boolean",
        "format": "",
        "title": "Confirmed",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sent to processing",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "dpsProConfirmed",
        "type": "boolean",
        "format": "",
        "title": "Head of DPS confirmed",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "filesToStock",
        "type": "array",
        "format": "string",
        "title": "Get the value of filesToStock",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "headActiveChanges",
        "type": "boolean",
        "format": "",
        "title": "Urgent changes",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "headConfirmed",
        "type": "boolean",
        "format": "",
        "title": "COF confirmed",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "priceConfirmed",
        "type": "boolean",
        "format": "",
        "title": "Value confirmed",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "processOnHold",
        "type": "boolean",
        "format": "",
        "title": "Put process on hold",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "active",
        "type": "boolean",
        "format": "",
        "title": "Active",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "comment",
        "type": "string",
        "format": "text",
        "title": "Reason",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created at",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo-operation-confirmation-changes",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated at",
        "additionalProperties": [],
        "description": "",
        "className": "CargoOperationConfirmationChanges",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-railway",
        "key": "calculated",
        "type": "boolean",
        "format": "",
        "title": "Calculated",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-railway",
        "key": "calculation",
        "type": "rel",
        "format": "calculation",
        "title": "Get the value of calculation",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-railway",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-railway",
        "key": "containerRent",
        "type": "number",
        "format": "float",
        "title": "Wagon\/Container rent",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-railway",
        "key": "containerRentCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Wagon\/Container rent currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo-railway",
        "key": "containerRentNeeded",
        "type": "boolean",
        "format": "",
        "title": "Wagon\/Container rent needed",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-railway",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-railway",
        "key": "platformRent",
        "type": "number",
        "format": "float",
        "title": "Platform rent",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-railway",
        "key": "platformRentCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Platform rent currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo-railway",
        "key": "platformRentNeeded",
        "type": "boolean",
        "format": "",
        "title": "Need platform rent?",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "cargo-railway",
        "key": "tariff",
        "type": "number",
        "format": "float",
        "title": "Tariff",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-railway",
        "key": "tariffCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Tariff currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo-railway",
        "key": "warehouse",
        "type": "number",
        "format": "float",
        "title": "Warehouse",
        "additionalProperties": [],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-railway",
        "key": "warehouseCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Warehouse currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CargoRailway",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "cargo-specificaction",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-specificaction",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-specificaction",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-specificaction",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Doer",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-specificaction",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "ID",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-specificaction",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "cargo-specificaction",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "cargo-specificaction",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated At",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSpecificaction",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "cargo-status",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Code",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-status",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full title",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo-status",
        "key": "fullNameEn",
        "type": "string",
        "format": "",
        "title": "Full title",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo-status",
        "key": "fullNameRu",
        "type": "string",
        "format": "",
        "title": "Full title",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "cargo-status",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-status",
        "key": "internalPoints",
        "type": "number",
        "format": "float",
        "title": "Internal points",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "cargo-status",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-status",
        "key": "nameEn",
        "type": "string",
        "format": "",
        "title": "Name (EN)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-status",
        "key": "nameRu",
        "type": "string",
        "format": "",
        "title": "Name (RU)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "cargo-status",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "CargoStatus",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-sticker",
        "key": "bottles",
        "type": "integer",
        "format": "",
        "title": "How many bottles (at all)",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSticker",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-sticker",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSticker",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "cargo-sticker",
        "key": "exciseStickers",
        "type": "integer",
        "format": "",
        "title": "Excise parcel glue",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSticker",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-sticker",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSticker",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-sticker",
        "key": "informationStickers",
        "type": "integer",
        "format": "",
        "title": "Put sticker with information",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSticker",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "cargo-sticker",
        "key": "qrCodeStickers",
        "type": "integer",
        "format": "",
        "title": "Put QR code",
        "additionalProperties": [],
        "description": "",
        "className": "CargoSticker",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carrier",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "carrier",
        "key": "accountantChecked",
        "type": "boolean",
        "format": "",
        "title": "Checked by accountant",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "carrier",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "carrier",
        "key": "bankAccountNumber",
        "type": "string",
        "format": "",
        "title": "Bank account number",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carrier",
        "key": "cargoLoadingCalculations",
        "type": "array",
        "format": "cargo-loading-calculation",
        "title": "Get the value of cargoLoadingCalculations",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carrier",
        "key": "carriersOrders",
        "type": "array",
        "format": "carriers-order",
        "title": "Get the value of carriersOrders",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carrier",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carrier",
        "key": "contacts",
        "type": "array",
        "format": "contact",
        "title": "Contacts",
        "additionalProperties": [
            {
                "mapped": "carrier"
            }
        ],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carrier",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "carrier",
        "key": "defaultLang",
        "type": "string",
        "format": "",
        "title": "Doc. language",
        "additionalProperties": [],
        "description": "<p>Choose, which language will process orders to carrier and deliver automatic information from system.<\/p>",
        "className": "Carrier",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Lithuanian",
                "value": "lt",
                "color": "slate"
            },
            {
                "label": "English",
                "value": "en",
                "color": "slate"
            },
            {
                "label": "Russian",
                "value": "ru",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "carrier",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "carrier",
        "key": "ftlUser",
        "type": "rel",
        "format": "user",
        "title": "FTL expeditor",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carrier",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carrier",
        "key": "includeToAutomaticPayments",
        "type": "boolean",
        "format": "",
        "title": "Include to automatic payments",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "carrier",
        "key": "kzCarriersOrders",
        "type": "array",
        "format": "kz-carriers-order",
        "title": "Get the value of kzCarriersOrders",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carrier",
        "key": "kzFtlCalculations",
        "type": "array",
        "format": "kz-ftl-calculation",
        "title": "Get the value of kzFtlCalculations",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carrier",
        "key": "loadingCountries",
        "type": "array",
        "format": "number",
        "title": "Work direction out",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "carrier",
        "key": "mergeMode",
        "type": "boolean",
        "format": "",
        "title": "Get the value of mergeMode",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "carrier",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carrier",
        "key": "paymentDelay",
        "type": "integer",
        "format": "",
        "title": "Delay (d.)",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carrier",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone number",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "carrier",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "carrier",
        "key": "transportServices",
        "type": "array",
        "format": "number",
        "title": "Transport services",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "json",
        "enum": [
            {
                "label": "Air",
                "value": "1",
                "color": "slate"
            },
            {
                "label": "Sea",
                "value": "2",
                "color": "slate"
            },
            {
                "label": "Railway",
                "value": "3",
                "color": "slate"
            },
            {
                "label": "Vehicle (FTL)",
                "value": "4",
                "color": "slate"
            },
            {
                "label": "Vehicle (LTL)",
                "value": "5",
                "color": "slate"
            }
        ],
        "naeType": "enum_multi_number"
    },
    {
        "schema": "carrier",
        "key": "unloadingCountries",
        "type": "array",
        "format": "number",
        "title": "Work direction in",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "carrier",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "Carrier",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "accounterNotes",
        "type": "string",
        "format": "",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "cancelReason",
        "type": "rel",
        "format": "po-cancel-reason",
        "title": "Cancel reason",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "carNumber",
        "type": "string",
        "format": "",
        "title": "Vehicle number \/ Partners name",
        "additionalProperties": [],
        "description": "<p><strong>Carriage like partial cargo<\/strong> (write partners name (local carriers, that will take cargo) or vehicle number)<\/p><p><strong>Carriage full vehicle <\/strong>(write vehicle number)<\/p><p><strong>Sea shipment<\/strong> (write sea lines name)<\/p><p><strong>Carriage by railway<\/strong> (write railway transport number)<\/p><p><strong>Air carriage<\/strong> (write airlines name)<\/p>",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "cargoCalculations",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "carriers-order",
        "key": "cargoCalculationsDiscount",
        "type": "",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "carriersOrderExpense",
        "type": "rel",
        "format": "carriers-order-expense",
        "title": "Additional expenses",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "client",
        "type": "rel",
        "format": "carrier",
        "title": "Carrier",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "contactedCarrier",
        "type": "boolean",
        "format": "",
        "title": "Contacted seller",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "carriers-order",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "carriers-order",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order",
        "key": "dateChanges",
        "type": "array",
        "format": "",
        "title": "Get the value of dateChanges",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carriers-order",
        "key": "dateChangesCount",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "carriers-order",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "extraCosts",
        "type": "array",
        "format": "extra-cost",
        "title": "Extra costs",
        "additionalProperties": [
            {
                "mapped": "carriersOrder"
            }
        ],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carriers-order",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "carriers-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carriers-order",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order",
        "key": "invoiceIncomings",
        "type": "array",
        "format": "invoice-incoming",
        "title": "Get the value of invoiceIncomings",
        "additionalProperties": [
            {
                "mapped": "carriersOrder"
            }
        ],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carriers-order",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order",
        "key": "invoiceNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "loadDate",
        "type": "string",
        "format": "date",
        "title": "Loading date",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order",
        "key": "loadingCountry",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "loadingType",
        "type": "integer",
        "format": "",
        "title": "What type of loading sender has?",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Pallet",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Manual",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Mixed",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "carriers-order",
        "key": "logisticPoints",
        "type": "number",
        "format": "float",
        "title": "Logistic points",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "carriers-order",
        "key": "mergeMode",
        "type": "boolean",
        "format": "",
        "title": "Get the value of mergeMode",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "carriers-order",
        "key": "notes",
        "type": "string",
        "format": "",
        "title": "Notes",
        "additionalProperties": [],
        "description": "<p>Fill in extra terms that you agreed with carrier.<\/p>",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "paymentTerms",
        "type": "string",
        "format": "",
        "title": "Payment terms",
        "additionalProperties": [],
        "description": "<p>Choose agreed payment terms with client. After you choose, text will be automatically translated in documents language. If you will write text by yourself he will not be translated.<\/p>",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "pdfFileName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "penalty",
        "type": "number",
        "format": "float",
        "title": "Penalty",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "carriers-order",
        "key": "priceChangeForTable",
        "type": "string",
        "format": "text",
        "title": "Price changes",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "text"
    },
    {
        "schema": "carriers-order",
        "key": "priceChanges",
        "type": "array",
        "format": "carriers-order-price-change",
        "title": "Get the value of priceChanges",
        "additionalProperties": [
            {
                "mapped": "carriersOrder"
            }
        ],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "carriers-order",
        "key": "responseDocsFinish",
        "type": "boolean",
        "format": "",
        "title": "Documents saved",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "carriers-order",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "carriers-order",
        "key": "seller",
        "type": "rel",
        "format": "sender",
        "title": "Seller",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "carriers-order",
        "key": "soClient",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "carriers-order",
        "key": "statusChanges",
        "type": "array",
        "format": "string",
        "title": "Status changes",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "carriers-order",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "carriers-order",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "carriers-order",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "carriers-order",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Total with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "carriers-order",
        "key": "transportType",
        "type": "integer",
        "format": "",
        "title": "Transportation type",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Vehicle",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Air",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Sea",
                "value": 40,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "carriers-order",
        "key": "unloadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order",
        "key": "unloadingTimeAgreement",
        "type": "integer",
        "format": "",
        "title": "Unloading time agreement?",
        "additionalProperties": [],
        "description": "<p>What was the agreement with carrie about unloading time?<\/p>",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Fixed",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Hourly",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "carriers-order",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "carriers-order-expense",
        "key": "additionalDriveIn",
        "type": "number",
        "format": "float",
        "title": "Additional drive in",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderExpense",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "carriers-order-expense",
        "key": "carriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderExpense",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-expense",
        "key": "downtime",
        "type": "number",
        "format": "float",
        "title": "Downtime",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderExpense",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "carriers-order-expense",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderExpense",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "carriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "loadDate",
        "type": "string",
        "format": "date",
        "title": "New loading date\t",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "oldLoadDate",
        "type": "string",
        "format": "date",
        "title": "Old loading date",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "oldUnloadDate",
        "type": "string",
        "format": "date",
        "title": "Old unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "unloadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date\t",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "carriers-order-load-date-change",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "carriers-order-payment-term",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPaymentTerm",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carriers-order-payment-term",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPaymentTerm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order-payment-term",
        "key": "nameEn",
        "type": "string",
        "format": "",
        "title": "Name (EN)",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPaymentTerm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order-payment-term",
        "key": "nameRu",
        "type": "string",
        "format": "",
        "title": "Name (RU)",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPaymentTerm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "carriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "Carriers order",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "comment",
        "type": "string",
        "format": "text",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "oldCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Old currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "oldTotal",
        "type": "number",
        "format": "float",
        "title": "Old total",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "carriers-order-price-change",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderPriceChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "carriers-order-problem",
        "key": "carriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-problem",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "carriers-order-problem",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-problem",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "carriers-order-problem",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "carriers-order-problem",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "carriers-order-problem",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "carriers-order-problem",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "carriers-order-problem",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "CarriersOrderProblem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "addLostReason",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client",
        "key": "allStats",
        "type": "rel",
        "format": "client-all-stats",
        "title": "All time stats",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "bank",
        "type": "rel",
        "format": "bank",
        "title": "Bank",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "blockForCancelReason",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "calculations",
        "type": "array",
        "format": "calculation",
        "title": "Get the value of quotations",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "clientCategory",
        "type": "rel",
        "format": "client-category",
        "title": "Category",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "clientStatus",
        "type": "rel",
        "format": "client-status",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Sales company",
        "additionalProperties": [],
        "description": "<p>Leave clear for the anticipated settings.<\/p>",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "contacts",
        "type": "array",
        "format": "contact",
        "title": "Contacts",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "contactsToUse",
        "type": "array",
        "format": "contact",
        "title": "",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "contractNumber",
        "type": "string",
        "format": "",
        "title": "Contract number",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "contractTerms",
        "type": "string",
        "format": "text",
        "title": "Carriage payment terms",
        "additionalProperties": [],
        "description": "<p>Choose payment terms that you agreed with client.<\/p>",
        "className": "Client",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client",
        "key": "contractType",
        "type": "integer",
        "format": "",
        "title": "Contract type",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Reusable contract",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "One-time contract",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "client",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "debtDiscussedTill",
        "type": "string",
        "format": "date",
        "title": "Get the value of debtDiscussedTill",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "client",
        "key": "defaultCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "<p>Choose what currency will be written orders, invoices.<\/p>",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "defaultLang",
        "type": "string",
        "format": "",
        "title": "Doc. language",
        "additionalProperties": [],
        "description": "<p>Choose what language will be processing orders for client and what language will be submitted auto information from system.<\/p>",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Lithuanian",
                "value": "lt",
                "color": "slate"
            },
            {
                "label": "English",
                "value": "en",
                "color": "slate"
            },
            {
                "label": "Russian",
                "value": "ru",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "client",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Company transportation information",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client",
        "key": "descriptionActivity",
        "type": "string",
        "format": "text",
        "title": "Company description",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "doneActSend",
        "type": "rel",
        "format": "client-done-jobs-act",
        "title": "Automatic sending of the certificate of completion",
        "additionalProperties": [],
        "description": "<p>By choosing, contact that is listed in order (SO), will automatically receive done job acts.<\/p><p>Listed date in act - last time when cargo was in car.<\/p>",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "filials",
        "type": "array",
        "format": "client-filial",
        "title": "Alternative name",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "filialsCount",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "fullClientPhones",
        "type": "array",
        "format": "string",
        "title": "Mobile phones",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "isDebtDiscussedActiveNow",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "isLead",
        "type": "boolean",
        "format": "",
        "title": "Get the value of isLead",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "isValidContract",
        "type": "boolean",
        "format": "",
        "title": "Is contract valid",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "lastContactDate",
        "type": "string",
        "format": "datetime",
        "title": "Contacted",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client",
        "key": "lastContactDateAdmin",
        "type": "string",
        "format": "datetime",
        "title": "Contacted",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client",
        "key": "leadAssignRate",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "leadLostReason",
        "type": "rel",
        "format": "lead-lost-reason",
        "title": "Reason",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "leadLostRecords",
        "type": "array",
        "format": "lead-lost-record",
        "title": "Reason",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "leadLostRecordsCache",
        "type": "array",
        "format": "string",
        "title": "leadLostRecordsCache",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "leadSource",
        "type": "rel",
        "format": "lead-source",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "leadSourceCategoryName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "mergeMode",
        "type": "boolean",
        "format": "",
        "title": "Get the value of mergeMode",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Company name",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "needBusinessDev",
        "type": "boolean",
        "format": "",
        "title": "Get the value of needBusinessDev",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "needBusinessDevDelay",
        "type": "boolean",
        "format": "",
        "title": "Get the value of needBusinessDevDelay",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "needBusinessDevDelayCount",
        "type": "integer",
        "format": "",
        "title": "Get the value of needBusinessDevDelayCount",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "needBusinessDevNote",
        "type": "string",
        "format": "text",
        "title": "Get the value of needBusinessDevNote",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client",
        "key": "needBusinessDevUser",
        "type": "rel",
        "format": "user",
        "title": "Get the value of needBusinessDevUser",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "noContactsFound",
        "type": "boolean",
        "format": "",
        "title": "Get the value of noContactsFound",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "orgDocumentSend",
        "type": "rel",
        "format": "client-original-documents",
        "title": "Automatic sending of the original documents",
        "additionalProperties": [],
        "description": "<p>After you choose, after every completed order (SO) will be sent originals (order + invoice + act)<\/p>",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "paymentTermDays",
        "type": "integer",
        "format": "",
        "title": "Delay period",
        "additionalProperties": [],
        "description": "<p>Enter for how many days client must pay for carriage.<\/p>",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "paymentTermType",
        "type": "integer",
        "format": "",
        "title": "Payment terms for carriage.",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Payment after unloading",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Payment after receiving from sender",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Payment after departure from warehouse",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Partial payment",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Payment after arrival to carriers transit warehouse",
                "value": 60,
                "color": "slate"
            },
            {
                "label": "Payment after crossing EU\/CIS border",
                "value": 70,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "client",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "priority",
        "type": "integer",
        "format": "",
        "title": "Priority",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "client",
        "key": "salesOrders",
        "type": "array",
        "format": "sales-order",
        "title": "Get the value of salesOrders",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "sendToBusinessDev",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "client",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "client",
        "key": "statusChanges",
        "type": "array",
        "format": "string",
        "title": "Status changes",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "totalDebt",
        "type": "number",
        "format": "float",
        "title": "Debt",
        "additionalProperties": [],
        "description": "<p>Amount of current debt<\/p>",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "tracking",
        "type": "rel",
        "format": "client-tracking",
        "title": "Tracking",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "type",
        "type": "integer",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Client",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Carrier",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Seller",
                "value": 30,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "client",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "webpage",
        "type": "string",
        "format": "",
        "title": "Web page",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "yearDeliveryTime",
        "type": "number",
        "format": "float",
        "title": "Delivery terms",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "yearDiffPaymentDelay",
        "type": "number",
        "format": "float",
        "title": "Payment execution",
        "additionalProperties": [],
        "description": "<p>The number indicates median time it takes client to pay for the order.<\/p>",
        "className": "Client",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "yearStats",
        "type": "rel",
        "format": "client-year-stats",
        "title": "Year stats",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client-all-stats",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Get the value of client",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-all-stats",
        "key": "deliveryTime",
        "type": "number",
        "format": "float",
        "title": "Delivery term",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-all-stats",
        "key": "diffPaymentDelay",
        "type": "number",
        "format": "float",
        "title": "Payments execution",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-all-stats",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-all-stats",
        "key": "ordersCount",
        "type": "number",
        "format": "float",
        "title": "Order quantity",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-all-stats",
        "key": "profitPerOrder",
        "type": "number",
        "format": "float",
        "title": "Profitability",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-all-stats",
        "key": "profitTurnover",
        "type": "number",
        "format": "float",
        "title": "Profit",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-all-stats",
        "key": "profitability",
        "type": "number",
        "format": "float",
        "title": "Profitability, %",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client-all-stats",
        "key": "turnover",
        "type": "number",
        "format": "float",
        "title": "Turnover",
        "additionalProperties": [],
        "description": "",
        "className": "ClientAllStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientCategory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-category",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "ClientCategory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-contract",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "ClientContract",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-contract",
        "key": "clientFilial",
        "type": "rel",
        "format": "client-filial",
        "title": "Alternative name",
        "additionalProperties": [],
        "description": "",
        "className": "ClientContract",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-contract",
        "key": "file",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "ClientContract",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "client-contract",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientContract",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-contract",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "ClientContract",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-contract",
        "key": "signDate",
        "type": "string",
        "format": "date",
        "title": "Sign date",
        "additionalProperties": [],
        "description": "",
        "className": "ClientContract",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "client-done-jobs-act",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "ClientDoneJobsAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-done-jobs-act",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Emails",
        "additionalProperties": [],
        "description": "",
        "className": "ClientDoneJobsAct",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client-done-jobs-act",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientDoneJobsAct",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-filial",
        "key": "address",
        "type": "string",
        "format": "",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "client-filial",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-filial",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-filial",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Sales company",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-filial",
        "key": "contractNumber",
        "type": "string",
        "format": "",
        "title": "Contract number",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-filial",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "client-filial",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-filial",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-filial",
        "key": "phones",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "client-filial",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "ClientFilial",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-original-documents",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Enter correspondence address",
        "additionalProperties": [],
        "description": "<p>Leave clear,<\/p><p>so you can use main<\/p><p>company address<\/p>",
        "className": "ClientOriginalDocuments",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client-original-documents",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "ClientOriginalDocuments",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-original-documents",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientOriginalDocuments",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-status",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "client-status",
        "key": "colorLogist",
        "type": "string",
        "format": "",
        "title": "Colour logist",
        "additionalProperties": [
            {
                "as": "color"
            }
        ],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "varchar",
        "as": "color",
        "naeType": "color"
    },
    {
        "schema": "client-status",
        "key": "colorManager",
        "type": "string",
        "format": "",
        "title": "Colour manager",
        "additionalProperties": [
            {
                "as": "color"
            }
        ],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "varchar",
        "as": "color",
        "naeType": "color"
    },
    {
        "schema": "client-status",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "Disabled",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "client-status",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-status",
        "key": "oneorder",
        "type": "number",
        "format": "float",
        "title": "Internal profit for order",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-status",
        "key": "ordersPerYear",
        "type": "number",
        "format": "float",
        "title": "Orders per year",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-status",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "client-status",
        "key": "titleLogist",
        "type": "string",
        "format": "",
        "title": "Title logist",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-status",
        "key": "titleManager",
        "type": "string",
        "format": "",
        "title": "Title manager",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-status",
        "key": "turnover",
        "type": "number",
        "format": "float",
        "title": "Profit for 12 months",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatus",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-status-data",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-status-data",
        "key": "clientStatus",
        "type": "rel",
        "format": "client-status",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-status-data",
        "key": "comment",
        "type": "string",
        "format": "text",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client-status-data",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-status-data",
        "key": "periodFinish",
        "type": "string",
        "format": "",
        "title": "Period finish",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-status-data",
        "key": "periodStart",
        "type": "string",
        "format": "",
        "title": "Period start",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client-status-data",
        "key": "profitability",
        "type": "number",
        "format": "float",
        "title": "Profitability, %",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client-status-data",
        "key": "turnover",
        "type": "number",
        "format": "float",
        "title": "Turnover",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-status-data",
        "key": "yearDeliveryTime",
        "type": "number",
        "format": "float",
        "title": "Delivery time",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-status-data",
        "key": "yearDiffPaymentDelay",
        "type": "number",
        "format": "float",
        "title": "Payment delay",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-status-data",
        "key": "yearProfitPerOrder",
        "type": "number",
        "format": "float",
        "title": "Profitability",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-status-data",
        "key": "yearProfitTurnover",
        "type": "number",
        "format": "float",
        "title": "Profit",
        "additionalProperties": [],
        "description": "",
        "className": "ClientStatusData",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-top-2-0",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client-top-2-0",
        "key": "createdAt",
        "type": "string",
        "format": "date",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "date"
    },
    {
        "schema": "client-top-2-0",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client-top-2-0",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "client-top-2-0",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "client-top-2-0",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "client-top-2-0",
        "key": "topIndex",
        "type": "integer",
        "format": "",
        "title": "Index",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "client-top-2-0",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "client-top20",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Get the value of client",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-top20",
        "key": "createdAt",
        "type": "string",
        "format": "date",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "client-top20",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-top20",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-top20",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-top20",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "client-top20",
        "key": "topIndex",
        "type": "integer",
        "format": "",
        "title": "Indeksas",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-top20",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTop20",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client-tracking",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTracking",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-tracking",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Emails",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTracking",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client-tracking",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientTracking",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-year-stats",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Get the value of client",
        "additionalProperties": [],
        "description": "",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "client-year-stats",
        "key": "deliveryTime",
        "type": "number",
        "format": "float",
        "title": "Delivery term",
        "additionalProperties": [],
        "description": "",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-year-stats",
        "key": "diffPaymentDelay",
        "type": "number",
        "format": "float",
        "title": "Payments execution",
        "additionalProperties": [],
        "description": "<p>The number indicates median time it takes client to pay for the order<\/p>",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-year-stats",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client-year-stats",
        "key": "ordersCount",
        "type": "number",
        "format": "float",
        "title": "Order quantity",
        "additionalProperties": [],
        "description": "<p>Orders quantity for last 12 months.<\/p>",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-year-stats",
        "key": "profitPerOrder",
        "type": "number",
        "format": "float",
        "title": "Profitability",
        "additionalProperties": [],
        "description": "<p>Profit for a single order.<\/p>",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-year-stats",
        "key": "profitTurnover",
        "type": "number",
        "format": "float",
        "title": "Profit",
        "additionalProperties": [],
        "description": "<p>Profit for the past 12 months<\/p>",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client-year-stats",
        "key": "profitability",
        "type": "number",
        "format": "float",
        "title": "Profitability, %",
        "additionalProperties": [],
        "description": "",
        "className": "ClientYearStats",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client-year-stats",
        "key": "turnover",
        "type": "number",
        "format": "float",
        "title": "Turnover",
        "additionalProperties": [],
        "description": "",
        "className": "ClientYearStats",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "comment-ik-notes",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CommentIkNotes",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "comment-ik-notes",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Note",
        "additionalProperties": [],
        "description": "",
        "className": "CommentIkNotes",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "comment-loading",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CommentLoading",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "comment-loading",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "CommentLoading",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "comment-operation",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CommentOperation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "comment-operation",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "CommentOperation",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "comment-processing",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CommentProcessing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "comment-processing",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "CommentProcessing",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "commission-expense",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CommissionExpense",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "commission-expense",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CommissionExpense",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "commission-expense",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CommissionExpense",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "commission-expense",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "CommissionExpense",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "commission-expense",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Get the value of salesOrder",
        "additionalProperties": [],
        "description": "",
        "className": "CommissionExpense",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "commission-expense",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "CommissionExpense",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "commission-expense",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Self cost",
        "additionalProperties": [],
        "description": "",
        "className": "CommissionExpense",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "company",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "address",
        "type": "string",
        "format": "",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "addressEn",
        "type": "string",
        "format": "",
        "title": "Address (english)",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "addressRu",
        "type": "string",
        "format": "",
        "title": "Address (russian)",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "bankAccountNumber",
        "type": "string",
        "format": "",
        "title": "Bank account number",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "bankName",
        "type": "string",
        "format": "",
        "title": "Bank",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "bankSwift",
        "type": "string",
        "format": "",
        "title": "SWIFT code",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "fax",
        "type": "string",
        "format": "",
        "title": "Fax",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "ftlUsers",
        "type": "array",
        "format": "",
        "title": "Get the value of ftlUsers",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "company",
        "key": "ftlUsersToFinish",
        "type": "integer",
        "format": "",
        "title": "FTL quotation to finish",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "company",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "company",
        "key": "invoiceSerialPrefix",
        "type": "string",
        "format": "",
        "title": "Invoice numbering",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "logoUrl",
        "type": "string",
        "format": "",
        "title": "Logo URL",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "ltlUsers",
        "type": "array",
        "format": "",
        "title": "Get the value of ltlUsers",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "company",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "nameEn",
        "type": "string",
        "format": "",
        "title": "Name (english)",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "nameRu",
        "type": "string",
        "format": "",
        "title": "Name (russian)",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "Phone",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "company",
        "key": "serialPrefix",
        "type": "string",
        "format": "",
        "title": "Orders numbering",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "stampUrl",
        "type": "string",
        "format": "",
        "title": "Stamp URL",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "webpage",
        "type": "string",
        "format": "",
        "title": "Web page",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "contact",
        "key": "carrier",
        "type": "rel",
        "format": "carrier",
        "title": "Carrier",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "contact",
        "key": "carrierFtlUserId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "contact",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "contact",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "contact",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "contact",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "contact",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "contact",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "contact",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "contact",
        "key": "loadingCountries",
        "type": "array",
        "format": "number",
        "title": "Work direction out",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "contact",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "contact",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            },
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "contact",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "contact",
        "key": "recommended",
        "type": "integer",
        "format": "",
        "title": "Recommended",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Yes",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "contact",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "contact",
        "key": "sender",
        "type": "rel",
        "format": "sender",
        "title": "Seller",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "contact",
        "key": "skype",
        "type": "string",
        "format": "",
        "title": "Skype",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "contact",
        "key": "softRemoved",
        "type": "boolean",
        "format": "",
        "title": "Get the value of softRemoved",
        "additionalProperties": [],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "contact",
        "key": "supplierRailway",
        "type": "rel",
        "format": "supplier-railway",
        "title": "Railway tariff supplier",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "contact",
        "key": "transportServices",
        "type": "array",
        "format": "number",
        "title": "Transport services",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "json",
        "enum": [
            {
                "label": "Air",
                "value": "1",
                "color": "slate"
            },
            {
                "label": "Sea",
                "value": "2",
                "color": "slate"
            },
            {
                "label": "Railway",
                "value": "3",
                "color": "slate"
            },
            {
                "label": "Vehicle (FTL)",
                "value": "4",
                "color": "slate"
            },
            {
                "label": "Vehicle (LTL)",
                "value": "5",
                "color": "slate"
            }
        ],
        "naeType": "enum_multi_number"
    },
    {
        "schema": "contact",
        "key": "unloadingCountries",
        "type": "array",
        "format": "number",
        "title": "Work direction in",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Contact",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "contact-duplicate",
        "key": "clientSource",
        "type": "rel",
        "format": "client",
        "title": "Company 1",
        "additionalProperties": [],
        "description": "",
        "className": "ContactDuplicate",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "contact-duplicate",
        "key": "clientTarget",
        "type": "rel",
        "format": "client",
        "title": "Company 2",
        "additionalProperties": [],
        "description": "",
        "className": "ContactDuplicate",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "contact-duplicate",
        "key": "fixed",
        "type": "integer",
        "format": "",
        "title": "Fixed",
        "additionalProperties": [],
        "description": "",
        "className": "ContactDuplicate",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "contact-duplicate",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ContactDuplicate",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "contact-duplicate",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "ContactDuplicate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "contract-term",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ContractTerm",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "contract-term",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "ContractTerm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "country",
        "key": "addCargoStatusCalc",
        "type": "integer",
        "format": "",
        "title": "Add cargo status in calculation",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "country",
        "key": "allowOnlyT1",
        "type": "boolean",
        "format": "",
        "title": "Allow only T1 status",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "country",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Code",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company for invoice",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "country",
        "key": "countryGeographyGroup",
        "type": "rel",
        "format": "country-geography-group",
        "title": "Group",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "country",
        "key": "countryLogisticGroup",
        "type": "rel",
        "format": "country-logistic-group",
        "title": "Logistic group",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "country",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "country",
        "key": "indexLen",
        "type": "integer",
        "format": "",
        "title": "indexLen",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "country",
        "key": "logisticPoints",
        "type": "number",
        "format": "float",
        "title": "Logistic points",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "country",
        "key": "nameEn",
        "type": "string",
        "format": "",
        "title": "Name (english)",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country",
        "key": "nameLt",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country",
        "key": "nameRu",
        "type": "string",
        "format": "",
        "title": "Name (russian)",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country",
        "key": "needCmr3and13",
        "type": "integer",
        "format": "",
        "title": "Add line about CMR 3,13 graph",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 5,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "country-geography-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CountryGeographyGroup",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "country-geography-group",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CountryGeographyGroup",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country-logistic-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CountryLogisticGroup",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "country-logistic-group",
        "key": "internalPoints",
        "type": "number",
        "format": "float",
        "title": "Internal points",
        "additionalProperties": [],
        "description": "",
        "className": "CountryLogisticGroup",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "country-logistic-group",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CountryLogisticGroup",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country-logistic-group",
        "key": "points",
        "type": "number",
        "format": "float",
        "title": "Points",
        "additionalProperties": [],
        "description": "",
        "className": "CountryLogisticGroup",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "country-region",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "CountryRegion",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "country-region",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CountryRegion",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "country-region",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CountryRegion",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "currency-rate",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "driver",
        "key": "adrCertificateDate",
        "type": "string",
        "format": "date",
        "title": "ADR license",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "adrCertificateDateFile",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "adrCertificateDateHas",
        "type": "boolean",
        "format": "",
        "title": "ADR license",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "after1mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "after1wDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "after2mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "after2wDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after2wDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "archived",
        "type": "boolean",
        "format": "",
        "title": "Archived",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "attorneyValid",
        "type": "string",
        "format": "date",
        "title": "Power of attorney",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "attorneyValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of attorneyValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "attorneyValidHas",
        "type": "boolean",
        "format": "",
        "title": "Power of attorney",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "bank",
        "type": "string",
        "format": "",
        "title": "Bank",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "bankAccount",
        "type": "string",
        "format": "",
        "title": "Bank account",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "before1mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "before2mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "before3mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before3mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "before5mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "before6mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before3mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "code95Date",
        "type": "string",
        "format": "date",
        "title": "95 code",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "code95DateFile",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "code95DateHas",
        "type": "boolean",
        "format": "",
        "title": "95 code",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "driverLicence95Valid",
        "type": "string",
        "format": "date",
        "title": "Drivers certificate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "driverLicence95ValidFile",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "driverLicence95ValidHas",
        "type": "boolean",
        "format": "",
        "title": "Drivers certificate",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "driverLicenceValid",
        "type": "string",
        "format": "date",
        "title": "Driver license",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "driverLicenceValidFile",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "driverLicenceValidHas",
        "type": "boolean",
        "format": "",
        "title": "Driver license",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "driver",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "driver",
        "key": "passportValid",
        "type": "string",
        "format": "date",
        "title": "Passport",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "passportValidFile",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "passportValidHas",
        "type": "boolean",
        "format": "",
        "title": "Passport",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "phoneBy",
        "type": "string",
        "format": "",
        "title": "BY",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "phoneKgz",
        "type": "string",
        "format": "",
        "title": "KGZ",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "phoneKz",
        "type": "string",
        "format": "",
        "title": "KZ",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "phoneLt",
        "type": "string",
        "format": "",
        "title": "LT",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "phoneRu",
        "type": "string",
        "format": "",
        "title": "RU",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "phoneWhatsapp",
        "type": "string",
        "format": "",
        "title": "WhatsApp\/Viber",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "driver",
        "key": "reportsData",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "driver",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "driver",
        "key": "temporaryAdmissionValid",
        "type": "string",
        "format": "date",
        "title": "Temporary admission",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "temporaryAdmissionValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of temporaryAdmissionValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "temporaryAdmissionValidHas",
        "type": "boolean",
        "format": "",
        "title": "Temporary admission",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "driver",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "driver",
        "key": "visaValid",
        "type": "string",
        "format": "date",
        "title": "Drivers visa",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "driver",
        "key": "visaValidFile",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "driver",
        "key": "visaValidHas",
        "type": "boolean",
        "format": "",
        "title": "Drivers visa",
        "additionalProperties": [],
        "description": "",
        "className": "Driver",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "etransport-truck",
        "key": "aw1",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "aw2",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "aw3",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "aw4",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "b1n",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "b1v",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "b2n",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "b2v",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "b3n",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "b3v",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "b4n",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "b4v",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "carId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "carNr",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "fuel",
        "type": "number",
        "format": "float",
        "title": "Fuel",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "ign",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "lat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "locality",
        "type": "string",
        "format": "",
        "title": "Place",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "lon",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "missSignal",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "etransport-truck",
        "key": "odo",
        "type": "integer",
        "format": "",
        "title": "Odometer",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "etransport-truck",
        "key": "rpm",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "speed",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "t1",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "t2",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "etransport-truck",
        "key": "timestamp",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "etransport-truck",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "etransport-truck",
        "key": "tw",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "EtransportTruck",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost",
        "key": "carriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "Get the value of carriersOrder",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "extra-cost",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "extra-cost",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Get the value of kzCarriersOrder",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "extra-cost",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Get the value of salesOrder",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost",
        "key": "type",
        "type": "integer",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCost",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Penalty",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Downtime",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Custom inspection",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "attachments",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Get the value of cargo",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Get the value of company",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "costSupplier",
        "type": "rel",
        "format": "supplier-extra-costs",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "hasFiles",
        "type": "boolean",
        "format": "",
        "title": "Has files?",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Total with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-cargo",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCargo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost-common",
        "key": "attachments",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "extra-cost-common",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Get the value of company",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-common",
        "key": "costSupplier",
        "type": "rel",
        "format": "supplier-extra-costs",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-common",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-common",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-common",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "extra-cost-common",
        "key": "hasFiles",
        "type": "boolean",
        "format": "",
        "title": "Has files?",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "extra-cost-common",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "extra-cost-common",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-common",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-common",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-common",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost-common",
        "key": "supplier",
        "type": "string",
        "format": "",
        "title": "Suppliers",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost-common",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-common",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-common",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Total with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostCommon",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-garant",
        "key": "attachments",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "extra-cost-garant",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Get the value of company",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-garant",
        "key": "costSupplier",
        "type": "rel",
        "format": "supplier-customs",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-garant",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-garant",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-garant",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "extra-cost-garant",
        "key": "hasFiles",
        "type": "boolean",
        "format": "",
        "title": "Has files?",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "extra-cost-garant",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "extra-cost-garant",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-garant",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-garant",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-garant",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost-garant",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-garant",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-garant",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Total with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostGarant",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "attachments",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Get the value of company",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "costSupplier",
        "type": "rel",
        "format": "supplier-railway",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "hasFiles",
        "type": "boolean",
        "format": "",
        "title": "Has files?",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-railway-tarif",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Total with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostRailwayTarif",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "attachments",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Get the value of company",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "hasFiles",
        "type": "boolean",
        "format": "",
        "title": "Has files?",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "extra-cost-terminal",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Total with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ExtraCostTerminal",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "file",
        "key": "appproved",
        "type": "boolean",
        "format": "",
        "title": "Get the value of appproved",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "file",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "deleted",
        "type": "boolean",
        "format": "",
        "title": "Get the value of deleted",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "fileName",
        "type": "string",
        "format": "",
        "title": "Get the value of fileName",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "folder",
        "type": "string",
        "format": "",
        "title": "Get the value of folder",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "file",
        "key": "orgFileName",
        "type": "string",
        "format": "",
        "title": "Get the value of orgFileName",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "Get the value of path",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "files-manager-file",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "files-manager-file",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "files-manager-file",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "files-manager-file",
        "key": "fileName",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "files-manager-file",
        "key": "filesSize",
        "type": "integer",
        "format": "",
        "title": "Size",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "files-manager-file",
        "key": "folder",
        "type": "rel",
        "format": "files-manager-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "files-manager-file",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "files-manager-file",
        "key": "shared",
        "type": "array",
        "format": "string",
        "title": "Shared",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "files-manager-file",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "files-manager-file",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "files-manager-file",
        "key": "uploadTime",
        "type": "string",
        "format": "date-time",
        "title": "Uploaded",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFile",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "files-manager-folder",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "files-manager-folder",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "files-manager-folder",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "files-manager-folder",
        "key": "files",
        "type": "array",
        "format": "files-manager-file",
        "title": "",
        "additionalProperties": [
            {
                "mapped": "folder"
            }
        ],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "files-manager-folder",
        "key": "filesCount",
        "type": "integer",
        "format": "",
        "title": "Number of files",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "files-manager-folder",
        "key": "filesSize",
        "type": "integer",
        "format": "",
        "title": "Files size",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "files-manager-folder",
        "key": "folderName",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "files-manager-folder",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "files-manager-folder",
        "key": "lastUploadTime",
        "type": "string",
        "format": "date-time",
        "title": "Uploaded file",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "files-manager-folder",
        "key": "shared",
        "type": "array",
        "format": "string",
        "title": "Shared",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "files-manager-folder",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "files-manager-folder",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "FilesManagerFolder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "filter-change",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "FilterChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "filter-change",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "FilterChange",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "filter-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FilterChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "filter-change",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "FilterChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "filter-change",
        "key": "nextMileage",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FilterChange",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "filter-change",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "FilterChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "filter-change",
        "key": "validMileage",
        "type": "integer",
        "format": "",
        "title": "Valid mileage",
        "additionalProperties": [],
        "description": "",
        "className": "FilterChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "follow-up",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "follow-up",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "follow-up",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "follow-up",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "follow-up",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "follow-up",
        "key": "onDate",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "follow-up",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "follow-up",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "follow-up",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "follow-up",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "insurance-rates",
        "key": "cargoValue",
        "type": "number",
        "format": "float",
        "title": "Cargo value",
        "additionalProperties": [],
        "description": "",
        "className": "InsuranceRates",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "insurance-rates",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InsuranceRates",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "insurance-rates",
        "key": "ldm",
        "type": "number",
        "format": "float",
        "title": "CLM",
        "additionalProperties": [],
        "description": "",
        "className": "InsuranceRates",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "inventory",
        "key": "conclusions",
        "type": "string",
        "format": "text",
        "title": "Conclusion about transport state",
        "additionalProperties": [],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "inventory",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "inventory",
        "key": "file",
        "type": "rel",
        "format": "file",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "inventory",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "inventory",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "inventory",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "inventory",
        "key": "trailer",
        "type": "rel",
        "format": "trailer",
        "title": "Trailer",
        "additionalProperties": [],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "inventory",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "Inventory",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "attachments",
        "type": "array",
        "format": "string",
        "title": "Invoice file",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "invoice-incoming",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "cargoSoSerialNumber",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "cargoType",
        "type": "string",
        "format": "",
        "title": "Additional costs type",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "",
                "value": "",
                "color": "slate"
            },
            {
                "label": "Insurance",
                "value": "insurance",
                "color": "slate"
            },
            {
                "label": "COO certificate",
                "value": "coo_cert",
                "color": "slate"
            },
            {
                "label": "phytosanitary certificate",
                "value": "fito_cert",
                "color": "slate"
            },
            {
                "label": "Crane",
                "value": "crane",
                "color": "slate"
            },
            {
                "label": "Terminal expenses",
                "value": "terminal",
                "color": "slate"
            },
            {
                "label": "Alcohol package getting",
                "value": "alco_marks",
                "color": "slate"
            },
            {
                "label": "Others",
                "value": "others",
                "color": "slate"
            },
            {
                "label": "Port taxes",
                "value": "port_dues",
                "color": "slate"
            },
            {
                "label": "Penalty",
                "value": "penalty",
                "color": "slate"
            },
            {
                "label": "For EX\/T1",
                "value": "ex_t1",
                "color": "slate"
            },
            {
                "label": "For additional carriage",
                "value": "additional_carriage",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "invoice-incoming",
        "key": "carrier",
        "type": "rel",
        "format": "carrier",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "carriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "Get the value of carriersOrder",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "clientBankAccountNumber",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "clientChecked",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "invoice-incoming",
        "key": "clientCode",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "clientIdAndSchema",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "invoice-incoming",
        "key": "clientName",
        "type": "string",
        "format": "",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "clientVatNumber",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "costSupplier",
        "type": "rel",
        "format": "supplier-customs",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "costSupplierExtra",
        "type": "rel",
        "format": "supplier-extra-costs",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "costSupplierRailway",
        "type": "rel",
        "format": "supplier-railway",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice-incoming",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-incoming",
        "key": "includeToAutomaticPayments",
        "type": "integer",
        "format": "",
        "title": "Include to automatic payments\t",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "invoice-incoming",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-incoming",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-incoming",
        "key": "invoicePaidDate",
        "type": "string",
        "format": "date-time",
        "title": "Paid date",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "date",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Get the value of kzCarriersOrder",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "overdueTotalWithVat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "invoice-incoming",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "invoice-incoming",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "invoice-incoming",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Supplier",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "For carriage",
                "value": "invoice",
                "color": "slate"
            },
            {
                "label": "For terminal expenses",
                "value": "terminal",
                "color": "slate"
            },
            {
                "label": "For guarantee expenses",
                "value": "garant",
                "color": "slate"
            },
            {
                "label": "For extra expenses",
                "value": "extra",
                "color": "slate"
            },
            {
                "label": "Additional costs for cargo",
                "value": "cargo_invoice",
                "color": "slate"
            },
            {
                "label": "For penalty",
                "value": "penalty",
                "color": "slate"
            },
            {
                "label": "For railway tariff",
                "value": "railway",
                "color": "slate"
            },
            {
                "label": "For platform rent",
                "value": "railway_platform_rent",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "invoice-incoming",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice-incoming",
        "key": "vat",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "attachments",
        "type": "array",
        "format": "string",
        "title": "Attachments",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "invoices",
        "type": "array",
        "format": "int",
        "title": "Get the value of invoices",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Serial number",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "invoice-incoming-sepa-export",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncomingSepaExport",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice-outgoing",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "clientCode",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-outgoing",
        "key": "clientId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-outgoing",
        "key": "clientName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-outgoing",
        "key": "clientVatNumber",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-outgoing",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-outgoing",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-outgoing",
        "key": "invoiceOutgoing",
        "type": "rel",
        "format": "invoice-outgoing",
        "title": "Get the value of invoiceOutgoing",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "invoiceOutgoingRequest",
        "type": "rel",
        "format": "invoice-outgoing-credit-request",
        "title": "Get the value of invoiceOutgoingRequest",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "invoices",
        "type": "array",
        "format": "invoice-outgoing",
        "title": "Invoices",
        "additionalProperties": [
            {
                "mapped": "invoiceOutgoing"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice-outgoing",
        "key": "isTmpInvoice",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "invoice-outgoing",
        "key": "payedTotal",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Get the value of payments",
        "additionalProperties": [
            {
                "mapped": "invoiceOutgoing"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice-outgoing",
        "key": "priceChanges",
        "type": "array",
        "format": "invoice-outgoing-price-change",
        "title": "Get the value of priceChanges",
        "additionalProperties": [
            {
                "mapped": "invoiceOutgoing"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice-outgoing",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Get the value of salesOrder",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "invoice-outgoing",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice-outgoing",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "totalDebt",
        "type": "number",
        "format": "float",
        "title": "Debt",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "type",
        "type": "integer",
        "format": "",
        "title": "Get the value of type",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-outgoing",
        "key": "vat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "vatTotal",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created at",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "denyReason",
        "type": "string",
        "format": "text",
        "title": "Deny reason",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "invoiceOutgoing",
        "type": "rel",
        "format": "invoice-outgoing",
        "title": "Invoice",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "issueReason",
        "type": "string",
        "format": "",
        "title": "Issue reason",
        "additionalProperties": [],
        "description": "<p>If you need to change the price, you can make changes in the invoice.<\/p>",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "longtext",
        "enum": [
            {
                "label": "Incorrect transportation price",
                "value": "Incorrect transportation price",
                "color": "slate"
            },
            {
                "label": "Service was not provided",
                "value": "Service was not provided",
                "color": "slate"
            },
            {
                "label": "Duplicated invoice",
                "value": "Duplicated invoice",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Sales order",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing-credit-request",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingCreditRequest",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice-outgoing-price-change",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-price-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-outgoing-price-change",
        "key": "invoiceOutgoing",
        "type": "rel",
        "format": "invoice-outgoing",
        "title": "Invoice",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-price-change",
        "key": "note",
        "type": "string",
        "format": "text",
        "title": "Change reason",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingPriceChange",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "invoice-outgoing-price-change",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing-price-change",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoingPriceChange",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "invoice-outgoing-price-change",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "New price",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoingPriceChange",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "accounterNotes",
        "type": "string",
        "format": "",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "additionalGarantCost",
        "type": "integer",
        "format": "",
        "title": "Additional invoices for garant expenses",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "adrClass",
        "type": "rel",
        "format": "adr-class",
        "title": "ADR class",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "allCargoValue",
        "type": "number",
        "format": "float",
        "title": "Cargo value",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "allCargoValueWithoutInsured",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "allCbm",
        "type": "number",
        "format": "float",
        "title": "CBM",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "allLdm",
        "type": "number",
        "format": "float",
        "title": "CLM",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "allWeight",
        "type": "number",
        "format": "float",
        "title": "Total weight",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "arrivedAtLoad",
        "type": "boolean",
        "format": "",
        "title": "Get the value of arrivedAtLoad",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order",
        "key": "carNumber",
        "type": "string",
        "format": "",
        "title": "Vehicle number \/ Partners name",
        "additionalProperties": [],
        "description": "<p><strong>Carriage like partial cargo<\/strong> (write partners name (local carriers, that will take cargo) or vehicle number)<\/p><p><strong>Carriage full vehicle <\/strong>(write vehicle number)<\/p><p><strong>Sea shipment<\/strong> (write sea lines name)<\/p><p><strong>Carriage by railway<\/strong> (write railway transport number)<\/p><p><strong>Air carriage<\/strong> (write airlines name)<\/p>",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargoExportType",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargoImportType",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargoNotes",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargoRef",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargoUnloadAddress",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargoUnloadCountry",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargos",
        "type": "array",
        "format": "cargo",
        "title": "Cargos",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "cargosCount",
        "type": "integer",
        "format": "",
        "title": "Cargos",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "client",
        "type": "rel",
        "format": "carrier",
        "title": "Carrier",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "contactedCarrier",
        "type": "boolean",
        "format": "",
        "title": "Contacted seller",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "creatorFullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "customsClearance",
        "type": "integer",
        "format": "",
        "title": "Who will make customs clearance?",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Cargo seller",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Our company",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order",
        "key": "dateChanges",
        "type": "array",
        "format": "",
        "title": "Get the value of dateChanges",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "dimensions",
        "type": "string",
        "format": "",
        "title": "Dimensions",
        "additionalProperties": [],
        "description": "<p>If the line will be clear, then data will be imported from cargos.<\/p>",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "dpsNotes",
        "type": "string",
        "format": "text",
        "title": "My notes (DPS)",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "kz-carriers-order",
        "key": "driver",
        "type": "string",
        "format": "",
        "title": "Drivers contacts",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "expeditorFtl",
        "type": "rel",
        "format": "user",
        "title": "Responsible",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostCommon",
        "type": "array",
        "format": "extra-cost-common",
        "title": "Extra expenses",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostCommonInvoices",
        "type": "array",
        "format": "invoice-incoming",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostRailwayPlatformInvoices",
        "type": "array",
        "format": "invoice-incoming",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostRailwayTarif",
        "type": "array",
        "format": "extra-cost-railway-tarif",
        "title": "Railway tariff",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostRailwayTarifInvoices",
        "type": "array",
        "format": "invoice-incoming",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCosts",
        "type": "array",
        "format": "extra-cost",
        "title": "Extra expenses",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostsGarant",
        "type": "array",
        "format": "extra-cost-garant",
        "title": "Garant expenses",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostsGarantInvoices",
        "type": "array",
        "format": "invoice-incoming",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostsTerminal",
        "type": "array",
        "format": "extra-cost-terminal",
        "title": "Terminal expenses",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraCostsTerminalInvoices",
        "type": "array",
        "format": "invoice-incoming",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraTotalCustomsInspection",
        "type": "number",
        "format": "float",
        "title": "Customs inspection",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraTotalDeliveryClient",
        "type": "number",
        "format": "float",
        "title": "Price for delivery to the client?",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraTotalDowntime",
        "type": "number",
        "format": "float",
        "title": "For downtime",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraTotalPenalty",
        "type": "number",
        "format": "float",
        "title": "Penalty",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraTotalSvh",
        "type": "number",
        "format": "float",
        "title": "How much paid for extra unloading?",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "extraTotalTemperatureMode",
        "type": "number",
        "format": "float",
        "title": "What was the price of temperature mode?",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "ftlCalculations",
        "type": "array",
        "format": "kz-ftl-calculation",
        "title": "Get the value of ftlCalculations",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "inspection",
        "type": "integer",
        "format": "",
        "title": "Inspection?",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "internalComment",
        "type": "string",
        "format": "text",
        "title": "My notes",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "kz-carriers-order",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order",
        "key": "invoiceIncomings",
        "type": "array",
        "format": "invoice-incoming",
        "title": "Get the value of invoiceIncomings",
        "additionalProperties": [
            {
                "mapped": "kzCarriersOrder"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "invoiceIssueDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order",
        "key": "invoiceNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "isFtlDirect",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order",
        "key": "kzInspection",
        "type": "rel",
        "format": "kz-inspection",
        "title": "Customs inspection",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "loadDate",
        "type": "string",
        "format": "date",
        "title": "Loading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order",
        "key": "loadingCountry",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "location",
        "type": "string",
        "format": "",
        "title": "Place",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "locationComment",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "locationUpdateDate",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order",
        "key": "locationUpdateDateDanger",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order",
        "key": "mergeMode",
        "type": "boolean",
        "format": "",
        "title": "Get the value of mergeMode",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "needExtraDeliveryLdm",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "needFtlSupport",
        "type": "integer",
        "format": "",
        "title": "Share with FTL logist?",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "needTemperatureLdm",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "<p>Write extra terms that you agreed with carrier.<\/p>",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "kz-carriers-order",
        "key": "notesForFtlCalculation",
        "type": "string",
        "format": "",
        "title": "Notes FTL",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "orderTerminalName",
        "type": "string",
        "format": "",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "orderTerminals",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "paymentTerms",
        "type": "string",
        "format": "",
        "title": "Payment term",
        "additionalProperties": [],
        "description": "<p>Choose agreed with carrier payment term. When you choose payment text will be translated automatically to document language. If you will write text by yourself text will not be translated.<\/p>",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "pdfFileName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "places",
        "type": "number",
        "format": "float",
        "title": "Places number",
        "additionalProperties": [],
        "description": "<p>If this will be clear, data will be imported from cargos.<\/p>",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "posibleGarantCosts",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "possibleTerminalCosts",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "railwayNumber",
        "type": "string",
        "format": "",
        "title": "Railway number",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "railwayTariffPayer",
        "type": "integer",
        "format": "",
        "title": "Who will pay for railway tariff?",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Our company",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Carrier",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "responseDocsFinish",
        "type": "boolean",
        "format": "",
        "title": "Documents dropped in",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "kz-carriers-order",
        "key": "status15Date",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "status15OrLoadDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "status15OrLoadDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "status20Date",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "status30Date",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order",
        "key": "statusChanges",
        "type": "array",
        "format": "string",
        "title": "Status changes",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "svhLdm",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Total with VAT",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order",
        "key": "transportAutoType",
        "type": "integer",
        "format": "",
        "title": "Vehicle type",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Standard tent",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Refrigerator",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Road train",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Mega",
                "value": 40,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "transportGuaranteeType",
        "type": "integer",
        "format": "",
        "title": "Carriage guarantee procedure",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Guarantee (we pay for)",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Tir (we pay for)",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Guarantee (carrier pays for)",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Tir (EPI carrier pays for)",
                "value": 40,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "transportRailwayType",
        "type": "integer",
        "format": "",
        "title": "Railway type",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Wagon",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Container",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "transportType",
        "type": "integer",
        "format": "",
        "title": "Carriage type",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Carriage by road (full truck)",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Railway",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Air",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Carriage by road (partial through expeditor)",
                "value": 50,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "trip",
        "type": "string",
        "format": "text",
        "title": "Get the value of trip",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "text"
    },
    {
        "schema": "kz-carriers-order",
        "key": "tripLongAddress",
        "type": "array",
        "format": "string",
        "title": "Get the value of trip",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "tripSort",
        "type": "array",
        "format": "string",
        "title": "Get the value of tripSort",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "kz-carriers-order",
        "key": "trueUnloadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order",
        "key": "unloadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order",
        "key": "userDps",
        "type": "rel",
        "format": "user",
        "title": "Responsible Document Processing Specialist",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order",
        "key": "vehicleStatus",
        "type": "integer",
        "format": "",
        "title": "Vehicle status",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Registered truck",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Transit truck",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "kz-carriers-order",
        "key": "weight",
        "type": "number",
        "format": "float",
        "title": "Total weight (kg)",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "loadDate",
        "type": "string",
        "format": "date",
        "title": "New loading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "oldLoadDate",
        "type": "string",
        "format": "date",
        "title": "Old loading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "oldUnloadDate",
        "type": "string",
        "format": "date",
        "title": "Old unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "unloadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-carriers-order-load-date-change",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLoadDateChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "newLocation",
        "type": "string",
        "format": "",
        "title": "New",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "oldLocation",
        "type": "string",
        "format": "",
        "title": "Old",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order-location",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated at",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderLocation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "carriers-order",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-carriers-order-problem",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "KzCarriersOrderProblem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "hasFile",
        "type": "boolean",
        "format": "",
        "title": "Has file",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "sender",
        "type": "rel",
        "format": "sender",
        "title": "Seller",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "sent",
        "type": "boolean",
        "format": "",
        "title": "Sent",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "sentTime",
        "type": "string",
        "format": "datetime",
        "title": "Sent time",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "enum": [
            {
                "label": "IE599",
                "value": "IE599",
                "color": "slate"
            },
            {
                "label": "EX1",
                "value": "20",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "kz-ex-1-files-send",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Get the value of cargo",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Get the value of contact",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "hasFile",
        "type": "boolean",
        "format": "",
        "title": "Get the value of hasFile",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "sender",
        "type": "rel",
        "format": "sender",
        "title": "Get the value of sender",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "sent",
        "type": "boolean",
        "format": "",
        "title": "Get the value of sent",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "sentTime",
        "type": "string",
        "format": "datetime",
        "title": "Get the value of sentTime",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Get the value of type",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-ex1-files-send",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "KzEx1FilesSend",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "client",
        "type": "rel",
        "format": "carrier",
        "title": "Carrier",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Supposed loading date",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "Get the value of kzCarriersOrder",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "kz-ftl-calculation",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "KzFtlCalculation",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "kz-inspection",
        "key": "additionalGarantCost",
        "type": "boolean",
        "format": "",
        "title": "Will be an additional bill?",
        "additionalProperties": [],
        "description": "",
        "className": "KzInspection",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "kz-inspection",
        "key": "finishDate",
        "type": "string",
        "format": "date-time",
        "title": "Finish date",
        "additionalProperties": [],
        "description": "",
        "className": "KzInspection",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "kz-inspection",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzInspection",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-inspection",
        "key": "kzCarriersOrder",
        "type": "rel",
        "format": "kz-carriers-order",
        "title": "KZ Carriers order",
        "additionalProperties": [],
        "description": "",
        "className": "KzInspection",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "kz-inspection",
        "key": "needPhotos",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzInspection",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "kz-inspection",
        "key": "photos",
        "type": "array",
        "format": "string",
        "title": "Photos",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "KzInspection",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "kz-inspection",
        "key": "startDate",
        "type": "string",
        "format": "date-time",
        "title": "Start date",
        "additionalProperties": [],
        "description": "",
        "className": "KzInspection",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "kz-inspection",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "KzInspection",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "kz-location-suggest",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KzLocationSuggest",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "kz-location-suggest",
        "key": "text",
        "type": "string",
        "format": "",
        "title": "Text",
        "additionalProperties": [],
        "description": "",
        "className": "KzLocationSuggest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "lead-lost-reason",
        "key": "blockOnRepeat",
        "type": "integer",
        "format": "",
        "title": "Block after X repeat",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-lost-reason",
        "key": "cleanLeadLostReasonOnImport",
        "type": "boolean",
        "format": "",
        "title": "Delete refusals during import",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "lead-lost-reason",
        "key": "color",
        "type": "string",
        "format": "",
        "title": "Colour",
        "additionalProperties": [
            {
                "as": "color"
            }
        ],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "varchar",
        "as": "color",
        "naeType": "color"
    },
    {
        "schema": "lead-lost-reason",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-lost-reason",
        "key": "pauseDays",
        "type": "integer",
        "format": "",
        "title": "Break",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-lost-reason",
        "key": "sendToBusinessDev",
        "type": "integer",
        "format": "",
        "title": "Send to development manager",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "lead-lost-reason",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-lost-reason",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostReason",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "lead-lost-record",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "lead-lost-record",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "lead-lost-record",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "lead-lost-record",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "lead-lost-record",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-lost-record",
        "key": "leadLostReason",
        "type": "rel",
        "format": "lead-lost-reason",
        "title": "Reason",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "lead-lost-record",
        "key": "reasonId",
        "type": "integer",
        "format": "",
        "title": "TMP",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-lost-record",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "lead-lost-record",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "LeadLostRecord",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "lead-source",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSource",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "lead-source",
        "key": "category",
        "type": "rel",
        "format": "lead-source-category",
        "title": "Category",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSource",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "lead-source",
        "key": "categoryIndex",
        "type": "integer",
        "format": "",
        "title": "Index",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSource",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "lead-source",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSource",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "lead-source",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSource",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-source",
        "key": "priority",
        "type": "integer",
        "format": "",
        "title": "Priority",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "LeadSource",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "lead-source",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "lead-source-category",
        "key": "cleanLeadLostReasonOnImport",
        "type": "boolean",
        "format": "",
        "title": "Delete refusal during import",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSourceCategory",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "lead-source-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSourceCategory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "lead-source-category",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSourceCategory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "lead-source-category",
        "key": "sendToBusinessDev",
        "type": "integer",
        "format": "",
        "title": "Send to development manager",
        "additionalProperties": [],
        "description": "",
        "className": "LeadSourceCategory",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "load-address",
        "key": "address",
        "type": "string",
        "format": "",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "LoadAddress",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "load-address",
        "key": "addressIndex",
        "type": "string",
        "format": "",
        "title": "Index",
        "additionalProperties": [],
        "description": "<p>Write only number, without any letters. (ex. DE50427, write like 50427)<\/p>",
        "className": "LoadAddress",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "load-address",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "LoadAddress",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "load-address",
        "key": "fullAddress",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LoadAddress",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "load-address",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "LoadAddress",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "load-address",
        "key": "logisticPoints",
        "type": "number",
        "format": "float",
        "title": "Logistic points",
        "additionalProperties": [],
        "description": "",
        "className": "LoadAddress",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "load-address",
        "key": "sender",
        "type": "rel",
        "format": "sender",
        "title": "Seller",
        "additionalProperties": [],
        "description": "",
        "className": "LoadAddress",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "load-address",
        "key": "senderRequirements",
        "type": "string",
        "format": "text",
        "title": "Seller requirements",
        "additionalProperties": [],
        "description": "",
        "className": "LoadAddress",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "load-address",
        "key": "stockWorkTime",
        "type": "string",
        "format": "",
        "title": "Warehouse work time",
        "additionalProperties": [],
        "description": "",
        "className": "LoadAddress",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "mail",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "mail",
        "key": "customId",
        "type": "string",
        "format": "",
        "title": "Get the value of customId",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "mail",
        "key": "html",
        "type": "string",
        "format": "text",
        "title": "Content",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "mail",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "mail",
        "key": "lastStatus",
        "type": "string",
        "format": "",
        "title": "Last status",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "mail",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "recipient",
        "type": "string",
        "format": "",
        "title": "Recipient",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "mail",
        "key": "subject",
        "type": "string",
        "format": "",
        "title": "Subject",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "note",
        "key": "calculation",
        "type": "rel",
        "format": "calculation",
        "title": "",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "content",
        "type": "string",
        "format": "text",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "note",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "note",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "notify",
        "type": "array",
        "format": "number|string",
        "title": "Inform",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "notifyAccept",
        "type": "array",
        "format": "number|string",
        "title": "Informed",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "note",
        "key": "replyTo",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "note",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "notification",
        "key": "calculation",
        "type": "rel",
        "format": "calculation",
        "title": "Get the value of quotation",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "notification",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Get the value of cargo",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "notification",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "notification",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "notification",
        "key": "dismissOn",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of dismissOn",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "notification",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "notification",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "notification",
        "key": "notification",
        "type": "string",
        "format": "text",
        "title": "Notification",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "notification",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Get the value of salesOrder",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "notification",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "notification",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "notification",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "Notification",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "oil-change",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "OilChange",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "oil-change",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "OilChange",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "oil-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OilChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "oil-change",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "OilChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "oil-change",
        "key": "nextMileage",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OilChange",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "oil-change",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "OilChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "oil-change",
        "key": "validMileage",
        "type": "integer",
        "format": "",
        "title": "Valid mileage",
        "additionalProperties": [],
        "description": "",
        "className": "OilChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "outgoing-documents-change-purpose",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "Extra expenses",
        "additionalProperties": [],
        "description": "",
        "className": "OutgoingDocumentsChangePurpose",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "outgoing-documents-change-purpose",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OutgoingDocumentsChangePurpose",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "outgoing-documents-change-purpose",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "OutgoingDocumentsChangePurpose",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "outgoing-documents-change-purpose",
        "key": "needConfirmMail",
        "type": "integer",
        "format": "",
        "title": "Need confirmation mail",
        "additionalProperties": [],
        "description": "",
        "className": "OutgoingDocumentsChangePurpose",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "outgoing-documents-change-purpose",
        "key": "salaryPoints",
        "type": "number",
        "format": "float",
        "title": "Salary points",
        "additionalProperties": [],
        "description": "",
        "className": "OutgoingDocumentsChangePurpose",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "bank",
        "type": "rel",
        "format": "bank",
        "title": "Bank",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment",
        "key": "invoiceOutgoing",
        "type": "rel",
        "format": "invoice-outgoing",
        "title": "Invoice",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Sales order",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "Total, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Payment",
                "value": "",
                "color": "slate"
            },
            {
                "label": "Write-off",
                "value": "write-off",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "photo-album",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "PhotoAlbum",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "photo-album",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "PhotoAlbum",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "photo-album",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PhotoAlbum",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "photo-album",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "PhotoAlbum",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "photo-album",
        "key": "trailer",
        "type": "rel",
        "format": "trailer",
        "title": "Trailer",
        "additionalProperties": [],
        "description": "",
        "className": "PhotoAlbum",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "photo-album",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "PhotoAlbum",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "po-cancel-reason",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PoCancelReason",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "po-cancel-reason",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "PoCancelReason",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "price-list-ftl",
        "key": "autoTrain",
        "type": "number",
        "format": "float",
        "title": "Autotrain",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "autoTrainProfit",
        "type": "number",
        "format": "float",
        "title": "Autotrain profit",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "autoTrainProfitOverload",
        "type": "number",
        "format": "float",
        "title": "Autotrain profit (overload)",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "autoTrainRaw",
        "type": "number",
        "format": "float",
        "title": "Autotrain Raw",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "city",
        "type": "string",
        "format": "",
        "title": "City",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "price-list-ftl",
        "key": "garant",
        "type": "number",
        "format": "float",
        "title": "Guarantee",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "garantCar",
        "type": "number",
        "format": "float",
        "title": "Guarantee truck",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "price-list-ftl",
        "key": "mega",
        "type": "number",
        "format": "float",
        "title": "Mega",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "megaProfit",
        "type": "number",
        "format": "float",
        "title": "Mega profit",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "megaProfitOverload",
        "type": "number",
        "format": "float",
        "title": "Mega profit (overload)",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "megaRaw",
        "type": "number",
        "format": "float",
        "title": "Mega Raw",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "ref",
        "type": "number",
        "format": "float",
        "title": "Refrigerator",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "refProfit",
        "type": "number",
        "format": "float",
        "title": "Refrigerator profit",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "refProfitOverload",
        "type": "number",
        "format": "float",
        "title": "Refrigerator profit (overload)",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "refRaw",
        "type": "number",
        "format": "float",
        "title": "Refrigerator Raw",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "tent",
        "type": "number",
        "format": "float",
        "title": "Tent",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "tentProfit",
        "type": "number",
        "format": "float",
        "title": "Tent profit",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "tentProfitOverload",
        "type": "number",
        "format": "float",
        "title": "Tent profit (overload)",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "tentRaw",
        "type": "number",
        "format": "float",
        "title": "Tent Raw",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ftl",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListFtl",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "price-list-garant",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListGarant",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "price-list-garant",
        "key": "insurancePercent",
        "type": "number",
        "format": "float",
        "title": "Self cost, %",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListGarant",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-garant",
        "key": "insuranceValue",
        "type": "number",
        "format": "float",
        "title": "Self cost, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListGarant",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-garant",
        "key": "valueFrom",
        "type": "number",
        "format": "float",
        "title": "Value from",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListGarant",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-garant",
        "key": "valueTo",
        "type": "number",
        "format": "float",
        "title": "Value to",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListGarant",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-garant",
        "key": "zoneName",
        "type": "string",
        "format": "",
        "title": "Guarantee (Zone)",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListGarant",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "price-list-ltl",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtl",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "price-list-ltl",
        "key": "extraCost",
        "type": "number",
        "format": "float",
        "title": "Extra expenses",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtl",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtl",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "price-list-ltl",
        "key": "lines",
        "type": "array",
        "format": "price-list-ltl-line",
        "title": "Prices",
        "additionalProperties": [
            {
                "mapped": "priceListLtl"
            }
        ],
        "description": "",
        "className": "PriceListLtl",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "price-list-ltl",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtl",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "price-list-ltl",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtl",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "Self cost",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "costWithoutGarant",
        "type": "number",
        "format": "float",
        "title": "Self cost without guarantee",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "ldm",
        "type": "number",
        "format": "float",
        "title": "CLM",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "priceListLtl",
        "type": "rel",
        "format": "price-list-ltl",
        "title": "Price list",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "profit1",
        "type": "number",
        "format": "float",
        "title": "Recommended margin 1",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "profit2",
        "type": "number",
        "format": "float",
        "title": "Recommended margin 2-3",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "profit4",
        "type": "number",
        "format": "float",
        "title": "Recommended margin 4",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "profit5",
        "type": "number",
        "format": "float",
        "title": "Recommended margin 5",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-ltl-line",
        "key": "profit6",
        "type": "number",
        "format": "float",
        "title": "Recommended margin 6",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListLtlLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-railway-carriage",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "Self cost",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayCarriage",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-railway-carriage",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayCarriage",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "price-list-railway-carriage",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayCarriage",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "price-list-railway-carriage",
        "key": "railwayType",
        "type": "integer",
        "format": "",
        "title": "Railway vehicle",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayCarriage",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Wagon 138m3",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "CMGV \"raketovozas\"",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Wagon 150m3",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Wagon 158m3",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Wagon thermos 918 (IVT)",
                "value": 40,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "price-list-railway-carriage",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayCarriage",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "price-list-railway-carriage",
        "key": "warehouseCost",
        "type": "number",
        "format": "float",
        "title": "Warehouse self cost",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayCarriage",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-railway-container",
        "key": "city",
        "type": "string",
        "format": "",
        "title": "City",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "price-list-railway-container",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "Self cost",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-railway-container",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "price-list-railway-container",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "price-list-railway-container",
        "key": "platformCost",
        "type": "number",
        "format": "float",
        "title": "Platform self cost",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "price-list-railway-container",
        "key": "railwayType",
        "type": "integer",
        "format": "",
        "title": "Railway vehicle",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Container 20 feet",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Container 40 feet",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Container 20 feet “High cube”",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Container 40 feet “High cube”",
                "value": 50,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "price-list-railway-container",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "price-list-railway-container",
        "key": "warehouseCost",
        "type": "number",
        "format": "float",
        "title": "Warehouse self cost",
        "additionalProperties": [],
        "description": "",
        "className": "PriceListRailwayContainer",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "purpose",
        "key": "completed",
        "type": "boolean",
        "format": "",
        "title": "Completed",
        "additionalProperties": [],
        "description": "",
        "className": "Purpose",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "purpose",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Purpose",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "purpose",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Goal",
        "additionalProperties": [],
        "description": "",
        "className": "Purpose",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "purpose",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Executor",
        "additionalProperties": [],
        "description": "",
        "className": "Purpose",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "purpose",
        "key": "dueDate",
        "type": "string",
        "format": "date",
        "title": "Due date",
        "additionalProperties": [],
        "description": "",
        "className": "Purpose",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "purpose",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Get the value of files",
        "additionalProperties": [
            {
                "as": "file"
            }
        ],
        "description": "",
        "className": "Purpose",
        "isDb": true,
        "dbType": "json",
        "as": "file",
        "naeType": "file"
    },
    {
        "schema": "purpose",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Purpose",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "purpose",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Purpose",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "repair-tension-bearings",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "RepairTensionBearings",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "repair-tension-bearings",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "RepairTensionBearings",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "repair-tension-bearings",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RepairTensionBearings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "repair-tension-bearings",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "RepairTensionBearings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "repair-tension-bearings",
        "key": "nextMileage",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RepairTensionBearings",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "repair-tension-bearings",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "RepairTensionBearings",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "repair-tension-bearings",
        "key": "validMileage",
        "type": "integer",
        "format": "",
        "title": "Valid mileage",
        "additionalProperties": [],
        "description": "",
        "className": "RepairTensionBearings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "repair-water-pomp",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "RepairWaterPomp",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "repair-water-pomp",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "RepairWaterPomp",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "repair-water-pomp",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RepairWaterPomp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "repair-water-pomp",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "RepairWaterPomp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "repair-water-pomp",
        "key": "nextMileage",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RepairWaterPomp",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "repair-water-pomp",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "RepairWaterPomp",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "repair-water-pomp",
        "key": "validMileage",
        "type": "integer",
        "format": "",
        "title": "Valid mileage",
        "additionalProperties": [],
        "description": "",
        "className": "RepairWaterPomp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "salary-point-cost",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalaryPointCost",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "salary-point-cost",
        "key": "cost",
        "type": "number",
        "format": "float",
        "title": "One point self cost",
        "additionalProperties": [],
        "description": "",
        "className": "SalaryPointCost",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "salary-point-cost",
        "key": "date",
        "type": "string",
        "format": "",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "SalaryPointCost",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "salary-point-cost",
        "key": "fullCost",
        "type": "number",
        "format": "float",
        "title": "Administrative expenses",
        "additionalProperties": [],
        "description": "",
        "className": "SalaryPointCost",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "salary-point-cost",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalaryPointCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "accounterNotes",
        "type": "string",
        "format": "",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "actDate",
        "type": "string",
        "format": "date",
        "title": "Act date",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-order",
        "key": "actRatio",
        "type": "integer",
        "format": "",
        "title": "Rate",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "addNote",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "address",
        "type": "rel",
        "format": "unload-address",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "allCargoValueEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "allCargosOneUnload",
        "type": "integer",
        "format": "",
        "title": "How you want cargos to be delivered to client?",
        "additionalProperties": [],
        "description": "<p>Choose wanted transportation principle. All clients cargos will be transported with other client cargos, or client will receive all his cargos in his private vehicle.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "How partial cargo with other clients cargos ",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "In a separate vehicle without other clients cargos",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "sales-order",
        "key": "allCargosOut",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "allCbm",
        "type": "number",
        "format": "float",
        "title": "CBM",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "allLdm",
        "type": "number",
        "format": "float",
        "title": "CLM",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "allWeight",
        "type": "number",
        "format": "float",
        "title": "Total weight",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "badges",
        "type": "array",
        "format": "string",
        "title": "Badges",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "calculation",
        "type": "rel",
        "format": "calculation",
        "title": "Quotation",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "calculationJson",
        "type": "array",
        "format": "string",
        "title": "ICargoCalculation",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "canSeparateCargos",
        "type": "integer",
        "format": "",
        "title": "Can cargo leave in a different truck?",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "sales-order",
        "key": "cargoExportType",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "cargoRelType",
        "type": "integer",
        "format": "",
        "title": "Type of transportation",
        "additionalProperties": [],
        "description": "<p>Carriage by road (LTL \/ Partial cargo) - partial cargos carriage by road.<\/p><p>Carriage by road (FTL \/ Full cargo) - full cargos carriage by road.<\/p><p>Air - cargos air shipment.<\/p><p>Multimodal - cargos carriage processing with more than one type of vehicle.<\/p><p>Seller delivers to our warehouse - seller provides cargo in our warehouse.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Carriage by road (LTL \/ Partial cargo)",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Carriage by road (FTL \/ Full cargo)",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Air",
                "value": 30,
                "color": "slate"
            },
            {
                "label": "Multimodal",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Seller delivers to our warehouse",
                "value": 50,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "sales-order",
        "key": "cargoSenders",
        "type": "string",
        "format": "",
        "title": "Sellers",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "cargoTerminals",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "cargos",
        "type": "array",
        "format": "cargo",
        "title": "Cargos",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "cargosAsString",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "cargosCount",
        "type": "integer",
        "format": "",
        "title": "Cargos",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "clientCountryName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "clientFilial",
        "type": "rel",
        "format": "client-filial",
        "title": "Alternative name",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "clientStatus",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "clientTrackInfo",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "commissionExpenses",
        "type": "array",
        "format": "commission-expense",
        "title": "Commission expenses",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "confirmCancelText",
        "type": "string",
        "format": "text",
        "title": "Cancel reason",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "sales-order",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "<p>Choose contact that is responsible of this cargos carriage.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "sales-order",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-order",
        "key": "debt",
        "type": "number",
        "format": "float",
        "title": "Debt",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "debtCurrency",
        "type": "string",
        "format": "",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "debtForToday",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "delayInDays",
        "type": "integer",
        "format": "",
        "title": "Is late to pay for days",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "diffPaymentDelay",
        "type": "integer",
        "format": "",
        "title": "Was late to pay",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "disableDebtMails",
        "type": "integer",
        "format": "",
        "title": "Payment reminder",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Send",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Do not send",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "sales-order",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "extraCosts",
        "type": "array",
        "format": "extra-cost",
        "title": "Extra expenses",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "finishDate",
        "type": "string",
        "format": "date",
        "title": "Finish date",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-order",
        "key": "fullCost",
        "type": "number",
        "format": "float",
        "title": "Self cost",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "hasLoadedCargo",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "hasNotCalculatedCargos",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "hasPoKz",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "hidePdfDimensions",
        "type": "integer",
        "format": "",
        "title": "Hide cargos dimensions in order",
        "additionalProperties": [],
        "description": "<p>By marking, customer will not be available to see cargos dimensions<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "sales-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "internalPoints",
        "type": "number",
        "format": "float",
        "title": "Internal points",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "internalPointsDb",
        "type": "number",
        "format": "float",
        "title": "Get the value of internalPointsDb",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "invoiceDebtTotal",
        "type": "number",
        "format": "float",
        "title": "Not included invoices total",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "invoicePaidTotal",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "invoicePriceChanges",
        "type": "array",
        "format": "invoice-outgoing-price-change",
        "title": "Get the value of invoicePriceChanges",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "invoices",
        "type": "array",
        "format": "invoice-outgoing",
        "title": "Invoices",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "invoicesAsString",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "invoicesCount",
        "type": "integer",
        "format": "",
        "title": "Get the value of invoicesCount",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "isContactDataOk",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "isCorrectUnloadAddress",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "isNeedContractFile",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "isNeedInvoiceFile",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "isNeedTerminal",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "maxUnloadDate",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "needSendOrgDocs",
        "type": "boolean",
        "format": "",
        "title": "needSendOrgDocs",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "needSendPdfAct",
        "type": "boolean",
        "format": "",
        "title": "needSendPdfAct",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "notes",
        "type": "string",
        "format": "",
        "title": "Notes",
        "additionalProperties": [],
        "description": "<p>This line is for extra terms that you agreed with client.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "notesRel",
        "type": "array",
        "format": "note",
        "title": "Notifications",
        "additionalProperties": [
            {
                "key": "filterSkip"
            },
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "notifications",
        "type": "array",
        "format": "notification",
        "title": "Notifications",
        "additionalProperties": [
            {
                "key": "filterSkip"
            },
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "notplannedCargosCount",
        "type": "integer",
        "format": "",
        "title": "Not planned",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "orderContractNumber",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "payedTotal",
        "type": "number",
        "format": "float",
        "title": "Payed total, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "paymentDates",
        "type": "array",
        "format": "sales-order-payment-dates",
        "title": "Get the value of paymentDates",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "paymentTermDate",
        "type": "string",
        "format": "date",
        "title": "Payment term",
        "additionalProperties": [],
        "description": "<p>Write in payments date agreed with client.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-order",
        "key": "paymentTermDateStart",
        "type": "string",
        "format": "date",
        "title": "Get the value of paymentTermDateStart",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-order",
        "key": "paymentTermDays",
        "type": "integer",
        "format": "",
        "title": "Delay period",
        "additionalProperties": [],
        "description": "<p>Fill in period that client has to do payment for carriage.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "paymentTermDaysChanges",
        "type": "integer",
        "format": "",
        "title": "Get the value of paymentTermDaysChanges",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "paymentTermType",
        "type": "integer",
        "format": "",
        "title": "Payment terms for carriage",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Payment after unloading",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Payment after receiving from sender",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Payment after departure from warehouse",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Partial payment",
                "value": 40,
                "color": "slate"
            },
            {
                "label": "Payment after arrival to carriers transit warehouse",
                "value": 60,
                "color": "slate"
            },
            {
                "label": "Payment after crossing EU\/CIS border",
                "value": 70,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "sales-order",
        "key": "paymentTermTypeText",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "paymentTermTypeTextLt",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Payments",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "pdfFileName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "plannedProfit",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "possibleUnloadDate",
        "type": "string",
        "format": "",
        "title": "Possible arrival date",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "priceChangeNotes",
        "type": "string",
        "format": "",
        "title": "Changes",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "priceChanges",
        "type": "array",
        "format": "sales-order-price-change",
        "title": "Get the value of priceChanges",
        "additionalProperties": [
            {
                "mapped": "salesOrder"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sales-order",
        "key": "profit",
        "type": "number",
        "format": "float",
        "title": "Profit",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "readyForLoad",
        "type": "boolean",
        "format": "",
        "title": "Waiting for load (to warehouse)",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "reasonSalesOrderNotFinished",
        "type": "string",
        "format": "",
        "title": "Reason",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "reasonSalesOrderNotFinishedForAccounting",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "resetDebt",
        "type": "boolean",
        "format": "",
        "title": "Reset debt",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "resetedTotal",
        "type": "number",
        "format": "float",
        "title": "Written-off amount",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "salesOrderUnloadGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "shortAddress",
        "type": "string",
        "format": "",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "sales-order",
        "key": "smallestKzOrderStatus",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "soClientName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "sales-order",
        "key": "statusChanges",
        "type": "array",
        "format": "string",
        "title": "Status changes",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "Total, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "totalWithCurrency",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "tracking",
        "type": "rel",
        "format": "sales-order-tracking",
        "title": "Tracking",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "unloadCountryName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "unloadDates",
        "type": "string",
        "format": "",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "unloadResponsibleComment",
        "type": "string",
        "format": "text",
        "title": "Comment",
        "additionalProperties": [],
        "description": "<p>If you have information that driver must know to contact Person in charge of unloading, write it.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "sales-order",
        "key": "unloadResponsiblePerson",
        "type": "rel",
        "format": "contact",
        "title": "Person in charge of unloading",
        "additionalProperties": [],
        "description": "<p>Choose contact that will be contacted when arrived.<\/p>",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "sales-order-payment-dates",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPaymentDates",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order-payment-dates",
        "key": "paymentTermDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPaymentDates",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-order-payment-dates",
        "key": "paymentTermType",
        "type": "integer",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPaymentDates",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Payment after unloading",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Payment after receiving from sender",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Payment after departure from warehouse",
                "value": 20,
                "color": "slate"
            },
            {
                "label": "Payment after arrival to carriers transit warehouse",
                "value": 60,
                "color": "slate"
            },
            {
                "label": "Payment after crossing EU\/CIS border",
                "value": 70,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "sales-order-payment-dates",
        "key": "percent",
        "type": "number",
        "format": "float",
        "title": "Percent",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPaymentDates",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order-payment-dates",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Get the value of salesOrder",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPaymentDates",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order-price-change",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order-price-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order-price-change",
        "key": "note",
        "type": "string",
        "format": "text",
        "title": "Change reason",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPriceChange",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "sales-order-price-change",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order-price-change",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "SalesOrderPriceChange",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "sales-order-price-change",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "New price",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderPriceChange",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order-tracking",
        "key": "clientTrackInfo",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderTracking",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order-tracking",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Emails",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderTracking",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "sales-order-tracking",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderTracking",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order-tracking",
        "key": "salesOrder",
        "type": "rel",
        "format": "sales-order",
        "title": "Sales order",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrderTracking",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sender",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sender",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "sender",
        "key": "attorneys",
        "type": "array",
        "format": "attorney",
        "title": "Attorneys",
        "additionalProperties": [
            {
                "mapped": "sender"
            }
        ],
        "description": "",
        "className": "Sender",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sender",
        "key": "contacts",
        "type": "array",
        "format": "contact",
        "title": "Contacts",
        "additionalProperties": [
            {
                "mapped": "sender"
            }
        ],
        "description": "",
        "className": "Sender",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sender",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "sender",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "sender",
        "key": "eori",
        "type": "string",
        "format": "",
        "title": "EORI",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sender",
        "key": "expeditor",
        "type": "rel",
        "format": "user",
        "title": "Expeditor",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sender",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sender",
        "key": "loadAddresses",
        "type": "array",
        "format": "load-address",
        "title": "Loading addresses",
        "additionalProperties": [
            {
                "mapped": "sender"
            }
        ],
        "description": "",
        "className": "Sender",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "sender",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sender",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "Sender",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "sender",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Sender",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "serial-number",
        "key": "className",
        "type": "string",
        "format": "",
        "title": "Get the value of className",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "serial-number",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "serial-number",
        "key": "incrementKey",
        "type": "string",
        "format": "",
        "title": "Get the value of incrementKey",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "serial-number",
        "key": "incrementValue",
        "type": "integer",
        "format": "",
        "title": "Get the value of incrementValue",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "columns",
        "type": "string",
        "format": "text",
        "title": "columns",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "exploreId",
        "type": "string",
        "format": "",
        "title": "exploreId",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "folder",
        "type": "rel",
        "format": "sf-explore-data-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlCount",
        "type": "string",
        "format": "text",
        "title": "sqlCount",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlData",
        "type": "string",
        "format": "text",
        "title": "sqlData",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "status",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Status",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "supplier-customs",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "supplier-customs",
        "key": "accountantChecked",
        "type": "boolean",
        "format": "",
        "title": "Checked by accountant",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "supplier-customs",
        "key": "bankAccountNumber",
        "type": "string",
        "format": "",
        "title": "Bank account number",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-customs",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-customs",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "supplier-customs",
        "key": "includeToAutomaticPayments",
        "type": "boolean",
        "format": "",
        "title": "Include to automatic payments",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "supplier-customs",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-customs",
        "key": "paymentDelay",
        "type": "integer",
        "format": "",
        "title": "Delay (d.)",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "supplier-customs",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierCustoms",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "accountantChecked",
        "type": "boolean",
        "format": "",
        "title": "Checked by accountant",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "bankAccountNumber",
        "type": "string",
        "format": "",
        "title": "Bank account number",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "includeToAutomaticPayments",
        "type": "boolean",
        "format": "",
        "title": "Include to automatic payments",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "paymentDelay",
        "type": "integer",
        "format": "",
        "title": "Delay (d.)",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "supplier-extra-costs",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierExtraCosts",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-railway",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "supplier-railway",
        "key": "accountantChecked",
        "type": "boolean",
        "format": "",
        "title": "Checked by accountant",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "supplier-railway",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "supplier-railway",
        "key": "bankAccountNumber",
        "type": "string",
        "format": "",
        "title": "Bank account number",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-railway",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-railway",
        "key": "contacts",
        "type": "array",
        "format": "contact",
        "title": "Get the value of contacts",
        "additionalProperties": [
            {
                "mapped": "supplierRailway"
            }
        ],
        "description": "",
        "className": "SupplierRailway",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "supplier-railway",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "supplier-railway",
        "key": "defaultLang",
        "type": "string",
        "format": "",
        "title": "Doc. language",
        "additionalProperties": [],
        "description": "<p>Choose which language orders will processing to carrier and which language will be automatic information from system.<\/p>",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Lithuanian",
                "value": "lt",
                "color": "slate"
            },
            {
                "label": "English",
                "value": "en",
                "color": "slate"
            },
            {
                "label": "Russian",
                "value": "ru",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "supplier-railway",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "supplier-railway",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "supplier-railway",
        "key": "includeToAutomaticPayments",
        "type": "boolean",
        "format": "",
        "title": "Include to automatic payments",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "supplier-railway",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "supplier-railway",
        "key": "paymentDelay",
        "type": "integer",
        "format": "",
        "title": "Delay (d.)",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "supplier-railway",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "supplier-railway",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "SupplierRailway",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "task",
        "key": "completed",
        "type": "boolean",
        "format": "",
        "title": "Completed",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "task",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "task",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Task",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "task",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Perfomer",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "task",
        "key": "dueDate",
        "type": "string",
        "format": "datetime",
        "title": "Due date",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "task",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "Files",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "task",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "task",
        "key": "longTerm",
        "type": "integer",
        "format": "",
        "title": "Type",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "enum": [
            {
                "label": "Simple",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Long-term",
                "value": 10,
                "color": "cyan"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "task",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "task",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "task",
        "key": "relatedTask",
        "type": "rel",
        "format": "task",
        "title": "Task",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "task",
        "key": "repeatPeriod",
        "type": "integer",
        "format": "",
        "title": "Repeat",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "enum": [
            {
                "label": "Not to repeat",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Every day",
                "value": 1,
                "color": "slate"
            },
            {
                "label": "Every working day",
                "value": 2,
                "color": "slate"
            },
            {
                "label": "Every second day",
                "value": 21,
                "color": "slate"
            },
            {
                "label": "Every week",
                "value": 3,
                "color": "slate"
            },
            {
                "label": "Every 2 weeks",
                "value": 5,
                "color": "slate"
            },
            {
                "label": "Every month",
                "value": 4,
                "color": "slate"
            },
            {
                "label": "Every 2 months",
                "value": 6,
                "color": "slate"
            },
            {
                "label": "Every 3 months",
                "value": 7,
                "color": "slate"
            },
            {
                "label": "Every 6 months",
                "value": 8,
                "color": "slate"
            },
            {
                "label": "Every year",
                "value": 9,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "task",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "task",
        "key": "taskCategory",
        "type": "rel",
        "format": "task-category",
        "title": "Category",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "task-category",
        "key": "color",
        "type": "string",
        "format": "",
        "title": "Colour",
        "additionalProperties": [
            {
                "as": "color"
            }
        ],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "varchar",
        "as": "color",
        "naeType": "color"
    },
    {
        "schema": "task-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "task-category",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "accountantChecked",
        "type": "boolean",
        "format": "",
        "title": "Checked by accountant",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "terminal",
        "key": "actAddress",
        "type": "string",
        "format": "",
        "title": "Border",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "address",
        "type": "string",
        "format": "",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "attorneyFile",
        "type": "array",
        "format": "string",
        "title": "Attorney file",
        "additionalProperties": [
            {
                "as": "file"
            }
        ],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "json",
        "as": "file",
        "naeType": "file"
    },
    {
        "schema": "terminal",
        "key": "bankAccountNumber",
        "type": "string",
        "format": "",
        "title": "Bank account number",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "contacts",
        "type": "array",
        "format": "terminal-contact",
        "title": "Get the value of contacts",
        "additionalProperties": [
            {
                "mapped": "terminal"
            }
        ],
        "description": "",
        "className": "Terminal",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "terminal",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "terminal",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "exCodePrice",
        "type": "number",
        "format": "float",
        "title": "EX code price",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "terminal",
        "key": "extraEmail",
        "type": "string",
        "format": "",
        "title": "Extra email",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "extraEmail2",
        "type": "string",
        "format": "",
        "title": "Extra email",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "fullAddress",
        "type": "string",
        "format": "",
        "title": "Full address",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "terminal",
        "key": "includeToAutomaticPayments",
        "type": "boolean",
        "format": "",
        "title": "Include to automatic payments",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "terminal",
        "key": "lang",
        "type": "string",
        "format": "",
        "title": "Mail language",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Lithuanian",
                "value": "lt",
                "color": "slate"
            },
            {
                "label": "English",
                "value": "en",
                "color": "slate"
            },
            {
                "label": "Russian",
                "value": "ru",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "terminal",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "paymentDelay",
        "type": "integer",
        "format": "",
        "title": "Delay (d.)",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "terminal",
        "key": "railwayStation",
        "type": "string",
        "format": "",
        "title": "Railway station",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "responsiblePerson",
        "type": "string",
        "format": "",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "terminal",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "Terminal",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal-contact",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "terminal-contact",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "terminal-contact",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "terminal-contact",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal-contact",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "terminal-contact",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "terminal-contact",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal-contact",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "terminal-contact",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "terminal-contact",
        "key": "terminal",
        "type": "rel",
        "format": "terminal",
        "title": "Terminal",
        "additionalProperties": [],
        "description": "",
        "className": "TerminalContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "tracking",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Tracking",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "trailer",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "after1mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "after1wDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "after2mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "after2wDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after2wDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "archived",
        "type": "boolean",
        "format": "",
        "title": "Archived",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "trailer",
        "key": "before1mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "before2mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "before3mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before3mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "before5mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "before6mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before3mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "certificateOfSuitabilityValid",
        "type": "string",
        "format": "date",
        "title": "Certificate of suitability",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "trailer",
        "key": "certificateOfSuitabilityValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of certificateOfSuitabilityValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "trailer",
        "key": "certificateOfSuitabilityValidHas",
        "type": "boolean",
        "format": "",
        "title": "Certificate of suitability",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "trailer",
        "key": "dimensions",
        "type": "string",
        "format": "",
        "title": "Dimensions",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "trailer",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "trailer",
        "key": "insuranceByValid",
        "type": "string",
        "format": "date",
        "title": "BY",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "trailer",
        "key": "insuranceByValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of insuranceByValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "trailer",
        "key": "insuranceByValidHas",
        "type": "boolean",
        "format": "",
        "title": "BY",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "trailer",
        "key": "insuranceEuValid",
        "type": "string",
        "format": "date",
        "title": "LT",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "trailer",
        "key": "insuranceEuValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of insuranceEuValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "trailer",
        "key": "insuranceEuValidHas",
        "type": "boolean",
        "format": "",
        "title": "LT",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "trailer",
        "key": "insuranceKzValid",
        "type": "string",
        "format": "date",
        "title": "Kz",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "trailer",
        "key": "insuranceKzValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of insuranceKzValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "trailer",
        "key": "insuranceKzValidHas",
        "type": "boolean",
        "format": "",
        "title": "KZ",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "trailer",
        "key": "insuranceLvValid",
        "type": "string",
        "format": "date",
        "title": "LV",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "trailer",
        "key": "insuranceLvValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of insuranceLvValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "trailer",
        "key": "insuranceLvValidHas",
        "type": "boolean",
        "format": "",
        "title": "LV",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "trailer",
        "key": "inventories",
        "type": "array",
        "format": "",
        "title": "Get the value of inventories",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "trailer",
        "key": "mark",
        "type": "string",
        "format": "",
        "title": "Model",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "number",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "trailer",
        "key": "photoAlbums",
        "type": "array",
        "format": "",
        "title": "Get the value of photoAlbums",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "trailer",
        "key": "reportsData",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "trailer",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "trailer",
        "key": "technicalInspectionValid",
        "type": "string",
        "format": "date",
        "title": "Technical inspection",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "trailer",
        "key": "technicalInspectionValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of technicalInspectionValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "trailer",
        "key": "technicalInspectionValidHas",
        "type": "boolean",
        "format": "",
        "title": "Technical inspection",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "trailer",
        "key": "technicalPassportFile",
        "type": "array",
        "format": "string",
        "title": "Technical passport",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "trailer",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "trailer",
        "key": "truckOwner",
        "type": "rel",
        "format": "truck-owner",
        "title": "Owner",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "trailer",
        "key": "tyreChecks",
        "type": "array",
        "format": "",
        "title": "Get the value of tyreChecks",
        "additionalProperties": [],
        "description": "",
        "className": "Trailer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "after1mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "after1wDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "after2mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "after2wDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of after2wDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "archived",
        "type": "boolean",
        "format": "",
        "title": "Archived",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "batteryMark",
        "type": "string",
        "format": "",
        "title": "Mark",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "batteryPurchaseDate",
        "type": "string",
        "format": "date",
        "title": "Purchase date",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "before1mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before1mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "before2mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "before3mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before3mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "before5mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before2mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "before6mDate",
        "type": "string",
        "format": "date-time",
        "title": "Get the value of before3mDate",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "driver",
        "type": "rel",
        "format": "driver",
        "title": "Driver",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "truck",
        "key": "etransportTruck",
        "type": "rel",
        "format": "etransport-truck",
        "title": "E-Transport",
        "additionalProperties": [
            {
                "mapped": "truck"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "truck",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "truck",
        "key": "filterChanges",
        "type": "array",
        "format": "",
        "title": "Filter changes",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "truck",
        "key": "insuranceByValid",
        "type": "string",
        "format": "date",
        "title": "BY",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "insuranceByValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of insuranceByValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "insuranceByValidHas",
        "type": "boolean",
        "format": "",
        "title": "BY",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "insuranceEuValid",
        "type": "string",
        "format": "date",
        "title": "LT",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "insuranceEuValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of insuranceEuValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "insuranceEuValidHas",
        "type": "boolean",
        "format": "",
        "title": "LT",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "insuranceKzValid",
        "type": "string",
        "format": "date",
        "title": "KZ",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "insuranceKzValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of insuranceKzValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "insuranceKzValidHas",
        "type": "boolean",
        "format": "",
        "title": "KZ",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "insuranceLvValid",
        "type": "string",
        "format": "date",
        "title": "LV",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "insuranceLvValidFile",
        "type": "array",
        "format": "string",
        "title": "LV",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "insuranceLvValidHas",
        "type": "boolean",
        "format": "",
        "title": "LV",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "insuranceRuValid",
        "type": "string",
        "format": "date",
        "title": "RU",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "insuranceRuValidFile",
        "type": "array",
        "format": "string",
        "title": "RU",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "insuranceRuValidHas",
        "type": "boolean",
        "format": "",
        "title": "RU",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "inventories",
        "type": "array",
        "format": "",
        "title": "Inventories",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck",
        "key": "licenceValid",
        "type": "string",
        "format": "date",
        "title": "License",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "licenceValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of licenceValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "licenceValidHas",
        "type": "boolean",
        "format": "",
        "title": "License",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "mark",
        "type": "string",
        "format": "",
        "title": "Model",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "truck",
        "key": "number",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "truck",
        "key": "oilChanges",
        "type": "array",
        "format": "",
        "title": "Oil change",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck",
        "key": "photoAlbums",
        "type": "array",
        "format": "",
        "title": "Photographing",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck",
        "key": "reportsData",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "truck",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "truck",
        "key": "skipCheck",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipCheck",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "tachographCheck",
        "type": "string",
        "format": "date",
        "title": "Tachograph check",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "tachographCheckFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of tachographCheckFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "tachographCheckHas",
        "type": "boolean",
        "format": "",
        "title": "Tachograph check",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "technicalInspectionValid",
        "type": "string",
        "format": "date",
        "title": "Technical inspection",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck",
        "key": "technicalInspectionValidFile",
        "type": "array",
        "format": "string",
        "title": "Get the value of technicalInspectionValidFile",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "technicalInspectionValidHas",
        "type": "boolean",
        "format": "",
        "title": "Technical inspection",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "truck",
        "key": "technicalPassportFile",
        "type": "array",
        "format": "string",
        "title": "Technical passport",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "truck",
        "key": "tensionBearings",
        "type": "array",
        "format": "",
        "title": "Tension bearings",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck",
        "key": "trailer",
        "type": "rel",
        "format": "trailer",
        "title": "Trailer",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "truck",
        "key": "truckOwner",
        "type": "rel",
        "format": "truck-owner",
        "title": "Owner",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "truck",
        "key": "tyreChecks",
        "type": "array",
        "format": "",
        "title": "Tyre check",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck",
        "key": "waterPomps",
        "type": "array",
        "format": "",
        "title": "Water pomp",
        "additionalProperties": [],
        "description": "",
        "className": "Truck",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "truck-location",
        "key": "fullUrl",
        "type": "string",
        "format": "",
        "title": "Reference",
        "additionalProperties": [],
        "description": "",
        "className": "TruckLocation",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "truck-location",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckLocation",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "truck-location",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "TruckLocation",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "truck-location",
        "key": "uniqId",
        "type": "string",
        "format": "",
        "title": "ID",
        "additionalProperties": [],
        "description": "",
        "className": "TruckLocation",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "truck-location",
        "key": "validTo",
        "type": "string",
        "format": "date",
        "title": "Valid to",
        "additionalProperties": [],
        "description": "",
        "className": "TruckLocation",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "truck-owner",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "TruckOwner",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "truck-owner",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TruckOwner",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "truck-owner",
        "key": "invoiceSerialPrefix",
        "type": "string",
        "format": "",
        "title": "Invoice numbering",
        "additionalProperties": [],
        "description": "",
        "className": "TruckOwner",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "truck-owner",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "TruckOwner",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "truck-owner",
        "key": "serialPrefix",
        "type": "string",
        "format": "",
        "title": "Orders numbering",
        "additionalProperties": [],
        "description": "",
        "className": "TruckOwner",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "truck-owner",
        "key": "showReports",
        "type": "boolean",
        "format": "",
        "title": "Show report",
        "additionalProperties": [],
        "description": "",
        "className": "TruckOwner",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "tyre-check",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "tyre-check",
        "key": "files",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "tyre-check",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "tyre-check",
        "key": "mileage",
        "type": "integer",
        "format": "",
        "title": "Mileage",
        "additionalProperties": [],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "tyre-check",
        "key": "nextCheckDate",
        "type": "string",
        "format": "date",
        "title": "Next check date",
        "additionalProperties": [],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "tyre-check",
        "key": "trailer",
        "type": "rel",
        "format": "trailer",
        "title": "Trailer",
        "additionalProperties": [],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "tyre-check",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "tyre-check",
        "key": "tyres",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TyreCheck",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "unload-address",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "unload-address",
        "key": "canAvia",
        "type": "integer",
        "format": "",
        "title": "Suitable for Air",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Yes",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "unload-address",
        "key": "canCarriage",
        "type": "integer",
        "format": "",
        "title": "Suitable for railway wagons",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Yes",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "unload-address",
        "key": "canContainer",
        "type": "integer",
        "format": "",
        "title": "Suitable for railway containers",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Yes",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "unload-address",
        "key": "canUnload",
        "type": "integer",
        "format": "",
        "title": "Suitable for unloading",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Yes",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "unload-address",
        "key": "city",
        "type": "string",
        "format": "",
        "title": "City",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "unload-address",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "unload-address",
        "key": "countryName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "unload-address",
        "key": "countryWithCityRu",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "unload-address",
        "key": "fullAddress",
        "type": "string",
        "format": "",
        "title": "Unloading address",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "unload-address",
        "key": "group",
        "type": "string",
        "format": "",
        "title": "Group",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "unload-address",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "unload-address",
        "key": "priceListFtl",
        "type": "rel",
        "format": "price-list-ftl",
        "title": "FTL price list",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "unload-address",
        "key": "priceListLtl",
        "type": "rel",
        "format": "price-list-ltl",
        "title": "LTL price list",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "unload-address",
        "key": "recommended",
        "type": "integer",
        "format": "",
        "title": "Recommended",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "Yes",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "No",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "unload-address",
        "key": "recommendedTerminal",
        "type": "rel",
        "format": "terminal",
        "title": "Recommended terminals",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "unload-address",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "unload-address",
        "key": "shortAddress",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "unload-address",
        "key": "zoneName",
        "type": "string",
        "format": "",
        "title": "Guarantee (Zone)",
        "additionalProperties": [],
        "description": "",
        "className": "UnloadAddress",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "bussinessDevClientContacts",
        "type": "integer",
        "format": "",
        "title": "Amount",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "clientClientContacts",
        "type": "integer",
        "format": "",
        "title": "Clients amount",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "clientContacts",
        "type": "integer",
        "format": "",
        "title": "Amount",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "clientLeadContacts",
        "type": "integer",
        "format": "",
        "title": "Leads amount",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "Blocked",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstNameEn",
        "type": "string",
        "format": "",
        "title": "First name (english)",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstNameRu",
        "type": "string",
        "format": "",
        "title": "First name (russian)",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "ftlCompanies",
        "type": "array",
        "format": "",
        "title": "Get the value of ftlCompanies",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "user",
        "key": "ftlHelper",
        "type": "integer",
        "format": "",
        "title": "FTL expeditor helper",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullNameRu",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "lastNameEn",
        "type": "string",
        "format": "",
        "title": "Last name (english)",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "lastNameRu",
        "type": "string",
        "format": "",
        "title": "Last name (russian)",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "Login",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "ltlCompanies",
        "type": "array",
        "format": "",
        "title": "Get the value of ltlCompanies",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "mobile",
        "type": "string",
        "format": "",
        "title": "Mobile phone",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "notificationOnPaymentTermType",
        "type": "integer",
        "format": "",
        "title": "Notification on payment terms changes",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "user",
        "key": "onHolidays",
        "type": "integer",
        "format": "",
        "title": "On holidays",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "No",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Yes",
                "value": 10,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "Password",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "Rights",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "",
                "value": "",
                "color": "slate"
            },
            {
                "label": "Office Manager",
                "value": "user-accounting",
                "color": "slate"
            },
            {
                "label": "Admin",
                "value": "user-admin",
                "color": "slate"
            },
            {
                "label": "Transport Operations Manager (LTL)",
                "value": "user-logistic",
                "color": "slate"
            },
            {
                "label": "Transport Operations Manager (FTL)",
                "value": "user-logistic-ftl",
                "color": "slate"
            },
            {
                "label": "Multimodal Operations Manager (AIR + SEA)",
                "value": "user-logistic-sea",
                "color": "slate"
            },
            {
                "label": "Multimodal Operations Manager (OLD)",
                "value": "user-logistic-avia",
                "color": "slate"
            },
            {
                "label": "Multimodal Operations Manager (Railroad)",
                "value": "user-logistic-train",
                "color": "slate"
            },
            {
                "label": "Sales Manager",
                "value": "user-manager",
                "color": "slate"
            },
            {
                "label": "Document Processing Specialist",
                "value": "user-operational",
                "color": "slate"
            },
            {
                "label": "Head of Document Processing Department",
                "value": "user-operational-pro",
                "color": "slate"
            },
            {
                "label": "Transport manager",
                "value": "user-transport-manager",
                "color": "slate"
            },
            {
                "label": "Chief Operations Officer",
                "value": "user-head-of-logistics",
                "color": "slate"
            },
            {
                "label": "Operations Department Administrator",
                "value": "user-head-of-logistics-helper",
                "color": "slate"
            },
            {
                "label": "Accountant",
                "value": "user-finances",
                "color": "slate"
            },
            {
                "label": "Forwarding manager",
                "value": "user-forwarding-manager",
                "color": "slate"
            },
            {
                "label": "Database Administrator",
                "value": "user-business-dev",
                "color": "slate"
            },
            {
                "label": "Chief Sales Officer",
                "value": "user-chief-sales-officer",
                "color": "slate"
            },
            {
                "label": "Head of transport",
                "value": "user-head-of-transport",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "Phone",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "photoUrl",
        "type": "string",
        "format": "",
        "title": "Photo URL",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "Password",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "positionEn",
        "type": "string",
        "format": "",
        "title": "Position (english)",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "positionRu",
        "type": "string",
        "format": "",
        "title": "Position (russian)",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "salt",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "showAllTrips",
        "type": "boolean",
        "format": "",
        "title": "Show all trips",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "showAsiaMenu",
        "type": "boolean",
        "format": "",
        "title": "Show VILT TRANS \/ ASIA EXPRESS menu",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "showPaymentsInCash",
        "type": "boolean",
        "format": "",
        "title": "Allow payment in cash?",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "showServiceMenu",
        "type": "boolean",
        "format": "",
        "title": "Show Vilt Truck Service menu",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "signatureUrl",
        "type": "string",
        "format": "",
        "title": "Signature URL",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "skype",
        "type": "string",
        "format": "",
        "title": "Skype",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Get the value of updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "user",
        "key": "userDpsSettings",
        "type": "rel",
        "format": "user-dps-settings",
        "title": "DPS settings",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "user",
        "key": "userLeadCategoryRels",
        "type": "array",
        "format": "user-lead-category-rel",
        "title": "Distribution",
        "additionalProperties": [
            {
                "mapped": "doer"
            },
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "user",
        "key": "userManagerSettings",
        "type": "rel",
        "format": "user-manager-settings",
        "title": "Manager Settings",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "user",
        "key": "userTomSettings",
        "type": "rel",
        "format": "user-tom-settings",
        "title": "TOM Settings",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "user",
        "key": "voximplantPassword",
        "type": "string",
        "format": "",
        "title": "VOX password",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "voximplantUser",
        "type": "string",
        "format": "",
        "title": "VOX user",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user-country-logistic-group-rel",
        "key": "countryLogisticGroup",
        "type": "rel",
        "format": "country-logistic-group",
        "title": "Logistic group",
        "additionalProperties": [],
        "description": "",
        "className": "UserCountryLogisticGroupRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-country-logistic-group-rel",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "UserCountryLogisticGroupRel",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "user-country-logistic-group-rel",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "UserCountryLogisticGroupRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-country-logistic-group-rel",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "UserCountryLogisticGroupRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-country-logistic-group-rel",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserCountryLogisticGroupRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-country-logistic-group-rel",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "UserCountryLogisticGroupRel",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "user-country-logistic-group-rel",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "UserCountryLogisticGroupRel",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "user-dps-settings",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserDpsSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-dps-settings",
        "key": "showStatsInDashboard",
        "type": "boolean",
        "format": "",
        "title": "Stats in dashboard",
        "additionalProperties": [],
        "description": "",
        "className": "UserDpsSettings",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user-dps-settings",
        "key": "startPoints",
        "type": "integer",
        "format": "",
        "title": "Start points",
        "additionalProperties": [],
        "description": "",
        "className": "UserDpsSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-dps-settings",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "Get the value of user",
        "additionalProperties": [],
        "description": "",
        "className": "UserDpsSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "category",
        "type": "rel",
        "format": "lead-source-category",
        "title": "Category",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "percent",
        "type": "number",
        "format": "float",
        "title": "Distribution percent",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "user-lead-category-rel",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "UserLeadCategoryRel",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "user-manager-settings",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserManagerSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-manager-settings",
        "key": "leadsLimit",
        "type": "integer",
        "format": "",
        "title": "Leads limit",
        "additionalProperties": [],
        "description": "<p>0 for unlimited<\/p>",
        "className": "UserManagerSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-manager-settings",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "User",
        "additionalProperties": [],
        "description": "",
        "className": "UserManagerSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-points-log",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user-points-log",
        "key": "cargo",
        "type": "rel",
        "format": "cargo",
        "title": "Cargo",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-points-log",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "user-points-log",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-points-log",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "user-points-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-points-log",
        "key": "points",
        "type": "number",
        "format": "float",
        "title": "Points",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "user-points-log",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "user-points-log",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Action",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user-points-log",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "user-points-settings",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user-points-settings",
        "key": "date",
        "type": "string",
        "format": "",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user-points-settings",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-points-settings",
        "key": "logisticPointBonus",
        "type": "number",
        "format": "float",
        "title": "Logistic point bonus, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "user-points-settings",
        "key": "logisticPoints",
        "type": "number",
        "format": "float",
        "title": "Logistic points",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "user-points-settings",
        "key": "operativePointBonus",
        "type": "number",
        "format": "float",
        "title": "Operation point bonus, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "user-points-settings",
        "key": "operativePoints",
        "type": "number",
        "format": "float",
        "title": "Operation points",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "user-points-settings",
        "key": "workingDays",
        "type": "number",
        "format": "float",
        "title": "Working days",
        "additionalProperties": [],
        "description": "",
        "className": "UserPointsSettings",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "user-tom-settings",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserTomSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-tom-settings",
        "key": "showStatsInDashboard",
        "type": "boolean",
        "format": "",
        "title": "Stats in dashboard",
        "additionalProperties": [],
        "description": "",
        "className": "UserTomSettings",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user-tom-settings",
        "key": "startPoints",
        "type": "integer",
        "format": "",
        "title": "Start points",
        "additionalProperties": [],
        "description": "",
        "className": "UserTomSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user-tom-settings",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "User",
        "additionalProperties": [],
        "description": "",
        "className": "UserTomSettings",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-client",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltClient",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "vilt-client",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Address",
        "additionalProperties": [],
        "description": "",
        "className": "ViltClient",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-client",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "ViltClient",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-client",
        "key": "contacts",
        "type": "array",
        "format": "vilt-contact",
        "title": "Get the value of contacts",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "ViltClient",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "vilt-client",
        "key": "country",
        "type": "rel",
        "format": "country",
        "title": "Country",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ViltClient",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "vilt-client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltClient",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "vilt-client",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "ViltClient",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-client",
        "key": "phones",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltClient",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "vilt-client",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "VAT number",
        "additionalProperties": [],
        "description": "",
        "className": "ViltClient",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-contact",
        "key": "client",
        "type": "rel",
        "format": "vilt-client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-contact",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "vilt-contact",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "vilt-contact",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-contact",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "vilt-contact",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "vilt-contact",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-contact",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Phone",
        "additionalProperties": [
            {
                "valueTransform": "phone"
            }
        ],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "vilt-contact",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [],
        "description": "",
        "className": "ViltContact",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-payment",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "vilt-payment",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Get the value of currencyRate",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "vilt-payment",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "vilt-payment",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-payment",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "vilt-payment",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "vilt-payment",
        "key": "viltSalesOrder",
        "type": "rel",
        "format": "vilt-sales-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "carNumber",
        "type": "string",
        "format": "",
        "title": "Vehicle number \/ Partners name",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "cargoInfo",
        "type": "string",
        "format": "text",
        "title": "Cargo information",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "client",
        "type": "rel",
        "format": "carrier",
        "title": "Carrier",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "contact",
        "type": "rel",
        "format": "contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "loadDate",
        "type": "string",
        "format": "date",
        "title": "Loading date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "loadingPlace",
        "type": "string",
        "format": "text",
        "title": "Loading address",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "paymentTerms",
        "type": "string",
        "format": "",
        "title": "Payment terms",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "Total VAT",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "unloadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "unloadingPlace",
        "type": "string",
        "format": "text",
        "title": "Unloading address",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "vilt-purchase-order",
        "key": "viltSalesOrder",
        "type": "rel",
        "format": "vilt-sales-order",
        "title": "Order",
        "additionalProperties": [],
        "description": "",
        "className": "ViltPurchaseOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-sales-order",
        "key": "client",
        "type": "rel",
        "format": "vilt-client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-sales-order",
        "key": "clientOrderNumber",
        "type": "string",
        "format": "",
        "title": "Client order number",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-sales-order",
        "key": "contact",
        "type": "rel",
        "format": "vilt-contact",
        "title": "Contact",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-sales-order",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "vilt-sales-order",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-sales-order",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-sales-order",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Get the value of currencyRate",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "vilt-sales-order",
        "key": "date",
        "type": "string",
        "format": "",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "vilt-sales-order",
        "key": "debtComment",
        "type": "string",
        "format": "text",
        "title": "Debt comment",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-sales-order",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-sales-order",
        "key": "guaranteeProcedure",
        "type": "integer",
        "format": "",
        "title": "Guarantee procedure",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "enum": [
            {
                "label": "",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Our company",
                "value": 10,
                "color": "slate"
            },
            {
                "label": "Client",
                "value": 20,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "vilt-sales-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "vilt-sales-order",
        "key": "invoiceDate",
        "type": "string",
        "format": "date",
        "title": "Invoice date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-sales-order",
        "key": "invoiceNumber",
        "type": "string",
        "format": "",
        "title": "Invoice number",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-sales-order",
        "key": "invoicePayToDate",
        "type": "string",
        "format": "date",
        "title": "Pay to date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-sales-order",
        "key": "loadDate",
        "type": "string",
        "format": "date",
        "title": "Loading date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-sales-order",
        "key": "loadingPlace",
        "type": "string",
        "format": "text",
        "title": "Loading",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-sales-order",
        "key": "payments",
        "type": "array",
        "format": "vilt-payment",
        "title": "Get the value of payments",
        "additionalProperties": [
            {
                "mapped": "viltSalesOrder"
            }
        ],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "vilt-sales-order",
        "key": "purchaseOrders",
        "type": "array",
        "format": "vilt-purchase-order",
        "title": "Get the value of purchaseOrders",
        "additionalProperties": [
            {
                "mapped": "viltSalesOrder"
            }
        ],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "vilt-sales-order",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "vilt-sales-order",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-sales-order",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "vilt-sales-order",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "vilt-sales-order",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "vilt-sales-order",
        "key": "totalDebt",
        "type": "number",
        "format": "float",
        "title": "Debt",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "vilt-sales-order",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "vilt-sales-order",
        "key": "totalWVat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "vilt-sales-order",
        "key": "trailer",
        "type": "rel",
        "format": "trailer",
        "title": "Trailer",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-sales-order",
        "key": "trip",
        "type": "string",
        "format": "",
        "title": "Trip",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "vilt-sales-order",
        "key": "unloadDate",
        "type": "string",
        "format": "date",
        "title": "Unloading date",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "vilt-sales-order",
        "key": "unloadingPlace",
        "type": "string",
        "format": "text",
        "title": "Unloading",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-sales-order",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "vilt-sales-order",
        "key": "vatPercent",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "vilt-sales-order",
        "key": "viltTrip",
        "type": "rel",
        "format": "vilt-trip",
        "title": "Trip",
        "additionalProperties": [],
        "description": "",
        "className": "ViltSalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "vilt-trip",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "vilt-trip",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "driver",
        "type": "rel",
        "format": "driver",
        "title": "Driver",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "vilt-trip",
        "key": "orders",
        "type": "array",
        "format": "vilt-sales-order",
        "title": "Get the value of orders",
        "additionalProperties": [
            {
                "mapped": "viltTrip"
            }
        ],
        "description": "",
        "className": "ViltTrip",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "vilt-trip",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "vilt-trip",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "vilt-trip",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "vilt-trip",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "vilt-trip",
        "key": "statusChanges",
        "type": "array",
        "format": "string",
        "title": "Status changes",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "vilt-trip",
        "key": "trailer",
        "type": "rel",
        "format": "trailer",
        "title": "Trailer",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "tripCarriers",
        "type": "string",
        "format": "text",
        "title": "Carrier",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": false,
        "dbType": "",
        "naeType": "text"
    },
    {
        "schema": "vilt-trip",
        "key": "tripCompanyInfo",
        "type": "string",
        "format": "text",
        "title": "Information",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": false,
        "dbType": "",
        "naeType": "text"
    },
    {
        "schema": "vilt-trip",
        "key": "truck",
        "type": "rel",
        "format": "truck",
        "title": "Truck",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "truckOwner",
        "type": "rel",
        "format": "truck-owner",
        "title": "Truck owner",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "vilt-trip",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated",
        "additionalProperties": [],
        "description": "",
        "className": "ViltTrip",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    }
]