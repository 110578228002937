import React, { useState, useEffect, Fragment } from "react";

import moment from "moment";
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';
import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import CalculationCostHistoryPopup from "./CalculationCostHistoryPopup";
import { useCalculationHookNae } from '../../../_generated/_custom/hooks/useCalculationHookNae';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";

export interface PropsDiffWith {
  id: number,
  createdAt: string;
  cost: string;
  extraData: any;
}
export interface PropsDiffWithExtraData {
  places: string;
  weight: string;
  dimensions: string;
  serialNumber: string;
  all: {
    places: number;
    weight: number;
    dimensions: number;
  };
}
const defElementPropsDiff: PropsDiffWith = {
  id: 0,
  createdAt: '',
  cost: '',
  extraData: '',
}


export default function CalculationCostHistory(props: PropsId) {
  const element = useCalculationHookNae(props.id);

  const [showChanges, setShowChanges] = useState(false);
  const toggleShowChanges = () => setShowChanges(!showChanges);

  const [diffWith, setDiffWith] = useState<PropsDiffWith | null>(null);

  const [getData, getDataParams] = useUList(NaeSSchemaMap.CalculationCostHistory.schema, getKeysFromObject(defElementPropsDiff));
  const t = (s: string) => s;

  const loadData = () => {
    getData(
      [
        { "and": [['i.calculation.id', '=', element.id, true]] }
      ],
      1,
      100,
      [
        {
          key: 'i.createdAt',
          value: 'DESC'
        }
      ]
    );
  };

  useEffect(() => {
    if (showChanges) {
      loadData();
    }
  }, [showChanges]);

  const data =
    !!getDataParams && !!getDataParams.data && !!getDataParams.data.data
      ? getDataParams.data.data
      : [];

  if (!element) {
    return <Fragment />;
  }
  if (element.costHistoryRecords === 0) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <div className={"grid gap-2"}>
        {!showChanges && (
          <MainButton
            color="bluelight"
            className={"w-full"}
            onClick={toggleShowChanges}
            iconName={"analytics"}
          >
            {t("Show cost changes")}
          </MainButton>
        )}

        {showChanges && (
          <WhiteCard isCompact={true} title={t("Price changes")}>
            <div className="space-y-2">
              {data
                // .filter((el: any) => el.cost !== element.cost)
                .map((el: any) => (
                  <MainButton
                    color="white"
                    skipPadding={true}
                    onClick={() => setDiffWith(el)}
                  >
                    {moment(el.createdAt).format("YYYY-MM-DD HH:mm")}
                  </MainButton>
                ))}
              <MainButton
                color="bluelight"
                className="w-full"
                onClick={toggleShowChanges}
              >
                {t("Hide")}
              </MainButton>
            </div>
          </WhiteCard>
        )}
      </div>
      {!!diffWith && (
        <Popup
          isPopup={!!diffWith}
          onClick={() => setDiffWith(null)}
          title={""}
        >
          <CalculationCostHistoryPopup id={props.id} diffWith={diffWith} />
        </Popup>
      )}
    </Fragment>
  );
}
