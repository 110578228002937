import { useTranslation } from "react-i18next"
import { useSalesOrderHookNae } from "../../../_generated/_custom/hooks/useSalesOrderHookNae";
import { Fragment } from "react";
import CargoManagerHold from "../CargoManagerHold";
import { MenuDivider } from "@newageerp/v3.bundles.modal-bundle";

type Props = {
    id: number,
    mainId: number,
}

export const PostponeDelivery = (props: Props) => {
    const t = (s: string) => s;
    const element = useSalesOrderHookNae(props.id);

    if (!element) {
        return <Fragment />
    }
    const cargos = element.cargos.map(e => e.id);

    return <Fragment>
        {/* <div>
            <p>{t('Your order will not be scheduled for shipment from the warehouse.')}</p>
            <p>{t('When the order is ready for pickup, click \'Allow delivery\'')}</ p>
        </div>
        <p className="font-medium">Cargos:</p> */}
        <MenuDivider/>
        {cargos.map(c => <CargoManagerHold mode="direct" key={`c-${c}`} id={c} mainId={props.mainId} />)}
        <MenuDivider/>
    </Fragment>
}

// const CargoLine = (props: PropsId) => {
//     const element = useCargoHookNae(props.id);

//     return <div className="flex gap-2 items-center">
//         <p>{element.serialNumber}</p>
//         <CargoManagerHold id={props.id} mode="direct" />
//     </div>
// }