import React, { Fragment } from 'react'
import { PropsId } from '../../_generated/_custom/models-cache-data/types'
import { DfValueView } from '@newageerp/v3.bundles.app-bundle';
import { useCargoHookNae } from '../../_generated/_custom/hooks/useCargoHookNae';
import moment from 'moment';
import classNames from 'classnames';
import { CompactRow, FieldHint } from '@newageerp/v3.bundles.form-bundle';

import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { CheckUserPermissionComponent, ENaeSPermissions } from "../../_generated/_custom/config/NaeSPermissions";
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { CargoContactedSellerTimeBtn } from './CargoContactedSellerTimePopup';
import { useTheme } from '@newageerp/v3.bundles.layout-bundle';

export default function CargoContactedSellerTime(props: PropsId) {
    const t = (s: string) => s;
    
    const element = useCargoHookNae(props.id);

    const [saveCargo] = useUSave("cargo");


    if (!element) {
        return <Fragment />
    }
    if (!element.contactedSellerTime) {
        return <Fragment />
    }

    const weekDay = new Date().getDay();
    // wednesday | thursday
    const durationToCheck = 3;

    const duration = element.contactedSellerTime
        ? moment
            .duration(moment().diff(moment(element.contactedSellerTime)))
            .asHours()
        : 0;

    const onClick = () => {
        const event = new CustomEvent(
            'SFSOpenEditModalWindow',
            {
                detail: {
                    schema: NaeSSchemaMap.CargoContactSellerLog.schema,
                    type: 'main',
                    id: "new",
                    options: {
                        createOptions: {
                            convert: {
                                schema: "cargo",
                                element: { id: props.id },
                            }
                        },
                    },
                    onSaveCallback: async (_el: any, back: any) => {
                        await saveCargo({
                            contactedSellerTime: moment().format('YYYY-MM-DD HH:mm:00')
                        }, props.id);
                        back();
                    }
                }
            }
        );
        window.dispatchEvent(event);
    }

    return (
        <CompactRow spaceY={"space-y-0 "} label={<FieldHint>{t('Contacted')}</FieldHint>}
            control={
                <div
                    className={
                        classNames(
                            { 'text-red-500': duration >= durationToCheck },
                            'flex gap-2 items-center'
                        )
                    }
                >
                    <span className='fa fa-paper-plane' />

                    <div className='flex-grow whitespace-nowrap'>
                        <DfValueView path="cargo.contactedSellerTime" id={element.id} />
                    </div>

                    <CheckUserPermissionComponent
                        permissions={
                            [
                                ENaeSPermissions.UserAdmin,
                                ENaeSPermissions.UserLogistic,
                                ENaeSPermissions.UserLogisticSea
                            ]
                        }
                    >
                        <ToolbarButton
                            iconName='arrows-rotate'
                            onClick={onClick}
                            bgColor='bg-transparent'
                            small={true}
                        />

                    </CheckUserPermissionComponent>

                    <CargoContactedSellerTimeBtn
                        ids={[element.id]}
                    />
                </div>
            }
        />

    )
}
