import React, { useState, Fragment, useRef } from "react";

// @ts-ignore
import FileViewer from "react-file-viewer";


import { TextCardTitle } from "@newageerp/v3.bundles.typography-bundle";
import { ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { useCargoHookNae } from "../../_generated/_custom/hooks/useCargoHookNae";
import { useCargoOperationConfirmationHookNae } from "../../_generated/_custom/hooks/useCargoOperationConfirmationHookNae";
import CargoStockList from "../../components/cargo/CargoDocumentsPopup/Components/CargoStockList";
import CargoIncomingList from "../../components/cargo/CargoDocumentsPopup/Components/CargoIncomingList";
import { DocumentViewer } from 'react-documents';

export default function CargoDpsCheckFiles(props: PropsId) {
    const cargo = useCargoHookNae(props.id);
    const confirmElement = useCargoOperationConfirmationHookNae(cargo && cargo.operationConfirmation ? cargo.operationConfirmation.id : -1)
    const headConfirmed = confirmElement ? confirmElement.headConfirmed : -1;

    const ref = useRef(null);

    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [path, setPath] = useState("");
    const [localPath, setLocalPath] = useState("");

    const t = (s: string) => s;

    const setStateDataFromFile = (f: any) => {
        const filePath =
            window.location.origin + "/app/nae-core/files/download?f=" +
            encodeURIComponent(
                JSON.stringify({
                    path: f.path,
                    name: f.filename,
                })
            ) +
            "&token=" +
            window.localStorage.getItem("token");

        setFileName(f.filename);
        setFileType(f.extension);
        setPath(filePath);
        setLocalPath(f.path);
    };

    const doClose = () => {
        // setShowCancelForm(false);
        setFileName("");
        setFileType("");
        setPath("");
        setLocalPath("");

        // props.toggleVisible();
    };



    const doDownload = () => {
        window.open(path);
    };


    if (!cargo) {
        return <Fragment />
    }

    const isPdf = fileName.indexOf(".pdf") >= 0;
    const isXls = fileName.indexOf(".xlsx") >= 0 || fileName.indexOf(".xls") >= 0;

    return (
        <div className={"flex gap-2 h-screen overscroll-none pb-8"}>
            <div className={"flex-grow space-y-2 md:overflow-visible overflow-x-auto rounded-md bg-white"}>
                {!!fileName && (
                    <div className={"flex px-6 py-2"}>

                        <TextCardTitle className={"flex-grow"}>{fileName}</TextCardTitle>

                        <ToolbarButton
                            onClick={doDownload}
                            iconName={"download"}
                        />

                    </div>
                )}
                {path && (
                    <Fragment>
                        {isXls && (
                            <div className='min-w-[50vw] min-h-[70vh]'>
                                <DocumentViewer
                                    url={path.replace('nae-core/files/download', 'nae-core/files/view')}
                                    viewer="office"
                                    style={{ width: '100%', height: 500 }}
                                />
                            </div>
                        )}
                        {isPdf && <iframe
                            className={"w-full h-full"}
                            src={
                                path.replace('nae-core/files/download', 'nae-core/files/view')
                            }
                            ref={ref}
                        ></iframe>}
                        {!isXls && !isPdf && <FileViewer
                            key={path + "_" + fileType}
                            fileType={fileType}
                            filePath={path}
                        />}
                    </Fragment>
                )}
            </div>
            <div className={"w-full md:w-[420px] md:min-w-[420px] md:max-w-[420px] overflow-auto"}>
                <div className={"space-y-2 pb-8"} style={{ maxWidth: '24rem' }}>
                    <CargoIncomingList id={cargo.id} localPath={localPath} onSelect={setStateDataFromFile} onClose={doClose} />

                    {headConfirmed &&
                        <CargoStockList id={cargo.id} localPath={localPath} onSelect={setStateDataFromFile} onClose={doClose} />
                    }
                </div>

            </div>

        </div>

    );
}
