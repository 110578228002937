import React, { Fragment } from 'react'
import BookmarksListButton from './BookmarksListButton'
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core'

type Props = {
  dataSource: string
}

export default function RightListToolbarResolver(props: Props) {
  const { userState } = useTemplatesCore()
  return (
    <Fragment>
      <BookmarksListButton user={userState.id} sourceSchema={props.dataSource} />
    </Fragment>
  )
}
