import React, { Fragment, useEffect } from "react";
import AviaLogisticsPlanning from "./Components/AviaLogisticsPlanning";
import AviaLogisticsPlanned from "./Components/AviaLogisticsPlanned";
import AviaLogisticsOnRoad from "./Components/AviaLogisticsOnRoad";
import AviaLogisticsUnload from "./Components/AviaLogisticsUnload";
import AviaLogisticsFormation from "./Components/AviaLogisticsFormation";
import KzToolbarComponent from '../../KzCarriersOrder/Components/KzToolbarComponent';

import { NaeSSchemaMap } from '../../../_generated/_custom/config/NaeSSchema';
import { getKeysFromObject } from "@newageerp/v3.bundles.utils-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { SocketService } from "@newageerp/v3.utils.socket-service";


type Props = {
  userId: number
}

interface IData {
  id: number,
  status: number,
  loadDate: string,
  status20Date: string,
}

const defObject: IData = {
  id: 0,
  status: 0,
  loadDate: '',
  status20Date: '',
}

let reloadTimeout : any = -1;



export default function AviaLogisticOrdersWrapper(props: Props) {
  const t = (s: string) => s;

  const {userState} = useTemplatesCore()
  
  const [getData, getDataParams] = useUList<IData>(NaeSSchemaMap.KzCarriersOrder.schema, getKeysFromObject(defObject));

  const loadData = () => {
    const filters = [
      {
        "and": [
          ['i.creator', '=', props.userId, true],
          ['i.status', 'in', [0, 10, 15, 20, 30]]
        ]
      }
    ]

    getData(
      filters,
      1,
      500,
      [
        {
          key: "i.serialNumber",
          value: "ASC",
        }
      ]
    )
  };

  useEffect(loadData, []);

  const reloadData = () => {
    window.clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      loadData();
    }, 500);
  };

  useEffect(() => {
    SocketService.subscribe("kz-carriers-dashboard");
    return () => {
      SocketService.unsubscribe("kz-carriers-dashboard");
    };
  }, [userState.id]);

  useEffect(() => {
    SocketService.addCallback(
      "kz-carriers-dashboard",
      "kz-carriers-dashboard",
      reloadData
    );
    return () => {
      SocketService.removeCallback(
        "kz-carriers-dashboard",
        "kz-carriers-dashboard"
      );
    };
  }, []);

  const isData = getDataParams && getDataParams.data && getDataParams.data.data;

  if (!isData) {
    return <Fragment />;
  }

  const data : IData[] = getDataParams.data.data;

  return (
    <Fragment>
      <KzToolbarComponent title={t("Order for carrier (IK)")} data={getDataParams.data.data} />
      
      <div className={"row"}>
        <div className={"col-sm-12"}>
          <AviaLogisticsPlanning
            data={data.filter((c: IData) => c.status === 0)}
          />
        </div>

        <div className={"col-sm-12"}>
          <AviaLogisticsPlanned
            data={data.filter((c: IData) => c.status === 10)}
          />
        </div>

        <div className={"col-sm-12"}>
          <AviaLogisticsFormation
            data={data.filter((c: IData) => c.status === 15)}
          />
        </div>

        <div className={"col-sm-12"}>
          <AviaLogisticsOnRoad
            data={data.filter((c: IData) => c.status === 20)}
          />
        </div>

        <div className={"col-sm-12"}>
          <AviaLogisticsUnload
            data={data.filter((c: IData) => c.status === 30)}
          />
        </div>
      </div>
    </Fragment>
  );
}
